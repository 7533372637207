import {TOKEN_NAME} from '../constants';
import moment from "moment-timezone";

export const doClearLocalStorage = () => {
  localStorage.removeItem(TOKEN_NAME);
};

export const detectLanguage = () => {
  return localStorage.getItem('i18nextLng') || 'vi'
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const timeFormatter = (value, officeShift) => {
  if (!value) return '-';

  if (officeShift) {
    return moment
      .utc(new Date(value))
      .tz(officeShift)
      .format('MM/DD/YYYY');
  } else {
    const momentDate = moment(new Date(value), moment.ISO_8601);

    return momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : value;
  }
};

export const getTime = (date) => {
  const time = new Date(date)
  return time.getTime()
}

export const handleActionByRole = (actions, userRoles) => {
  if (!userRoles || userRoles.length < 0) return {}
  let newActions = {}
  Object.keys(actions).map(key => {
    if (actions[key] === true || userRoles.includes(actions[key])) {
      newActions[key] = actions[key]
    }
    return key
  })
  return newActions
}

export const handleDownloadFile = (imgUrl) => {
  fetch(imgUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/png',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      const fileName = imgUrl.split('/').pop()
      link.setAttribute(
        'download',
        fileName,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}

export const getFormattedDate = (date) => {
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    const hh = date.getHours()
    const mm = date.getMinutes()
    const ss = date.getSeconds()
    const timeStr = `${hh < 10 ? '0' + hh : hh}:${mm < 10 ? '0' + mm : mm}:${ss < 10 ? '0' + ss : ss}`
    return `${year}-${month}-${day} ${timeStr}`
}

export const saveByteArray = (fileName, byte) => {
    let binaryString = window.atob(byte);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    let blob = new Blob([bytes], {type: "application/pdf"});
    let link = URL.createObjectURL(blob);
    window.open(link, '_blank');
};
