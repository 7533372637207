import { TOKEN_NAME } from '../../../constants';

/**
 * Define a DateUtil that supports some functions to work with date time
 */
export default class TokenUtil {
    static buildCustomHeader() {
        const token = localStorage.getItem(TOKEN_NAME);
        return {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
        }
    }
}
