import React from 'react';
import PropTypes from 'prop-types';
import {Spin} from "antd";

const CustomSpin = ({children, spinning}) => {
  return (
    <>
      {spinning ? <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh'
      }}>
        <Spin />
      </div> : children}
    </>
  );
};

CustomSpin.propTypes = {

};

export default CustomSpin;
