import CharCustom from "../chartCustom";
import { useTranslation } from "react-i18next";
import {useEffect, useState} from "react";
import axiosService from "../../../../../services/axiosService";
import {CONFIG} from "../../../../../configs";
function Clients() {
  const [data, setData] = useState([])
  const { t } = useTranslation();
  useEffect(() => {
    getChartData()

    let id = setInterval( () => {
      getChartData()
    }, 60000);
    return () => clearInterval(id);
  }, [])

  const getChartData = async () => {
    const {data: dataApi} = await axiosService.get(`${CONFIG.API_URL.BASE}/api/client/count-type`)
    const result = (dataApi || []).map(chart => ({type: chart.clientType || '', count: chart.data}))
    setData(result)
    // console.log(result)
  }
  return (
    <div className="dashboard-tile-table-class">
      <div className="div_title_header" span={24}>
        <div className="tw-inline-flex">
          <img
            className="img_tilte"
            src="/images/client_management.png"
            align="top"
            alt=""
          />
          <strong>&nbsp;&nbsp;{t("DASHBOARD.clients")}</strong>
        </div>
        <div className="tw-flex-col tw-flex">
          {data.length > 0 ? (
            <>
              <div className="canvas-container-class" span={12}>
                <CharCustom data={data} />
              </div>
              <div className="tw-p-0.5">
                {/* <table className="table-standard-border tw-w-full">
                <thead>
                  <tr style={{ background: "#1C5B75", color: "#fff" }}>
                    <td width="45%">Type</td>
                    <td width="37%">Status</td>
                    <td width="10%">Count</td>
                  </tr>
                </thead>
                <tbody>{data.map((item, index) => getTr(item, index))}</tbody>
              </table> */}
              </div>
            </>
          ) : (
            <p className="vaild_err">This module is hidden/not in use.</p>
          )}
        </div>
      </div>
    </div>
  );
}
export default Clients;
