import React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { CheckAuthInLoginPage } from 'src/utils/checkAuth.utils';

const Login = () => {
    const isAuth = CheckAuthInLoginPage();
    if (isAuth === undefined) return null;
    return (
        <div className="login-wrap tw-h-screen">
            <ForgotPasswordForm />
        </div>
    );
};

export default Login;
