import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import Datatable from "../../../components/Datatable";
import Checkbox from "antd/lib/checkbox/Checkbox";
import FormSection from "../../../components/FormSection";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { columnTestAnalyteRun } from "../mock/configAnalyte";

const ResultEntriesForm = ({
  configData,
  actionRef,
  afterSubmit,
  ...props
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("TEST.FORM.sampleID"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("TEST.FORM.sampleIDExternal"),
      width: 200,
      dataIndex: "generalInformation.testName",
    },
    {
      title: t("TEST.FORM.sampleStattus"),
      width: 200,
      dataIndex: "generalInformation.testName",
    },
    {
      title: t("TEST.FORM.sampleType"),
      width: 200,
      dataIndex: "generalInformation.testName",
    },
    {
      title: t("TEST.FORM.associatedAnalytes"),
      width: 200,
      dataIndex: "generalInformation.testName",
    },
    {
      title: t("TEST.FORM.qcSample"),
      width: 200,
      dataIndex: "generalInformation.testName",
    },
  ];
  const data = [];
  const [visible, setVisible] = useState(false);
  const formRef = useRef();
  const [form] = Form.useForm();
  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setVisible(false);
    form.resetFields();
  };
  const handleOpen = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    // console.log(e);
  };

  const setFieldsValue = (values) => {
    form.setFieldsValue({
      testName: values["generalInformation.testName"],
      associatedSamples: values["generalInformation.associatedSamples"],
      createdBy: values["generalInformation.createdBy"],
      associatedInventories: values["generalInformation.associatedInventories"],
      description: values["generalInformation.description"],
      sampleIDExternal: values["generalInformation.sampleIDExternal"],
      completionDate: values["generalInformation.completionDate"],
      associatedSamplesExternalID:
        values["generalInformation.associatedSamplesExternalID"],
      createdOn: values["generalInformation.createdOn"],
      testIDExternal: values["generalInformation.testIDExternal"],
      group: values["generalInformation.group"],
      associatedInstruments: values["generalInformation.associatedInstruments"],
      code: values["generalInformation.code"],
      barcodeID: values["generalInformation.barcodeID"],
      method: values["generalInformation.method"],
      associatedKits: values["generalInformation.associatedKits"],

      technicianID: values["custodialInformation.technicianID"],
      reviewerID: values["custodialInformation.reviewerID"],
      technicianName: values["custodialInformation.technicianName"],

      remindBeforeTurnaroundTimeThreshold:
        values["turnaroundTimeAlarm.remindBeforeTurnaroundTimeThreshold"],

      processingNotes: values["testDetails.processingNotes"],
      rejectionCriteria: values["testDetails.rejectionCriteria"],
      containerType: values["testDetails.containerType"],
      patientPreparation: values["testDetails.patientPreparation"],
      synonyms: values["testDetails.synonyms"],
    });
  };

  useEffect(() => {
    const userAction = {
      close: handleClose,
      openResultEntries: handleOpen,
      setFieldsValue: setFieldsValue,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    // eslint-disable-next-line
  }, []);
  const toolbarExtra = () => {
    return (
      <>
        <Row gutter={[32, 8]}>
          <Form.Item label={t(`TEST.FORM.testID`)} labelAlign={"left"}>
            <Input />
          </Form.Item>
          <Form.Item label={t(`TEST.FORM.testType`)} labelAlign={"left"}>
            <Input />
          </Form.Item>
          <Form.Item label={t(`TEST.FORM.testGroup`)} labelAlign={"left"}>
            <Input />
          </Form.Item>
          <Form.Item label={t(`TEST.FORM.testCode`)} labelAlign={"left"}>
            <Input />
          </Form.Item>
        </Row>
      </>
    );
  };
  const toolbarExtraTestAnalyteRun = () => {
    return (
      <Row gutter={[32, 8]}>
        <Form.Item label={t(`TEST.FORM.testRuns`)} labelAlign={"left"}>
          <PlusCircleOutlined />
          <MinusCircleOutlined />
        </Form.Item>
        <Form.Item label={t(`TEST.FORM.freeze`)} labelAlign={"left"}>
          <Input />
        </Form.Item>
      </Row>
    );
  };
  return (
    <div>
      <Modal
        width={"100vw"}
        style={{ top: 20 }}
        title={t("Result Entries")}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        forceRender
      >
        <Datatable
          columns={columns}
          dataSource={data}
          rowKey={"generalInformation.testID"}
          autoFlatData={true}
          allowSelect={true}
          actionConfig={{}}
          toolbarExtra={toolbarExtra()}
        />
        <Row className="tw-mt-2">
          <Form.Item label={t(`TEST.FORM.flaggSets`)} labelAlign={"left"}>
            <Select showSearch placeholder={t("selectValue")}>
              <Select.Option value="dfsdf"></Select.Option>
            </Select>
          </Form.Item>
        </Row>
        <Row>
          <Checkbox>{t(`TEST.FORM.resultEntryCompleted`)}</Checkbox>
        </Row>
        <Row gutter={[32, 4]}>
          <Form.Item>
            <Checkbox>{t(`TEST.FORM.update`)}</Checkbox>
          </Form.Item>
          <Form.Item
            label={t(`TEST.FORM.quantityToBeDeducted`)}
            labelAlign={"left"}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t(`TEST.FORM.totalConsumed`)} labelAlign={"left"}>
            <Input />
          </Form.Item>
          <Form.Item labelAlign={"left"}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t(`SAMPLE.FORM.currentQuantity`)}
            labelAlign={"left"}
          >
            <Input />
          </Form.Item>
          <Form.Item labelAlign={"left"}>
            <Input />
          </Form.Item>
        </Row>
        <FormSection header={t(`TEST.FORM.testAnalyteRuns`)}>
          <Datatable
            columns={columnTestAnalyteRun(t)}
            dataSource={data}
            rowKey={"generalInformation.testID"}
            autoFlatData={true}
            allowSelect={true}
            actionConfig={{}}
            toolbarExtra={toolbarExtraTestAnalyteRun()}
          />
        </FormSection>
      </Modal>
    </div>
  );
};

ResultEntriesForm.propTypes = {
  actionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterSubmit: PropTypes.func,
};

export default ResultEntriesForm;
