import { CONFIG } from "../../configs";
import axiosService from "../../services/axiosService";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */
export const getListClient = () => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/client`);
};
export const apiAddClient = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/client`,
    data
  );
};

export const apiDeleteClient = async (ids) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/api/client?ids=${ids}`);
};

export const apiEditClient = async (id, data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/client/${id}`, data);
};
