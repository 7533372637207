import { takeLatest, call, put } from 'redux-saga/effects';
import {
    getList,
    getListSuccess,
    // getListFailure,
    doAddSample,
    doAddSampleSuccess,
    doDeleteSample,
    doAllDone, doDeleteSampleSuccess, doEditSample, doEditSampleSuccess
} from './slice';
import {apiAddSample, apiDeleteSample, apiEditSample, getListSample} from "src/apis/samples";
import {Samples} from "../../mockApi/sampleData";

function* fetchList({ payload }) {
    const { status, data } = yield call(getListSample, payload);
    if (status === 200) {
        yield put(getListSuccess(data));
    } else {
        yield put(doAllDone());
    }
}

function* addSample({ payload }) {
    const { status, data } = yield call(apiAddSample, payload);
    if (status === 200) {
        yield put(doAddSampleSuccess(data));
    } else {
        yield put(doAllDone());
    }
}

function* deleteSample({ payload }) {
    const { status } = yield call(apiDeleteSample, payload);
    if (status === 200) {
        yield put(doDeleteSampleSuccess(payload));
    } else {
        yield put(doAllDone());
    }
}

function* editSample({ payload }) {
    const { status, data } = yield call(apiEditSample, payload);
    if (status === 200) {
        const sample = new Samples(data)
        yield put(doEditSampleSuccess(sample));
    } else {
        yield put(doAllDone());
    }
}

function* samplesSaga() {
    yield takeLatest(getList.type, fetchList);
    yield takeLatest(doAddSample.type, addSample);
    yield takeLatest(doDeleteSample.type, deleteSample);
    yield takeLatest(doEditSample.type, editSample);
}
export default samplesSaga;
