import React, { useEffect, useState } from "react";
import { Col, Layout, Row, Drawer, Dropdown, Button, Menu, Modal, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, LogoutOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { REFRESH_TOKEN, TOKEN_NAME } from "../../constants";
import Sidebar from "../Sidebar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/user/selector";
import { changePassword } from "../../apis/login";

const HeaderWrapper = styled.div`
  .ant-drawer-title {
    text-align: center !important;
  }
  .ant-drawer-close {
    color: #fff;
  }
`;
const Header = () => {
  const { Header } = Layout;
  const [isShowDraw, setIsShowDraw] = useState(false);
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser)
  const logout = () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN);
    window.location.reload();
  };

  const [visible, setVisible] = useState(false);

  const [form, setForm] = useState({})
  const [showPassword, setShowPassword] = useState([false, false, false])

  const onClose = () => {
    setVisible(false);
    setForm({})
    setShowPassword([false, false, false])
  }

  const onSubmit = async () => {
    if (!form.OldPassword) {
      message.error("`Mật khẩu cũ` là trường bắt buộc!")
      return
    }
    if (!form.NewPassword) {
      message.error("`Mật khẩu mới` là trường bắt buộc!")
      return
    }
    if (!form.ConfirmNewPassword) {
      message.error("`Nhập lại mật khẩu` là trường bắt buộc!")
      return
    }
    if (form.NewPassword !== form.ConfirmNewPassword) {
      message.error("Nhập lại mật khẩu phải trùng với mật khẩu mới!")
      return
    }
    const { data } = await changePassword(form)
    if (data.status === 200) {
      message.success("Đổi mật khẩu thành công!")
      onClose();

    }
    else {
      message.error(data?.error?.message)
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key={"1"}>
        <div onClick={() => setVisible(true)}>Đổi mật khẩu</div>
      </Menu.Item>
      <Menu.Item key={"2"}>
        <div onClick={logout}>Đăng xuất</div>
      </Menu.Item>
    </Menu>
  );

  const onChange = (e) => {
    setForm(pre => {
      return { ...pre, [e.target.name]: e.target.value }
    })
  }

  const handleChangeShowPassword = (index) => {
    setShowPassword(pre => {
      const next = [...pre];
      next[index] = !next[index];
      return next;
    })
  }

  useEffect(() => {
    if (visible) {
      setShowPassword([false, false, false])
    }
  }, [visible])

  return (
    <HeaderWrapper>
      <Modal
        visible={visible}
        title="Đổi mật khẩu"
        onOk={onSubmit}
        onCancel={onClose}
        okText="Đồng ý"
        cancelText="Hủy"
      >
        <Row>
          <Col span={24} style={{ marginBottom: "1rem" }}>
            <Row>
              <Col span={12}>Tên đăng nhập</Col>
              <Col span={12}><Input value={currentUser.LoginInfomation.Username} disabled={true} /> </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: "1rem" }}>
            <Row>
              <Col span={12}>Mật khẩu cũ <span style={{ color: "red" }}>*</span></Col>
              <Col span={12}>
                <Input value={form.OldPassword} name="OldPassword" type={showPassword[0] ? "text" : "password"} iconRender={v => (v ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} onChange={onChange} style={{ paddingRight: "30px" }} />
                <span className="ant-input-suffix" style={{ position: "absolute", top: "9px", right: "11px" }}>{showPassword[0] ? <EyeTwoTone onClick={() => handleChangeShowPassword(0)} /> : <EyeInvisibleOutlined onClick={() => handleChangeShowPassword(0)} />}</span>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: "1rem" }}>
            <Row>
              <Col span={12}>Mật khẩu mới <span style={{ color: "red" }}>*</span></Col>
              <Col span={12}>
                <Input value={form.NewPassword} name="NewPassword" type={"password"} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} onChange={onChange} style={{ paddingRight: "30px" }} />
                <span className="ant-input-suffix" style={{ position: "absolute", top: "9px", right: "11px" }}>{showPassword[1] ? <EyeTwoTone onClick={() => handleChangeShowPassword(1)} /> : <EyeInvisibleOutlined onClick={() => handleChangeShowPassword(1)} />}</span>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: "1rem" }}>
            <Row>
              <Col span={12}>Nhập lại mật khẩu <span style={{ color: "red" }}>*</span></Col>
              <Col span={12}>
                <Input value={form.ConfirmNewPassword} name="ConfirmNewPassword" type={"password"} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} onChange={onChange} style={{ paddingRight: "30px" }} />
                <span className="ant-input-suffix" style={{ position: "absolute", top: "9px", right: "11px" }}>{showPassword[2] ? <EyeTwoTone onClick={() => handleChangeShowPassword(2)} /> : <EyeInvisibleOutlined onClick={() => handleChangeShowPassword(2)} />}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      <Header
        className="site-layout-background tw-bg-white"
        style={{ padding: 0, position: "fixed", zIndex: 99, width: "100%" }}
      >
        <Row>
          <Col>
            <div
              className="logo tw-flex tw-flex-row tw-justify-start tw-items-center"
              style={{
                height: "64px",
                padding: "5px",
                paddingLeft: "16px",
                textAlign: "center",
                backgroundColor: "#fff",
              }}
            >
              <div className="tw-pr-5">
                <MenuUnfoldOutlined
                  onClick={() => setIsShowDraw(!isShowDraw)}
                  style={{ verticalAlign: "middle", fontSize: "1.5rem" }}
                />
              </div>
              <div className="tw-text-white tw-text-2xl tw-h-full">
                <Link to={'/'}>
                  <img src="/logo.jpg" alt="" className={"tw-h-full"} />
                </Link>
              </div>
            </div>
          </Col>
          <Col flex="auto" className="tw-text-right tw-flex tw-justify-end">
            <div style={{ paddingRight: "16px" }}>
              <span>{currentUser.UserInfomation.FirstName} {currentUser.UserInfomation.LastName}</span>
              {/* <LogoutOutlined onClick={logout} className={'tw-ml-4'} /> */}
              <Dropdown overlay={menu} placement="bottomLeft" arrow>
                <LogoutOutlined className={'tw-ml-4'} />
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Drawer
          title={<div style={{ color: "#fff" }}>{t("MENU.category")}</div>}
          drawerStyle={{ overflowX: "hidden" }}
          headerStyle={{ height: 64, backgroundColor: "#109cf1" }}
          bodyStyle={{ padding: 0 }}
          placement="left"
          closable={true}
          onClose={() => setIsShowDraw(!isShowDraw)}
          visible={isShowDraw}
          className={"my-drawer"}
        // extra={
        //   <Space>
        //     <Button>Cancel</Button>
        //   </Space>
        // }
        >
          <Sidebar onChange={() => setIsShowDraw(!isShowDraw)} />
        </Drawer>
      </Header>
    </HeaderWrapper>
  );
};

Header.propTypes = {};

export default Header;
