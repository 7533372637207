// import axiosService from 'src/services/axiosService';
// import { CONFIG } from '../../configs';

import {sleep} from "../../utils/utils";
import {CLIENT_DATA, Client} from "../../mockApi/clientData";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */
export const getListClient = () => {
  let res = []
  for (let i = 0; i < 5; i++) {
    let item = new Client(CLIENT_DATA);
    item.clientID += i;
    res.push(item)
  }

  return {status: 200, data: res};
  // return axiosService.post(`${CONFIG.API_URL.BASE}/auth`, data)
};
export const apiAddClient = async (data) => {
  await sleep(1000);
  const res = new Client(data);
  return {status: 200, data: res};
  // return axiosService.post(`${CONFIG.API_URL.BASE}/auth`, data)
};

export const apiDeleteClient = async (data) => {
  await sleep(1000);
  return {status: 200, data: ''};
  // return axiosService.post(`${CONFIG.API_URL.BASE}/auth`, data)
};

export const apiEditClient = async (data) => {
  await sleep(1000);
  return {status: 200, data: data};
  // return axiosService.post(`${CONFIG.API_URL.BASE}/auth`, data)
};
