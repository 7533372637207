import demo from './demo/slice';
import login from './login/slice';
import user from './user/slice';
import permission from './permission/slice'
import sample from './samples/slice'
import test from './test/slice'
import client from './client/slice'

const rootReducer = {
    demo,
    login,
    user,
    permission,
    sample,
    test,
    client,
};

export default rootReducer;
