import { Col, Input, Modal, Row, Select, Button, message } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axiosService from 'src/services/axiosService';
import { addToCart, apiAddCart, apiDeleteCart, getListCart } from 'src/apis/card'
import { getUsersApi } from '../../apis/user';

const FormCard = ({
  actionRef,
  reset,
  data = {},
}) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const [visible, setVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [dataCard, setDataCard] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [record, setRecord] = useState({});
  const [newCartName, setNewCartName] = useState("");
  const [isAvailble, setisAvailble] = useState(false);
  const [form, setForm] = useState({
    card: '',
    cartResponsibleUserId: 0,
    cartRequestMessage: ""
  })

  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setVisible(false);
    setForm({})
  };
  const handleOpen = (records, isPool) => {
    // setIsPoolSample(isPool)
    // setData(records)
    const d = records?.[0] || {}
    setRecord(d)
    setForm({
      card: d.cartId,
      cartResponsibleUserId: d.cartResponsibleUserId || [],
      cartRequestMessage: d.cartRequestMessage
    })
    console.log(`form`, form)
    setisAvailble(d.cartId || false)
    setVisible(true);
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      open: handleOpen,
      close: handleClose,
    }

    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction)
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction
    }

    getList()
    return () => {
      setIsMounted(false);
    }
    // eslint-disable-next-line
  }, [])

  const getList = async () => {
    const { data } = await getListCart()
    data.map(d => {
      return { ...d, value: d.cartId, label: d.cartName }
    })
    setDataCard(data)
    const x = await getUsersApi({ "listApp": ["NT_Core", "Sample", "NT_Document", "UserManagament", "FormConfiguration"] })
    const dU = x.data.data.map(x => { return { ...x, value: x.IdUser, lable: x.Username } })
    setDataUser(dU);
  }

  const handleSubmit = async () => {
    if (isAvailble) {
      handleClose();
      return;
    }

    if (!form.card) {
      message.warning("Vui lòng chọn phân tích mẫu!")
      return;
    }

    if (form.cartResponsibleUserId?.length === 0) {
      message.warning("Vui lòng chọn mã nhân viên phụ trách!")
      return;
    }

    const { data, status } = await addToCart({
      "cartId": form.card,
      "cartRequestMessage": form.cartRequestMessage,
      "cartResponsibleUserId": form.cartResponsibleUserId,
      "sampleId": record.sampleId,
      // "staffInCharge": [(dataUser.find(x => x.IdUser == form.cartResponsibleUserId) || {})?.Username]
      "staffInCharge": form.cartResponsibleUserId.map(id => (dataUser.find(x => x.IdUser == id) || {})?.Username)
    })

    if (status === 400) {
      message.warning(data.message)
    }
    handleClose();
    reset();
  }

  const handleChange = (r) => {
    setForm(x => { return { ...x, card: r } })
  }

  const handleChangeUser = (r) => {
    setForm(x => { return { ...x, cartResponsibleUserId: r } })
  }

  useEffect(() => {
    if (isCreate && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCreate])

  const createCard = async () => {
    const { data } = await apiAddCart({
      cartName: newCartName
    })
    setNewCartName("")
    await getList()
    setForm(x => { return { ...x, card: data.cartId } })
  }

  const handleDeleteTestCode = async (name) => {
    await apiDeleteCart(name)
    await getList();
  }

  return (
    <div>
      <Modal
        centered={true}
        title={t('TOOLBAR.MoveToCard')}
        visible={visible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        cancelButtonProps={isAvailble ? { style: { display: "none" } } : {}}
        okText={isAvailble ? t("BUTTON.close") : t("BUTTON.save")}
        cancelText={"Hủy bỏ"}
        okButtonProps={{ htmlType: "submit" }}
        width={1000}
        forceRender
      >
        <Row>
          <Col span="12" style={{ marginBottom: "2rem" }}>
            <Row>
              <Col span="6">
                Phân tích mẫu <span style={{ color: "red" }}>*</span>
              </Col>
              <Col span="10">
                {
                  !isCreate ?
                    <Select disabled={isAvailble} value={form.card} style={{ width: 120 }} onChange={handleChange}>
                      {/* {
                        dataCard.map(d => <Select.Option key={d.cartId} value={d.cartId}>{d.cartName}</Select.Option>)
                      } */}
                      {
                        dataCard.map(d => <Select.Option style={{ position: "relative" }} key={d.cartId} value={d.cartId}>{d.cartName} {<p style={{ position: "absolute", top: 5, right: 5, cursor: "pointer" }} onClick={e => {
                          e.stopPropagation()
                          handleDeleteTestCode(d.cartId);
                        }}><DeleteOutlined /></p>}</Select.Option>)
                      }
                    </Select> :
                    <Input
                      ref={inputRef}
                      value={newCartName}
                      onChange={(e) => setNewCartName(e.target.value)}
                      onKeyPress={(e => {
                        if (e.key === 'Enter') {
                          if (!(newCartName)) {
                            message.warning("Tên phân tích mẫu không được để trống")
                            return
                          }
                          createCard()
                          setIsCreate(false);
                        }
                      })}
                    />
                }
              </Col>
              <Col span="4" style={{ paddingLeft: "0.5rem" }}>
                {!isCreate ?
                  <PlusOutlined style={{ cursor: "pointer", paddingTop: "0.5rem", color: "#1890ff" }} color="primary" onClick={() => {
                    setIsCreate(true);
                  }} /> :
                  <Button type="primary" onClick={() => {
                    if (!(newCartName)) {
                      message.warning("Tên phân tích mẫu không được để trống")
                      return
                    }
                    createCard()
                    setIsCreate(false);
                  }}>Lưu</Button>
                }
              </Col>
              {
                isCreate && <Col span="4" style={{ paddingLeft: "0.5rem" }}>
                  <Button type="primary" onClick={() => {
                    setIsCreate(false);
                  }}>Hủy</Button>
                </Col>
              }
            </Row>
          </Col>
          <Col span="12" style={{ marginBottom: "2rem" }}>
            <Row>
              <Col span="10">
                Mã nhân viên phụ trách <span style={{ color: "red" }}>*</span>
              </Col>
              <Col span="12">
                {/* <Input
                  value={form.cartResponsibleUserId}
                  onChange={e => setForm(x => { return { ...x, cartResponsibleUserId: e.target.value } })}
                /> */}
                <Select mode="multiple" disabled={isAvailble} value={form.cartResponsibleUserId} style={{ width: 120 }} onChange={handleChangeUser}>
                  {
                    dataUser.map(d => <Select.Option key={d.IdUser} value={d.IdUser}>{d.Username}</Select.Option>)
                  }
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span="12" style={{ marginBottom: "2rem" }}>
            <Row>
              <Col span="6">
                Nội dung yêu cầu
              </Col>
              <Col span="10">
                <Input
                  value={form.cartRequestMessage}
                  onChange={e => setForm(x => { return { ...x, cartRequestMessage: e.target.value } })}
                  disabled={isAvailble}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default FormCard;