import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, message, Modal } from "antd";
import { useSelector } from "react-redux";
import { formStatus } from "src/redux/samples/selector";
import { useTranslation } from "react-i18next";
import SectionRender from "./components/sectionRenderr";
import { apiAddSample, apiEditSample } from "../../apis/samples";
import moment from "moment-timezone";
import SamplerInformation from "./SamplerInformation";
import NotifiModal from "./components/NotifiModal";

const initialValueForm = {
  // sampleIdExternal: ''
  currentQuantityUnit: "ml",
  packedSizeUnit: "l",
};
// const imageDefault = [
//   {
//     uid: "-3",
//     name: "image.png",
//     status: "done",
//     url:
//       "https://www.pharmaceutical-technology.com/wp-content/uploads/sites/10/2020/10/Feature-image-top-ten-pharma-companies-in-2020.jpg",
//   },
// ];

const hiddenInputs = ["sampleId"];
const requireFields = ["custodianName", "samplerInformation", "sampleName", "dosageForm", "registerNumber", "currentQuantity", "productionDate", "expirationDate", "receivedDate", "chronicleBatchDate", "samplerName"]

const configSubstanceInformation = {
  substanceName: {
    default: true,
    display: true,
    type: "textbox",
  },
  substanceType: {
    default: true,
    display: true,
    type: "dropdown",
    data: ["Tân dược", "Đông dược", "Dược liệu", "Mỹ phẩm", "Vị thuốc cổ truyền"],
  },
  substanceContent: {
    default: true,
    display: true,
    type: "number",
  },
  substanceUnit: {
    default: true,
    display: true,
    type: "dropdown",
    data: ["ml", "ul (microlit)", "l", "g", "mg", "microgram (ug)", "kg", "ppm", "bbm", "mmol/L", "mol/L", "nmol/L", "pmol/L", "ng/g", "%volume", "CFU", "CFU_GM", "CFU/10ml", "CFU/100ml/ CFU/15ml", "CFU/300ml", "CFU/50ml", "m", "cm", "dm", "g/L", "mg/L", "kg/L", "mg/ml", "g/100g", "%", "cái", "bịch", "gói", "viên", "chai", "lọ", "vỉ", "hũ", "ống", "lô", "con", "hộp", "IU", "10^8 CFU", "10^9 CFU", "tuýt","Tuýp"]
  },
  drugNameRelatedDocs: {
    default: true,
    display: true,
    type: "textbox",
  },
  action: {
    default: true,
    display: true,
    type: "action",
  },
}

const SampleForm = ({
  templateId,
  configData,
  actionRef,
  afterSubmit,
  ...props
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const modalSpecifyRef = useRef();
  const modalNotifiRef = useRef();
  const [form] = Form.useForm();
  const [isMounted, setIsMounted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [editData, setEditData] = useState(null);
  const [hiddenData, setHiddenData] = useState({});
  const { submitting } = useSelector(formStatus);
  const [ishowDrug, setIsShowDrug] = useState(false);
  const [configDt, setConfigDt] = useState([]);
  const [fieldNameSearchUser, setFieldNameSearchUser] = useState(null);
  const [dataSave, setDataSave] = useState({});
  const [id, setId] = useState();
  const [documentId, setDocumentId] = useState(null);

  const [positionSample, setPositionSample] = useState({});
  const [isClearSelectSamplerInforRows, setIsClearSelectSamplerInforRows] = useState(false);

  useEffect(() => {
    if (configData?.configData?.config) {
      const data = configData?.configData?.config;
      setConfigDt(data);
    }
  }, [configData]);
  useEffect(() => {
    if (!isEdit) {
      const data = configData?.configData?.config;
      setConfigDt(data);
    }
  }, [isEdit]);
  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setIsEdit(false);
    // setEditData(null);
    setIsClearSelectSamplerInforRows(!isClearSelectSamplerInforRows);
    setVisible(false);
    form.resetFields();
  };
  const handleOpen = (config) => {
    let configTemp = [...config.configData.config];
    let item = configTemp.findIndex(
      (item) => item.sectionName === "substanceInformation"
    );
    configTemp[item] = {
      display: true,
      sectionName: "substanceInformation",
      attribute: [configSubstanceInformation]
      ,
    };
    setConfigDt(configTemp);
    setVisible(true);
    setIsShowDrug(false)
    let value = form.getFieldsValue();

    form.setFieldsValue({
      ...value,
      substanceInformation: [{}],
    });
  };

  const setFieldsValue = (v, config) => {
    let values = { ...v }
    setIsShowDrug(values["importDrug"] || false)
    setDocumentId(values["documentId"])
    setId(values["sampleId"]);
    Object.keys(values).map((key) => {
      if (key == "position") {
        const array = (values[key] || "").split(",");
        if (array.length === 2) {
          setPositionSample({
            latitude: parseFloat(array[0]),
            longitude: parseFloat(array[1]),
          });
        }
      }
      if (hiddenInputs.includes(key)) {
        setHiddenData((prev) => ({
          ...prev,
          [key]: values[key],
        }));
      }
      for (let item of config.configData.config) {
        for (const [key, value] of Object.entries(item.attribute)) {
          if (value.type === "date") {
            values[key] = values[key] ? moment(values[key]) : undefined;
          }
        }
      }
      return key;
    });
    setIsEdit(true);
    form.setFieldsValue(values);
    let configTemp = [...config.configData.config];
    if (
      Array.isArray(values.samplerInformation) &&
      values.samplerInformation.length > 0
    ) {
      let datas = [];
      let configSampler = {};
      values.samplerInformation.map((item, index) => {
        Object.keys(item).map((items, idx) => {
          configSampler[`${items}`] = {
            default: true,
            display: true,
            type: "textbox",
          };
        });
        datas.push(configSampler);
      });
      let item = configTemp.findIndex(
        (item) => item.sectionName === "samplerInformation"
      );
      configTemp[item] = {
        display: true,
        sectionName: "samplerInformation",
        attribute:
          datas.length > 0
            ? datas
            : [
              {
                samplerName: {
                  default: true,
                  display: true,
                  type: "textbox",
                },
                samplerId: {
                  default: true,
                  display: true,
                  type: "textbox",
                },
                samplerPosition: {
                  default: true,
                  display: true,
                  type: "textbox",
                },
                samplerIdCard: {
                  default: true,
                  display: true,
                  type: "textbox",
                },
                samplerDepartment: {
                  default: true,
                  display: true,
                  type: "textbox",
                },
              },
            ],
      };
    }
    if (
      Array.isArray(values.substanceInformation) &&
      values.substanceInformation.length > 0
    ) {
      let datas = [];
      let configSampler = {};
      values.substanceInformation.map((item, index) => {
        Object.keys(item).map((items, idx) => {
          configSampler[`${items}`] = {
            default: true,
            display: true,
            type: "textbox",
          };
        });
        datas.push(configSubstanceInformation);
      });
      let item = configTemp.findIndex(
        (item) => item.sectionName === "substanceInformation"
      );
      configTemp[item] = {
        display: true,
        sectionName: "substanceInformation",
        attribute:
          datas.length > 0
            ? datas
            : [
              configSubstanceInformation,
            ],
      };
    }
    setConfigDt(configTemp);
    onSelect(values.samplerInformation);
  };

  const onUpload = (val) => {
    const { fieldName, path } = val;
    form.setFieldsValue({
      [fieldName]: path,
    });
  };

  const onFinish = async (data) => {
    console.log(`data`, data)
    /*
      Validate
    */
    const { fromDate, issuedDate, planningDate, toDate, custodianName, samplerInformation, sampleName, dosageForm, registerNumber, currentQuantity, unit, productionDate, expirationDate, receivedDate, chronicleBatchDate } = data;

    for (let i in data) {
      if (requireFields.includes(i) && !data[i]) {
        message.error(`${t(`SAMPLE.FORM.${i}`)} là trường bắt buộc!`)
        return;
      }
    }

    // if (!custodianName && !samplerInformation && !sampleName && !dosageForm && !registerNumber && !currentQuantity && !unit && !productionDate && !expirationDate && !receivedDate && !chronicleBatchDate) {
    //   message.error("Vui lòng nhập các trường bắt buộc!")
    //   return;
    // }

    if (issuedDate && planningDate && issuedDate < planningDate) {
      message.error("`Ngày lập kế hoạch` phải trước `Ngày ban hành`")
      return;
    }
    if (fromDate && toDate && toDate < fromDate) {
      message.error("Trường `Đến ngày` phải trước trường `Từ ngày`")
      return;
    }

    // return
    if (!isEdit) {
      setDataSave(data);
      modalNotifiRef.current.openNotifiModal();
    } else {
      data.position = positionSample.latitude
        ? `${positionSample.latitude},${positionSample.longitude}`
        : "";
      let result = {};
      configDt.map((item) => {
        const child = {};
        if (Array.isArray(item.attribute)) {
          if (item.sectionName === "samplerInformation") {
            result[item.sectionName] = form.getFieldValue("samplerInformation");
          }
          if (item.sectionName === "substanceInformation") {
            result[item.sectionName] = form.getFieldValue("substanceInformation");
          }
        } else if (item.sectionName === "substanceInformation") {
          result[item.sectionName] = form.getFieldValue("substanceInformation");
        } else {
          Object.keys(item.attribute).map((key) => {
            if (hiddenData[key]) {
              return (child[key] = hiddenData[key]);
            }
            if (item.attribute[key].type.toUpperCase() === "number") {
              return (child[key] = parseInt(data[key]));
            }
            if (data[key]) {
              return (child[key] = data[key]);
            }
            if (item.sectionName !== "samplerInformation")
              child[key] = null;
            // if (item.sectionName !== "substanceInformation")
            //   child[key] = null;
            return key;
          });
          if (Object.keys(child).length > 0) {
            result[item.sectionName] = child;
          }
        }
        result.position = data.position;
        return result;
      });

      if (result["generalInformation"]) {
        result["generalInformation"]["importDrug"] = ishowDrug || false;
      }
      if (documentId && result["generalInformation"])
        result["generalInformation"]["documentId"] = documentId

      if (isEdit) {
        result["generalInformation"]["sampleId"] = id
        const res = await apiEditSample(result);
        if (res.status === 200) {
          message.success(t("SAMPLE.MESSAGE.editSampleSuccess"));
          handleClose();
          afterSubmit && afterSubmit();
        } else {
          message.error(res?.data?.message || t("SAMPLE.MESSAGE.editSampleFailed"));
        }
      } else {
        const res = await apiAddSample(templateId, result);
        if (res.status === 201) {
          message.success(t("SAMPLE.MESSAGE.addSampleSuccess"));
          handleClose();
          afterSubmit();
        } else {
          message.error(res?.data?.message || t("SAMPLE.MESSAGE.addSampleFailed"));
        }
      }
    }
  };

  useEffect(() => {
    if (!isMounted) return;
    if (!submitting) {
      setVisible(false);
      form.resetFields();
      if (afterSubmit) {
        afterSubmit();
      }
    }
    // eslint-disable-next-line
  }, [submitting]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      close: handleClose,
      open: handleOpen,
      setFieldsValue: setFieldsValue,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line
  }, []);
  const changeValue = (value, all) => {
    if (Object.keys(value)[0] == "importDrug") {
      setIsShowDrug(value.importDrug);
    }
  };
  const onSearch = () => {
    setFieldNameSearchUser("samplerName");
    modalSpecifyRef.current.openSamplerModal();
  };
  const onSearchCustodianName = () => {
    setFieldNameSearchUser("custodianName");
    modalSpecifyRef.current.openSamplerModal();
  };
  const onSelect = (data = []) => {
    if (data.length === 0) return
    if (fieldNameSearchUser === "samplerName") {
      let dataConvert = [];
      for (let item of data) {
        dataConvert.push({
          samplerName: item.Username,
          samplerId: item.MaUser,
          samplerPosition: item.Position,
          samplerIdCard: item.CMND,
          samplerDepartment: item.Position,
        });
      }
      let configSampler = {};
      let datas = [];
      if (dataConvert.length > 0) {
        dataConvert.map((item, index) => {
          Object.keys(item).map((items, idx) => {
            configSampler[`${items}`] = {
              default: true,
              display: true,
              type: "textbox",
            };
          });
          datas.push(configSampler);
        });
        let configTemp = [...configDt];
        let item = configTemp.findIndex(
          (item) => item.sectionName === "samplerInformation"
        );
        configTemp[item] = {
          display: true,
          sectionName: "samplerInformation",
          attribute:
            datas.length > 0
              ? datas
              : [
                {
                  samplerName: {
                    default: true,
                    display: true,
                    type: "textbox",
                  },
                  samplerId: {
                    default: true,
                    display: true,
                    type: "textbox",
                  },
                  samplerPosition: {
                    default: true,
                    display: true,
                    type: "textbox",
                  },
                  samplerIdCard: {
                    default: true,
                    display: true,
                    type: "textbox",
                  },
                  samplerDepartment: {
                    default: true,
                    display: true,
                    type: "textbox",
                  },
                },
              ],
        };
        let value = form.getFieldsValue();

        form.setFieldsValue({
          ...value,
          samplerInformation: data,
        });
        setConfigDt(configTemp);
      }
    }
    if (fieldNameSearchUser === "custodianName") {
      let configTemp = [...configDt];
      let item = configTemp.findIndex(
        (item) => item.sectionName === "custodianInformation"
      );
      configTemp[item].attribute.custodianUserId = {
        default: true,
        display: true,
        type: "textbox",
      };
      let value = form.getFieldsValue();
      form.setFieldsValue({
        ...value,
        custodianUserId: data[0].samplerUserId,
        custodianPosition: data[0].samplerPosition,
        custodianId: data[0].samplerId,
        custodianName: data[0].samplerName,
        custodian: data[0].samplerDepartment,
      });
      setConfigDt(configTemp);
    }
  };
  const setIsSaveContinues = async (isCheckContinue) => {
    let data = { ...dataSave };
    if (isCheckContinue) {
      data.position = positionSample.latitude
        ? `${positionSample.latitude},${positionSample.longitude}`
        : "";
      let result = {};
      configDt.map((item) => {
        const child = {};
        if (Array.isArray(item.attribute)) {
          if (item.sectionName === "samplerInformation") {
            result[item.sectionName] = form.getFieldValue("samplerInformation");
          }
          if (item.sectionName === "substanceInformation") {
            result[item.sectionName] = form.getFieldValue("substanceInformation");
          }
        } else {
          Object.keys(item.attribute).map((key) => {
            if (hiddenData[key]) {
              return (child[key] = hiddenData[key]);
            }
            if (item.attribute[key].type.toUpperCase() === "number") {
              return (child[key] = parseInt(data[key]));
            }
            if (data[key]) {
              return (child[key] = data[key]);
            }
            return key;
          });
          if (Object.keys(child).length > 0) {
            result[item.sectionName] = child;
          }
        }
        result.position = data.position;
        return result;
      });
      // result["substanceInformation"] = [result["substanceInformation"]]
      if (documentId && result["generalInformation"])
        result["generalInformation"]["documentId"] = documentId
      const res = await apiAddSample(templateId, result);
      if (res.status === 201) {
        message.success(t("SAMPLE.MESSAGE.addSampleSuccess"));
        // handleClose();
        let resetData = {};
        afterSubmit();
        let val = Object.keys(result);
        for (let item of val) {
          if (
            item === "substanceInformation" ||
            item === "generalInformation" ||
            item === "quantityInformation" ||
            item === "producerInformation" ||
            item === "chronicleInformation" ||
            item === "custodianInformation"
          ) {
            if (result[item])
              for (let ite of Object.keys(result[item])) {
                resetData[ite] = undefined;
              }
          }

          if (item === "substanceInformation") {
            if (Array.isArray(result[item])) {
              let temp = []
              for (let index in result[item])
                if (result[item][index])
                  temp.push({})
              resetData[item] = temp
            }
          }
        }
        let values = form.getFieldsValue();
        form.setFieldsValue({ ...values, ...resetData });
      } else {
        message.error(t("SAMPLE.MESSAGE.addSampleFailed"));
      }
    } else {
      data.position = positionSample.latitude
        ? `${positionSample.latitude},${positionSample.longitude}`
        : "";
      let result = {};
      configDt.map((item) => {
        const child = {};
        if (Array.isArray(item.attribute)) {
          if (item.sectionName === "samplerInformation") {
            result[item.sectionName] = form.getFieldValue("samplerInformation");
          }
          if (item.sectionName === "substanceInformation") {
            result[item.sectionName] = form.getFieldValue("substanceInformation");
          }
        } else {
          Object.keys(item.attribute).map((key) => {
            if (hiddenData[key]) {
              return (child[key] = hiddenData[key]);
            }
            if (item.attribute[key].type.toUpperCase() === "number") {
              return (child[key] = parseInt(data[key]));
            }
            if (data[key]) {
              return (child[key] = data[key]);
            }
            return key;
          });
          if (Object.keys(child).length > 0) {
            result[item.sectionName] = child;
          }
        }
        result.position = data.position;
        return result;
      });
      if (documentId && result["generalInformation"])
        result["generalInformation"]["documentId"] = documentId
      const res = await apiAddSample(templateId, result);
      if (res.status === 201) {
        message.success(t("SAMPLE.MESSAGE.addSampleSuccess"));
        handleClose();
        afterSubmit();
      } else {
        message.error(t("SAMPLE.MESSAGE.addSampleFailed"));
      }
    }
  };

  const setConfigFirst = (isFirst = true) => {
    let configTemp = [...configDt];
    let item = configTemp.findIndex(
      (item) => item.sectionName === "substanceInformation"
    );
    configTemp[item] = {
      display: true,
      sectionName: "substanceInformation",
      attribute: !isFirst ? [...configTemp[item].attribute, configSubstanceInformation] : [
        configSubstanceInformation, configSubstanceInformation
      ],
    };
    let value = form.getFieldsValue();
    let temp =[...( value["substanceInformation"] || [{}])]
    temp.push({})

    // form.setFieldsValue({
    //   ...value,
    //   // substanceInformation: !isFirst ? [(value["substanceInformation"] || {}), {}] : [value["substanceInformation"] || {}, {}],
    //   substanceInformation: !isFirst ? [(value["substanceInformation"] || {}), {}] : [value["substanceInformation"] || {}, {}],
    // });
    setConfigDt(configTemp);
  }

  const deleteConfig = (index) => {
    let configTemp = [...configDt];
    let item = configTemp.findIndex(
      (item) => item.sectionName === "substanceInformation"
    );
    let value = form.getFieldsValue();
    configTemp[item].attribute.splice(index, 1)
    configTemp[item] = {
      display: true,
      sectionName: "substanceInformation",
      attribute: configTemp[item].attribute,
    };

    value["substanceInformation"].splice(index, 1)

    form.setFieldsValue({
      ...value,
      substanceInformation: value["substanceInformation"],
    });
    setConfigDt(configTemp);
  }

  const handleChangeForm = (key, v) => {
    let value = form.getFieldsValue();
    if (key === "documentId") setDocumentId(v)
    form.setFieldsValue({
      ...value,
      [key]: v,
    });
  }

  return (
    <div>
      <Modal
        centered={true}
        title={
          isEdit ? t("DASHBOARD.edit_sample") : t("DASHBOARD.add_new_sample")
        }
        visible={visible}
        onOk={handleOk}
        confirmLoading={submitting}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        width={1000}
        forceRender
      >
        <Form
          ref={formRef}
          form={form}
          name="basic"
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          initialValues={initialValueForm}
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={(value, allvalue) => changeValue(value, allvalue)}
        >
          {configDt?.map((item, idx) => (
            <SectionRender
              key={idx}
              item={item}
              ishowDrug={ishowDrug}
              hiddenInputs={hiddenInputs}
              onUpload={onUpload}
              onSearch={onSearch}
              onSearchCustodianName={onSearchCustodianName}
              positionSample={positionSample}
              setPositionSample={setPositionSample}
              setConfigFirst={setConfigFirst}
              deleteConfig={deleteConfig}
              handleChangeForm={handleChangeForm}
            />
          ))}
        </Form>
      </Modal>
      <SamplerInformation
        fieldNameSearchUser={fieldNameSearchUser}
        actionRef={modalSpecifyRef}
        onSelectItem={onSelect}
        isClearSelectSamplerInforRows={isClearSelectSamplerInforRows}
      />
      <NotifiModal
        actionRef={modalNotifiRef}
        setIsSaveContinue={setIsSaveContinues}
      />
    </div>
  );
};

SampleForm.propTypes = {
  actionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterSubmit: PropTypes.func,
};

export default SampleForm;
