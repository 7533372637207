import { takeLatest, call, put } from 'redux-saga/effects';
import { loginApi } from '../../apis/login';
import { loginFail, updateToken, doLogin, updateUser } from './slice';
import {doUpdateRole} from "../permission/slice";

function* login({ payload }) {
    const { status, data, message } = yield call(loginApi, payload);
    if (status === 200) {
        yield put(updateUser(data.data));
        yield put(updateToken(data.data.access_token));
        // yield put(doUpdateRole(data?.data?.profile?.roles || []))
    } else {
        yield put(loginFail(message));
    }
}
function* loginSaga() {
    yield takeLatest(doLogin.type, login);
}
export default loginSaga;
