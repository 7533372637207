import axiosService from 'src/services/axiosService';
import { CONFIG } from '../../configs';
import {Auth, USER_ADMIN, USER_CLIENT, USER_SAMPLE, USER_TASK, USER_TEST} from "../../mockApi/auth";
import {sleep} from "../../utils/utils";
import {REFRESH_TOKEN, TOKEN_NAME} from "../../constants";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */

const switchAccount = (data) => {
  if (!['admin_sample', 'admin_test', 'admin_client', 'admin_task', 'admin'].includes(data.password)) return {status: 401, message: 'username or password invalid'}
  let user = null
  switch (data.username) {
    case 'admin_task':
      user = USER_TASK;
      break;
    case 'admin_sample':
      user = USER_SAMPLE;
      break;
    case 'admin_test':
      user = USER_TEST;
      break;
    case 'admin_client':
      user = USER_CLIENT;
      break;
    case 'admin':
      user = USER_ADMIN;
      break;
    default:
      break;
  }
  if (!user) return {status: 401, message: 'username or password invalid'}

  const res = new Auth(user)
  return res.login()
}
export const loginApi = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/api/user/login`, data)
};


export const refreshTokenApi = async () => {
  const token = localStorage.getItem(TOKEN_NAME)
  const tokenRefresh = localStorage.getItem(REFRESH_TOKEN) || ''
  return axiosService.get(`${CONFIG.API_URL.AUTH}/api/user/refresh_token?refresh_token=${tokenRefresh}`,'', {
    Authorization: `Bearer ${token}`,
  })
}

export const forgotPasswordApi = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/api/user/forgot_password`, data)
};

export const confirmForgotPasswordApi = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/api/user/change_otp_password`, data)
};

export const changePassword = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.AUTH}/api/user/change_password`, data)
};
