import React, { useContext, createContext, useEffect, useState } from 'react';
import { getTokenInfo } from 'src/utils/auth.utils';
import { selectToken } from 'src/redux/login/selector';
import { useSelector, useDispatch } from 'react-redux';
import { TOKEN_NAME } from 'src/constants';
import { updateToken } from 'src/redux/login/slice';

const authContext = createContext();

export const useAuthProvide = () => {
    const token = useSelector(selectToken);
    const [userInfo, setUserInfo] = useState();
    const [isAuth, setIsAuth] = useState();

    useEffect(() => {
        const user = getTokenInfo(token);
        setUserInfo(user);
        setIsAuth(!!user);
    }, [token]);

    return {
        userInfo,
        isAuth,
    };
};

export const HandleLocalStorageChange = () => {
    const dispath = useDispatch();
    window.addEventListener('storage', (e) => {
        // console.log('on storage removed', e);
        const { key, newValue } = e;
        if (key === TOKEN_NAME) {
            dispath(updateToken(newValue));
        }
    });
};

export const AuthProvider = ({ children }) => {
    // HandleLocalStorageChange();
    const auth = useAuthProvide();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);
