import React from "react";
import Storage from "./component/storage";
import Subject from "./component/subject";
import Alerts from "./component/alert";
import Sample from "./component/sample";
import Test from "./component/test";
import PackageShipment from "./component/packageShipment";
import LabIventory from "./component/labInventory";
import Studies from "./component/studies";
import ServiesBilling from "./component/servicesBilling";
import Clients from "./component/clients";
import Kits from "./component/kits";
import InstrucmentCalib from "./component/instrucmentCalib";
const DashboardDetail = () => {
  return (
    <div className="tw-border-solid tw-overflow-scroll tw-border-2 tw-border-light-blue-500">
      <div id="dashboard-container">
        {/*<div className="dashboard-tile-div normal-size-tile">
          <Subject />
        </div>
        <div className="dashboard-tile-div normal-size-tile">
          <Storage />
        </div>*/}
        <div className="dashboard-tile-div double-size-tile">
          <Test />
        </div>
        <div className="dashboard-tile-div normal-size-tile">
          <Clients />
        </div>
        <div className="dashboard-tile-div double-size-tile">
          <Alerts />
        </div>
        <div className="dashboard-tile-div double-size-tile">
          <Sample />
        </div>
        {/*<div className="dashboard-tile-div normal-size-tile">
          <PackageShipment />
        </div>
        <div className="dashboard-tile-div normal-size-tile">
          <LabIventory />
        </div>
        <div className="dashboard-tile-div normal-size-tile">
          <Studies />
        </div>
        <div className="dashboard-tile-div double-size-tile">
          <ServiesBilling />
        </div>
        <div className="dashboard-tile-div normal-size-tile">
          <Kits />
        </div>
        <div className="dashboard-tile-div double-size-tile">
          <InstrucmentCalib />
        </div>*/}
      </div>
    </div>
  );
};
export default DashboardDetail;
