import React, { useState, useEffect } from "react";
import { Modal, Button, Divider, Row, Col, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { downloadTemplate } from "../../apis/samples";

const UploadTemplate = (props) => {
  const { t } = useTranslation();
  const { isModalVisible, handleCancel, onSelectFile, uploadFile } = props;
  const [fileSelect, setFileSelect] = useState(null);
  const onChangeChooseFile = (file) => {
    setFileSelect(() => file);
  };
  const downloadTemp = () => {
    downloadTemplate()
      .then((result) => {})
      .catch((err) => {
        // console.log("err", err);
      });
  };
  useEffect(() => {
    if (!isModalVisible) {
      setFileSelect(null);
    }
  }, [isModalVisible]);
  const closeModal = () => {
    setFileSelect(null);
    handleCancel();
  };
  return (
    <Modal
      title={t("ACTIONS.upload_template")}
      visible={props.isModalVisible}
      onOk={() => uploadFile(fileSelect)}
      onCancel={closeModal}
    >
      <>
        <Row>
          <Col span={12}>
            <Button
              onClick={downloadTemp}
              type="primary"
              icon={<DownloadOutlined />}
            >
              {t("ACTIONS.download_template")}
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Upload
            multiple={false}
            // onChange={onChangeChooseFile}
            accept=".xlsx"
            fileList={fileSelect ? [fileSelect] : []}
            beforeUpload={(file) => onChangeChooseFile(file)}
            // {...props}
          >
            <Button icon={<UploadOutlined />}>
              {t("ACTIONS.select_file")}
            </Button>
          </Upload>
        </Row>
      </>
    </Modal>
  );
};
export default UploadTemplate;
