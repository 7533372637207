import { createSlice } from '@reduxjs/toolkit';

export const MODULE_NAME = 'user';

const initialState = {
  userList: [],
  user: null,
  flag_delete: false,
  userAuth: {},
  loading: false,
};

const slice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    doCreate: (state, action) => {

    },
    doGet: (state, action) => {

    },
    doGetSuccess: (state, action) => {
      state.user = action.payload;
    },
    doUpdate: (state, action) => {

    },
    doDelete: (state, action) => {

    },
    doGetUserAuth: (state, action) => {

    },

    doGetListUser: (state, action) => {
      state.loading = true;
    },
    doGetListUserSuccess: (state, action) => {
      state.userList = [...action.payload];
    },
    doClearItem: (state, action) => {
      state.user = null;
    },
    doChangeFlagDelete: (state, action) => {
      state.flag_delete = action.payload;
    },
    getUserAuthSuccess: (state, action) => {
      state.userAuth = action.payload;
    },

  },
});

export const {
  doGetListUserSuccess,
  doGetSuccess,
  doChangeFlagDelete,
  getUserAuthSuccess,
  doGetListUser,
  doCreate,
  doGet,
  doUpdate,
  doDelete,
  doGetUserAuth,
} = slice.actions;

export default slice.reducer;
