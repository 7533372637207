import React, { useEffect, useRef, useState } from 'react';
import Datatable from "../../components/Datatable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectClients } from "../../redux/client/selector";
import { doDeleteClient } from "../../redux/client/slice";
import ClientForm from "../Client/clientForm";
import { apiDeleteClient, getListClient } from '../../apis/clients';

const Client = () => {
  const { t } = useTranslation()
  const columns = [
    {
      title: t('CLIENT.First_Name'),
      width: 200,
      dataIndex: 'firstName',
    },
    {
      title: t('CLIENT.Email'),
      width: 200,
      dataIndex: 'email',
    },
    {
      title: t('CLIENT.Facility_Name'),
      width: 200,
      dataIndex: 'facilityName',
    },
    {
      title: t('CLIENT.Client_ID'),
      width: 200,
      dataIndex: 'externalId',
    },
    {
      title: t('CLIENT.Address'),
      width: 200,
      dataIndex: 'address',
    },
    {
      title: t('CLIENT.city'),
      width: 200,
      dataIndex: 'clientType',
    },
    {
      title: t('CLIENT.Identification_Number'),
      width: 200,
      dataIndex: 'identificationNumber',
    },
    // {
    //   title: t('CLIENT.Street'),
    //   width: 200,
    //   dataIndex: 'street',
    // },
    {
      title: t('CLIENT.Phone_No'),
      width: 200,
      dataIndex: 'phoneNo',
    },
    // {
    //   title: t('CLIENT.Zip_Code'),
    //   width: 200,
    //   dataIndex: 'zipCode',
    // },
    {
      title: t('CLIENT.Caption'),
      width: 200,
      dataIndex: 'imageCaption',
    }
  ]

  const modalRef = useRef()
  const tableRef = useRef()
  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getList()
  }, [])

  const getList = async () => {
    setLoading(true);
    const { data } = await getListClient()
    setData(data?.clientList || [])
    setLoading(false);
  }

  const onDelete = async (ids) => {
    await apiDeleteClient(ids.join(","));
    getList();
  };

  const handleDelete = async (id) => {
    await apiDeleteClient(id);
  };

  const openForm = (record) => {
    if (modalRef.current) {
      modalRef.current.open()
      if (record) {
        modalRef.current.setFieldsValue(record)
      }
    }
  }

  const clearSelectRows = () => {
    if (tableRef.current) {
      tableRef.current.clearSelectRows()
    }
  }

  return (
    <div>
      <Datatable
        tableRef={tableRef}
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={'clientID'}
        autoFlatData={true}
        allowSelect={true}
        onDelete={onDelete}
        onAddNew={openForm}
        onEdit={openForm}
        rowKey={"id"}
        actionConfig={{
          allowEdit: true,
          allowAddNew: true,
          allowDelete: true,
          // allowAddTestType: true,
        }}
      />

      <ClientForm actionRef={modalRef} afterSubmit={clearSelectRows}
        handleSave={() => getList()}
      />
    </div>
  );
};

export default Client;
