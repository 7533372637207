import React from 'react';
import {Row, Col} from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const NotFountPage = () => (
    <Row gutter={[24, 16]} justify="center" align="middle" style={{height: '100%'}}>
        <Col span={4}>
            <h1 style={{textAlign: 'right', fontSize: '100px', color: '#f39c12'}}>404</h1>
        </Col>
        <Col span={8}>
            <WarningOutlined style={{color: '#f39c12'}} /> Oops! Page not found.
            <p>We could not find the page you were looking for.</p>
        </Col>
    </Row>
);

export default NotFountPage;
