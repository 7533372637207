import { Col, Input, Modal, Row, Select, Button, message, Table } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getListAnalyte, getListAnalyteGroup } from '../../../apis/analyte';
import { apiAddTestInformation } from '../../../apis/testInformation';
import { getListTestGroup, getListTestTypeByGroup } from '../../../apis/configTest';

const ModalAnalyte = ({
  actionRef,
  reset,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [dataTestGroup, setDataTestGroup] = useState([]);
  const [dataTestID, setDataTestID] = useState([]);
  const [dataAnalyteGroup, setDataAnalyteGroup] = useState([]);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({})
  const [selected, setSelected] = useState([])
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (visible) {
      setDataAnalyteGroup([])
      setDataTestID([]);
      setData([])
      setSelected([])
      setParams({})
      getList()
    }
  }, [visible])

  const getList = async () => {
    const { data } = await getListTestGroup();
    setDataTestGroup(data)
  }

  useEffect(async () => {
    if (!params.testGroupId) return;
    const { data } = await getListTestTypeByGroup(params.testGroupId)
    setDataTestID((data || []).map(x => { return { ...x, name: x.testId } }))
  }, [params.testGroupId])

  useEffect(async () => {
    if (!params.testId) return;
    const { data } = await getListAnalyteGroup(params.testId)
    setDataAnalyteGroup((data || []))
  }, [params.testId])

  useEffect(async () => {
    if (!params.analytesGroup) return;
    const { data } = await getListAnalyte(params.analytesGroup)
    setData((data || []))
  }, [params.analytesGroup])


  const [form, setForm] = useState({
    card: '',
    cartResponsibleUserId: 0,
    cartRequestMessage: ""
  })

  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setVisible(false);
    setForm({})
  };
  const handleOpen = (records, isPool) => {
    // setIsPoolSample(isPool)
    // setData(records)
    setRecords(records);
    setVisible(true);
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      open: handleOpen,
      close: handleClose,
    }

    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction)
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction
    }

    return () => {
      setIsMounted(false);
    }
    // eslint-disable-next-line
  }, [])

  const handleSubmit = async () => {
    if (selected.length === 0 || records.length == 0) return;
    const res = await apiAddTestInformation({
      testId: params.testId,
      analytesId: selected[0].id,
      sampleId: [records?.[0]?.sampleId],
      associatedId: records?.[0]?.sampleId
    })
    handleClose();
    reset();
  }

  const columns = [
    {
      title: 'Mã phân tích',
      dataIndex: 'analytesId',
    },
    {
      title: 'Mã chỉ tiêu',
      dataIndex: 'analytesId',
    },
    {
      title: 'Mã phương pháp',
      dataIndex: 'methodId',
    },
    {
      title: 'Chi phí dự trù',
      dataIndex: 'costEstimate',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <Modal
        centered={true}
        title={"Chỉ định chỉ tiêu phân tích"}
        visible={visible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        width={1000}
        forceRender
      >
        <Row style={{ marginBottom: "1rem" }}>
          <Col span={6}>
            <Row>
              <Col span={8}>Nhóm phân tích</Col>
              <Col span={12}>
                <Select value={params?.testGroupId} onChange={(e) => setParams(p => { return { ...p, testGroupId: e } })}>
                  {
                    dataTestGroup.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)
                  }
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col span={8}>Mã phân tích</Col>
              <Col span={12}>
                <Select value={params?.testId} onChange={(e) => setParams(p => { return { ...p, testId: e } })}>
                  {
                    dataTestID.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)
                  }
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col span={8}>Nhóm thử nghiệm</Col>
              <Col span={12}>
                <Select value={params?.analytesGroup} onChange={(e) => setParams(p => { return { ...p, analytesGroup: e } })}>
                  {
                    dataAnalyteGroup.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)
                  }
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Button type='primary' onClick={() => {
              setParams({})
              setData([])
            }}>Làm mới</Button>
          </Col>
        </Row>
        <Table
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          rowKey={["id"]}
        />

      </Modal>
    </div>
  )
}

export default ModalAnalyte;