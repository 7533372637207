import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const RouterItem = ({ main: ComponentToRender, path, exact }) => {
    const showItem = () => (
            <Route
                path={path}
                exact={exact}
                render={({ props }) => <ComponentToRender {...props} />}
            />
        );

    return showItem();
};

RouterItem.propTypes = {
    path: PropTypes.string,
    exact: PropTypes.bool,
};

export default RouterItem;
