import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Form, message, Modal, Row, Table, Input, Popconfirm, Typography, InputNumber, Button } from "antd";
import { useSelector } from "react-redux";
import { formStatus } from "src/redux/samples/selector";
import { useTranslation } from "react-i18next";
import SectionRender from "./components/sectionRenderr";
import moment from "moment-timezone";
import FormSection from "../../components/FormSection";
import { cloneSampleForm } from "../../apis/samples";
const EditableContext = React.createContext(null);

const initialValueForm = {
  // sampleIdExternal: ''
  currentQuantityUnit: "ml",
  packedSizeUnit: "l",
};

const hiddenInputs = ["sampleId"];
let keyNumber = 0;

const SampleFormDerivative = ({
  templateId,
  actionRef,
  afterSubmit,
  reset,
  ...props
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isPoolSample, setIsPoolSample] = useState(false);
  // const [editData, setEditData] = useState(null);
  const [hiddenData, setHiddenData] = useState({});
  const { submitting } = useSelector(formStatus);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [currentSampleParentUnit, setCurrentSampleParentUnit] = useState(0);
  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setIsEdit(false);
    // setEditData(null);
    setVisible(false);
    form.resetFields();
  };
  const handleOpen = (records, isPool) => {
    setIsPoolSample(isPool)
    setData(records)
    setVisible(true);
    setSubmitSuccess(false);
  };

  const onFinish = async () => {
    if (!submitSuccess) {
      if (data[0].sampleStatus !== "RECEIVED" && data[0].sampleStatus !== "STORED"){
        message.warning(t("SAMPLE.MESSAGE.warning_5"))
        return;
      }
      if (currentSampleParentUnit === 0) {
        message.warning(t("SAMPLE.MESSAGE.SAMPLE_QUANTITY_INVALID"))
        return;
      }
      else if (currentSampleParentUnit < 0) {
        message.warning(t("SAMPLE.MESSAGE.warning_4"))
        return;
      }
      const request = await cloneSampleForm({
        "currentQuantities": dataSeparate.map(x => x.currentQuantity),
        "parentId": data[0].sampleId
      })

      if (request.status === 400) {
        message.warning(t(`SAMPLE.MESSAGE.${request?.data?.code}`))
      }
      else {
        setSubmitSuccess(true);
        //TODO: lấy thông tin mẫu con
        setDataSeparate(request.data);
        message.success(t("SAMPLE.MESSAGE.separateSampleSuccess"));
        handleClose();
        reset();
      }

    }
    else {
      handleClose();
      reset();
    }

  };

  useEffect(() => {
    if (!isMounted) return;
    if (!submitting) {
      setVisible(false);
      form.resetFields();
      if (afterSubmit) {
        afterSubmit();
      }
    }
    // eslint-disable-next-line
  }, [submitting]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      closeDerivative: handleClose,
      openDerivative: handleOpen,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: 'Mẫ mẫu',
      dataIndex: 'sampleIdInternal',
      key: 'sampleIdInternal',
    },
    {
      title: 'Loại mẫu',
      dataIndex: 'sampleName',
      key: 'sampleName',
    },
    {
      title: 'Mẫ mẫu (ngoài)',
      dataIndex: 'sampleIdExternal',
      key: 'sampleIdExternal',
    },
    {
      title: 'Số lượng bạn đầu',
      dataIndex: 'currentQuantity',
      key: 'currentQuantity',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'quantifyUnit',
      key: 'quantifyUnit',
    },
    {
      title: 'Số lượng hiện tại',
      dataIndex: 'currentQuantity',
      key: 'currentQuantity',
      render: (x) => <b style={currentSampleParentUnit < 0 ? { color: "red" } : {}}>{currentSampleParentUnit}</b>
    },
    {
      title: 'Đơn vị',
      dataIndex: 'quantifyUnit',
      key: 'quantifyUnit',
    }
  ];

  const [editingKey, setEditingKey] = useState('');
  const [dataSeparate, setDataSeparate] = useState([])

  useEffect(() => {
    setDataSeparate([{ ...data[0], currentQuantity: 0, sampleIdInternal: '', keyNumber }])
    keyNumber++;
    setCurrentSampleParentUnit(data?.[0]?.currentQuantity);
  }, [data])

  const addRow = () => {
    const newData = [...dataSeparate];
    newData.push({ ...data[0], currentQuantity: 0, sampleIdInternal: '', keyNumber })
    keyNumber++;
    setDataSeparate(newData)
  }

  const isEditing = (record) => record.keyNumber === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.keyNumber);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSeparate];
      const index = newData.findIndex((item) => key === item.keyNumber);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSeparate(newData);
        const sum = newData.reduce((a, b) => a + (b?.currentQuantity || 0), 0)
        setCurrentSampleParentUnit(data?.[0]?.currentQuantity - sum);
        setEditingKey('');
      } else {
        newData.push(row);
        setDataSeparate(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
    }
  };

  const column2 = [
    {
      title: 'Mẫ mẫu',
      dataIndex: 'sampleIdInternal',
      key: 'sampleIdInternal',
      ellipsis: true,
      render: (x) => <span title={x}>{x}</span>
    },
    {
      title: 'Loại mẫu',
      dataIndex: 'sampleName',
      key: 'sampleName',
      ellipsis: true,
      render: (x) => <span title={x}>{x}</span>
      // width: "100px"
    },
    {
      title: 'Mẫ mẫu (ngoài)',
      dataIndex: 'sampleIdExternal',
      key: 'sampleIdExternal',
    },
    {
      title: 'Số lượng tách',
      dataIndex: 'currentQuantity',
      key: 'currentQuantity',
      editable: true,
      type: 'number',
      // onCell: (record) => ({
      //   record,
      //   inputType: 'number',
      //   editing: isEditing(record),
      // }),
      // render: (x) => <InputNumber title={x} value={x} />
    },
    {
      title: 'Đơn vị',
      dataIndex: 'quantifyUnit',
      key: 'quantifyUnit',
    },
    // {
    //   title: 'Vật chứa đựng',
    //   dataIndex: 'quantifyUnit',
    //   key: '',
    //   ellipsis: true,
    //   render: (x) => <span title={x}>{x}</span>,
    //   editable: true,
    // },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.keyNumber)}
              style={{
                marginRight: 8,
              }}
            >
              Lưu
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Hủy</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Sửa
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = column2.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.type === 'number' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });


  return (
    <div>
      <Modal
        centered={true}
        title={
          isPoolSample ? t("TOOLBAR.PoolSample") : t("TOOLBAR.Derivative")
        }
        visible={visible}
        onOk={handleOk}
        confirmLoading={submitting}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        width={1000}
        forceRender
      >
        <Form
          ref={formRef}
          form={form}
          name="basic"
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          initialValues={initialValueForm}
          onFinish={onFinish}
          autoComplete="off"
        >
          <FormSection header={t("SAMPLE.parentSample")}>
            {/* <Row gutter={[32, 8]}> */}
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}

            />
            {/* </Row> */}
          </FormSection>
          <FormSection header={t("SAMPLE.childSample")}>
            <Table
              dataSource={dataSeparate}
              pagination={false}
              columns={mergedColumns}
              rowClassName="editable-row"
              rowKey={'keyNumber'}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}

            />
            <div style={{ textAlign: "end", marginTop: "0.5rem" }}>
              <Button type="primary" onClick={addRow}>+</Button>
            </div>
          </FormSection>
        </Form>
      </Modal>
    </div>
  );
};

SampleFormDerivative.propTypes = {
  actionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterSubmit: PropTypes.func,
};

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};


export default SampleFormDerivative;
