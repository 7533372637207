import Chart from "react-apexcharts";
import { colorChar } from "../../../../../constants/color.constant";

function CharCustom(props) {
  const data = {
    series: props.data.map((item) => item.count),
    options: {
      colors: colorChar,
      labels: props.data.map((item) => item.type),
      legend: {
        show: true,
        width: 100,
        position: "right",
        horizontalAlign: "top",
        offsetX: 12,
        offsetY: -22,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          width: 5,
          height: 5,
        },
      },
      dataLabels: {
        enabled: true,
        // enabledOnSeries: undefined,
        formatter: function (val, opts) {
          const value = opts.w.globals.initialSeries[opts.seriesIndex];
          return value;
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          startAngle: 0,
          endAngle: 360,
          offsetX: -10,
          offsetY: 10,
          customScale: 1.2,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: "50%",
            background: "transparent",
            labels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                color: "red",
                offsetY: 5,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                showAlways: true,
                // label: "Total",
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "red",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    },
  };
  return <Chart options={data.options} series={data.series} type="donut" />;
}
export default CharCustom;
