import React from "react";
import { Layout } from "antd";

const Footer = () => {
  const { Footer } = Layout;
  return (
    <Footer style={{ textAlign: "center" }}>
      © 2021 EZ Lab All rights reserved.
    </Footer>
  );
};

export default Footer;
