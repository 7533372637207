import React, { useState } from 'react';
import { Tabs, Card } from 'antd';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const ManagementTab = React.lazy(() => import('./Tabs/ManagementTab'));
const ListTab = React.lazy(() => import('./Tabs/ListTab'));

export default function DocumentApp() {
    const [activeTab, setActiveTab] = useState('1');
    const { t } = useTranslation();
    const tabOnChange = (key) => setActiveTab(key);

   const renderingManagementTab = () => {
        return <ManagementTab />
   }

    const renderingListTab = () => {
        return <ListTab />
    }

    return (
        <Card className="h-screen" id="doc-app">
            <Tabs defaultActiveKey={activeTab} type="card" size='large' onChange={tabOnChange}>
                <TabPane tab={`${t("DOCUMENT.TABS.management")}`} key="1" >{activeTab === '1' && renderingManagementTab()}</TabPane>
                <TabPane tab={`${t("DOCUMENT.TABS.list")}`} key="2">{activeTab === '2' && renderingListTab()}</TabPane>
            </Tabs>
        </Card>

    )
}