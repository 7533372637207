import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import qs from 'qs';

const useQueryParams = (propsInitialValues = {}) => {
  const history = useHistory();
  const location = useLocation();
  const initialValues = useMemo(
    () => ({
      ...propsInitialValues,
      ...qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    }),
    // eslint-disable-next-line
    []
  );

  const [queryParams, setQueryParams] = useState(initialValues);
  const [isSyncedWithUrlParams, setSynced] = useState(false);

  const isUrlParamsNotEqualLocalParams = useCallback(() => {
    return qs.stringify(queryParams, {skipNulls: true}) !== qs.stringify(qs.parse(location.search, { ignoreQueryPrefix: true }), {skipNulls: true});
  }, [queryParams, location.search]);

  useEffect(() => {
    if (isUrlParamsNotEqualLocalParams()) {
      history.replace({
        pathname: location.pathname,
        hash: location.hash,
        search: qs.stringify(queryParams, { addQueryPrefix: true, skipNulls: true }),
      });
    }
    setSynced(true);
    return () => {
      setSynced(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (isUrlParamsNotEqualLocalParams() && isSyncedWithUrlParams) {
        setQueryParams(qs.parse(location.search, { ignoreQueryPrefix: true }));
      }
    },
    // eslint-disable-next-line
    [location.search, isSyncedWithUrlParams]
  );

  const setQueryParamsAndPush = useCallback(
    (queryParams = {}) => {
      setQueryParams(queryParams);
      history.push({
        pathname: location.pathname,
        hash: location.hash,
        search: qs.stringify(queryParams, { addQueryPrefix: true, skipNulls: true }),
      });
    },
    // eslint-disable-next-line
    []
  );

  return [queryParams, setQueryParamsAndPush];
};

export default useQueryParams;
