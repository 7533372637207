import axiosService from 'src/services/axiosService';
import { CONFIG } from '../../../configs';
import TokenUtil from '../Utils/TokenUtil';

export function getUserGroups() {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.AUTH}/api/accounts/get_group`, null, CUSTOM_HEADER).then(({ data }) => {
        return data.data.map(item => {
            let listGroups = item._listGroup.map(group => ({ value: group.idGroup, label: group.groupName }));
            return {
                appCode: item.appCode,
                listGroups,
            }
        });
    }).catch(({data: error}) => {
        return error;
    })
}

export function getDocumentUserList() {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.post(`${CONFIG.API_URL.AUTH}/api/user/list_user`, {
        "listApp": ["NT_Document"]
    }, CUSTOM_HEADER).then(({ data }) => {
        return (data.data || []).map(item => ({
            key: `${item.IdUser}-${Date.now()}`,
            value: item.IdUser,
            label: item.Username,
        }));
    }).catch(({data: error}) => {
        return error;
    })
}

export function getUserDetail(userID) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.AUTH}/api/user/get_user_detail?idUser=${userID}`, null, CUSTOM_HEADER).then(({ data }) => {
        if (data.data) {
            const { UserInfomation } = data.data;
            if (UserInfomation) {
                let userPosition = '';
                if (UserInfomation.Position && UserInfomation.Department) {
                    userPosition = `${UserInfomation.Department} - ${UserInfomation.Position}`
                } else if (UserInfomation.Department) {
                    userPosition = UserInfomation.Department;
                } else if (UserInfomation.Position) {
                    userPosition = UserInfomation.Position;
                }

                return {
                    userPosition
                }
            }
            return null;
        } else {
            return null;
        }
    }).catch(({data: error}) => {
        return error;
    })
}