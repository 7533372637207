export class Test {
  constructor(data) {
    this.generalInformation = {
      testID: Math.floor(Math.random() * 10000) + 1,
      testName: data.testName,
      associatedSamples: data.associatedSamples,
      createdBy: data.createdBy,
      associatedInventories: data.associatedInventories,
      description: data.description,
      sampleIDExternal: data.sampleIDExternal,
      completionDate: data.completionDate,
      associatedSamplesExternalID: data.associatedSamplesExternalID,
      createdOn: data.createdOn,
      testIDExternal: data.testIDExternal,
      group: data.group,
      associatedInstruments: data.associatedInstruments,
      code: data.code,
      barcodeID: data.barcodeID,
      method: data.method,
      associatedKits: data.associatedKits,
    }
    this.custodialInformation = {
      technicianID: data.technicianID,
      reviewerID: data.reviewerID,
      technicianName: data.technicianName,
    }
    this.turnaroundTimeAlarm = {
      remindBeforeTurnaroundTimeThreshold: data.remindBeforeTurnaroundTimeThreshold,
    }
    this.testDetails = {
      processingNotes: data.processingNotes,
      rejectionCriteria: data.rejectionCriteria,
      containerType: data.containerType,
      patientPreparation: data.patientPreparation,
      synonyms: data.synonyms,
    }
  }
}

export const TEST_DATA = {
  testID: '',
  testName: 'Lorem',
  associatedSamples: '',
  CreatedBy: '',
  AssociatedInventories: '',
  Description: '',
  SampleIDExternal: '',
  CompletionDate: '',
  AssociatedSamplesExternalID: '',
  CreatedOn: '',
  TestIDExternal: '',
  Group: '',
  AssociatedInstruments: '',
  Code: '',
  BarcodeID: '',
  Method: '',
  AssociatedKits: '',
  TechnicianID: '',
  ReviewerID: '',
  TechnicianName: '',

  RemindBeforeTurnaroundTimeThreshold: '',

  ProcessingNotes: '',
  RejectionCriteria: '',
  ContainerType: '',
  PatientPreparation: '',
  Synonyms: '',
}
