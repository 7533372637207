import axiosService from 'src/services/axiosService';
import { CONFIG } from '../../configs';
import ListDataMock from 'src/mockApi/listDataTable';
import AgGridData from 'src/mockApi/agGridData';
import PivotData from 'src/mockApi/pivotData';

/**
 * fetch data demo
 * @param {model} include:
 * @param username
 * @param password
 */

export const getListApi = () =>
     ListDataMock.list(); // mock data

/**
 * update item for demo list
 * @param {data} include:
 */
export const updateItemDemoApi = () => {
    const resultData = {
        status: 200,
        data: true
    };
    return resultData; // mock data
    // return axiosService.post(LIST_URL.GET_LIST, model);
};

/**
 * delete item for demo list
 * @param {data} include:
 */
export const deleteItemDemoApi = () => {
    const resultData = {
        status: 200,
        data: true
    };
    return resultData; // mock data
    // return axiosService.post(LIST_URL.GET_LIST, model);
};

/**
 * add item for demo list
 * @param {data} include:
 */
export const addItemDemoApi = () => {
    const resultData = {
        status: 200,
        data: true
    };
    return resultData; // mock data
    // return axiosService.post(LIST_URL.GET_LIST, model);
};

/**
 * fetch data ag-grid
 * @param {params} include:
 * @param limit
 * @param page
 */

// SSR
export const getListAgGridApi = (params) => {
    return AgGridData.list(params); // mock data
    // return axiosService.post(LIST_URL.GET_LIST, model);
};

/**
 * fetch data pivot
 * @param {params} include:
 * @param limit
 * @param page
 */
export const getListPivotApi = () =>
     PivotData.list();

/**
 * push data notification
 * @param {params} include:
 */

export const postNotification = (notification) => axiosService.post(CONFIG.API_URL.FIREBASE_URL, notification, 'FIRE_BASE');
