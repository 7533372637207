import React from 'react';
import { Select } from 'antd';
import querystring from 'querystring';

import { searchDocumentFromSample } from '../../Services/document.services';

const { Option } = Select;

let timeout;
let currentValue;

function fetch(value, callback) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    function search() {
        const filterStr = querystring.encode({
            searchStr : value,
        });

        searchDocumentFromSample(filterStr).then((data) => {
            const options = [];
            data.forEach(r => {
                options.push({
                    value: `${r.folderTitle}-${r.title}`,
                    text: `${r.folderTitle}-${r.title}`,
                    id: r.id,
                });
            });
            callback(options);
        });
    }

    timeout = setTimeout(search, 300);
}

class SearchDocumentInput extends React.Component {
    state = {
        data: [],
        value: undefined,
    };

    handleSearch = value => {
        if (value) {
            fetch(value, data => this.setState({ data }));
        } else {
            this.setState({ data: [] });
        }
    };

    handleChange = (value, data) => {
        this.setState({ value });
        this.props.onChange(value, data.key);
    };

    render() {

        // const options = [...this.props.options, ...this.state.data];
        const options = [...this.state.data];

        return (
            <Select
                showSearch
                value={this.state.value || this.props.defaultValue}
                placeholder={this.props.placeholder}
                style={this.props.style}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}
            >
                {
                    options.map((item, key) => (
                        <Select.Option value={item.value} key={item.id}>
                            {item.text}
                        </Select.Option>
                    ))
                }
            </Select>
        );
    }
}

export default SearchDocumentInput;
