import { sleep } from "../../utils/utils";
import { CONFIG } from "../../configs";
import axiosService from "../../services/axiosService";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */
export const getListTestType = () => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/test-id`);
};
export const getListTestTypeByGroup = (testGroupId, testCodeId) => {
  // return axiosService.get(`${CONFIG.API_URL.BASE}/api/test-id?testGroupId=${testGroupId}`);
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/test-id?testGroupId=${testGroupId}${testCodeId ? `&testCodeId=${testCodeId}` : ""}`);
};
export const apiAddTestType = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/test-id`,
    data
  );
};

export const apiDeleteTestType = async (data) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/api/test-id/${data}`);
};

export const apiEditTestType = async (data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/test-id`, data);
};

// test-group

export const getListTestGroup = () => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/test-group`);
};
export const getDetailTestGroup = (id) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/test-group/${id}`);
};
export const apiAddTestGroup = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/test-group`,
    data
  );
};

export const apiDeleteTestGroup = async (data) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/api/test-group/${data}`);
};

export const apiEditTestGroup = async (data) => {
  return axiosService.post(`${CONFIG.API_URL.BASE}/api/test-group`, data);
};

// test-code

export const getListTestCode = (groupId) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/test-code?testGroupId=${groupId}`);
};
export const apiAddTestCode = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/test-code`,
    data
  );
};

export const apiDeleteTestCode = async (data) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/api/test-code/${data}`);
};

export const apiEditTestCode = async (data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/test-code`, data);
};



export const getPreviewTestCode = (id) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/preview/test-code?testCodeUUID=${id}`);
};
