import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Col, Form, message, Row, Select, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {SaveOutlined} from "@ant-design/icons";
import {setPermissionApi} from "../../apis/permissions";
import UseAllAppPermission from "../../hooks/useAllAppPermission";
import styled from "styled-components";
import ModalAddUserGroup from "./components/ModalAddUserGroup";

const PermissionSetting = () => {
  const {t} = useTranslation()
  const ruleRequire = [{ required: true, message: t("VALIDATION.require") }];
  const formRef = useRef()
  const [form] = Form.useForm();
  const {data: listApp, fetching, fetchAllAppCode} = UseAllAppPermission()
  const [listGroup, setListGroup] = useState([])
  const [selectedApp, setSelectedApp] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [visibleAddGroup, setVisibleAddGroup] = useState(false)

  useEffect(() => {
    if (listApp.length > 0) {
      const AppCode = form.getFieldValue('AppCode')
      if (AppCode) {
        const selectedApp = listApp.find(item => item.AppCode === AppCode)
        setSelectedApp(selectedApp)
        setListGroup(selectedApp.listGroup || [])
      }
    }
  }, [listApp])

  const handleChangeAppCode = (value) => {
    form.resetFields()
    form.setFieldsValue({
      AppCode: value
    })
    const selectedApp = listApp.find(item => item.AppCode === value)
    if (selectedApp) {
      setSelectedApp(selectedApp)
      setListGroup(selectedApp.listGroup || [])
    }
  }

  const handleChangeGroup = (value) => {
    const checkedList = listGroup.find(item => item.Id === value)
    if (checkedList) {
      const data = {}
      checkedList.Permits.map(item => {
        data[item] = true
      })
      const values = form.getFieldsValue()
      const {AppCode, UserGroup} = values
      form.resetFields()
      form.setFieldsValue({AppCode, UserGroup, ...data})
    } else {
      const values = form.getFieldsValue()
      const {AppCode, UserGroup} = values
      form.resetFields()
      form.setFieldsValue({AppCode, UserGroup})
    }
  }

  const handleUpdatePermission = async (data) => {
    const res = await setPermissionApi(data)
    setSubmitting(false)
    if (res.data.status === 1) {
      message.success(t(`PERMISSION.MESSAGE.updateSuccess`))
      fetchAllAppCode().then()
    } else {
      message.error(t(`PERMISSION.MESSAGE.updateFailure`))
    }
  }

  const onFinish = (values) => {
    setSubmitting(true)
    const {AppCode, UserGroup, ...Permits} = values
    const selectedPermission = Object.keys(Permits).filter(key => Permits[key])
    const data = {
      "Id": UserGroup,
      "AppCode": AppCode,
      "Permits": selectedPermission || []
    }
    handleUpdatePermission(data).then()
  }

  const renderGroupPermission = () => {
    return (
      <>
        {selectedApp?.listPermissionGroup?.map((item,index) => {
          return <Row gutter={[0,0]} key={`row_${index}`}>
            <Col span={6}>
              <div className={'celAction'}>{item.Name}</div>
            </Col>
            <Col span={18}>
              {item.Permissions.map((_item, _index) => {
                return <div className={'celAction'} key={`cell_${index}_${_index}`}>
                  <Form.Item
                    name={_item.Code}
                    labelAlign={"left"}
                    valuePropName={'checked'}
                    noStyle={true}
                  >
                    <Checkbox>{t(`PERMISSION.FORM.${_item.Code}`)}</Checkbox>
                  </Form.Item>
                </div>
              })}
            </Col>
          </Row>
        })}
      </>
    )
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Spin tip="Loading..." spinning={fetching}>
        <Form
          ref={formRef}
          form={form}
          name="permissionSetting"
          onFinish={onFinish}
          autoComplete="off"
        >
          <FormStyled>
            <Row gutter={[32, 8]}>
              <Col flex="350px">
                <Form.Item
                  label={t(`PERMISSION.FORM.AppCode`)}
                  name={'AppCode'}
                  labelAlign={"left"}
                  rules={ruleRequire}
                >
                  <Select showSearch placeholder={t("selectValue")} allowClear={true} allowClear={true} onChange={handleChangeAppCode}>
                    {listApp.map(item => {
                      return <Select.Option value={item.AppCode} key={item.AppCode}>{item.TenApp}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="550px">
                <div className={'tw-flex tw-flex-row'}>
                  <Form.Item
                    label={t(`PERMISSION.FORM.UserGroup`)}
                    name={'UserGroup'}
                    labelAlign={"left"}
                    rules={ruleRequire}
                    className={`tw-flex-1 tw-mr-3`}
                  >
                    <Select showSearch placeholder={t("selectValue")} allowClear={true} allowClear={true} onChange={handleChangeGroup}>
                      {listGroup.map(item => {
                        return <Select.Option value={item.Id} key={item.Id}>{item.TenGroup}</Select.Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{height: "auto", padding: "3px 5px", alignItems: "center"}}
                      onClick={() => setVisibleAddGroup(true)}
                    >
                      {t("PERMISSION.BUTTON.groupManagement")}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
              <Col flex="auto">
                <Form.Item className={'tw-items-right'}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                    disabled={submitting}
                    style={{height: "auto", padding: "3px 5px", alignItems: "center"}}
                  >
                    <SaveOutlined />
                    {t("BUTTON.save")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <div className={'tabled'}>
              {renderGroupPermission()}
            </div>

          </FormStyled>
        </Form>
      </Spin>

      <ModalAddUserGroup
        visible={visibleAddGroup}
        onCancel={() => setVisibleAddGroup(false)}
        dataAppCode={listApp}
        fetching={fetching}
        reloadData={fetchAllAppCode}
      />
    </div>
  );
};

PermissionSetting.propTypes = {

};

export default PermissionSetting;

const FormStyled = styled.div`
  .tabled {
    background-color: #fff;
    > .ant-row {
      border-top: 1px solid #f0f0f0;
      &:first-child {
        border-top: 0;
      }
      > .ant-col {
        border-right: 1px solid #f0f0f0;
        .celAction {
          &:first-child {
            border-top: 0;
          }
        }
      }
    }
    .celAction {
      border-top: 1px solid #f0f0f0;
      padding: 10px 15px;
    }
  }
`

