// NT_Core
export const NT_Core_GetInfoUser = "NT_Core_GetInfoUser"
export const NT_Core_AddUser = "NT_Core_AddUser"
export const NT_Core_UpdateUser = "NT_Core_UpdateUser"
export const NT_Core_DeleteUser = "NT_Core_DeleteUser"
export const NT_Core_ListUser = "NT_Core_ListUser"
export const NT_Core_CheckAccountApp = "NT_Core_CheckAccountApp"
export const NT_Core_ADAccountGroup = "NT_Core_ADAccountGroup"
export const NT_Core_AccountBOGroup = "NT_Core_AccountBOGroup"
export const NT_Core_AccountBOUser = "NT_Core_AccountBOUser"
export const NT_Core_ADAccountApp = "NT_Core_ADAccountApp"
export const NT_Core_ActiveAccount = "NT_Core_ActiveAccount"
export const NT_Core_ListApp = "NT_Core_ListApp"
export const NT_Core_ListPermit = "NT_Core_ListPermit"
export const NT_Core_ListGroupPermit = "NT_Core_ListGroupPermit"
export const NT_Core_ListAccountermit = "NT_Core_ListAccountermit"
export const NT_Core_CreateGroup = "NT_Core_CreateGroup"
export const NT_Core_UpdateGroupPermit = "NT_Core_UpdateGroupPermit"
export const NT_Core_DeleteGroup = "NT_Core_DeleteGroup"
export const NT_Core_UpdateGroup = "NT_Core_UpdateGroup"


export const Sample = "Sample"
export const Sample_Add = "Sample_Add"
export const Sample_Edit = "Sample_Edit"
export const Sample_Delete = "Sample_Delete"
export const Sample_Cancel = "Sample_Cancel"
export const Sample_Restore = "Sample_Restore"
export const Sample_MoveToCart = "Sample_MoveToCart"
export const Sample_Split = "Sample_Split"
export const Sample_Receive = "Sample_Receive"
export const Sample_SpecificCriteria = "Sample_SpecificCriteria"
export const Sample_Import = "Sample_Import"
export const Sample_View = "Sample_View"
export const Sample_Export = "Sample_Export"
export const Sample_SendExternal = "Sample_SendExternal"
export const Sample_Admin = "Sample_Admin"
export const Sample_Export_Report = "Sample_Export_Report"

export const Test = "Test"
export const Add_Test = "Add_Test"
export const Edit_Test = "Edit_Test"
export const Delete_Test = "Delete_Test"
export const Return_Test = "Return_Test"
export const Reviewer_Approve = "Reviewer_Approve"
export const Approve_Result = "Approve_Result"
export const Lock_Result = "Lock_Result"
export const Unlock_Result = "Unlock_Result"
export const Edit_Result = "Edit_Result"
export const Generate_Report = "Generate_Report"
export const View_Trend = "View_Trend"
export const View_Full_Test = "View_Full_Test"

export const Client = "Client"
export const View_Client = "View_Client"
export const Add_Client = "Add_Client"
export const Edit_Client = "Edit_Client"
export const Delete_Client = "Delete_Client"

export const NT_Document = "NT_Document"
export const Document_CreateFolder = "Document_CreateFolder"
export const Document_CreateFile = "Document_CreateFile"
export const Document_EditFile = "Document_EditFile"
export const Document_RenameFile = "Document_RenameFile"
export const Document_ReplaceFile = "Document_ReplaceFile"
export const Document_DeleteFile = "Document_DeleteFile"
export const Document_DeleteFolder = "Document_DeleteFolder"
export const Document_RequestReview = "Document_RequestReview"
export const Document_RequestDowload = "Document_RequestDowload"
export const Document_RequestPrint = "Document_RequestPrint"
export const Document_RequestTraining = "Document_RequestTraining"
export const Document_EditFolder = "Document_EditFolder"
export const Document_ListData = "Document_ListData"
export const Document_ListDocumentData = "Document_ListDocumentData"
export const Document_ListRequestPrint = "Document_ListRequestPrint"
export const Document_ListRequestTraining = "Document_ListRequestTraining"
export const Document_ListRequestDownload = "Document_ListRequestDownload"
export const Document_PublicDocument = "Document_PublicDocument"


export const UserManagament = "UserManagament"
export const UserManagament_AddUser = "UserManagament_AddUser"
export const UserManagament_ViewUser = "UserManagament_ViewUser"
export const UserManagament_EditUser = "UserManagament_EditUser"
export const UserManagament_DeleteUser = "UserManagament_DeleteUser"
export const UserManagament_AssignRight = "UserManagament_AssignRight"

export const FormConfiguration = "FormConfiguration"
export const CreateConfig = "CreateConfig"
export const EditConfig = "EditConfig"
export const ViewFormConfig = "ViewFormConfig"
