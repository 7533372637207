import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Col, DatePicker, Form, Input, message, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formStatus } from "src/redux/test/selector";
import SectionRender from "./sectionRenderr";
import { apiAddAnalyte } from "../../../apis/analyte";

const ANALYTE_GROUP_ID = "5ee571d1-6d8c-4dcf-8f7e-698e850134c8"

const AnalyteForm = ({ configData, actionRef, afterSubmit, dataTestID, handleSave, testGroup, ...props }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const { submitting } = useSelector(formStatus);
  const ruleRequire = [{ required: true, message: t("VALIDATION.require") }];
  const [id, setId] = useState(null);

  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setIsEdit(false);
    setEditData(null);
    setVisible(false);
    form.resetFields();
  };
  const handleOpen = (testGroup) => {
    form.setFieldsValue({
      analyteGroup: testGroup.name
    });
    setVisible(true);
  };

  const onChange = (e) => {
    // console.log(e);
  };

  const setFieldsValue = (values) => {
    setIsEdit(true);
    setEditData(values);
    form.setFieldsValue({
      ...values,
      analyteId: values["analytesId"],
      analyteName: values["analytesName"],
      // observedValue: values["observedValue"],
      observedValue: values["testRunNumber"],
      testRunNumber: values["costEstimate"],
      costEstimate: values["costEstimate"],
      status: values["lod"],
      methodId: values["methodId"],
      limit: values["otherLabResult"],
      analyteGroup: testGroup.name
    });
    setId(values["id"])
  };

  useEffect(() => {
    if (!isMounted) return;
    if (!submitting) {
      setVisible(false);
      form.resetFields();
      if (afterSubmit) {
        afterSubmit();
      }
    }
    // eslint-disable-next-line
  }, [submitting]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      close: handleClose,
      open: handleOpen,
      setFieldsValue: setFieldsValue,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line
  }, []);

  const onFinish = async (data) => {
    // console.log(`data`, data)
    if (!data.analyteId) {
      message.warning("Mã phân tích là trường bắt buộc!")
      return;
    }
    if (!data.analyteName) {
      message.warning("Tên phân tích là trường bắt buộc!")
      return;
    }
    data = {
      // "analytesFlagId": null,
      "analytesGroupId": testGroup.id,
      "analytesId": data.analyteId,
      "analytesName": data.analyteName,
      "observedValue": data.testRunNumber,
      "costEstimate": data.testRunNumber,
      "id": id,
      "lod": data.status,
      "loq": data.loq,
      "methodId": data.methodId,
      "otherLabResult": data.limit,
      "result": data.result,
      "testRunNumber": data.observedValue,
      "unit": data.unit,
      "description": data.description,
    }
    await apiAddAnalyte(data)

    handleSave && handleSave();
    handleClose();


    /*
      "analytesFlagId": null,
      "analytesGroupId": "5ee571d1-6d8c-4dcf-8f7e-698e850134c8",
      "analytesId": "kekeke123",
      "analytesName": "keke123",
      "costEstimate": 0,
      "id": null,
      "lod": "0",
      "loq": "0",
      "methodId": "0",
      "otherLabResult": 0,
      "result": "0",
      "testRunNumber": 0,
      "unit": "ul"
    */

  };

  return (
    <div>
      <Modal
        centered={true}
        title={t("Analyte Information")}
        visible={visible}
        onOk={handleOk}
        confirmLoading={submitting}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        width={1000}
        forceRender
      >
        <Form
          ref={formRef}
          form={form}
          name="basic"
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          autoComplete="off"
          onFinish={onFinish}
        >
          {configData?.data?.config.map((item, idx) => (
            <SectionRender key={idx} item={item} />
          ))}
        </Form>
      </Modal>
    </div>
  );
};

AnalyteForm.propTypes = {
  actionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterSubmit: PropTypes.func,
};

export default AnalyteForm;
