import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Col, DatePicker, Form, Input, message, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formStatus } from "src/redux/test/selector";
import FormSection from "src/components/FormSection";
import { doAddTest, doEditTest } from "../../redux/test/slice";
import SectionRender from "./sectionRenderr";
import { apiAddTestInformation, apiEditTestInformation } from "../../apis/testInformation";
import moment from "moment-timezone";
import { selectCurrentUser } from "../../redux/user/selector";
const hiddenInputs = ["associatedSamples"];

const TestForm = ({ configData, actionRef, afterSubmit, ...props }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const { submitting } = useSelector(formStatus);
  const ruleRequire = [{ required: true, message: t("VALIDATION.require") }];
  const [hiddenData, setHiddenData] = useState({});
  const [params, setParams] = useState({});
  const currentUser = useSelector(selectCurrentUser)

  const [testId, setTestId] = useState({});

  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setIsEdit(false);
    setEditData(null);
    setVisible(false);
    form.resetFields();
  };
  const handleOpen = (testGroupId, testCodeId) => {
    setParams({
      testGroupId,
      testCodeId
    })
    form.setFieldsValue({
      createdOn: moment(),
      createdBy: `${currentUser.UserInfomation.FirstName} ${currentUser.UserInfomation.LastName}`
    })
    setVisible(true);
  };

  const onChange = (e) => {
    // console.log(e);
  };

  const setFieldsValue = (values, testGroupId, testCodeId) => {
    // console.log(`values`, values)
    setParams({
      testGroupId,
      testCodeId,
      description: values["description"],
      id: values["id"],
      status: values["status"],
      testInformationCode: values["testInformationCode"],
      sampleTypes: values["testIdResponseDTO.sampleTypes"],
    })
    setIsEdit(true);
    setEditData(values);
    form.setFieldsValue({
      testId: values["testIdResponseDTO.id"],
      analyteGroup: values["analytesDTO.analytesGroupId"],
      testName: values["testName"],
      analyteId: values["analytesDTO.id"],
      analyteName: values["testName"],
      methodId: values["analytesDTO.methodId"],
      // createdBy: values["testName"],
      // department  : values["testName"],
      sampleIdExternal: values["generalInformation.sampleName"],
      sampleIdInternal: values["generalInformation.sampleIdInternal"],
      // sampleName: values["generalInformation.sampleIdExternal"],
      sampleName: values["generalInformation.sampleId"],
      sampleType: values["testIdResponseDTO.sampleTypes"]?.[0]?.id,
      approver: values["technicianInformationDTO.approver"],
      approverId: values["technicianInformationDTO.approverId"],
      reviewer: values["technicianInformationDTO.reviewer"],
      reviewerId: values["technicianInformationDTO.reviewerId"],
      technicianId: values["technicianInformationDTO.technicianId"],
      technicianName: values["technicianInformationDTO.technicianName"],
      receivedDate: values["timeInformationDTO.receivedDate"] ? moment(values["timeInformationDTO.receivedDate"]) : null,
      samplingDate: values["timeInformationDTO.samplingDate"] ? moment(values["timeInformationDTO.samplingDate"]) : null,
      startDate: values["timeInformationDTO.startDate"] ? moment(values["timeInformationDTO.startDate"]) : null,
      containerType: values["timeDetailDTO.containerType"],
      entryDate: values["timeDetailDTO.entryDate"] ? moment(values["timeDetailDTO.entryDate"]) : null,
      entryTime: values["timeDetailDTO.entryTime"] ? moment(moment(parseInt(values["timeDetailDTO.entryTime"])).format('HH:mm:ss'), "HH:mm:ss") : null,
      patientPreparation: values["timeDetailDTO.patientPreparation"],
      processingNotes: values["timeDetailDTO.processingNotes"],
      rejectionCriteria: values["timeDetailDTO.rejectionCriteria"],
      remark: values["timeDetailDTO.remark"],
      report: values["timeDetailDTO.report"],
      oservedResults: values["sectionName.oservedResults"],
      otherLabResults: values["sectionName.otherLabResults"],
      result: values["sectionName.result"],
      resultDate: values["sectionName.resultDate"] ? moment(values["sectionName.resultDate"]) : null,
      resultedBy: values["sectionName.resultedBy"],
      sampleTestingResult: values["sectionName.sampleTestingResult"],
      unit: values["sectionName.unit"],
      validDate: values["sectionName.validDate"] ? moment(values["sectionName.validDate"]) : null,

      // associatedSamples: values["generalInformation.associatedSamples"],
      createdBy: values["sectionName.resultedBy"],
      // associatedInventories: values["generalInformation.associatedInventories"],
      // description: values["generalInformation.description"],
      // sampleIDExternal: values["generalInformation.sampleIDExternal"],
      // completionDate: values["generalInformation.completionDate"],
      // associatedSamplesExternalID:
      //   values["generalInformation.associatedSamplesExternalID"],
      createdOn: values["sectionName.resultDate"] ? moment(values["sectionName.resultDate"]) : null,
      // testIDExternal: values["generalInformation.testIDExternal"],
      // group: values["generalInformation.group"],
      // associatedInstruments: values["generalInformation.associatedInstruments"],
      // code: values["generalInformation.code"],
      // barcodeID: values["generalInformation.barcodeID"],
      // method: values["generalInformation.method"],
      // associatedKits: values["generalInformation.associatedKits"],

      // technicianID: values["custodialInformation.technicianID"],
      // reviewerID: values["custodialInformation.reviewerID"],
      // technicianName: values["custodialInformation.technicianName"],

      // remindBeforeTurnaroundTimeThreshold:
      //   values["turnaroundTimeAlarm.remindBeforeTurnaroundTimeThreshold"],

      // processingNotes: values["testDetails.processingNotes"],
      // rejectionCriteria: values["testDetails.rejectionCriteria"],
      // containerType: values["testDetails.containerType"],
      // patientPreparation: values["testDetails.patientPreparation"],
      // synonyms: values["testDetails.synonyms"],
    });

    setTestId({
      id: values["testIdResponseDTO.id"],
      analytesGroupId: values["analytesDTO.analytesGroupId"],
      sampleTypeId: values["testIdResponseDTO.sampleTypes"]?.[0]?.id,
      // analyteId: values["analytesDTO.id"],
      sampleTypes: values["testIdResponseDTO.sampleTypes"],
    })
  };

  const onFinish = async (data) => {
    if (!data.testId) {
      message.warning("Vui lòng chọn mã thử nghiệm!")
      return
    }
    if (!data.analyteGroup) {
      message.warning("Vui lòng chọn nhóm phân tích!")
      return
    }
    if (!data.analyteId) {
      message.warning("Vui lòng chọn mã phân tích!")
      return
    }
    if (!data.sampleName) {
      message.warning("Vui lòng chọn tên mẫu!")
      return
    }
    let result = {};
    let output = {};
    (configData?.configData?.config || []).map((item) => {
      const child = {};
      if (Array.isArray(item.attribute)) {
        if (item.sectionName === "samplerInformation") {
          result[item.sectionName] = form.getFieldValue("samplerInformation");
        }
      } else {
        Object.keys(item.attribute).map((key) => {
          if (hiddenData[key]) {
            return (child[key] = hiddenData[key]);
          }
          if (item.attribute[key].type.toUpperCase() === "number") {
            return (child[key] = parseInt(data[key]));
          }
          if (item.attribute[key].type == "date" || item.attribute[key].type == "time") {
            if (data[key])
              return (child[key] = new Date(moment(data[key]).format()).getTime());
          }

          if (data[key]) {
            if (key === "analyteId" || key === "testId")
              output[key] = data[key]
            return (child[key] = data[key]);
          }
          return key;
        });
        if (Object.keys(child).length > 0) {
          result[item.sectionName] = child;
          if (item.sectionName === "technicianInfo") {
            output["technicianInformationDTO"] = child
          } else if (item.sectionName === "timeInfo") {
            output["timeInformationDTO"] = child
          } else if (item.sectionName === "testDetailInfo") {
            output["timeDetailDTO"] = child
          } else if (item.sectionName === "requiredInfo") {
            output["sectionName"] = child
          }
        }
      }
      const i = {}
      if (data['createdOn']) {
        i["resultDate"] = new Date(moment(data['createdOn']).format()).getTime()
      }
      if (data['createdBy']) {
        i["resultedBy"] = data['createdBy']
      }
      output["sectionName"] = i
      result.position = data.position
      output.analytesId = output.analyteId
      output.sampleId = [data.sampleName]
      output.testName = data.testName
      if (output["technicianInformationDTO"])
        output["technicianInformationDTO"].approverDepartment = form.getFieldValue("approverDepartment")
      data.associatedSamples = data.sampleName
      return result;
    });

    if (isEdit) {
      output.id = params.id
      output.description = params.description
      output.testInformationCode = params.testInformationCode
      output.status = params.status
      const res = await apiAddTestInformation(output);
      if (res.status === 201) {
        message.success("Sữa phân tích mẫu thành công");
        handleClose();
        afterSubmit();
      } else {
        message.error("Sữa phân tích mẫu không thành công");
      }
    } else {
      const res = await apiAddTestInformation(output);
      if (res.status === 201) {
        message.success("Thêm phân tích mẫu thành công");
        handleClose();
        afterSubmit();
      } else {
        message.error(t("Thêm phân tích mẫu không thành công"));
      }
    }
  };

  useEffect(() => {
    if (!isMounted) return;
    if (!submitting) {
      setVisible(false);
      form.resetFields();
      if (afterSubmit) {
        afterSubmit();
      }
    }
    // eslint-disable-next-line
  }, [submitting]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      close: handleClose,
      openTestForm: handleOpen,
      setFieldsValue: setFieldsValue,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line
  }, []);

  const handleChangeForm = (key, v) => {
    let value = form.getFieldsValue();
    if (key === "sampleNameDetail") {
      form.setFieldsValue({
        ...value,
        'sampleIdInternal': v?.generalInformation?.sampleIdInternal,
        'sampleIdExternal': v?.generalInformation?.sampleName,
        // 'sampleName': v?.generalInformation?.sampleName,
        'sampleId': v?.generalInformation?.sampleId
      });
      return;
    }
    if (key === "analyteDetail") {
      let keys = 'hours', duration = 0;
      if (v?.timeTurnaround) {
        duration = v?.timeTurnaround
      }
      else if (v?.dayTurnaround) {
        keys = 'days';
        duration = v?.dayTurnaround
      }
      if (!isEdit) {
        if (key === "hours")
          form.setFieldsValue({
            ...value,
            'entryDate': moment().add(duration, keys),
            'entryTime': moment().add(duration, keys),
          });
        else
          form.setFieldsValue({
            ...value,
            'entryDate': moment().add(duration, keys),
          });
      }
      return
    }
    if (key === "sampleTypeId") {
      setTestId(x => {
        return {
          ...x,
          sampleTypeId: v
        }
      })
      return;
    }
    if (key === "dataTestId" || key === "approverDepartment") {
      const out = {
        ...value,
        [key]: v,
        // 'testName': v?.name,
        // 'sampleType': v?.sampleTypes?.[0]?.templateName,
        // 'sampleTypeId': v?.sampleTypes?.[0]?.id,

      }
      form.setFieldsValue(out);
      setTestId({
        ...v,
        [key]: v,
        // 'testName': v?.name,
        // 'sampleType': v?.sampleTypes?.[0]?.templateName,
        // 'sampleTypeId': v?.sampleTypes?.[0]?.id,
        'sampleTypes': v?.sampleTypes,
      });
      return
    }
    form.setFieldsValue({
      ...value,
      [key]: v,
    });
  }

  return (
    <div>
      <Modal
        centered={true}
        title={t("Add Test")}
        visible={visible}
        onOk={handleOk}
        confirmLoading={submitting}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        width={1000}
        forceRender
      >
        <Form
          ref={formRef}
          form={form}
          name="basic"
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          // initialValues={initialValueForm}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {configData?.configData?.config?.map((item, idx) => (
            <SectionRender
              key={idx}
              item={item}
              handleChangeForm={handleChangeForm}
              params={params}
              form={form}
              testId={testId}
              hiddenInputs={hiddenInputs}
            />
          ))}
        </Form>
      </Modal>
    </div>
  );
};

TestForm.propTypes = {
  actionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterSubmit: PropTypes.func,
};

export default TestForm;