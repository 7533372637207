export const NET_WEIGHT_UNIT = ["bịch", "ống", "chai", "g", "mg", "l", "ml"];

export const PACKAGE_SIZE = ["kg", "g", "mg", "ug", "l", "ml", "ul"];

export const status = [
  "Received",
  "In Cart",
  "Disposed",
  "Expected",
  "Shipped",
  "Stored",
  "In Transit",
  "Requested",
  "Checked Out",
  "Under Testing",
];

export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  // total: 20,
  defaultCurrent: 1,
  pageSizeOptions: ["10", "20", "30", "50"],
  showSizeChanger: true,
  position: ["bottomRight"],
};

export const ROLES = {
  NT_Core: 'NT_Core',
  NT_Sample: "Sample",
  NT_Test: "NT_Test",
  NT_Dashboard: "NT_Dashboard",
  NT_Client: "NT_Client",
  NT_Admin: "NT_Admin",
  NT_Task: "NT_Task",
  NT_Document: "NT_Document"
};

export const TYPE = {
  textbox: "textbox",
  upload: "upload",
  date: "date",
  time: "time",
  dropdown: "dropdown",
  number: "number",
  checkbox: "checkbox",
};
