class AgGridData {
    constructor(data) {
        this.data = data;
    }

    list(params) {
        const limitTmp = params.limit || 10;
        const pageTmp = params.page || 1;
        const totalPage = Math.ceil(this.data.length / limitTmp);
        const nextPage = pageTmp < totalPage ? pageTmp + 1 : totalPage;
        const result = this.data.filter((item, index) => index >= (pageTmp - 1) * limitTmp && index < pageTmp * limitTmp);
        return {
            status: 200,
            data: {
                totalPage,
                currentPage: pageTmp,
                nextPage,
                rows: result
            }
        };
    }
}

/**
 * data
 * * */
const data = [{
    athlete: 'Michael Phelps',
    age: 23,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 8,
    silver: 0,
    bronze: 0,
    total: 8
}, {
    athlete: 'Michael Phelps',
    age: 19,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 6,
    silver: 0,
    bronze: 2,
    total: 8
}, {
    athlete: 'Michael Phelps',
    age: 27,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 4,
    silver: 2,
    bronze: 0,
    total: 6
}, {
    athlete: 'Natalie Coughlin',
    age: 25,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 3,
    total: 6
}, {
    athlete: 'Aleksey Nemov',
    age: 24,
    country: 'Russia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Gymnastics',
    gold: 2,
    silver: 1,
    bronze: 3,
    total: 6
}, {
    athlete: 'Alicia Coutts',
    age: 24,
    country: 'Australia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 3,
    bronze: 1,
    total: 5
}, {
    athlete: 'Missy Franklin',
    age: 17,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 4,
    silver: 0,
    bronze: 1,
    total: 5
}, {
    athlete: 'Ryan Lochte',
    age: 27,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 2,
    silver: 2,
    bronze: 1,
    total: 5
}, {
    athlete: 'Allison Schmitt',
    age: 22,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 3,
    silver: 1,
    bronze: 1,
    total: 5
}, {
    athlete: 'Natalie Coughlin',
    age: 21,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 2,
    silver: 2,
    bronze: 1,
    total: 5
}, {
    athlete: 'Ian Thorpe',
    age: 17,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 3,
    silver: 2,
    bronze: 0,
    total: 5
}, {
    athlete: 'Dara Torres',
    age: 33,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 3,
    total: 5
}, {
    athlete: 'Cindy Klassen',
    age: 26,
    country: 'Canada',
    year: 2006,
    date: '26/02/2006',
    sport: 'Speed Skating',
    gold: 1,
    silver: 2,
    bronze: 2,
    total: 5
}, {
    athlete: 'Nastia Liukin',
    age: 18,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Gymnastics',
    gold: 1,
    silver: 3,
    bronze: 1,
    total: 5
}, {
    athlete: 'Marit Bjørgen',
    age: 29,
    country: 'Norway',
    year: 2010,
    date: '28/02/2010',
    sport: 'Cross Country Skiing',
    gold: 3,
    silver: 1,
    bronze: 1,
    total: 5
}, {
    athlete: 'Sun Yang',
    age: 20,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 1,
    total: 4
}, {
    athlete: 'Kirsty Coventry',
    age: 24,
    country: 'Zimbabwe',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 3,
    bronze: 0,
    total: 4
}, {
    athlete: 'Libby Lenton-Trickett',
    age: 23,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 1,
    total: 4
}, {
    athlete: 'Ryan Lochte',
    age: 24,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 2,
    total: 4
}, {
    athlete: 'Inge de Bruijn',
    age: 30,
    country: 'Netherlands',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 2,
    total: 4
}, {
    athlete: 'Petria Thomas',
    age: 28,
    country: 'Australia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 3,
    silver: 1,
    bronze: 0,
    total: 4
}, {
    athlete: 'Ian Thorpe',
    age: 21,
    country: 'Australia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 1,
    total: 4
}, {
    athlete: 'Inge de Bruijn',
    age: 27,
    country: 'Netherlands',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 3,
    silver: 1,
    bronze: 0,
    total: 4
}, {
    athlete: 'Gary Hall Jr.',
    age: 25,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 1,
    total: 4
}, {
    athlete: 'Michael Klim',
    age: 23,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 2,
    bronze: 0,
    total: 4
}, {
    athlete: 'Susie O\'Neill',
    age: 27,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 1,
    silver: 3,
    bronze: 0,
    total: 4
}, {
    athlete: 'Jenny Thompson',
    age: 27,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 3,
    silver: 0,
    bronze: 1,
    total: 4
}, {
    athlete: 'Pieter van den Hoogenband',
    age: 22,
    country: 'Netherlands',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 2,
    total: 4
}, {
    athlete: 'An Hyeon-Su',
    age: 20,
    country: 'South Korea',
    year: 2006,
    date: '26/02/2006',
    sport: 'Short-Track Speed Skating',
    gold: 3,
    silver: 0,
    bronze: 1,
    total: 4
}, {
    athlete: 'Aliya Mustafina',
    age: 17,
    country: 'Russia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Gymnastics',
    gold: 1,
    silver: 1,
    bronze: 2,
    total: 4
}, {
    athlete: 'Shawn Johnson',
    age: 16,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Gymnastics',
    gold: 1,
    silver: 3,
    bronze: 0,
    total: 4
}, {
    athlete: 'Dmitry Sautin',
    age: 26,
    country: 'Russia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Diving',
    gold: 1,
    silver: 1,
    bronze: 2,
    total: 4
}, {
    athlete: 'Leontien Zijlaard-van Moorsel',
    age: 30,
    country: 'Netherlands',
    year: 2000,
    date: '01/10/2000',
    sport: 'Cycling',
    gold: 3,
    silver: 1,
    bronze: 0,
    total: 4
}, {
    athlete: 'Petter Northug Jr.',
    age: 24,
    country: 'Norway',
    year: 2010,
    date: '28/02/2010',
    sport: 'Cross Country Skiing',
    gold: 2,
    silver: 1,
    bronze: 1,
    total: 4
}, {
    athlete: 'Ole Einar Bjørndalen',
    age: 28,
    country: 'Norway',
    year: 2002,
    date: '24/02/2002',
    sport: 'Biathlon',
    gold: 4,
    silver: 0,
    bronze: 0,
    total: 4
}, {
    athlete: 'Janica Kostelic',
    age: 20,
    country: 'Croatia',
    year: 2002,
    date: '24/02/2002',
    sport: 'Alpine Skiing',
    gold: 3,
    silver: 1,
    bronze: 0,
    total: 4
}, {
    athlete: 'Nathan Adrian',
    age: 23,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Yannick Agnel',
    age: 20,
    country: 'France',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Brittany Elmslie',
    age: 18,
    country: 'Australia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Matt Grevers',
    age: 27,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Ryosuke Irie',
    age: 22,
    country: 'Japan',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Cullen Jones',
    age: 28,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Ranomi Kromowidjojo',
    age: 21,
    country: 'Netherlands',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Camille Muffat',
    age: 22,
    country: 'France',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Mel Schlanger',
    age: 25,
    country: 'Australia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Emily Seebohm',
    age: 20,
    country: 'Australia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Rebecca Soni',
    age: 25,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Satomi Suzuki',
    age: 21,
    country: 'Japan',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 2,
    total: 3
}, {
    athlete: 'Dana Vollmer',
    age: 24,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Alain Bernard',
    age: 25,
    country: 'France',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'László Cseh Jr.',
    age: 22,
    country: 'Hungary',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 3,
    bronze: 0,
    total: 3
}, {
    athlete: 'Matt Grevers',
    age: 23,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Margaret Hoelzer',
    age: 25,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Katie Hoff',
    age: 19,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 2,
    total: 3
}, {
    athlete: 'Leisel Jones',
    age: 22,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Kosuke Kitajima',
    age: 25,
    country: 'Japan',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Andrew Lauterstein',
    age: 21,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 2,
    total: 3
}, {
    athlete: 'Jason Lezak',
    age: 32,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Pang Jiaying',
    age: 23,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 2,
    total: 3
}, {
    athlete: 'Aaron Peirsol',
    age: 25,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Steph Rice',
    age: 20,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Jess Schipper',
    age: 21,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 2,
    total: 3
}, {
    athlete: 'Rebecca Soni',
    age: 21,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Eamon Sullivan',
    age: 22,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Dara Torres',
    age: 41,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 3,
    bronze: 0,
    total: 3
}, {
    athlete: 'Amanda Beard',
    age: 22,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Antje Buschschulte',
    age: 25,
    country: 'Germany',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 3,
    total: 3
}, {
    athlete: 'Kirsty Coventry',
    age: 20,
    country: 'Zimbabwe',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Ian Crocker',
    age: 21,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Grant Hackett',
    age: 24,
    country: 'Australia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Brendan Hansen',
    age: 22,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Jodie Henry',
    age: 20,
    country: 'Australia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Otylia Jedrzejczak',
    age: 20,
    country: 'Poland',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Leisel Jones',
    age: 18,
    country: 'Australia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Kosuke Kitajima',
    age: 21,
    country: 'Japan',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Laure Manaudou',
    age: 17,
    country: 'France',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Aaron Peirsol',
    age: 21,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Kaitlin Sandeno',
    age: 21,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Roland Schoeman',
    age: 24,
    country: 'South Africa',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Pieter van den Hoogenband',
    age: 26,
    country: 'Netherlands',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Therese Alshammar',
    age: 23,
    country: 'Sweden',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Yana Klochkova',
    age: 18,
    country: 'Ukraine',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Lenny Krayzelburg',
    age: 24,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Massimiliano Rosolino',
    age: 22,
    country: 'Italy',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Petria Thomas',
    age: 25,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Matt Welsh',
    age: 23,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Lee Jeong-Su',
    age: 20,
    country: 'South Korea',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Apolo Anton Ohno',
    age: 27,
    country: 'United States',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 0,
    silver: 1,
    bronze: 2,
    total: 3
}, {
    athlete: 'Wang Meng',
    age: 24,
    country: 'China',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Jin Seon-Yu',
    age: 17,
    country: 'South Korea',
    year: 2006,
    date: '26/02/2006',
    sport: 'Short-Track Speed Skating',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Lee Ho-Seok',
    age: 19,
    country: 'South Korea',
    year: 2006,
    date: '26/02/2006',
    sport: 'Short-Track Speed Skating',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Apolo Anton Ohno',
    age: 23,
    country: 'United States',
    year: 2006,
    date: '26/02/2006',
    sport: 'Short-Track Speed Skating',
    gold: 1,
    silver: 0,
    bronze: 2,
    total: 3
}, {
    athlete: 'Wang Meng',
    age: 20,
    country: 'China',
    year: 2006,
    date: '26/02/2006',
    sport: 'Short-Track Speed Skating',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Marc Gagnon',
    age: 26,
    country: 'Canada',
    year: 2002,
    date: '24/02/2002',
    sport: 'Short-Track Speed Skating',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Yang Yang (A)',
    age: 25,
    country: 'China',
    year: 2002,
    date: '24/02/2002',
    sport: 'Short-Track Speed Skating',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Stephanie Beckert',
    age: 21,
    country: 'Germany',
    year: 2010,
    date: '28/02/2010',
    sport: 'Speed Skating',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Martina Sáblíková',
    age: 22,
    country: 'Czech Republic',
    year: 2010,
    date: '28/02/2010',
    sport: 'Speed Skating',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Enrico Fabris',
    age: 24,
    country: 'Italy',
    year: 2006,
    date: '26/02/2006',
    sport: 'Speed Skating',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Chad Hedrick',
    age: 28,
    country: 'United States',
    year: 2006,
    date: '26/02/2006',
    sport: 'Speed Skating',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Jochem Uytdehaage',
    age: 25,
    country: 'Netherlands',
    year: 2002,
    date: '24/02/2002',
    sport: 'Speed Skating',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Sabine Völker',
    age: 28,
    country: 'Germany',
    year: 2002,
    date: '24/02/2002',
    sport: 'Speed Skating',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Gregor Schlierenzauer',
    age: 20,
    country: 'Austria',
    year: 2010,
    date: '28/02/2010',
    sport: 'Ski Jumping',
    gold: 1,
    silver: 0,
    bronze: 2,
    total: 3
}, {
    athlete: 'Lars Bystøl',
    age: 27,
    country: 'Norway',
    year: 2006,
    date: '26/02/2006',
    sport: 'Ski Jumping',
    gold: 1,
    silver: 0,
    bronze: 2,
    total: 3
}, {
    athlete: 'Johnny Spillane',
    age: 29,
    country: 'United States',
    year: 2010,
    date: '28/02/2010',
    sport: 'Nordic Combined',
    gold: 0,
    silver: 3,
    bronze: 0,
    total: 3
}, {
    athlete: 'Felix Gottwald',
    age: 30,
    country: 'Austria',
    year: 2006,
    date: '26/02/2006',
    sport: 'Nordic Combined',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Georg Hettich',
    age: 27,
    country: 'Germany',
    year: 2006,
    date: '26/02/2006',
    sport: 'Nordic Combined',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Felix Gottwald',
    age: 26,
    country: 'Austria',
    year: 2002,
    date: '24/02/2002',
    sport: 'Nordic Combined',
    gold: 0,
    silver: 0,
    bronze: 3,
    total: 3
}, {
    athlete: 'Samppa Lajunen',
    age: 22,
    country: 'Finland',
    year: 2002,
    date: '24/02/2002',
    sport: 'Nordic Combined',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Aly Raisman',
    age: 18,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Gymnastics',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Kohei Uchimura',
    age: 23,
    country: 'Japan',
    year: 2012,
    date: '12/08/2012',
    sport: 'Gymnastics',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Zou Kai',
    age: 24,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Gymnastics',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Cheng Fei',
    age: 20,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Gymnastics',
    gold: 1,
    silver: 0,
    bronze: 2,
    total: 3
}, {
    athlete: 'Yang Wei',
    age: 28,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Gymnastics',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Yang Yilin',
    age: 15,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Gymnastics',
    gold: 1,
    silver: 0,
    bronze: 2,
    total: 3
}, {
    athlete: 'Zou Kai',
    age: 20,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Gymnastics',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Marian Dragulescu',
    age: 23,
    country: 'Romania',
    year: 2004,
    date: '29/08/2004',
    sport: 'Gymnastics',
    gold: 0,
    silver: 1,
    bronze: 2,
    total: 3
}, {
    athlete: 'Paul Hamm',
    age: 21,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Gymnastics',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Carly Patterson',
    age: 16,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Gymnastics',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Catalina Ponor',
    age: 16,
    country: 'Romania',
    year: 2004,
    date: '29/08/2004',
    sport: 'Gymnastics',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Simona Amânar',
    age: 20,
    country: 'Romania',
    year: 2000,
    date: '01/10/2000',
    sport: 'Gymnastics',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Svetlana Khorkina',
    age: 21,
    country: 'Russia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Gymnastics',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Yekaterina Lobaznyuk',
    age: 17,
    country: 'Russia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Gymnastics',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Yelena Zamolodchikova',
    age: 17,
    country: 'Russia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Gymnastics',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Guo Shuang',
    age: 26,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Cycling',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Chris Hoy',
    age: 32,
    country: 'Great Britain',
    year: 2008,
    date: '24/08/2008',
    sport: 'Cycling',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Bradley Wiggins',
    age: 24,
    country: 'Great Britain',
    year: 2004,
    date: '29/08/2004',
    sport: 'Cycling',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Florian Rousseau',
    age: 26,
    country: 'France',
    year: 2000,
    date: '01/10/2000',
    sport: 'Cycling',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Justyna Kowalczyk',
    age: 27,
    country: 'Poland',
    year: 2010,
    date: '28/02/2010',
    sport: 'Cross Country Skiing',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Johan Olsson',
    age: 29,
    country: 'Sweden',
    year: 2010,
    date: '28/02/2010',
    sport: 'Cross Country Skiing',
    gold: 1,
    silver: 0,
    bronze: 2,
    total: 3
}, {
    athlete: 'Stefania Belmondo',
    age: 33,
    country: 'Italy',
    year: 2002,
    date: '24/02/2002',
    sport: 'Cross Country Skiing',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Yuliya Chepalova',
    age: 25,
    country: 'Russia',
    year: 2002,
    date: '24/02/2002',
    sport: 'Cross Country Skiing',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Frode Estil',
    age: 29,
    country: 'Norway',
    year: 2002,
    date: '24/02/2002',
    sport: 'Cross Country Skiing',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Bente Skari-Martinsen',
    age: 29,
    country: 'Norway',
    year: 2002,
    date: '24/02/2002',
    sport: 'Cross Country Skiing',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Magdalena Neuner',
    age: 23,
    country: 'Germany',
    year: 2010,
    date: '28/02/2010',
    sport: 'Biathlon',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Emil Hegle Svendsen',
    age: 24,
    country: 'Norway',
    year: 2010,
    date: '28/02/2010',
    sport: 'Biathlon',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Albina Akhatova',
    age: 29,
    country: 'Russia',
    year: 2006,
    date: '26/02/2006',
    sport: 'Biathlon',
    gold: 1,
    silver: 0,
    bronze: 2,
    total: 3
}, {
    athlete: 'Ole Einar Bjørndalen',
    age: 32,
    country: 'Norway',
    year: 2006,
    date: '26/02/2006',
    sport: 'Biathlon',
    gold: 0,
    silver: 2,
    bronze: 1,
    total: 3
}, {
    athlete: 'Sven Fischer',
    age: 34,
    country: 'Germany',
    year: 2006,
    date: '26/02/2006',
    sport: 'Biathlon',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Martina Glagow-Beck',
    age: 26,
    country: 'Germany',
    year: 2006,
    date: '26/02/2006',
    sport: 'Biathlon',
    gold: 0,
    silver: 3,
    bronze: 0,
    total: 3
}, {
    athlete: 'Michael Greis',
    age: 29,
    country: 'Germany',
    year: 2006,
    date: '26/02/2006',
    sport: 'Biathlon',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Kati Wilhelm',
    age: 29,
    country: 'Germany',
    year: 2006,
    date: '26/02/2006',
    sport: 'Biathlon',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Kati Wilhelm',
    age: 25,
    country: 'Germany',
    year: 2002,
    date: '24/02/2002',
    sport: 'Biathlon',
    gold: 2,
    silver: 1,
    bronze: 0,
    total: 3
}, {
    athlete: 'Yohan Blake',
    age: 22,
    country: 'Jamaica',
    year: 2012,
    date: '12/08/2012',
    sport: 'Athletics',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Usain Bolt',
    age: 25,
    country: 'Jamaica',
    year: 2012,
    date: '12/08/2012',
    sport: 'Athletics',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Allyson Felix',
    age: 26,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Athletics',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Shelly-Ann Fraser-Pryce',
    age: 25,
    country: 'Jamaica',
    year: 2012,
    date: '12/08/2012',
    sport: 'Athletics',
    gold: 1,
    silver: 2,
    bronze: 0,
    total: 3
}, {
    athlete: 'Carmelita Jeter',
    age: 32,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Athletics',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Usain Bolt',
    age: 21,
    country: 'Jamaica',
    year: 2008,
    date: '24/08/2008',
    sport: 'Athletics',
    gold: 3,
    silver: 0,
    bronze: 0,
    total: 3
}, {
    athlete: 'Veronica Campbell-Brown',
    age: 22,
    country: 'Jamaica',
    year: 2004,
    date: '29/08/2004',
    sport: 'Athletics',
    gold: 2,
    silver: 0,
    bronze: 1,
    total: 3
}, {
    athlete: 'Justin Gatlin',
    age: 22,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Athletics',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Bode Miller',
    age: 32,
    country: 'United States',
    year: 2010,
    date: '28/02/2010',
    sport: 'Alpine Skiing',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Aksel Lund Svindal',
    age: 27,
    country: 'Norway',
    year: 2010,
    date: '28/02/2010',
    sport: 'Alpine Skiing',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Anja Pärson',
    age: 24,
    country: 'Sweden',
    year: 2006,
    date: '26/02/2006',
    sport: 'Alpine Skiing',
    gold: 1,
    silver: 0,
    bronze: 2,
    total: 3
}, {
    athlete: 'Stephan Eberharter',
    age: 32,
    country: 'Austria',
    year: 2002,
    date: '24/02/2002',
    sport: 'Alpine Skiing',
    gold: 1,
    silver: 1,
    bronze: 1,
    total: 3
}, {
    athlete: 'Ding Ning',
    age: 22,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Table Tennis',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Feng Tian Wei',
    age: 25,
    country: 'Singapore',
    year: 2012,
    date: '12/08/2012',
    sport: 'Table Tennis',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Li Xiaoxia',
    age: 24,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Table Tennis',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Dmitrij Ovtcharov',
    age: 23,
    country: 'Germany',
    year: 2012,
    date: '12/08/2012',
    sport: 'Table Tennis',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Wang Hao',
    age: 28,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Table Tennis',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Zhang Jike',
    age: 24,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Table Tennis',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Guo Yue',
    age: 20,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Table Tennis',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Ma Lin',
    age: 28,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Table Tennis',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Wang Hao',
    age: 24,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Table Tennis',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Wang Liqin',
    age: 30,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Table Tennis',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Wang Nan',
    age: 29,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Table Tennis',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Zhang Yining',
    age: 26,
    country: 'China',
    year: 2008,
    date: '24/08/2008',
    sport: 'Table Tennis',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Zhang Yining',
    age: 22,
    country: 'China',
    year: 2004,
    date: '29/08/2004',
    sport: 'Table Tennis',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Kong Linghui',
    age: 24,
    country: 'China',
    year: 2000,
    date: '01/10/2000',
    sport: 'Table Tennis',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Li Ju',
    age: 24,
    country: 'China',
    year: 2000,
    date: '01/10/2000',
    sport: 'Table Tennis',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Liu Guoliang',
    age: 24,
    country: 'China',
    year: 2000,
    date: '01/10/2000',
    sport: 'Table Tennis',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Wang Nan',
    age: 21,
    country: 'China',
    year: 2000,
    date: '01/10/2000',
    sport: 'Table Tennis',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Viktoriya Azarenko',
    age: 22,
    country: 'Belarus',
    year: 2012,
    date: '12/08/2012',
    sport: 'Tennis',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Mike Bryan',
    age: 34,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Tennis',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Andy Murray',
    age: 25,
    country: 'Great Britain',
    year: 2012,
    date: '12/08/2012',
    sport: 'Tennis',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Serena Williams',
    age: 30,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Tennis',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Fernando González',
    age: 24,
    country: 'Chile',
    year: 2004,
    date: '29/08/2004',
    sport: 'Tennis',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Nicolás Massú',
    age: 26,
    country: 'Chile',
    year: 2004,
    date: '29/08/2004',
    sport: 'Tennis',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Venus Williams',
    age: 20,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Tennis',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Ona Carbonell',
    age: 22,
    country: 'Spain',
    year: 2012,
    date: '12/08/2012',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Andrea Fuentes',
    age: 29,
    country: 'Spain',
    year: 2012,
    date: '12/08/2012',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Huang Xuechen',
    age: 22,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Nataliya Ishchenko',
    age: 26,
    country: 'Russia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Synchronized Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Liu Ou',
    age: 25,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Svetlana Romashina',
    age: 22,
    country: 'Russia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Synchronized Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Anastasiya Davydova',
    age: 25,
    country: 'Russia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Synchronized Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Andrea Fuentes',
    age: 25,
    country: 'Spain',
    year: 2008,
    date: '24/08/2008',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Gemma Mengual',
    age: 31,
    country: 'Spain',
    year: 2008,
    date: '24/08/2008',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Anastasiya Yermakova',
    age: 25,
    country: 'Russia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Synchronized Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Alison Bartosik',
    age: 21,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Anastasiya Davydova',
    age: 21,
    country: 'Russia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Synchronized Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Anna Kozlova',
    age: 31,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Miya Tachibana',
    age: 29,
    country: 'Japan',
    year: 2004,
    date: '29/08/2004',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Miho Takeda',
    age: 27,
    country: 'Japan',
    year: 2004,
    date: '29/08/2004',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Anastasiya Yermakova',
    age: 21,
    country: 'Russia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Synchronized Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Olga Brusnikina',
    age: 21,
    country: 'Russia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Synchronized Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Mariya Kiselyova',
    age: 25,
    country: 'Russia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Synchronized Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Miya Tachibana',
    age: 25,
    country: 'Japan',
    year: 2000,
    date: '01/10/2000',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Miho Takeda',
    age: 24,
    country: 'Japan',
    year: 2000,
    date: '01/10/2000',
    sport: 'Synchronized Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Becky Adlington',
    age: 23,
    country: 'Great Britain',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Bronte Barratt',
    age: 23,
    country: 'Australia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Elizabeth Beisel',
    age: 19,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Mireia Belmonte',
    age: 21,
    country: 'Spain',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Ricky Berens',
    age: 24,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Aleksandra Gerasimenya',
    age: 26,
    country: 'Belarus',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Brendan Hansen',
    age: 30,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Jessica Hardy',
    age: 25,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Chad le Clos',
    age: 20,
    country: 'South Africa',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Clément Lefert',
    age: 24,
    country: 'France',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Amaury Leveaux',
    age: 26,
    country: 'France',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'James Magnussen',
    age: 21,
    country: 'Australia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Takeshi Matsuda',
    age: 28,
    country: 'Japan',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Oussama Mellouli',
    age: 28,
    country: 'Tunisia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Park Tae-Hwan',
    age: 22,
    country: 'South Korea',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Christian Sprenger',
    age: 26,
    country: 'Australia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Jeremy Stravius',
    age: 24,
    country: 'France',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Aya Terakawa',
    age: 27,
    country: 'Japan',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Nick Thoman',
    age: 26,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Marleen Veldhuis',
    age: 33,
    country: 'Netherlands',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Ye Shiwen',
    age: 16,
    country: 'China',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Becky Adlington',
    age: 19,
    country: 'Great Britain',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Leith Brodie',
    age: 22,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Cate Campbell',
    age: 16,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'César Cielo Filho',
    age: 21,
    country: 'Brazil',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Hugues Duboscq',
    age: 26,
    country: 'France',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Felicity Galvez',
    age: 23,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Grant Hackett',
    age: 28,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Kara Lynn Joyce',
    age: 22,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Amaury Leveaux',
    age: 22,
    country: 'France',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Christine Magnuson',
    age: 22,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Patrick Murphy',
    age: 24,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Park Tae-Hwan',
    age: 18,
    country: 'South Korea',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Shayne Reese',
    age: 25,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Brenton Rickard',
    age: 24,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Mel Schlanger',
    age: 21,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Julia Smit',
    age: 20,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Britta Steffen',
    age: 24,
    country: 'Germany',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Hayden Stoeckel',
    age: 24,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Matt Targett',
    age: 22,
    country: 'Australia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Peter Vanderkaay',
    age: 24,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Arkady Vyachanin',
    age: 24,
    country: 'Russia',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Garrett Weber-Gale',
    age: 23,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Lindsay Benko',
    age: 27,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Gary Hall Jr.',
    age: 29,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Brooke Hanson',
    age: 26,
    country: 'Australia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Kara Lynn Joyce',
    age: 18,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Klete Keller',
    age: 22,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Yana Klochkova',
    age: 22,
    country: 'Ukraine',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Rachel Komisarz',
    age: 27,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Libby Lenton-Trickett',
    age: 19,
    country: 'Australia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Jason Lezak',
    age: 28,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Ryan Lochte',
    age: 20,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Alice Mills',
    age: 18,
    country: 'Australia',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Tomomi Morita',
    age: 19,
    country: 'Japan',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Markus Rogan',
    age: 22,
    country: 'Austria',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Jenny Thompson',
    age: 31,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Franziska van Almsick',
    age: 26,
    country: 'Germany',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Neil Walker',
    age: 28,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Amanda Weir',
    age: 18,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Takashi Yamamoto',
    age: 26,
    country: 'Japan',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Brooke Bennett',
    age: 20,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Beatrice Coada-Caslaru',
    age: 25,
    country: 'Romania',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Josh Davis',
    age: 28,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Tom Dolan',
    age: 25,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Anthony Ervin',
    age: 19,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Domenico Fioravanti',
    age: 23,
    country: 'Italy',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Grant Hackett',
    age: 20,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Geoff Huegill',
    age: 21,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Leisel Jones',
    age: 15,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Klete Keller',
    age: 18,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Jason Lezak',
    age: 24,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Diana Mocanu',
    age: 16,
    country: 'Romania',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Martina Moravcová',
    age: 24,
    country: 'Slovakia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Ed Moses',
    age: 20,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Diana Munz',
    age: 18,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Mai Nakamura',
    age: 21,
    country: 'Japan',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Todd Pearson',
    age: 22,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Adam Pine',
    age: 24,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Claudia Poll',
    age: 27,
    country: 'Costa Rica',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Megan Quann-Jendrick',
    age: 16,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Giaan Rooney',
    age: 17,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Courtney Shealy',
    age: 22,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Ashley Tappin',
    age: 25,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Stev Theloke',
    age: 22,
    country: 'Germany',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Amy Van Dyken',
    age: 27,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Neil Walker',
    age: 24,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'J. R. Celski',
    age: 19,
    country: 'United States',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Charles Hamelin',
    age: 25,
    country: 'Canada',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Lee Ho-Seok',
    age: 23,
    country: 'South Korea',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Park Seung-Hui',
    age: 17,
    country: 'South Korea',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 0,
    silver: 0,
    bronze: 2,
    total: 2
}, {
    athlete: 'Katherine Reutter',
    age: 21,
    country: 'United States',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'Seong Si-Baek',
    age: 22,
    country: 'South Korea',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Marianne St-Gelais',
    age: 19,
    country: 'Canada',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'François-Louis Tremblay',
    age: 29,
    country: 'Canada',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 1,
    silver: 0,
    bronze: 1,
    total: 2
}, {
    athlete: 'Zhou Yang',
    age: 18,
    country: 'China',
    year: 2010,
    date: '28/02/2010',
    sport: 'Short-Track Speed Skating',
    gold: 2,
    silver: 0,
    bronze: 0,
    total: 2
}, {
    athlete: 'Choi Eun-Gyeong',
    age: 21,
    country: 'South Korea',
    year: 2006,
    date: '26/02/2006',
    sport: 'Short-Track Speed Skating',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Anouk Leblanc-Boucher',
    age: 21,
    country: 'Canada',
    year: 2006,
    date: '26/02/2006',
    sport: 'Short-Track Speed Skating',
    gold: 0,
    silver: 1,
    bronze: 1,
    total: 2
}, {
    athlete: 'François-Louis Tremblay',
    age: 25,
    country: 'Canada',
    year: 2006,
    date: '26/02/2006',
    sport: 'Short-Track Speed Skating',
    gold: 0,
    silver: 2,
    bronze: 0,
    total: 2
}, {
    athlete: 'Choi Eun-Gyeong',
    age: 17,
    country: 'South Korea',
    year: 2002,
    date: '24/02/2002',
    sport: 'Short-Track Speed Skating',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}, {
    athlete: 'Go Gi-Hyeon',
    age: 15,
    country: 'South Korea',
    year: 2002,
    date: '24/02/2002',
    sport: 'Short-Track Speed Skating',
    gold: 1,
    silver: 1,
    bronze: 0,
    total: 2
}];
export default new AgGridData(data);
