import { takeLatest, call, put } from 'redux-saga/effects';
import {
    doAddClient,
    doAddClientSuccess,
    doAllDone,
    doDeleteClient, doDeleteClientSuccess,
    doEditClient, doEditClientSuccess,
    getList,
    getListSuccess
} from "./slice";
import {apiAddClient, apiDeleteClient, apiEditClient, getListClient} from "../../apis/client";
import {Client} from 'src/mockApi/clientData'

function* fetchList({ payload }) {
    const { status, data } = yield call(getListClient, payload);
    if (status === 200) {
        yield put(getListSuccess(data));
    } else {
        yield put(doAllDone());
    }
}

function* addClient({ payload }) {
    const { status, data } = yield call(apiAddClient, payload);
    if (status === 200) {
        yield put(doAddClientSuccess(data));
    } else {
        yield put(doAllDone());
    }
}

function* deleteClient({ payload }) {
    const { status } = yield call(apiDeleteClient, payload);
    if (status === 200) {
        yield put(doDeleteClientSuccess(payload));
    } else {
        yield put(doAllDone());
    }
}

function* editClient({ payload }) {
    const { status, data } = yield call(apiEditClient, payload);
    if (status === 200) {
        const sample = new Client(data)
        yield put(doEditClientSuccess(sample));
    } else {
        yield put(doAllDone());
    }
}

function* clientSaga() {
    yield takeLatest(getList.type, fetchList);
    yield takeLatest(doAddClient.type, addClient);
    yield takeLatest(doDeleteClient.type, deleteClient);
    yield takeLatest(doEditClient.type, editClient);
}
export default clientSaga;
