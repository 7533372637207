import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getAllAppPermission} from "../apis/permissions";

const UseAllAppPermission = () => {
  const [listAppCode, setListAppCode] = useState([])
  const [fetching, setFetching] = useState(false)

  const fetchAllAppCode = async () => {
    setFetching(true)
    const res = await getAllAppPermission()
    setFetching(false)
    if (res.data.status === 1) {
      setListAppCode(res.data.data)
    } else {
      // console.log('get app code error', res);
    }
  }
  useEffect(() => {
    fetchAllAppCode().then()
  }, [])

  return {data: listAppCode, fetching, fetchAllAppCode}
};

UseAllAppPermission.propTypes = {

};

export default UseAllAppPermission;
