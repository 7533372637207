import axiosService from "src/services/axiosService";
import { CONFIG } from "../../configs";
import { TOKEN_NAME } from "../../constants";

export const getUsersApi = (data) => {
  const token = localStorage.getItem(TOKEN_NAME);
  return axiosService.post(`${CONFIG.API_URL.AUTH}/api/user/list_user`, data, {
    Authorization: `Bearer ${token}`,
  });
};

export const getCurrentUser = (id) => {
  const token = localStorage.getItem(TOKEN_NAME);
  return axiosService.get(
    `${CONFIG.API_URL.AUTH}/api/user/get_user_detail?idUser=${id}`,
    "",
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

export const createUserApi = (data) => {
  const token = localStorage.getItem(TOKEN_NAME);
  return axiosService.post(
    `${CONFIG.API_URL.AUTH}/api/user/create_user_account`,
    data,
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

export const editUserApi = (data) => {
  const token = localStorage.getItem(TOKEN_NAME);
  return axiosService.post(`${CONFIG.API_URL.AUTH}/api/user/edit_user`, data, {
    Authorization: `Bearer ${token}`,
  });
};

export const deleteUserApi = (id) => {
  const token = localStorage.getItem(TOKEN_NAME);
  return axiosService.get(
    `${CONFIG.API_URL.AUTH}/api/user/delete_user?idUser=${id}`,
    "",
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

export const getUserAuthAPI = (params) => {
  const token = localStorage.getItem(TOKEN_NAME);
  return axiosService.get(
    `${CONFIG.API_URL.AUTH}/api/user/get_user_detail`,
    params,
    {
      Authorization: `Bearer ${token}`,
    }
  );
};
export const getUserByAppCode = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  return axiosService.post(
    `${CONFIG.API_URL.AUTH}/api/user/list_user`,
    { listApp: ["Sample"] },
    {
      Authorization: `Bearer ${token}`,
    }
  );
};
