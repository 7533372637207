import {createSlice} from '@reduxjs/toolkit';

export const MODULE_NAME = 'demo'

const initialState = {
    dataList: null,
    dataAgGrid: null,
    dataPivot: null,
    loading: false,
};
const slice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        doGetList: (state, action) => {
            state.loading = true;
        },
        doGetListAgGrid: (state, action) => {
            state.loading = true;
        },
        doGetListPivot: (state, action) => {
            state.loading = true;
        },
        doUpdateItemDemo: (state, action) => {
            state.loading = true;
        },
        doDeleteItemDemo: (state, action) => {
            state.loading = true;
        },
        doAddItemDemo: (state, action) => {
            state.loading = true;
        },
        doGetListSuccess: (state, action) => {
            state.dataList = action.payload;
        },
        doGetListAgGridSuccess: (state, action) => {
            state.dataAgGrid = action.payload;
        },
        doGetListPivotSuccess: (state, action) => {
            state.dataPivot = action.payload;
        },
        doUpdateItemDemoSuccess: (state, action) => {
            state.dataList = action.payload;
        },
        doDeleteItemDemoSuccess: (state, action) => {
            state.dataList = action.payload;
        },
        doAddItemDemoSuccess: (state, action) => {
            state.dataList = action.payload;
        }
    },
});

export const {
    doGetList,
    doGetListAgGrid,
    doGetListPivot,
    doUpdateItemDemo,
    doDeleteItemDemo,
    doAddItemDemo,
    doGetListSuccess,
    doGetListAgGridSuccess,
    doGetListPivotSuccess,
    doUpdateItemDemoSuccess,
    doDeleteItemDemoSuccess,
    doAddItemDemoSuccess } = slice.actions;

export default slice.reducer;
