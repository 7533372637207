import React from 'react';
import {Button, Form, Input, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import {CONFIG} from "../../configs";
import {testApi} from "../../apis/testting";

const TestApi = () => {

  const onFinish = async (values) => {
    if (values.params) {
      values = {
        ...values,
        params: JSON.parse(values.params)
      }
    }

    const res = await testApi(values)
    // console.log(res);
  }

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Url"
          name="url"
        >
          <Input addonBefore={CONFIG.API_URL.BASE} placeholder={"end point"}/>
        </Form.Item>

        <Form.Item label={'method'} name="method">
          <Select style={{ width: 120 }} >
            <Select.Option value="get">GET</Select.Option>
            <Select.Option value="post">POST</Select.Option>
            <Select.Option value="put">PUT</Select.Option>
            <Select.Option value="delete">DELETE</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Params"
          name="params"
        >
          <TextArea />
        </Form.Item>


        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <div>

      </div>
    </div>
  );
};


export default TestApi;
