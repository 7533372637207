export const getNestedKey = (key, parentKey) => {
  const prefix = parentKey ? `${parentKey}.` : "";
  return `${prefix}${key}`;
};

export const getFlatRecord = (flatObj = {}, nestedObj = {}, parentKey = "") => {
  let newFlatObj = { ...flatObj };
  Object.keys(nestedObj).forEach((key) => {
    const value = nestedObj[key];
    const nestedKey = getNestedKey(key, parentKey);

    if (Array.isArray(value) || typeof value !== "object") {
      newFlatObj[nestedKey] = value;
      return;
    }

    if (typeof value === "object" && value !== null) {
      newFlatObj = getFlatRecord(newFlatObj, value, key);
      return;
    }

    newFlatObj[nestedKey] = value;
  });

  return newFlatObj;
};
