import axiosService from 'src/services/axiosService';
import { CONFIG } from '../../../configs';
import TokenUtil from '../Utils/TokenUtil';
import DateUtil from '../Utils/DateUtil';
import { ACTION_TYPES, DATE_FORMAT } from '../Constants';

export function getDocumentList(filterUrl) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/get_list_documents${filterUrl ? filterUrl: ''}`, null, CUSTOM_HEADER).then(({data}) => {
        return data.data;
    }).catch(({data: err}) => {
        return err;
    });
}
export function searchDocumentFromSample(searchStr) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/get_list_documents_v2?${searchStr}`, null, CUSTOM_HEADER).then(({data}) => {
        return data.data;
    }).catch(({data: err}) => {
        return err;
    });
}

export function getTreeData() {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/get_folders`, null, CUSTOM_HEADER).then(({data}) => {
        return data.data;
    }).catch(({data: err}) => {
        return err;
    });
}

export function createFolder(values) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.post(`${CONFIG.API_URL.DOC}/create_folder`, values, CUSTOM_HEADER);
}

export function updateFolder(values) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.post(`${CONFIG.API_URL.DOC}/edit_folder`, values, CUSTOM_HEADER);
}

export function createdDocument(values) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.post(`${CONFIG.API_URL.DOC}/create_file`, values, CUSTOM_HEADER);
}

export function updateDocument(values) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.post(`${CONFIG.API_URL.DOC}/edit_file`, values, CUSTOM_HEADER);
}

export function deleteFolder(deleteID) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/delete_folder?idFolder=${deleteID}`, null, CUSTOM_HEADER);
}

export function deleteDocument(deleteID) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/delete_file?idFile=${deleteID}`, null, CUSTOM_HEADER);
}

export function replaceDocument(payload) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.post(`${CONFIG.API_URL.DOC}/replace_file`, payload, CUSTOM_HEADER);
}

export function publicDocument(fileID) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/public_file?idFile=${fileID}`, null, CUSTOM_HEADER);
}

export function getDocumentDetail(docID) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/get_detail_document?idFile=${docID}`, null, CUSTOM_HEADER).then(({ data}) => {
        if (data.data) {
            return {
                id: data.data.id,
                folderId: data.data.idFolder,
                folderTitle: data.data.folderTitle,
                title: data.data.title,
                type: data.data.type,
                createDate: data.data.createDate,
                createBy: data.data.createBy,
                createName: data.data.createName,
                version: data.data.version,
                status: data.data.status,
                titleEnglish: data.data.titleEnglish,
                _listApprover: data.data._listApprover,
                _listReviewer: data.data._listReviewer,
                accessGroups: data.data.accessGroupList,
                createByGroup: data.data.createGroup,
            }
        }

        return {};
    }).catch(({data: err}) => {
        return err;
    });
}

export function getFolderHistory(folderID) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/get_folder_history?idFolder=${folderID}`, null, CUSTOM_HEADER).then(({ data }) => {
        return (data.data || []).map((item, index) => ({
            key: `${item.IdFolder}-${index}`,
            id: item.IdFolder,
            createdBy: item.CreateBy,
            createdDate: DateUtil.formatDate(new Date(item.CreateDate), DATE_FORMAT.L),
            action: ACTION_TYPES[item.ActionType],
            content: item.ContentUpdate,
        }))
    }).catch(({data: err}) => {
        return err;
    });
}

export function getRemarkReviewList(fileID) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/get_list_remark?idFile=${fileID}`, null, CUSTOM_HEADER).then(({ data }) => {
        return (data.data || []).map((item, index) => ({
            key: `${item.idFile}-${index}`,
            id: item.idFile,
            status: item.status,
            idUser: item.idUser,
            username: item.nameUser,
            content: item.comment,
        }))
    }).catch(({data: err}) => {
        return err;
    });
}

export function getFileById(fileID) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.DOC}/download_file_v3?idFile=${fileID}`, null, CUSTOM_HEADER);
}