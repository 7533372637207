import axiosService from 'src/services/axiosService';
import { CONFIG } from '../../../configs';
import TokenUtil from '../Utils/TokenUtil';
import { loginApi } from '../../../apis/login';

export function getListGroupsByApp(appCode) {
    const CUSTOM_HEADER = TokenUtil.buildCustomHeader();
    return axiosService.get(`${CONFIG.API_URL.AUTH}/api/permissions/app_group_permission`, appCode ? { appCode } : null, CUSTOM_HEADER).then(({ data }) => {
        return (data.data || []).map(item => ({
            key: `${item.Id}-${Date.now()}`,
            value: item.Id,
            label: item.TenGroup,
        }));
    }).catch(({data: error}) => {
        // console.log("error");
        return error;
    });
}

export async function confirmPasswordApi(password) {
    const username = localStorage.getItem("Username");
    const res = await loginApi({
        username,
        password
    })
    return res.data.status === 1;
}