import { takeLatest, call, put } from 'redux-saga/effects';
import {
    getList,
    getListSuccess,
    doAllDone, doAddTest, doDeleteTest, doEditTest, doEditTestSuccess, doDeleteTestSuccess, doAddTestSuccess
} from './slice';
import {Samples} from "../../mockApi/sampleData";
import {apiAddTest, apiDeleteTest, apiEditTest, getListTest} from "../../apis/test";

function* fetchList({ payload }) {
    const { status, data } = yield call(getListTest, payload);
    if (status === 200) {
        yield put(getListSuccess(data));
    } else {
        yield put(doAllDone());
    }
}

function* addTest({ payload }) {
    const { status, data } = yield call(apiAddTest, payload);
    if (status === 200) {
        yield put(doAddTestSuccess(data));
    } else {
        yield put(doAllDone());
    }
}

function* deleteTest({ payload }) {
    const { status } = yield call(apiDeleteTest, payload);
    if (status === 200) {
        yield put(doDeleteTestSuccess(payload));
    } else {
        yield put(doAllDone());
    }
}

function* editTest({ payload }) {
    const { status, data } = yield call(apiEditTest, payload);
    if (status === 200) {
        const sample = new Samples(data)
        yield put(doEditTestSuccess(sample));
    } else {
        yield put(doAllDone());
    }
}

function* testSaga() {
    yield takeLatest(getList.type, fetchList);
    yield takeLatest(doAddTest.type, addTest);
    yield takeLatest(doDeleteTest.type, deleteTest);
    yield takeLatest(doEditTest.type, editTest);
}
export default testSaga;
