import React from 'react';

const DOC_CONTEXT_VALUES = {
    treeData: null,
}

export const GlobalContext = React.createContext(DOC_CONTEXT_VALUES);

export function GlobalProvider({ children, treeData, custodian, accessGroups, refreshTreeData, userList }) {
    return <GlobalContext.Provider value={{treeData, custodian, accessGroups, refreshTreeData, userList}}>{children}</GlobalContext.Provider>;
}


