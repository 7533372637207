import React from "react";
import { Provider } from "react-redux";
// import "tailwindcss/tailwind.css"
import "./App.css";
import "antd/dist/antd.css";
import { AuthProvider } from "./hooks/Auth/useAuth";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./Views/Login";
import Register from "./Views/Register";
import ForgotPassword from "./Views/ForgotPassword";
import ConfirmForgotPassword from "./Views/ConfirmForgotPassword";
import AdminPage from "./Layout/AdminPage";
import NotFountPage from "./Views/NotFound";
import store from "./redux/store/storeConfig";

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/forgot_password/:token" component={ConfirmForgotPassword} />
            <Route path="/" component={AdminPage} />
            <Route path="" exact component={NotFountPage} />
          </Switch>
        </Router>
      </AuthProvider>
    </Provider>
  );
}

export default App;
