import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { timeFormatter } from "../../utils/utils";
import { getReportCodeTest } from "../../apis/testInformation";
import iDate from "utils-format/iDate";

// Register font
Font.register({
  family: "Times-new-roman",
  src: "/fonts/font-times-new-roman.ttf",
});
Font.register({
  family: "Times-new-roman-bold",
  src: "/fonts/font-times-new-roman-bold.ttf",
});
Font.register({
  family: "Times-new-roman-italic",
  src: "/fonts/Times-New-Roman-Italic.ttf",
});

const SamplePdfView = ({ data }) => {
  const [currentDate] = useState(new Date());
  const d = data[0] || {};

  const text = d?.["sampleIdInternal"]?.startsWith("L") ? "lấy" : "gửi"

  const {
    locationName,
    pickingAddress,
    pickingSellType,
    pickingPhone,
    representativePerson,
    importDrug,
    importFacility,
  } = data[0] || {};

  const [numberReport, setNumberReport] = useState(null);

  const getReportCode = async () => {
    const { data } = await getReportCodeTest(d?.id)
    if (data) {
      setNumberReport(`${data}/${iDate().y}/KNĐN`)
    }
  };

  useEffect(() => {
    // handleConvertDataTable();
    getReportCode();
    // handletSamplerInformations();
  }, [data]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {/* HEADER */}
          <View style={[styles.row, header.row]}>
            <View style={header.item1}>
              <Text style={{ fontSize: "10px" }}>SỞ Y TẾ THÀNH PHỐ ĐÀ NẴNG</Text>
              <Text style={{ fontFamily: "Times-new-roman-bold", fontSize: "10px" }}>TRUNG TÂM KIỂM NGHIỆM</Text>
              <Text style={{ fontSize: "7px" }}>118 Lê Đình Lý, Quận Thanh Khê, Thành phố Đà Nẵng
                SĐT: 0236.3810.247
              </Text>
              <View
                style={[
                  styles.underline,
                  {
                    width: "60px",
                    margin: "2px 0px 2px 0px",
                    marginLeft: "53px",
                  },
                ]}
              ></View>
              <Image src={"/images/logo-kndn.png"} style={styles.logo} />
            </View>
            <View style={header.item2}>
              <View style={header.top}>
                <Text style={styles.bold}>
                  CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
                </Text>
                <Text style={[styles.bold, { fontSize: "13px" }]}>
                  Độc lập - Tự do - Hạnh phúc
                </Text>
                <View
                  style={[
                    styles.underline,
                    {
                      width: "150px",
                      margin: "2px 0px 2px 0px",
                      marginLeft: "115px",
                    },
                  ]}
                ></View>

              </View>

              <View style={header.title}>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.bold, { fontSize: "16px", marginLeft: "40px", marginTop: "10px" }]}>
                    PHIẾU KIỂM NGHIỆM / PHÂN TÍCH
                  </Text>
                  <Text style={{ fontSize: "13px" }}>(Bản thảo)</Text>
                </View>
              </View>
            </View>
          </View>

          {/* CONTENT */}
          <View>
            <Text><b style={styles.bold}>Mẫu để kiểm nghiệm: </b> {d?.["sampleName"]}</Text>
            <Text><b style={styles.bold}>Cơ sở sản xuất: </b>{d?.["producerName"]}</Text>
            <Text><b style={styles.bold}>Cơ sở nhập khẩu: </b>{d?.["importDrug"] ? d?.["importFacility"] : "Không"} </Text>
            {/* <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text><b style={styles.bold}>Số lô (SKS): </b>{d?.["chronicleBatchDate"]}</Text>
              <Text style={{ flex: 1 }}>
                {locationName}{" "}
                {locationName && representativePerson ? "-" : null}{" "}
                {representativePerson}
              </Text>
            </View> */}
            <View style={{ flexDirection: "row", justifyContent: "space-between" }} key={1}>
              <View
                style={{
                  width: "33.3333%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text><b style={styles.bold}>Số lô (SKS): </b>{d?.["chronicleBatchDate"]}</Text>
                <Text style={{ flex: 1, width: "100%", textAlign: "left" }}> </Text>
              </View>
              <View
                style={{
                  width: "33.3333%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text><b style={styles.bold}>Ngày sản xuất: </b>{d?.["productionDate"]}</Text>
                <Text style={{ flex: 1, width: "100%", textAlign: "left" }}> </Text>
              </View>
              <Text style={{ width: "33.3333%", textAlign: "right" }}>
                {" "}
                <Text><b style={styles.bold}>Hạn dùng: </b>{d?.["expirationDate"]}</Text>
              </Text>

            </View>
            <Text><b style={styles.bold}>Số giấy đăng ký đăng ký lưu hành hoặc số giấy phép nhập khẩu:</b> {d?.["registerNumber"]} </Text>
            <Text><b style={styles.bold}>Yêu cầu kiểm nghiệm:</b>{d?.["samplerRequired"]}</Text>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text><b style={styles.bold}>Ngày tháng năm nhận mẫu:</b> {Number.isInteger(d?.["samplingDate"] || d?.["submissionDate"]) ? timeFormatter(d?.["samplingDate"] || d?.["submissionDate"]) : (d?.["samplingDate"] || d?.["submissionDate"])} </Text>
              {/* <Text style={{ flex: 1 }}>
                {locationName}{" "}
                {locationName && representativePerson ? "-" : null}{" "}
                {representativePerson}
              </Text> */}
              <Text><b style={styles.bold}>Số đăng ký kiểm nghiệm:</b> {d?.['sampleIdExternal']}</Text>
            </View>
            <Text><b style={styles.bold}>Tiêu chuẩn áp dụng:</b> {d?.['testMethod']}</Text>
            <Text><b style={styles.bold}>Tình trạng mẫu khi nhận và khi mở niêm phong để kiểm nghiệm:</b> {d?.["remark"]} </Text>
            {/* {samplerInformations} */}
            {/*<Text>2. .......................... - Chức vụ: ........................, Trung tâm Kiểm nghiệm thành phố Đà Nẵng.</Text>*/}

            <View style={{ marginBottom: "5px" }}></View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol, styles.col1]}>
                  <Text style={styles.tableCell}>STT</Text>
                </View>
                <View style={[styles.tableCol, styles.col2]}>
                  <Text style={styles.tableCell}>
                    Chỉ tiêu chất lượng
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.col3]}>
                  <Text style={styles.tableCell}>
                    Yêu cầu chất lượng
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.col4]}>
                  <Text style={styles.tableCell}>Kết quả và kết luận</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol, styles.col1, { height: "480px" }]}>
                </View>
                <View style={[styles.tableCol, styles.col2]}>
                  {/* <Text style={styles.tableCell}>{d?.["analytesDTO.analytesId"]}</Text> */}
                </View>
                <View style={[styles.tableCol, styles.col3]}>

                </View>
                <View style={[styles.tableCol, styles.col4]}>
                  {/* <Text style={styles.tableCell}>{d?.["testingResultDTO.sampleTestingResult"]}</Text> */}
                </View>
              </View>
            </View>

            <View style={{ marginTop: "108px" }}><Text></Text></View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol, styles.col1]}>
                  <Text style={styles.tableCell}>STT</Text>
                </View>
                <View style={[styles.tableCol, styles.col2]}>
                  <Text style={styles.tableCell}>
                    Chỉ tiêu chất lượng
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.col3]}>
                  <Text style={styles.tableCell}>
                    Yêu cầu chất lượng
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.col4]}>
                  <Text style={styles.tableCell}>Kết quả và kết luận</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol, styles.col1, { height: "500px" }]}>
                </View>
                <View style={[styles.tableCol, styles.col2]}>
                  {/* <Text style={styles.tableCell}>{d?.["analytesDTO.analytesId"]}</Text> */}
                </View>
                <View style={[styles.tableCol, styles.col3]}>

                </View>
                <View style={[styles.tableCol, styles.col4]}>
                  {/* <Text style={styles.tableCell}>{d?.["testingResultDTO.sampleTestingResult"]}</Text> */}
                </View>
              </View>
            </View>
            <View style={{ marginBottom: "5px" }}></View>


            <Text><b style={styles.bold}>Kết luận: </b></Text>

          </View>
          {/* CONTENT */}



          {/* FOOTER */}
          <View style={styles.footer}>
            <View style={styles.footerItem}>
              <Text style={[styles.italic, { fontSize: "12px" }]}>
                Đà Nẵng, ngày .... tháng{" "}
                .... năm{" "}
                ....
              </Text>
              <Text style={styles.bold}>TRƯỞNG PHÒNG</Text>
              {/* <Text>(Ký và ghi rõ họ tên)</Text> */}
            </View>

            <View style={styles.footerItem}>
              <Text style={[styles.italic, { fontSize: "12px" }]}>
                Đà Nẵng, ngày .... tháng{" "}
                .... năm{" "}
                ....
              </Text>
              <Text style={styles.bold}>KIỂM NGHIỆM VIÊN</Text>
              {/* <Text>(Ký và ghi rõ họ tên)</Text> */}
            </View>
          </View>
          {/* FOOTER */}
        </View>
      </Page>
    </Document>
  );
};

SamplePdfView.propTypes = {};

export default SamplePdfView;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    fontFamily: "Times-new-roman",
    fontStyle: "normal",
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexFlow: "row wrap",
  },
  logo: {
    width: "60px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  bold: {
    fontFamily: "Times-new-roman-bold",
  },
  italic: {
    fontFamily: "Times-new-roman-italic",
  },
  underline: {
    borderBottom: "0.5px solid black",
    display: "inline-block",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "between",
    alignItems: "between",
    marginTop: "5px",
  },
  footerItem: {
    width: '50%',
    textAlign: 'center'
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableCell: {
    // margin: "auto",
    padding: 5,
    // marginTop: 5,
    // marginBottom: 5,
    fontSize: 10,
  },
  col1: {
    width: "10%",
  },
  col2: {
    width: "30%",
  },
  col3: {
    width: "30%",
  },
  col4: {
    width: "30%",
  },
});

const header = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "10px",
  },
  item1: {
    display: "block",
    flex: "0 0 30%",
    maxWidth: "30%",
    textAlign: "center",
    justifyContent: "center",
  },
  item2: {
    display: "block",
    flex: "0 0 70%",
    maxWidth: "70%",
  },
  top: {
    textAlign: "center",
  },
  title: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "10px",
  },
});
