import React from "react";
import PropTypes from "prop-types";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import iDate from "utils-format/iDate";

const TablePdf = ({ data }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.col1]}>
          <Text style={styles.tableCell}>STT</Text>
        </View>
        <View style={[styles.tableCol, styles.col2]}>
          <Text style={styles.tableCell}>
            Tên thuốc, nồng độ, hàm lượng, số đăng ký
          </Text>
        </View>
        <View style={[styles.tableCol, styles.col3]}>
          <Text style={styles.tableCell}>
            Lô sản xuất, Ngày sản xuất, Hạn dùng
          </Text>
        </View>
        <View style={[styles.tableCol, styles.col4]}>
          <Text style={styles.tableCell}>Số lượng gửi</Text>
        </View>
        <View style={[styles.tableCol, styles.col8]}>
          <Text style={styles.tableCell}>Tên nhà sản xuất</Text>
        </View>
        <View style={[styles.tableCol, styles.col6]}>
          <Text style={styles.tableCell}>Tên nhà nhập khẩu</Text>
        </View>
        <View style={[styles.tableCol, styles.col7]}>
          <Text style={styles.tableCell}>Yêu cầu KN</Text>
        </View>
        <View style={[styles.tableCol, styles.col5]}>
          <Text style={styles.tableCell}>Phương pháp thử</Text>
        </View>
      </View>
      {data.map((item, index) => {
        return (
          <React.Fragment key={index + "_row"}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.col1]}>
                <Text style={styles.tableCell}>{item.sampleIdExternal}</Text>
                {/* <Text style={styles.tableCell}>{item.sampleIdInternal}</Text> */}
              </View>
              <View style={[styles.tableCol, styles.col2]}>
                <Text style={styles.tableCell}>{item.sampleName}</Text>
                <Text style={styles.tableCell}>
                  {
                    (item.substanceInformation || []).map(element => {
                      return <Text style={styles.tableCell}>
                        {[element.substanceName, element.substanceContent, element.substanceUnit]
                          .filter((item) => item)
                          .join(" ")}
                      </Text>
                    }
                    )
                  }
                  {/* {[item.substanceName, item.substanceContent, item.substanceUnit]
                    .filter((item) => item)
                    .join(" ")} */}
                </Text>
                <Text style={styles.tableCell}>{item.registerNumber}</Text>
              </View>
              <View style={[styles.tableCol, styles.col3]}>
                <Text style={styles.tableCell}>{item.chronicleBatchDate}</Text>
                <Text style={styles.tableCell}>{item.productionDate && (Number.isInteger(item.productionDate) ? iDate(new Date(item.productionDate), "{j}/{n}/{f}") : item.productionDate)}</Text>
                <Text style={styles.tableCell}>{item.expirationDate && (Number.isInteger(item.expirationDate) ? iDate(new Date(item.expirationDate), "{j}/{n}/{f}") : item.expirationDate)}</Text>
              </View>
              <View style={[styles.tableCol, styles.col4]}>
                <Text style={styles.tableCell}>{item.currentQuantity} {item.unit}</Text>
              </View>
              <View style={[styles.tableCol, styles.col8]}>
                <Text style={styles.tableCell}>
                  {[item.producerName, item.producerAddress]
                    .filter((item) => item)
                    .join("-")}
                </Text>
              </View>
              <View style={[styles.tableCol, styles.col6]}>
                <Text style={styles.tableCell}>{item?.["importDrug"] ? item?.["importFacility"] : "Không"}</Text>
              </View>
              <View style={[styles.tableCol, styles.col7]}>
                <Text style={styles.tableCell}>{item.samplerRequired}</Text>
              </View>
              <View style={[styles.tableCol, styles.col5]}>
                <Text style={styles.tableCell}>{item.testMethod}</Text>
              </View>
            </View>
            {/* <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.col1]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col2]}><Text style={styles.tableCell}>
                {item.substanceName}{`\n`}
                {item.substanceContent}
              </Text></View>
              <View style={[styles.tableCol, styles.col3]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col4]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col5]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col6]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col7]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col8]}><Text style={styles.tableCell}>&nbsp;</Text></View>
            </View>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.col1]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col2]}><Text style={styles.tableCell}>{item.registerNumber}</Text></View>
              <View style={[styles.tableCol, styles.col3]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col4]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col5]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col6]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col7]}><Text style={styles.tableCell}>&nbsp;</Text></View>
              <View style={[styles.tableCol, styles.col8]}><Text style={styles.tableCell}>&nbsp;</Text></View>
            </View> */}
          </React.Fragment>
        );
      })}
    </View>
  );
};

TablePdf.propTypes = {};
TablePdf.defaultProps = {
  data: [],
};

export default TablePdf;

// Create styles
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableCell: {
    // margin: "auto",
    padding: 2,
    // marginTop: 5,
    // marginBottom: 5,
    fontSize: 10,
  },
  col1: {
    width: "10%",
  },
  col2: {
    width: "20%",
  },
  col3: {
    width: "13%",
  },
  col4: {
    width: "7%",
  },
  col5: {
    width: "7%",
  },
  col6: {
    width: "15%",
  },
  col7: {
    width: "15%",
  },
  col8: {
    width: "13%",
  },
});
