import { createSlice } from '@reduxjs/toolkit';
import { TOKEN_NAME } from 'src/constants';

export const MODULE_NAME = 'login';

const initialState = {
    token: localStorage.getItem(TOKEN_NAME),
    authModel: {
        username: '',
        password: '',
    },
    messageErr: '',
    loading: false,
};
const slice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        updateToken: (state, action) => {
            localStorage.setItem(TOKEN_NAME, action.payload);
            state.token = action.payload;
            state.loading = false;
        },
        updateUser: (state, action) => {
            // const userId = action.payload.GeneralInfomation.IdUser
            // const appCode = action.payload.GeneralInfomation.IdUser
        },
        loginFail: (state) => {
            state.messageErr =
                'Your email or password is incorrect please try again.';
            state.loading = false;
        },
        // onLoging: (state) => {
        //     state.messageErr = '';
        //     state.buttonLoading = true;
        // },
        doLogin: (state, action) => {
            state.messageErr = '';
            state.loading = true;
        }
    },
});

export const { updateToken, loginFail, doLogin, updateUser } = slice.actions;

export default slice.reducer;
