import { getTr } from "../../../action";
import CharCustom from "../chartCustom";
import { useTranslation } from "react-i18next";
function InstrucmentCalib() {
  const { t } = useTranslation();
  const data = [
    { type: "Instrucment_GC", status: "Stored", count: 1 },
    { type: "Instrucment_HPLC", status: "Expected", count: 3 },
    { type: "Instrucment_HPLChs", status: "Expected", count: 3 },
  ];

  return (
    <div className="dashboard-tile-table-class">
      <div className="div_title_header" span={24}>
        <div className="tw-inline-flex">
          <img
            className="img_tilte"
            src="/images/instrument_management.png"
            align="top"
            alt=""
          />
          <strong>
            &nbsp;&nbsp;{t("DASHBOARD.instrument_calibration_maintenace")}
          </strong>
        </div>
        <div className="tw-flex-col tw-flex">
          <div className="canvas-container-class" span={12}>
            <CharCustom data={data} />
          </div>
          <div className="tw-p-0.5">
            <table className="table-standard-border tw-w-full">
              <thead>
                <tr style={{ background: "#1C5B75", color: "#fff" }}>
                  <td width="45%">
                    {t("INSTRUMENT_CALIBRATION_MAINTENACE.type")}
                  </td>
                  <td width="37%">
                    {t("INSTRUMENT_CALIBRATION_MAINTENACE.status")}
                  </td>
                  <td width="10%">
                    {t("INSTRUMENT_CALIBRATION_MAINTENACE.count")}
                  </td>
                </tr>
              </thead>
              <tbody>{data.map((item, index) => getTr(item, index))}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default InstrucmentCalib;
