import React, { useState } from "react";
// import logo from 'src/assets/images/logo512.png';
// import logoWhite from 'src/assets/images/logo192.png';
import routes from "src/routes";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRole } from "src/redux/permission/selector";
import { useTranslation } from "react-i18next";

const Sidebar = ({ onChange }) => {
  const { t } = useTranslation();
  const roles = useSelector(selectRole);
  const location = useLocation();
  const [openKey, setOpenKeys] = useState([]);

  // submenu keys of first level
  const rootSubmenuKeys = routes.map((item) => item.name);
  const onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => openKey.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const MenuItem = () => {
    let xhtml = null;
    xhtml = routes.map((item, idx) => {
      if (!item.isMenu) return "";
      if (
        item.children &&
        (!item.role || item.role.some((item) => roles.includes(item)))
      ) {
        return (
          <Menu.SubMenu
            key={item.name}
            title={item.name}
            icon={typeof item.icon === "function" && item.icon()}
          >
            {item.children.map((_item, _id) => {
              if (!_item.isMenu) return "";
              return (
                <Menu.Item
                  className={
                    location.pathname === _item.path
                      ? "ant-menu-item-selected"
                      : ""
                  }
                  key={`${idx}+${_id}`}
                  onClick={() => onChange()}
                >
                  <Link to={_item.path}>{t(item.name)}</Link>
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        );
      }
      if (item.role && !item.role.some((item) => roles.includes(item)))
        return "";
      return (
        <Menu.Item
          key={item.name}
          className={
            location.pathname === item.path ? "ant-menu-item-selected" : ""
          }
          icon={typeof item.icon === "function" && item.icon()}
          onClick={() => onChange()}
        >
          <Link to={item.path ? item.path : ""}>{t(item.name)}</Link>
        </Menu.Item>
      );
    });
    return xhtml;
  };

  return (
    // <Sider trigger={null} collapsible collapsed={collapsed} className="site-layout-background ant-layout-sider-light">
    //     <div className="logo tw-flex tw-flex-row tw-justify-start tw-items-center" style={{height: '64px', padding: '16px', textAlign: 'center', backgroundColor: '#109CF1'}}>
    //         <div onClick={() => setCollapsed(!collapsed)} className="tw-p-2">
    //             {collapsed ? <MenuUnfoldOutlined style={{color: '#fff', verticalAlign: 'bottom', fontSize: '1.5rem'}} /> : <MenuFoldOutlined style={{color: '#fff', verticalAlign: 'bottom', fontSize: '1.5rem'}} />}
    //         </div>
    //         <div className="tw-text-white tw-text-2xl">NTS</div>
    //     </div>
    <Menu
      theme="light"
      mode="inline"
      openKeys={openKey}
      onOpenChange={onOpenChange}
      className="ant-menu-light"
    >
      {MenuItem()}
    </Menu>
    // {props.children}
    // </Sider>
  );
};

export default Sidebar;
