import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import TablePdf from "./TablePdf";
import { apiGetReportCode } from "../../apis/samples";
import iDate from "utils-format/iDate";

// Register font
Font.register({
  family: "Times-new-roman",
  src: "/fonts/font-times-new-roman.ttf",
});
Font.register({
  family: "Times-new-roman-bold",
  src: "/fonts/font-times-new-roman-bold.ttf",
});
Font.register({
  family: "Times-new-roman-italic",
  src: "/fonts/Times-New-Roman-Italic.ttf",
});

const monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const SamplePdfView = ({ data, signSampler, signLab }) => {
  const [currentDate] = useState(data[0]?.samplingDate ? new Date(data[0]?.samplingDate) : new Date());
  const [issuedDate] = useState(data[0]?.issuedDate || null);

  const {
    locationName,
    pickingAddress,
    pickingSellType,
    pickingPhone,
    representativePerson,
    importDrug,
    importFacility,
    sampleIdExternal,
  } = data[0] || {};

  const [dataTable, setDataTable] = useState([]);
  const [samplerInformations, setSamplerInformations] = useState([]);
  const [numberReport, setNumberReport] = useState(null);
  const handleConvertDataTable = () => {
    let facility = "";
    if (importDrug) {
      facility = importFacility;
    }
    let result = [];
    if (data.length > 0) {
      data = data.sort((a, b) => (a.sampleIdInternal < b.sampleIdInternal) ? -1 : 1);
      data.map((item, index) => {
        const row = {
          stt: index + 1,
          sampleName: item.sampleName,
          chronicleBatchDate: item.chronicleBatchDate,
          productionDate: item.productionDate,
          expirationDate: item.expirationDate,
          packedSize: item.unit,
          currentQuantity: item.currentQuantity,
          quantifyUnit: item.quantifyUnit,
          producerName: item.producerName,
          producerAddress: item.producerAddress,
          status: item.status,
          substanceName: item.substanceName,
          substanceContent: item.substanceContent,
          substanceUnit: item.substanceUnit,
          registerNumber: item.registerNumber,
          // facility: facility,
          sampleIdInternal: item.sampleIdInternal,
          sampleIdExternal: item.sampleIdExternal,
          facility: item.importFacility,
          substanceInformation: item.substanceInformation,
          importDrug: item.importDrug,
          importFacility: item.importFacility,
        };
        result.push(row);
      });
    }

    setDataTable(result);
  };

  const handletSamplerInformations = () => {
    const xhtml = [];
    let samplerInformations = data
      .flatMap((item) => item.samplerInformation)
      .filter((value, index, self) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          self.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
    if (samplerInformations.length > 0) {
      samplerInformations.forEach((item, index) => {
        xhtml.push(
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
            key={index}
          >
            <View
              style={{
                width: "22.2222%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text>{index + 1}. </Text>
              <Text style={{ flex: 1, width: "100%", textAlign: "left" }}>
                {item.samplerName}{" "}
              </Text>
            </View>
            <View
              style={{
                width: "44.4444%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text>- Chức vụ: </Text>
              <Text style={{ flex: 1, width: "100%", textAlign: "left" }}>
                {(item.samplerPosition || "") + `${item.samplerDepartment ? " - " + item.samplerDepartment : ""}`}
              </Text>
            </View>
            <Text style={{ width: "33.3333%" }}>
              {" "}
              Trung tâm Kiểm nghiệm thành phố Đà Nẵng;
            </Text>
          </View>
        );
      });
    } else {
      xhtml.push(
        <View style={{ flexDirection: "row", justifyContent: "space-between" }} key={1}>
          <View
            style={{
              width: "22.2222%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text>1. </Text>
            <Text style={{ flex: 1, width: "100%", textAlign: "left" }}> </Text>
          </View>
          <View
            style={{
              width: "44.4444%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text>- Chức vụ: </Text>
            <Text style={{ flex: 1, width: "100%", textAlign: "left" }}> </Text>
          </View>
          <Text style={{ width: "33.3333%" }}>
            {" "}
            Trung tâm Kiểm nghiệm thành phố Đà Nẵng;
          </Text>
        </View>
      );
    }
    setSamplerInformations(xhtml);
  };

  const getReportCode = () => {
    const userId = localStorage.getItem("IdUser");
    let filter = {
      sampleIds: data.map(item => item.sampleId),
      technicianId: userId,
    };
    apiGetReportCode(filter).then((result) => {
      if (result.status === 200) {
        setNumberReport(result.data.code);
      }
      else {
        return;
      }
    });
  };

  useEffect(() => {
    handleConvertDataTable();
    getReportCode();
    handletSamplerInformations();
  }, [data]);

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={"landscape"}>
        <View style={styles.section}>
          {/* HEADER */}
          <View style={[styles.row, header.row]}>
            <View style={header.item1}>
              <Text style={{ fontSize: "10px" }}>SỞ Y TẾ THÀNH PHỐ ĐÀ NẴNG</Text>
              <Text style={{ fontFamily: "Times-new-roman-bold", fontSize: "10px" }}>TRUNG TÂM KIỂM NGHIỆM</Text>
              <View
                style={[
                  styles.underline,
                  {
                    width: "60px",
                    margin: "2px 0px 2px 0px",
                    marginLeft: "90px",
                  },
                ]}
              ></View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }} key={1}>
                <View
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Image src={"/images/logo-kndn.png"} style={styles.logo} />
                </View>
                <View
                  style={{
                    // width: "50%",
                    // display: "flex",
                    // flexDirection: "row",
                    position: "absolute",
                    top: "-15px",
                    left: "114px",
                  }}
                >
                  <Image src={"/images/signal.png"} style={{ width: "90px" }} />
                </View>
              </View>
              {/* <View style={{ display: "flex", justifyContent: "center" }}>
                <Image src={"/images/logo-kndn.png"} style={styles.logo} />
                <Image src={"/images/signal.JPG"} style={styles.logo} />
              </View> */}
            </View>
            <View style={header.item2}>
              <View style={header.top}>
                <Text style={styles.bold}>
                  CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
                </Text>
                <Text style={[styles.bold, { fontSize: "13px" }]}>
                  Độc lập - Tự do - Hạnh phúc
                </Text>
                <View
                  style={[
                    styles.underline,
                    {
                      width: "150px",
                      margin: "2px 0px 2px 0px",
                      marginLeft: "208px",
                    },
                  ]}
                ></View>
                <Text style={[styles.italic, { fontSize: "13px" }]}>
                  Đà Nẵng, ngày {currentDate.getDate()} tháng{" "}
                  {monthArr[currentDate.getMonth()]} năm{" "}
                  {currentDate.getFullYear()}
                </Text>
              </View>

              <View style={header.title}>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.bold, { fontSize: "12px" }]}>
                    BIÊN BẢN LẤY MẪU THUỐC/ DƯỢC LIỆU/ VỊ THUỐC CỔ TRUYỀN
                  </Text>
                  <Text style={[styles.bold, { fontSize: "12px" }]}>
                    ĐỂ XÁC ĐỊNH CHẤT LƯỢNG
                  </Text>
                  <Text style={{ fontSize: "13px" }}>Số: {numberReport}</Text>
                </View>
              </View>
            </View>
          </View>

          {/* CONTENT */}
          <View>
            <Text>
              Quyết định thành lập đoàn lấy mẫu để kiểm tra chất lượng tại các cơ sở kinh doanh, phân phối, cấp phát trên địa bàn thành phố Đà Nẵng số {data[0]?.number}/QĐ-TTKN
              {issuedDate ? iDate(new Date(issuedDate), " ngày {j} tháng {n} năm {f}")
                : null} của Giám đốc Trung tâm Kiểm
              nghiệm thành phố Đà Nẵng.
            </Text>
            <Text>
              Họ tên, chức vụ, cơ quan công tác của cán bộ tham gia lấy mẫu:
            </Text>
            {samplerInformations}
            {/*<Text>2. .......................... - Chức vụ: ........................, Trung tâm Kiểm nghiệm thành phố Đà Nẵng.</Text>*/}
            {/* <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text>Tên cơ sở được lấy mẫu: </Text>
              <Text style={{ flex: 1 }}>
                {locationName}{" "}
                {locationName && representativePerson ? "-" : null}{" "}
                {representativePerson}
              </Text>
              <Text>Phân loại: {pickingSellType}</Text>
            </View> */}
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View
                style={{
                  width: "66.6666%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text>Tên cơ sở được lấy mẫu: </Text>
                <Text style={{ flex: 1, width: "100%", textAlign: "left" }}>
                  {locationName}{" "}
                  {locationName && representativePerson ? "-" : null}{" "}
                  {representativePerson}
                </Text>
              </View>
              <Text style={{ width: "33.3333%" }}>
                {" "}
                Phân loại: {pickingSellType}
              </Text>
            </View>
            {/* <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text>Địa chỉ: </Text>
              <Text style={{ flex: 1 }}>{pickingAddress}</Text>
              <Text>Điện thoại: {pickingPhone}</Text>
            </View> */}
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View
                style={{
                  width: "66.6666%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text>Địa chỉ: </Text>
                <Text style={{ flex: 1, width: "100%", textAlign: "left" }}>
                  {pickingAddress}
                </Text>
              </View>
              <Text style={{ width: "33.3333%" }}>
                {" "}
                Điện thoại: {pickingPhone}
              </Text>
            </View>

            <View style={{ marginBottom: "5px" }}></View>
            <TablePdf data={dataTable} />
            <View style={{ marginBottom: "5px" }}></View>

            <Text>
              Điều kiện bảo quản khi lấy mẫu:{" "}
              {data[0]?.temperature && `Nhiệt độ: ${data[0]?.temperature}°C, `}
              {data[0]?.humidity && `Độ ẩm: ${data[0]?.humidity}%, `}
              {data[0]?.sensoryAssessment &&
                `Đánh giá cảm quan: ${data[0]?.sensoryAssessment}`}
            </Text>
            <Text>
              Biên bản này được lập thành 03 bản: 01 bản lưu tại cơ sở được lấy
              mẫu; 01 bản lưu tại Trung tâm Kiểm nghiệm Đà Nẵng; 01 bản lưu tại
              cơ quan, bộ phận liên quan.
            </Text>
          </View>
          {/* CONTENT */}

          {/* FOOTER */}
          <View style={styles.footer}>
            <View style={styles.footerItem}>
              <Text style={styles.bold}>Phòng TC-HC-KH-TC nhận mẫu</Text>
              <Text>(Ký và ghi rõ họ tên)</Text>
            </View>
            <View style={[styles.footerItem, { width: "50%" }]}>
              <Text style={styles.bold}>Người lấy mẫu</Text>
              <Text>(Ký và ghi rõ họ tên)</Text>
              <Image
                src={signSampler}
                alt=""
                style={{ width: 400, height: 80 }}
              />
            </View>
            <View style={styles.footerItem}>
              <Text style={styles.bold}>Đại diện cơ sở được lấy mẫu</Text>
              <Text>(Ký và ghi rõ họ tên)</Text>
              <Image src={signLab} alt="" style={{ width: 200, height: 80 }} />
            </View>
          </View>
          {/* FOOTER */}
        </View>
      </Page>
    </Document>
  );
};

SamplePdfView.propTypes = {};

export default SamplePdfView;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    fontFamily: "Times-new-roman",
    fontStyle: "normal",
    fontSize: 10,
  },
  section: {
    margin: "5px 10px",
    padding: "5px 10px",
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexFlow: "row wrap",
  },
  logo: {
    width: "60px",
    // marginLeft: "auto",
    // marginRight: "auto",
  },
  bold: {
    fontFamily: "Times-new-roman-bold",
  },
  italic: {
    fontFamily: "Times-new-roman-italic",
  },
  underline: {
    borderBottom: "0.5px solid black",
    display: "inline-block",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "between",
    alignItems: "between",
    marginTop: "5px",
  },
  footerItem: {
    width: "25%",
    textAlign: "center",
  },
});

const header = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "5px",
  },
  item1: {
    display: "block",
    flex: "0 0 30%",
    maxWidth: "30%",
    textAlign: "center",
    justifyContent: "center",
  },
  item2: {
    display: "block",
    flex: "0 0 70%",
    maxWidth: "70%",
  },
  top: {
    textAlign: "center",
  },
  title: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "10px",
  },
});
