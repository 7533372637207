import { useTranslation } from "react-i18next";
import {useEffect, useState} from "react";
import axiosService from "../../../../../services/axiosService";
import {CONFIG} from "../../../../../configs";

const Alerts = () => {
  const [data, setData] = useState({})
  const { t } = useTranslation();

  useEffect(() => {
    getChartData()

    let id = setInterval( () => {
      getChartData()
    }, 60000);
    return () => clearInterval(id);
  }, [])

  const getChartData = async () => {
    const {data: dataApi} = await axiosService.get(`${CONFIG.API_URL.BASE}/api/alert/alert-count`)
    const result = dataApi || []
    setData(result)
    // console.log(result)
  }

  return (
    <div className="dashboard-tile-table-class">
      <div className="div_title_header" span={24}>
        <div className="tw-inline-flex">
          <img className="img_tilte" src="/images/alert_tile.png" align="top" alt=""/>
          <strong>&nbsp;&nbsp;{t("DASHBOARD.alert_noti")}</strong>
        </div>
        <div className="tw-flex-row tw-flex">
          <div className="canvas-container-class" span={12}>
            <div
              style={{
                verticalAlign: "top",
                textAlign: "left",
                padding: "5px 5px",
              }}
            >
              <div className="task-alert">
                <span style={{ whiteSpace: "nowrap", align: "left" }}>
                  <label id="task-btn" style={{ cursor: "pointer" }}>
                    <img
                      // src="resources/images/app_module_icons/task_tab.png?ver=260RC201"
                      align="left"
                      alt=""
                    />
                    &nbsp;&nbsp;{t("ALERT.task_pending")}
                    <b>
                      <font color="orange"> {data.taskPendingCount || 0}</font>
                    </b>
                  </label>
                  <br />
                  <label id="alert-btn">
                    <img
                      // src="resources/images/app_module_icons/alert_tab.png?ver=260RC201"
                      align="left"
                      alt=""
                    />
                    &nbsp;&nbsp;{t("ALERT.unread_alerts")}
                    <b>
                      <font color="orange"> {data.unreadCount || 0}</font>
                    </b>
                  </label>
                  <br />
                  {/*<label id="expiry-updates-button">
                    <img
                      // src="resources/images/info_icons/expire_updates.png?ver=260RC201"
                      align="left"
                      alt=""
                    />{" "}
                    &nbsp;&nbsp;{t("ALERT.expiry_updates")}
                  </label>*/}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <Row className="dashboard-tile-table-class">
    //   <Col className="div_title_header" span={24}>
    //     <Row className="tw-inline-flex">
    //       <img className="img_tilte" src="/images/alert_tile.png" align="top" />
    //       <strong>&nbsp;&nbsp;Alert & Notifiactions</strong>
    //     </Row>
    //     <Row style={{ height: 186 }}>
    //       <Col span={12}>
    //         <div
    //           style={{
    //             verticalAlign: "top",
    //             textAlign: "left",
    //             padding: "5px 5px",
    //           }}
    //         >
    //           <div className="task-alert">
    //             <span style={{ whiteSpace: "nowrap", align: "left" }}>
    //               <label id="task-btn" style={{ cursor: "pointer" }}>
    //                 <img
    //                   // src="resources/images/app_module_icons/task_tab.png?ver=260RC201"
    //                   align="left"
    //                 />
    //                 &nbsp;&nbsp;Tasks Pending:{" "}
    //                 <b>
    //                   <font color="orange">0</font>
    //                 </b>
    //               </label>
    //               <br />
    //               <label id="alert-btn">
    //                 <img
    //                   // src="resources/images/app_module_icons/alert_tab.png?ver=260RC201"
    //                   align="left"
    //                 />
    //                 &nbsp;&nbsp;Unread Alerts:{" "}
    //                 <b>
    //                   <font color="orange">1</font>
    //                 </b>
    //               </label>
    //               <br />
    //               <label id="expiry-updates-button">
    //                 <img
    //                   // src="resources/images/info_icons/expire_updates.png?ver=260RC201"
    //                   align="left"
    //                 />{" "}
    //                 &nbsp;&nbsp;Expiry Updates
    //               </label>
    //             </span>
    //           </div>
    //         </div>
    //       </Col>
    //     </Row>
    //     <Row style={{ height: 148 }}></Row>
    //   </Col>
    // </Row>
  );
};
export default Alerts;
