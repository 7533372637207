import React from 'react';
import PropTypes from 'prop-types';
import {Collapse} from "antd";
import styled from "styled-components";
import {theme} from "../../constants/color.constant";

const FormSectionStyled = styled.div`
  //.ant-collapse-item {
  //  border-bottom: 0;
  //}
  .ant-collapse-header-text {
    font-weight: bold;
  }
  .ant-collapse-arrow {
    vertical-align: 1px!important;
  }
  .ant-form-item-label-left {
    margin-right: 5px;
    label {
      background-color: ${theme.colors.blue['300']};
      border-radius: 3px;
      padding-left: 3px;
      width: 100%;
      height: auto;
      white-space: break-spaces;
      min-height: 100%;
    }
  }
  .ant-form-item-label > label::after {
    display: none;
  }
  .ant-collapse-content {
    border-top: 0;
  }
  .ant-collapse-header {
    //border-bottom: 1px solid #d9d9d9;
    position: sticky!important;
    top: 0;
    z-index: 3;
    background-color: ${theme.colors.white};
    > * {
      position: relative;
      z-index: 1;
      color: #fff;
    }
    &:before {
      content: '';
      position: absolute;
      width: 45%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #378BB6;
      z-index: 0;
    }
    &:after {
      content: '';
      position: absolute;
      width: 46px;
      height: 100%;
      left: 45%;
      top: 0;
      border-bottom-right-radius: 85%;
      background-color: #378BB6;
      z-index: 0;
      //border-right: 12px solid orange;
      //transform-origin: bottom left;
      //-ms-transform: skew(-45deg, 0deg);
      //-webkit-transform: skew(
      //        -45deg,
      //        0deg);
      //transform: skew(
      //        -45deg,
      //        0deg);
    }
  }
`
const FormSection = ({keyPanel = '1', header, ...props}) => {
  return (
    <FormSectionStyled>
      <Collapse collapsible="header" defaultActiveKey={['1']}>
        <Collapse.Panel header={header} key={keyPanel}>
          {props.children}
        </Collapse.Panel>
      </Collapse>
    </FormSectionStyled>
  );
};

FormSection.propTypes = {
  keyPanel: PropTypes.any,
  header: PropTypes.node,
};

export default FormSection;
