import React, { useEffect, useMemo, useRef, useState } from "react";
import Datatable from "src/components/Datatable";
import { base64StringToBlob } from 'blob-util';
import SampleForm from "./form";
import { useTranslation } from "react-i18next";
import { Select, Input, message, Modal, Button, Row, Col, Tag, Radio } from "antd";
import { getListTemplate } from "../../apis/Template";
import ModalShowErr from "./components/ModalShowError";
import { apiGetReports, apiPostReports, apiCheckReport } from "../../apis/samples";
import {
  getListSample,
  apiDeleteSample,
  apiDissociateStorage,
  uploadTemplate,
  updateStatusSample,
} from "../../apis/samples";
import {
  handleActionByRole,
  handleDownloadFile,
  timeFormatter,
} from "../../utils/utils";
import { CONFIG } from "../../configs";
import UploadTemplate from "../../components/UploadTemplate";
import SamplePdfView from "../../components/SamplePdf/SamplePdfView";
import SamplePdfView2 from "../../components/SamplePdf/SamplePdfView2";
import SamplePdfView3 from "../../components/SamplePdf/SamplePdfView3";
import { PDFViewer, Text, View, PDFDownloadLink } from "@react-pdf/renderer";
import { ExportOutlined, HighlightOutlined } from "@ant-design/icons";
import CloneRecord from "./components/CloneRecord";
import { useSelector } from "react-redux";
import { selectRole } from "../../redux/permission/selector";
import {
  Sample_Add,
  Sample_Cancel,
  Sample_Delete,
  Sample_Edit,
  Sample_Export,
  Sample_Import,
  Sample_MoveToCart,
  Sample_Receive,
  Sample_Restore,
  Sample_SendExternal,
  Sample_Split,
} from "../../constants/roles.constant";
import SampleFormDerivative from "./formDerivative";
import FormCard from "./formCard";
import BarcodePdfView from "../../components/SamplePdf/BarcodePdfView";
import { useHistory } from "react-router";
import Signature from "../../components/Signature";
import * as PropTypes from "prop-types";
import ModalAnalyte from "./components/ModalAnalyte";
import FileUtil from "../Document/Utils/FileUtil";
import NotifyUtil from "../Document/Utils/NotifyUtil";
import { getFileById } from "../Document/Services/document.services";
// import { base64StringToBlob } from 'blob-util';
import { notification } from 'antd';
import i18next from "i18next";

const allowDownload = ["barcode"];
const renderColumns = (configs, t, base64StringToBlob) => {
  if (!configs?.configData) return [];
  const columns = [];
  // columns.push({
  //   title: t(`SAMPLE.FORM.${"sampleIdInternal"}`),
  //   width: 200,
  //   dataIndex: `${"sampleIdInternal"}`,
  // })
  let barcodeItem = null;
  const index = configs.configData.config.findIndex(x => x?.sectionName === "generalInformation")
  if (index > 0)
    [configs.configData.config[0], configs.configData.config[index]] = [configs.configData.config[index], configs.configData.config[0]];
  configs.configData.config.map((item) => {
    Object.keys(item.attribute).map((key) => {
      if (["sampleId", "custodianId", "custodianPosition", "idCard"
        , "status", "importFacility", "importFacilityAddress", "packedSize", "representativePerson", "position", "priority", "planName", "issuedDate", "issuedDatePlan", "fromDate", "introductoryPaper", "attachedFile", "importDrug", "documentName"].includes(key)) return;
      else if (item.attribute[key].type.toUpperCase() === "UPLOAD") {
        const fixed = key === "barcode" ? "right" : false;
        const col = {
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `${key}`,
          fixed: fixed,
          render: (value) => {
            if (item.sectionName.toUpperCase() === "SAMPLEIMAGE") {
              const toggleClass = "hover-zoom";
              return (
                <div
                  style={{ width: 50, height: 50 }}
                  onMouseLeave={(e) => {
                    let imgEle = e.currentTarget.getElementsByTagName("img")[0];
                    imgEle && imgEle.classList.toggle(toggleClass);
                  }}
                  onMouseEnter={(e) => {
                    let imgEle = e.currentTarget.getElementsByTagName("img")[0];
                    if (imgEle) {
                      const rect = imgEle.getBoundingClientRect();
                      imgEle.style.left = (rect.left) + "px";
                      //zoom height = 300px, class = toggleClass
                      imgEle.style.top = (rect.top - 150) + "px";
                      imgEle.classList.toggle(toggleClass);
                    }
                  }}
                  onClick={() => {
                    if (allowDownload.includes(key)) {
                      handleDownloadFile(CONFIG.API_URL.BASE + value);
                    }
                  }}
                >
                  <img
                    src={`${CONFIG.API_URL.BASE + value}`}
                    alt=""
                    style={{ maxHeight: 35, display: "inline-block" }}
                  />
                </div>
              );
            }

            return (
              <span
                className={`tw-inline-block ${allowDownload.includes(key) ? "tw-cursor-pointer" : ""}`}
                onClick={() => {
                  if (allowDownload.includes(key)) {
                    handleDownloadFile(CONFIG.API_URL.BASE + value);
                  }
                }}
              >
                <img
                  src={`${CONFIG.API_URL.BASE + value}`}
                  alt=""
                  style={{ maxHeight: 35, display: "inline-block" }}
                />
              </span>
            );
          },
        };
        if (key === "barcode") {
          return (barcodeItem = col);
        }
        return columns.push(col);
      } else if (key === "substanceName") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `substanceName`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.substanceName)
                xhtml.push(<div key={index}>{x.substanceName}<br /></div>);
            })
            return <div>{xhtml}</div>;
          }
        });

      } else if (key === "substanceContent") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `substanceContent`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.substanceContent)
                xhtml.push(<div key={index}>{x.substanceContent}<br /></div>);
            })
            return <div>{xhtml}</div>;
          }
        });

      } else if (key === "substanceType") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `substanceType`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.substanceType)
                xhtml.push(<div key={index}>{x.substanceType}<br /></div>);
            })
            return <div>{xhtml}</div>;
          }
        });

      } else if (key === "substanceUnit") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `substanceUnit`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.substanceUnit)
                xhtml.push(<div key={index}>{x.substanceUnit}<br /></div>);
            })
            return <div>{xhtml}</div>;
          }
        });

      } else if (key === "drugNameRelatedDocs") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `drugNameRelatedDocs`,
          render: (_, record) => {
            let xhtml = [];
            record.substanceInformation.forEach((x, index) => {
              if (x.drugNameRelatedDocs)
                xhtml.push(<div key={index}>{x.drugNameRelatedDocs}<br /></div>);
            })
            return <div>{xhtml}</div>;
          }
        });

      } else if (item.attribute[key].type.toUpperCase() === "DATE") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `${key}`,
          render: (value) => {
            return <div>{Number.isInteger(value) ? timeFormatter(value) : value}</div>;
          },
        });
      } else if (item.attribute[key].type.toUpperCase() === "DROPDOWN") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `${key}`,
          render: (value) => {
            if (key === "priority") {
              let color = "default";
              switch (value) {
                case "mức thấp":
                  color = "success";
                  break;
                case "mức trung bình":
                  color = "warning";
                  break;
                case "mức cao":
                  color = "error";
                  break;
                default:
              }
              return <Tag color={color}>{value}</Tag>;
            } else {
              return <div>{value}</div>;
            }
          },
        });
      } else if (key === "samplerName") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `samplerName`,
          render: (_, record) => {
            let xhtml = [];
            record.samplerInformation.forEach((x, index) => {
              if (x.samplerName)
                xhtml.push(<div key={index}>{x.samplerName}<br /></div>);
            })
            return <div>{xhtml}</div>;
          }
        });

      } else if (key === "samplerId") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `samplerId`,
          render: (_, record) => {
            let xhtml = [];
            record.samplerInformation.forEach((x, index) => {
              if (x.samplerId)
                xhtml.push(<div key={index}>{x.samplerId}<br /></div>);
            })
            return <div>{xhtml}</div>;
          }
        });

      } else if (key === "samplerPosition") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `samplerPosition`,
          render: (_, record) => {
            let xhtml = [];
            record.samplerInformation.forEach((x, index) => {
              if (x.samplerPosition)
                xhtml.push(<div key={index}>{x.samplerPosition}<br /></div>);
            })
            return <div>{xhtml}</div>;
          }
        });

      } else if (key === "samplerIdCard") {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `samplerIdCard`,
          render: (_, record) => {
            let xhtml = [];
            record.samplerInformation.forEach((x, index) => {
              if (x.samplerIdCard)
                xhtml.push(<div key={index}>{x.samplerIdCard}<br /></div>);
            })
            return <div>{xhtml}</div>;
          }
        });

      } else {
        return columns.push({
          title: t(`SAMPLE.FORM.${key}`),
          width: 200,
          dataIndex: `${key}`,
        });
      }
      return key;
    });
    return item;
  });
  // if (barcodeItem) {
  //   columns.push(barcodeItem);
  // }
  // columns.push({
  //   title: t(`SAMPLE.parentName`),
  //   width: 150,
  //   dataIndex: `parentName`,
  //   fixed: "right",
  // });
  columns.push({
    title: t(`SAMPLE.FORM.documentName`),
    width: 200,
    dataIndex: `documentId`,
    render: (_, record) => {
      let xhtml = [];
      record.samplerInformation.forEach((x, index) => {
        if (x.samplerId)
          xhtml.push(<div key={index}>{x.samplerId}<br /></div>);
      })
      return <a onClick={async () => {
        try {
          const file = await getFileById(_);
          window.open(file.data.data.fileUrl, '_blank')
          return
          const blob = base64StringToBlob(file.data.data.base64, file.data.data.type);
          const url = URL.createObjectURL(blob);
          FileUtil.previewFile(blob, "application/pdf");
        } catch (err) {
          notification["warning"]({ message: '', description: i18next.t("DOCUMENT.MODAL.REVIEW.ApiNotify.errorType") })
          // NotifyUtil.showApiError();
        }
      }}>{"Link"}</a>;
    }
  });
  columns.push({
    title: t("SAMPLE.status"),
    width: 150,
    dataIndex: "sampleStatus",
    fixed: "right",
    render: function (text, record, index) {
      switch (text) {
        case "NOT_RECEIVE":
          return t("STATUSTEMPLATESAMPLE.NOT_RECEIVE");
        case "RECEIVED":
          return t("STATUSTEMPLATESAMPLE.RECEIVED");
        case "STORED":
          return t("STATUSTEMPLATESAMPLE.STORED");
        case "EXPORTED":
          return t("STATUSTEMPLATESAMPLE.EXPORTED");
        case "DESTROYED":
          return t("STATUSTEMPLATESAMPLE.DESTROYED");
        case "ANALYZING":
          return t("STATUSTEMPLATESAMPLE.ANALYZING");
        case "MOVE_TO_CART":
          return t("STATUSTEMPLATESAMPLE.MOVE_TO_CART");
        case "REPORT_SAMPLE_HAS_EXISTED":
          return t("STATUSTEMPLATESAMPLE.REPORT_SAMPLE_HAS_EXISTED");
        case "REPORTED":
          return t("STATUSTEMPLATESAMPLE.REPORTED");

        default:
          return text;
      }
    },
  });
  return columns;
};

class TabPane extends React.Component {
  render() {
    return null;
  }
}

TabPane.propTypes = {
  tab: PropTypes.string,
  children: PropTypes.node
};
const Samples = () => {
  const { t } = useTranslation();
  const modalRef = useRef();
  const modalRefSample = useRef();
  const modalRefCard = useRef();
  const modalAnayle = useRef();
  const tableRef = useRef();
  const cloneRef = useRef();
  const history = useHistory()
  const signRefSampler = useRef(null)
  const signRefLab = useRef(null)
  const signRefSampleSender = useRef(null)
  const signRefSampleRecipient = useRef(null)
  const roles = useSelector(selectRole);
  const [dataForms, setDataForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [currentTemplate, setcurrentTemplate] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [inputSearch, setInputSearch] = useState(null);
  const [isOpenModalUpload, setIsOpenModalUpload] = useState(false);
  const [isError, setisError] = useState(false);
  const [listErr, setlistErr] = useState([]);
  const [visiblePdf, setVisiblePdf] = useState(false);
  const [visiblePdfBarcode, setVisiblePdfBarcode] = useState(false);
  const [dataSelected, setDataSelected] = useState([]);
  const [typeReport, setTypeReport] = useState(EXPORT_TEMPLATE.sampler);

  // signature
  const [visibleSign, setVisibleSign] = useState(false)
  const [activeSignTab, setActiveSignTab] = useState(SIGN_TABS.sampler)
  const [activeSignTabTest, setActiveSignTabTest] = useState(SIGN_TABS.sampleSender)
  const [signImgSampler, setSignImgSampler] = useState('')
  const [signImgLab, setSignImgLab] = useState('')
  const [signImgSampleSender, setSignImgSampleSender] = useState('')
  const [signImgSampleRecipient, setSignImgSampleRecipient] = useState('')
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString())
  const [query, setQuery] = useState({ page: 1, size: 10, search: "" });
  const [total, setTotal] = useState(0);

  const columns = useMemo(() => {
    return renderColumns(form, t, base64StringToBlob);
  }, [form]);

  const fetchSamples = async (currentTemplate) => {
    console.log('query', query)
    setLoading(true);
    getListSample(currentTemplate, selectedYear, query.page, query.size, query.search)
      .then((result) => {
        const data = result.data?.sampleList.map((item) => {
          let record = {};
          Object.keys(item).map((key) => {
            if (key === "samplerInformation" || key == "substanceInformation") {
              return (record[key] = item[key]);
            } else {
              return (record = {
                ...record,
                ...item[key],
              });
            }
          });
          record["representativePerson"] = item?.["pickingInformation"]?.["representativePerson"]
          return record;
        });
        setDataTable(data);
        setTotal(result.data?.total || 0)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(`Error in promises ${error}`);
      });
  };
  const handleDelete = (ids) => {
    // let f = false;
    // dataSelected.forEach(d => {
    //   if (d?.sampleStatus === "REPORTED" || d?.sampleStatus === "MOVE_TO_CART") {
    //     f = true;
    //   }
    // })

    // if (f) {
    //   return message.warning("Trạng thái hiện tại không thể xóa!")

    // }
    const promises = [];
    ids.map((id) => {
      return promises.push(apiDeleteSample(id));
    });
    Promise.all(promises)
      .then((result) => {
        fetchSamples(currentTemplate).then((data) => {
          console.log('data', data)
        })
          .catch(err => {
            console.log('err', err)
          });
      })
      .catch((error) => {
        // console.log(`Error in promises ${error}`);
        fetchSamples(currentTemplate).then();
      });
  };
  const reset = () => {
    fetchSamples(currentTemplate).then();
  };
  // const fetchListTemplate = async () => {
  //   const res = await getListTemplate("SAMPLE");
  //   if (res.status === 200) {
  //     setDataForms(res.data || []);
  //     // setDataForms(dataMock);
  //     setForm(res.data[0]);
  //     setcurrentTemplate(res.data[0]?.id || "");
  //   }
  // };
  const onDissociateStorage = async (ids, type) => {
    if (type == "DESTROYED") {
      const res = await updateStatusSample({
        samplesIds: ids,
        status: type,
      })
      if (res.status === 200) message.success("Hủy mẫu thành công")
      else return message.warning(t("SAMPLE.MESSAGE." + res.data.code));
      fetchSamples(currentTemplate).then();
      return
    }
    const promises = [];
    ids.map(async (id) => {
      var res = await apiDissociateStorage(id, type);
      if (res.status === 200) promises.push(res);
      else return message.warning(t("SAMPLE.MESSAGE." + res.data.code));
    });
    Promise.all(promises)
      .then((result) => {
        fetchSamples(currentTemplate).then();
      })
      .catch((error) => {
        // console.log(`Error in promises ${error}`);
        fetchSamples(currentTemplate).then();
      });
  };

  const openForm = (record) => {
    if (currentTemplate == "0") {
      message.warning("Vui lòng chọn tên nhóm mẫu!")
      return
    }
    // if (record && (record.sampleStatus === "EXPORTED" || record.sampleStatus === "REPORTED")) {
    //   message.warning("Trạng thái hiện tại không thể sửa!")
    //   return
    // }
    if (modalRefSample.current) {
      modalRefSample.current.open(form);
      if (record) {
        modalRefSample.current.setFieldsValue(record, form);
      }
    }
  };

  const openModalCard = (data) => {
    if (modalRefCard.current) {
      modalRefCard.current.open(dataSelected);
    }
  };

  const openModalAnalyte = (data) => {
    // console.log(`data`, data, dataSelected)
    if (modalAnayle.current) {
      modalAnayle.current.open(dataSelected);
    }
  }

  const openFormDerivative = (record, isPool = false) => {
    if (record.length === 0)
      record = dataSelected;
    if (modalRef.current) {
      modalRef.current.openDerivative(record, isPool);
    }
  };

  const clearSelectRows = () => {
    if (tableRef.current) {
      tableRef.current.clearSelectRows();
    }
  };

  const handleAfterSubmit = () => {
    clearSelectRows();
    fetchSamples(currentTemplate).then();
  };

  const onExport = () => {
    if (!dataSelected || dataSelected.length <= 0) {
      return message.warning(t("SAMPLE.MESSAGE.warning_select_first"));
    } else {
      if (dataSelected.length > 1) {
        let listSamplerCheck = dataSelected[0].samplerInformation.map(
          (i) => i.samplerUserId
        );
        let localCheck = dataSelected[0].locationName;
        for (let i = 0; i < dataSelected.length; i++) {
          let listSampler = dataSelected[i].samplerInformation.map(
            (i) => i.samplerUserId
          );
          if (dataSelected[i].locationName !== localCheck) {
            return message.warning(t("SAMPLE.MESSAGE.different_location_name"));
          } else if (listSamplerCheck.length !== listSampler.length) {
            return message.warning(t("SAMPLE.MESSAGE.different_sampler"));
          } else {
            for (let item of listSamplerCheck) {
              if (
                (listSamplerCheck || listSampler) &&
                !listSampler.includes(item)
              ) {
                return message.warning(t("SAMPLE.MESSAGE.different_sampler"));
              }
            }
          }
        }
        // for(let item of dataSelected){
        //   if(item.custodianName !== localCheck){

        //   }
        // }
      }
      const userId = localStorage.getItem("IdUser");
      let filter = {
        sampleIds: dataSelected.map(item => item.sampleId),
        technicianId: userId,
      };
      apiCheckReport(filter).then((result) => {
        if (result.status === 200) {
          setVisiblePdf(true);
          getSignImage();
          // onDissociateStorage(dataSelected.map(x => x.sampleId), "EXPORTED")
          reset()
        }
        else {
          let sampleIds = result?.data?.args[0];
          let names = [];
          sampleIds.forEach(item => {
            let name = dataSelected.find(x => x.sampleId == item).sampleName
            if (name)
              names.push(name);
          });
          return message.warning(t(`SAMPLE.MESSAGE.${result?.data?.code}`).replace('{names}', names.join(', ')))
        }
      });
    }
    return;
    if (form?.configData) {
      let pickingInformation = form.configData.config.find(
        (e) => e.sectionName === "pickingInformation" && e.display === true
      );
      if (pickingInformation) {
        if (
          pickingInformation.attribute.locationName &&
          pickingInformation.attribute.locationName.display === true
        ) {
          let locationNameArr = dataSelected
            .filter(function (item) {
              return typeof item.locationName === "string";
            })
            .map(function (item) {
              return item.locationName;
            });
          if (
            locationNameArr.some(function (element, index) {
              // Do your thing, then:
              return index > 0 && locationNameArr.indexOf(element) === index;
            })
          )
            return message.warning(t("SAMPLE.MESSAGE.different_location_name"));
        }
      }
    }

    setVisiblePdf(true);
  };

  const onShowBarcode = () => {
    if (!dataSelected || dataSelected.length <= 0) {
      return message.warning(t("SAMPLE.MESSAGE.warning_select_first"));
    }
    if (dataSelected.length === 1) setVisiblePdfBarcode(true);
  };

  useEffect(() => {
    if (currentTemplate) {
      fetchSamples(currentTemplate);
      setDataSelected([]);
    }
    // eslint-disable-next-line
  }, [currentTemplate, selectedYear, query]);

  const fetchListTemplate = async () => {
    const res = await getListTemplate("SAMPLE");
    if (res.status === 200) {
      let d = res.data || [];
      d.unshift({ id: "0", templateName: "Tất cả" })
      setDataForms(d);
      // setDataForms(dataMock);
      setForm(d[d.length - 1]);
      setcurrentTemplate(res.data[0]?.id || "");
    }
  };

  useEffect(() => {
    fetchListTemplate().then();
    // eslint-disable-next-line
  }, [module]);

  const changeForm = (id) => {
    if (id == "0") {
      setForm(dataForms[dataForms.length - 1]);
      setcurrentTemplate(id);
      return
    }
    let val = dataForms.find((item) => item.id === id);
    setForm(val);
    setcurrentTemplate(val.id);
    setQuery(q => { return { ...q, page: 1 } })
    tableRef.current.resetPagination();
  };

  const searchTable = (e) => {
    let val = e.target.value;
    setInputSearch(val.trim());
  };

  const onUploadTemplate = () => {
    const val = !isOpenModalUpload;
    setIsOpenModalUpload(val);
  };

  const uploadFile = (file) => {
    uploadTemplate(file, currentTemplate)
      .then((result) => {
        setIsOpenModalUpload(false);
        if (result.status === 201) {
          message.success(t("ACTIONS.upload_success"));
          fetchSamples(currentTemplate);
        } else {
          let err = JSON.parse(result.data.details);
          setlistErr(err);
          setisError(true);
          // message.error(t("ACTIONS.upload_fail"));
        }
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  const closeModalError = () => {
    setisError(false);
  };

  const toolbarExtra = () => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 20 }}>
          <label style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>Tên nhóm mẫu</label>
        </div>
        <div style={{ marginRight: 20 }}>
          <Select
            showSearch
            style={{ minWidth: 100, marginRight: 20 }}
            placeholder="Select a template"
            optionFilterProp="children"
            onChange={(value) => changeForm(value)}
            value={currentTemplate || null}
            // value={module}
            className="custom-ant-select"
          >
            {dataForms
              ? dataForms.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.templateName}
                </Select.Option>
              ))
              : ""}
          </Select>
        </div>
        <div style={{ marginRight: 20 }}>
          <label style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>Năm</label>
        </div>
        <div style={{ marginRight: 20 }}>
          <Select
            showSearch
            style={{ minWidth: 100, marginRight: 20 }}
            placeholder="Chọn năm..."
            optionFilterProp="children"
            onChange={(v) => {
              tableRef.current.resetPagination();
              setQuery(q => { return { ...q, page: 1 } })
              setSelectedYear(v)
            }}
            value={selectedYear}
            className="custom-ant-select"
          >
            {
              Array.from(Array(new Date().getFullYear() - 2020).keys()).map(year => <Select.Option key={year} value={(year + 2021).toString()}>
              {(year + 2021).toString()}
            </Select.Option>) 
            }
          </Select>
        </div>
        <Input.Search
          allowClear
          // style={{ width: 200 }}
          width={100}
          placeholder={t("ACTIONS.search")}
          // onChange={searchTable}
          enterButton
          onSearch={v => {
            tableRef.current.resetPagination();
            setQuery(q => { return { ...q, search: v, page: 1 } })
          }}
        // value={inputSearch}
        />
        {/*{roles.includes(Sample_Export) && (
          <Button type="primary" size={"default"} onClick={onExport}>
            <ExportOutlined /> {t("ACTIONS.export")}
          </Button>
        )}*/}
      </div>
    );
  };

  const handleChange = (r) => {
    setTypeReport(r);
  };

  const getSignImage = async () => {
    const resSampler = await apiGetReports(dataSelected.map(x => x.sampleId).join(','), SIGN_TABS.sampler);
    if (resSampler.status === 200) {
      setSignImgSampler(resSampler.data.path ? process.env.REACT_APP_API + resSampler.data.path : null);
    }
    const resLab = await apiGetReports(dataSelected.map(x => x.sampleId).join(','), SIGN_TABS.lab);
    if (resLab.status === 200) {
      setSignImgLab(resLab.data.path ? process.env.REACT_APP_API + resLab.data.path : null)
    }
    const resSampleSender = await apiGetReports(dataSelected.map(x => x.sampleId).join(','), SIGN_TABS.sampleSender);
    if (resSampleSender.status === 200) {
      setSignImgSampleSender(resSampleSender.data.path ? process.env.REACT_APP_API + resSampleSender.data.path : null)
    }
    const resSampleRecipient = await apiGetReports(dataSelected.map(x => x.sampleId).join(','), SIGN_TABS.sampleRecipient);
    if (resSampleRecipient.status === 200) {
      setSignImgSampleRecipient(resSampleRecipient.data.path ? process.env.REACT_APP_API + resSampleRecipient.data.path : null)
    }
  };

  const postSignImage = async () => {
    if (typeReport === EXPORT_TEMPLATE.sampler && activeSignTab === SIGN_TABS.sampler) {
      const image = await signRefSampler.current.getImage();
      const res = await apiPostReports(image, dataSelected.map(x => x.sampleId).join(','), SIGN_TABS.sampler);
      if (res.status === 201) {
        setTypeReport('5')
        setSignImgSampler(process.env.REACT_APP_API + res.data.path)
        setTypeReport(EXPORT_TEMPLATE.sampler)
      }
    }
    if (typeReport === EXPORT_TEMPLATE.sampler && activeSignTab === SIGN_TABS.lab) {
      const image = await signRefLab.current.getImage()
      const res = await apiPostReports(image, dataSelected.map(x => x.sampleId).join(','), SIGN_TABS.lab);
      if (res.status === 201) {
        setTypeReport('5')
        setSignImgLab(process.env.REACT_APP_API + res.data.path)
        setTypeReport(EXPORT_TEMPLATE.sampler)
      }
    }
    if (typeReport === EXPORT_TEMPLATE.test_request && activeSignTabTest === SIGN_TABS.sampleSender) {
      const image = await signRefSampleSender.current.getImage()
      const res = await apiPostReports(image, dataSelected.map(x => x.sampleId).join(','), SIGN_TABS.sampleSender);
      if (res.status === 201) {
        setTypeReport('5')
        setSignImgSampleSender(process.env.REACT_APP_API + res.data.path)
        setTypeReport(EXPORT_TEMPLATE.test_request)
      }
    }
    if (typeReport === EXPORT_TEMPLATE.test_request && activeSignTabTest === SIGN_TABS.sampleRecipient) {
      const image = await signRefSampleRecipient.current.getImage()
      const res = await apiPostReports(image, dataSelected.map(x => x.sampleId).join(','), SIGN_TABS.sampleRecipient);
      if (res.status === 201) {
        setTypeReport('5')
        setSignImgSampleRecipient(process.env.REACT_APP_API + res.data.path)
        setTypeReport(EXPORT_TEMPLATE.test_request)
      }
    }
    setVisibleSign(false)
  }
  const clearSignature = () => {
    if (typeReport === EXPORT_TEMPLATE.sampler && activeSignTab === SIGN_TABS.sampler) {
      if (signRefSampler.current) {
        signRefSampler.current.clear()
        setSignImgSampler('')
      }
    }
    if (typeReport === EXPORT_TEMPLATE.sampler && activeSignTab === SIGN_TABS.lab) {
      if (signRefSampler.current) {
        signRefLab.current.clear()
        setSignImgLab('')
      }
    }
    if (typeReport === EXPORT_TEMPLATE.test_request && activeSignTabTest === SIGN_TABS.sampleSender) {
      if (signRefSampleSender.current) {
        signRefSampleSender.current.clear()
        setSignImgSampleSender('')
      }
    }
    if (typeReport === EXPORT_TEMPLATE.test_request && activeSignTabTest === SIGN_TABS.sampleRecipient) {
      if (signRefSampleRecipient.current) {
        signRefSampleRecipient.current.clear()
        setSignImgSampleRecipient('')
      }
    }
  }

  return (
    <div>
      <Datatable
        tableRef={tableRef}
        loading={loading}
        columns={columns}
        dataSource={dataTable}
        rowKey={"sampleId"}
        autoFlatData={true}
        allowSelect={true}
        onDelete={handleDelete}
        onDissociateStorage={onDissociateStorage}
        onAddNew={openForm}
        onEdit={(record) => openForm(record)}
        toolbarExtra={toolbarExtra()}
        inputSearch={inputSearch}
        onUploadTemplate={onUploadTemplate}
        onSelect={(records) => setDataSelected(records)}
        onCloneRecord={(recordId) => cloneRef.current.open(recordId)}
        onOpenDerivatice={openFormDerivative}
        onOpenMoveToCard={openModalCard}
        onShowBarcode={onShowBarcode}
        openAnalyte={openModalAnalyte}
        onExport={onExport}
        updateQuery={value => setQuery(q => { return { ...q, page: value.current, size: value.pageSize } })}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              history.push(`/sample/${record.sampleId}`);
            }, // double click row
          };
        }}
        total={total}
        actionConfig={handleActionByRole(
          {
            allowEdit: Sample_Edit,
            allowAddNew: Sample_Add,
            allowDelete: Sample_Delete,
            allowCancel: Sample_Cancel,
            allowRestore: Sample_Restore,
            allowReceive: Sample_Receive,
            allowMoveToCard: Sample_MoveToCart,
            allowSendOutSamples: Sample_SendExternal,
            allowImport: Sample_Import,
            allowAssign: true,
            allowUpload: true,
            allow1: false,
            allow2: false,
            allow3: false,
            allow4: false,
            allow5: false,
            allowCloneRecord: Sample_Split,
            allowMergeRecord: true,
            allow8: true,
            allow9: true,
            allow10: false,
            allow11: false,
            allow12: roles.includes(Sample_Export),
            allow13: true,
            allow14: false,
            allow15: false,
            allow16: true,
          },
          roles
        )}
      />

      <Modal
        title={t("MODAL.export")}
        visible={visiblePdf}
        centered
        width={"100%"}
        bodyStyle={{ height: "90vh" }}
        footer={null}
        onCancel={() => {
          setVisiblePdf(false)
          setSignImgSampler('')
          clearSignature()
        }}
      >
        <Row>
          <Col span={4}>{t("SAMPLE.reportType")}</Col>
          <Col span={4} className={"tw-flex tw-flex-row"} style={{ whiteSpace: "pre" }}>
            <Select
              defaultValue={typeReport}
              style={{ width: 120, marginBottom: "1rem" }}
              onChange={handleChange}
            >
              <Select.Option value={EXPORT_TEMPLATE.sampler}>Biên bản lấy mẫu</Select.Option>
              <Select.Option value={EXPORT_TEMPLATE.test_request}>Phiếu yêu cầu kiểm nghiệm</Select.Option>
              <Select.Option value={EXPORT_TEMPLATE.template}>Phiếu kiểm nghiệm/ Phân tích</Select.Option>
            </Select>

            <Button disabled={typeReport === EXPORT_TEMPLATE.template} type={'primary'} style={{ marginRight: "10px" }} className={'tw-ml-3'} onClick={() => setVisibleSign(true)} icon={<HighlightOutlined />}>{t('BUTTON.sign')}</Button>
            <div style={{ paddingTop: "5px" }}>
              <PDFDownloadLink style={{ width: "40px" }} document={<SamplePdfView data={dataSelected} signSampler={signImgSampler || 'abc'} signLab={signImgLab || 'abc'} />} fileName="report.pdf">
                {({ blob, url, loading, error }) => (true ? 'Tải về' : 'Tải về')}
              </PDFDownloadLink>
            </div>
          </Col>
        </Row>
        {
          typeReport == EXPORT_TEMPLATE.sampler &&
          <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
            <SamplePdfView data={dataSelected} signSampler={signImgSampler || 'abc'} signLab={signImgLab || 'abc'} />
          </PDFViewer>
        }
        {
          typeReport == EXPORT_TEMPLATE.test_request &&
          <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
            <SamplePdfView2 data={dataSelected} signSampleSender={signImgSampleSender || 'abc'} signRefSampleRecipient={signImgSampleRecipient || 'abc'} />
          </PDFViewer>
        }
        {
          typeReport == EXPORT_TEMPLATE.template &&
          <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
            <SamplePdfView3 data={dataSelected} />
          </PDFViewer>
        }
      </Modal>

      <Modal
        title={"Barcode"}
        visible={visiblePdfBarcode}
        centered
        width={"100%"}
        bodyStyle={{ height: "90vh" }}
        footer={null}
        onCancel={() => setVisiblePdfBarcode(false)}
      >
        <PDFViewer style={{ width: "100%", height: "100%" }}>
          <BarcodePdfView data={dataSelected} />
        </PDFViewer>
      </Modal>

      <SampleForm
        templateId={currentTemplate}
        configData={form}
        actionRef={modalRefSample}
        afterSubmit={handleAfterSubmit}
      />
      <SampleFormDerivative actionRef={modalRef} reset={() => reset()} />
      <FormCard actionRef={modalRefCard} reset={() => reset()} />
      <UploadTemplate
        isModalVisible={isOpenModalUpload}
        handleCancel={() => setIsOpenModalUpload(!isOpenModalUpload)}
        uploadFile={(file) => uploadFile(file)}
      />
      <ModalShowErr
        listErr={listErr}
        isError={isError}
        onCloseModalErr={closeModalError}
      />

      <ModalAnalyte
        actionRef={modalAnayle}
        reset={() => reset()}
      />

      {/* MODAL CLONE RECORD */}
      <CloneRecord itemRef={cloneRef} afterClone={handleAfterSubmit} />
      {/* MODAL CLONE RECORD */}

      <Modal
        title={t("MODAL.signature")}
        visible={visibleSign}
        width={750}
        onOk={postSignImage}
        onCancel={() => setVisibleSign(false)}
        maskClosable={false}
        footer={null}
      // footer={[
      //   <Button key={"cancel"} className={`${((activeSignTab == SIGN_TABS.sampler && signImgSampler) || (activeSignTab == SIGN_TABS.lab && signImgLab) || (activeSignTabTest == SIGN_TABS.sampleSender && signImgSampleSender) || (activeSignTabTest == SIGN_TABS.sampleRecipient && signImgSampleRecipient))
      //     && 'tw-hidden'}`} onClick={() => {
      //       clearSignature()
      //     }}>
      //     {t("BUTTON.clear")}
      //   </Button>,
      //   <Button key={"save"} className={`${((activeSignTab == SIGN_TABS.sampler && signImgSampler) || (activeSignTab == SIGN_TABS.lab && signImgLab) || (activeSignTabTest == SIGN_TABS.sampleSender && signImgSampleSender) || (activeSignTabTest == SIGN_TABS.sampleRecipient && signImgSampleRecipient))
      //     && 'tw-hidden'}`} onClick={postSignImage} type={"primary"}>
      //     {t("BUTTON.save")}
      //   </Button>,
      // ]}
      >
        <div className={`${typeReport !== EXPORT_TEMPLATE.sampler && 'tw-hidden'}`}>
          <div className={'tw-text-center tw-mb-3'} style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ margin: "auto" }}>
              <Radio.Group
                options={[
                  { label: t('SAMPLE.sampler'), value: SIGN_TABS.sampler },
                  { label: t('SAMPLE.sampled_basis'), value: SIGN_TABS.lab }
                ]}
                onChange={(e) => setActiveSignTab(e.target.value)}
                value={activeSignTab}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <div>
              <Button key={"cancel"} className={`${((activeSignTab == SIGN_TABS.sampler && signImgSampler) || (activeSignTab == SIGN_TABS.lab && signImgLab) || (activeSignTabTest == SIGN_TABS.sampleSender && signImgSampleSender) || (activeSignTabTest == SIGN_TABS.sampleRecipient && signImgSampleRecipient))
                && 'tw-hidden'}`} onClick={() => {
                  clearSignature()
                }}>
                {t("BUTTON.clear")}
              </Button>
              <Button key={"save"} className={`${((activeSignTab == SIGN_TABS.sampler && signImgSampler) || (activeSignTab == SIGN_TABS.lab && signImgLab) || (activeSignTabTest == SIGN_TABS.sampleSender && signImgSampleSender) || (activeSignTabTest == SIGN_TABS.sampleRecipient && signImgSampleRecipient))
                && 'tw-hidden'}`} onClick={postSignImage} type={"primary"} style={{ marginLeft: "5px" }}>
                {t("BUTTON.save")}
              </Button>
            </div>
          </div>
          {/* <div className="ant-modal-footer" style={{ border: "none" }} > */}

          {/* </div> */}
          <div>
            <div className={`tw-border tw-border-slate-500 ${activeSignTab !== SIGN_TABS.sampler && 'tw-hidden'}`}>
              {
                signImgSampleSender ? <img src={signImgSampler} width={700} height={200} /> :
                  <Signature signRef={signRefSampler} signBase={signImgSampler} width={700} height={200} />
              }
            </div>
            <div className={`tw-border tw-border-slate-500 tw-max-w-max tw-mx-auto ${activeSignTab !== SIGN_TABS.lab && 'tw-hidden'}`}>
              {
                signImgSampleSender ? <img src={signImgLab} width={700} height={200} /> :
                  <Signature signRef={signRefLab} signBase={signImgLab} width={700} height={200} />
              }
            </div>
          </div>
        </div>

        <div className={`${typeReport !== EXPORT_TEMPLATE.test_request && 'tw-hidden'}`}>
          <div className={'tw-text-center tw-mb-3'} style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ margin: "auto" }}>
              <Radio.Group
                options={[
                  { label: t('SAMPLE.sampleSender'), value: SIGN_TABS.sampleSender },
                  { label: t('SAMPLE.sampleRecipient'), value: SIGN_TABS.sampleRecipient }
                ]}
                onChange={(e) => setActiveSignTabTest(e.target.value)}
                value={activeSignTabTest}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <div>
              <Button key={"cancel"} className={`${((activeSignTab == SIGN_TABS.sampler && signImgSampler) || (activeSignTab == SIGN_TABS.lab && signImgLab) || (activeSignTabTest == SIGN_TABS.sampleSender && signImgSampleSender) || (activeSignTabTest == SIGN_TABS.sampleRecipient && signImgSampleRecipient))
                && 'tw-hidden'}`} onClick={() => {
                  clearSignature()
                }}>
                {t("BUTTON.clear")}
              </Button>
              <Button key={"save"} className={`${((activeSignTab == SIGN_TABS.sampler && signImgSampler) || (activeSignTab == SIGN_TABS.lab && signImgLab) || (activeSignTabTest == SIGN_TABS.sampleSender && signImgSampleSender) || (activeSignTabTest == SIGN_TABS.sampleRecipient && signImgSampleRecipient))
                && 'tw-hidden'}`} onClick={postSignImage} type={"primary"} style={{ marginLeft: "5px" }}>
                {t("BUTTON.save")}
              </Button>
            </div>
          </div>
          <div className="ant-modal-footer" style={{ border: "none" }} >
            <Button key={"cancel"} className={`${((activeSignTab == SIGN_TABS.sampler && signImgSampler) || (activeSignTab == SIGN_TABS.lab && signImgLab) || (activeSignTabTest == SIGN_TABS.sampleSender && signImgSampleSender) || (activeSignTabTest == SIGN_TABS.sampleRecipient && signImgSampleRecipient))
              && 'tw-hidden'}`} onClick={() => {
                clearSignature()
              }}>
              {t("BUTTON.clear")}
            </Button>
            <Button key={"save"} className={`${((activeSignTab == SIGN_TABS.sampler && signImgSampler) || (activeSignTab == SIGN_TABS.lab && signImgLab) || (activeSignTabTest == SIGN_TABS.sampleSender && signImgSampleSender) || (activeSignTabTest == SIGN_TABS.sampleRecipient && signImgSampleRecipient))
              && 'tw-hidden'}`} onClick={postSignImage} type={"primary"}>
              {t("BUTTON.save")}
            </Button>
          </div>
          <div>
            <div className={`tw-border tw-border-slate-500 ${activeSignTabTest !== SIGN_TABS.sampleSender && 'tw-hidden'}`}>
              {
                signImgSampleSender ? <img src={signImgSampleSender} width={700} height={200} /> :
                  <Signature signRef={signRefSampleSender} signBase={signImgSampleSender} width={700} height={200} />
              }
            </div>
            <div className={`tw-border tw-border-slate-500 tw-max-w-max tw-mx-auto ${activeSignTabTest !== SIGN_TABS.sampleRecipient && 'tw-hidden'}`}>
              {
                signImgSampleSender ? <img src={signImgSampleRecipient} width={700} height={200} /> :
                  <Signature signRef={signRefSampleRecipient} signBase={signImgSampleRecipient} width={700} height={200} />
              }
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Samples;


const SIGN_TABS = {
  sampler: 'sampler',
  lab: 'lab',
  sampleSender: 'sampleSender',
  sampleRecipient: 'sampleRecipient'
}

const EXPORT_TEMPLATE = {
  sampler: 'sampler',
  test_request: 'test_request',
  template: 'template',
}
