import React, { Fragment } from "react";
import { Row, Divider, Input, Form, Col, Button } from "antd";
import FormSection from "src/components/FormSection";
import { useTranslation } from "react-i18next";
import { FieldsRenderComponent } from "./filedRender";
import Map from "./Map";

const SectionRender = (props) => {
  const { item, ishowDrug, onSearch, onSearchCustodianName, positionSample, setPositionSample, setConfigFirst, deleteConfig, handleChangeForm } = props;
  const data = Object.entries(item.attribute);
  const { t } = useTranslation();
  if (item.display && item.sectionName === "samplerInformation") {
    if (Array.isArray(item?.attribute)) {
      return (
        <FormSection header={t(`SAMPLE.FORM.${item.sectionName}`)}>
          <Form.List name="samplerInformation">
            {(fields) => (
              <>
                {item?.attribute.map((field, index) => {
                  return (
                    <Fragment key={index}>
                      <Row gutter={[32, 8]}>
                        {Object.entries(field).map((val, idx) => {
                          val[0] = [fields[index]?.name, val[0]];
                          if (val[0][1] === "samplerUserId") return;
                          return (
                            <FieldsRenderComponent
                              key={idx}
                              item={val}
                              onUpload={props.onUpload}
                              onSearch={onSearch}
                              sectionName={item.sectionName}
                              positionSample={positionSample}
                              setPositionSample={setPositionSample}
                              handleChangeForm={handleChangeForm}
                            />
                          );
                        })}
                      </Row>
                      <Divider className="tw-mt-1" />
                    </Fragment>
                  );
                })}
              </>
            )}
          </Form.List>
        </FormSection>
      );
    } else {
      <FormSection header={t(`SAMPLE.FORM.${item.sectionName}`)}>
        <Row gutter={[32, 8]}>
          {data.map((item, idx) => {
            if (props?.hiddenInputs?.includes(item[0])) return;
            if (
              (item[0] === "importFacility" && !ishowDrug) ||
              item[0] === "samplerUserId"
            ) {
              return;
            }
            return (
              <FieldsRenderComponent
                key={idx}
                item={item}
                onUpload={props.onUpload}
                onSearch={onSearch}
                positionSample={positionSample}
                setPositionSample={setPositionSample}
                handleChangeForm={handleChangeForm}
              />
            );
          })}
        </Row>
      </FormSection>;
    }
  }
  if (item.display && item.sectionName === "substanceInformation") {
    if (Array.isArray(item?.attribute)) {
      return (
        <FormSection header={t(`SAMPLE.FORM.${item.sectionName}`)}>
          <Form.List name="substanceInformation">
            {(fields, { add, remove }) => (
              <>
                {item?.attribute.map((field, index) => {
                  return (
                    <Fragment key={index}>
                      <Row gutter={[32, 8]}>
                        {Object.entries(field).map((val, idx) => {
                          val[0] = [fields[index]?.name || 0, val[0]];
                          if (val[0][1] === "action") {
                            return <Col span={12}>
                              {
                                (fields.length === 1 || fields.length === 0 || fields[index]?.name === (fields.length - 1)) &&
                                <Button style={{ marginRight: "0.5rem" }} type="primary" onClick={() => {setConfigFirst(false); add()}}> + Thêm hợp chất</Button>
                              }
                              {
                                fields.length > 1 && <Button type="dashed" onClick={() => deleteConfig(fields[index]?.name)}> Xóa</Button>
                              }
                            </Col>
                          }
                          return (
                            <FieldsRenderComponent
                              key={idx}
                              item={val}
                              onUpload={props.onUpload}
                              onSearch={onSearch}
                              sectionName={item.sectionName}
                              positionSample={positionSample}
                              setPositionSample={setPositionSample}
                              handleChangeForm={handleChangeForm}
                            />
                          );
                        })}
                      </Row>
                      <Divider className="tw-mt-1" />
                    </Fragment>
                  );
                })}
              </>
            )}
          </Form.List>
        </FormSection>
      );
    } else {
      return <FormSection header={t(`SAMPLE.FORM.${item.sectionName}`)}>
        <Row gutter={[32, 8]}>
          {data.map((item, idx) => {
            if (props?.hiddenInputs?.includes(item[0])) return;
            if (
              (item[0] === "importFacility" && !ishowDrug) ||
              item[0] === "samplerUserId"
            ) {
              return;
            }
            return (
              <FieldsRenderComponent
                key={idx}
                item={item}
                onUpload={props.onUpload}
                onSearch={onSearch}
                positionSample={positionSample}
                setPositionSample={setPositionSample}
                handleChangeForm={handleChangeForm}
              />
            );
          })}
          <Col span={12}>
            <Button type="primary" onClick={() => setConfigFirst(true)}> + Thêm hợp chất</Button>
          </Col>
        </Row>
      </FormSection>;
    }
  }
  return item.display ? (
    <FormSection header={t(`SAMPLE.FORM.${item.sectionName}`)}>
      <Row gutter={[32, 8]}>
        {data.map((item, idx) => {
          if (props?.hiddenInputs?.includes(item[0])) return;
          if (item[0] === "importFacility" && !ishowDrug) {
            return;
          }
          return (
            <FieldsRenderComponent
              key={idx}
              item={item}
              onUpload={props.onUpload}
              onSearch={onSearch}
              onSearchCustodianName={onSearchCustodianName}
              positionSample={positionSample}
              setPositionSample={setPositionSample}
              handleChangeForm={handleChangeForm}
            />
          );
        })}
      </Row>
      {
        item.sectionName == "pickingInformation" &&
        <Map locations={[{
          id: 1,
          latitude: 21.0228161,
          longitude: 105.8528011,
          zoom: 14,
          ...positionSample,
        }]} />
      }
    </FormSection>
  ) : null;
};

export default SectionRender;
