import React from 'react';
import {Breadcrumb} from "antd";
import routeList from 'src/routes/index'
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const MyBreadcrumb = () => {
  const location = useLocation()
  const {t} = useTranslation()

  const handleBreadCrumb = () => {
    const currentLocation = location.pathname.split('/').filter(item => !!item)
    let currentRoute = routeList[0]
    routeList.map(route => {
      const paths = route.path?.split('/').filter(item => !!item)
      if (paths && paths.length > 0 && paths.includes(currentLocation[0])) {
        currentRoute = route
      }
      return currentRoute
    })

    return (
      <>
        <IconBreadcrumbStyled>{currentRoute?.icon()}</IconBreadcrumbStyled>
        <span>{t(currentRoute.name)}</span>
      </>
    )
  }
  return (
    <div className={'tw-mt-3'}>
      <Breadcrumb>
        <Breadcrumb.Item>
          {handleBreadCrumb()}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

MyBreadcrumb.propTypes = {

};

export default MyBreadcrumb;

const IconBreadcrumbStyled = styled.span`
  margin-right: 5px;
  img {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    pointer-events: none;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 3px;
  }
`
