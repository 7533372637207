import { CONFIG } from "../../configs";
import axiosService from "../../services/axiosService";

export const getListTestInformation = (params) => {
  let urlParams = '';
  let index = 0;
  for (let key in params) {
    if (!params[key]) continue;
    urlParams += `${index === 0 ? '?' : '&'}${key}=${params[key]}`
    index++;
  }
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/test-information${urlParams}`);
};
export const apiAddTestInformation = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/test-information`,
    data
  );
};

export const apiDeleteTestInformation = async (body) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/api/test-information/delete`, {}, body);
};

export const apiEditTestInformation = async (id, data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/test-information/${id}`, data);
};


export const postResultEntryCompleted = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/test-information/result-entry-completed`,
    data
  );
};

// status

export const getListTestInformationStatus = () => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/test-information/status`);
};
export const apiAddTestInformationStatus = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/test-information/status`,
    data
  );
};
export const apiAddTestInformationStatusApproval = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/test-information/status/approval`,
    data
  );
};


export const postListTestResult = (data, fileList = []) => {
  let formData = new FormData();
  for (let i in data) {
    if (data[i]) {
      formData.append(i, data[i]);
    }
  }
  for (let i of fileList) {
    formData.append("documents", i);
  }
  return axiosService.post(`${CONFIG.API_URL.BASE}/api/test-result`, formData, {});
};

export const changeQCSample = (testResultId) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/test-result/update-qc-sample/${testResultId}`)
}

export const getReportCodeTest = (ids) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/test-information/report-code?ids=${ids}`)
}