import React  from "react";
import { Row } from "antd";
import { Tabs } from "antd";
import RoleDetail from "./RoleDetail";
const { TabPane } = Tabs;
const Role = () => {
  const callback = () => {
  };
  return (
    <>
      <Row>
        <Tabs
          defaultActiveKey="2"
          onChange={callback}
          style={{ width: "100%" }}
        >
          <TabPane tab="Employess" key="1">
            Employess
          </TabPane>
          <TabPane tab="Roles" key="2">
            <RoleDetail />
          </TabPane>
          <TabPane tab="Group" key="3">
            Group
          </TabPane>
        </Tabs>
      </Row>
    </>
  );
};
export default Role;
