import { sleep } from "../../utils/utils";
import { CONFIG } from "../../configs";
import axiosService from "../../services/axiosService";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */
export const getListAnalyte = (id) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/analytes?analytesGroupId=${id}`);
};

export const getListAnalyteByTestId = (id) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/analytes?testId=${id}`);
};

export const apiAddAnalyte = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/analytes`,
    data
  );
};

export const apiDeleteAnalyte = async (data) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/analytes/${data}`);
};

export const apiEditAnalyte = async (data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/analytes`, data);
};


// analyte group

export const getListAnalyteGroup = (testId) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/analytes/group?testId=${testId}`);
};
export const apiAddAnalyteGroup = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/analytes/group`,
    data
  );
};

export const apiDeleteAnalyteGroup = async (data) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/analytes/group/${data}`);
};

export const apiEditAnalyteGroup = async (data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/analytes/group`, data);
};


// Flag

export const getAnalyteFlagById = (id) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/analytes/flag/${id}`);
};

export const apiAddAnalyteFlag = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/analytes/flag`,
    data
  );
};

export const apiDeleteAnalyteFlag = async (data) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/analytes/flag/${data}`);
};

// unit

export const getListAnalyteUnit = () => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/analytes/unit`);
};

export const addAnalyteUnit = (data) => {
  return axiosService.post(`${CONFIG.API_URL.BASE}/analytes/unit`, data);
};
