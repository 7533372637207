export const theme = {
  colors: {
    white: '#fff',
    green: {
      '300': '#AAA713'
    },
    blue: {
      '300': 'rgb(234, 236, 240)'
    }
  }
}
export const colorChar = [
  "#b284be",
  "#b0bf1a",
  "#e936a7",
  "#e936a7",
  "#7cb9e8",
  "#84de02",
];
