import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Popconfirm, message } from "antd";
import AddRecord from "src/assets/images/icons/add_record.png";
import AddRecords from "src/assets/images/icons/add_multiple_samples.png";
import EditRecord from "src/assets/images/icons/edit_record.png";
import DeleteRecord from "src/assets/images/icons/delete_permanently.png";
import DissociateStorage from "src/assets/images/icons/dispose_samples.png";
import MoveToCart from "src/assets/images/icons/move_to_cart.png";
import ExportedCart from "src/assets/images/icons/exported_samples.png";
import ReceivedSample from "src/assets/images/icons/received_sample.png";
import RestoreSamples from "src/assets/images/icons/restore_samples.png";
import StoredSamples from "src/assets/images/icons/stored_samples.png";
import Upload from "src/assets/images/icons/upload.png";
import PTich from "src/assets/images/icons/pt.jpg";
import One from "src/assets/images/icons/1.png";
import Two from "src/assets/images/icons/2.png";
import Three from "src/assets/images/icons/3.png";
import Four from "src/assets/images/icons/4.png";
import Five from "src/assets/images/icons/5.png";
import Six from "src/assets/images/icons/6.png";
import Seven from "src/assets/images/icons/7.png";
import Eight from "src/assets/images/icons/8.png";
import Nice from "src/assets/images/icons/9.png";
import Ten from "src/assets/images/icons/10.png";
import Eleven from "src/assets/images/icons/11.png";
import Twelve from "src/assets/images/icons/12.png";
import Thirteen from "src/assets/images/icons/13.png";
import Fourteen from "src/assets/images/icons/14.png";
import Fifteen from "src/assets/images/icons/15.png";
import Sixteen from "src/assets/images/icons/move_to_cart.png";
import ValidationTest from "src/assets/images/icons/icSendTo.svg";
import Validation from "src/assets/images/icons/validation.svg";
import ResultImage from "src/assets/images/icons/result.svg";
import icLockTest from "src/assets/images/icons/icLockTest.svg";
import icUnlock from "src/assets/images/icons/icUnlock.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ContainerOutlined } from "@ant-design/icons";

const GroupToolbarStyled = styled.div`
  img {
    width: 20px;
  }
  .ant-card-body {
    border: 1px solid orange;
    border-left: 6px solid orange;
    padding: 5px;
  }
`;

const Toolbar = ({
  onDelete,
  actionConfig,
  selected,
  onAddNew,
  onAddTestType,
  onEdit,
  onResultEntries,
  toolbarExtra,
  dissociateStorage,
  onUploadTemplate,
  onCloneRecord,
  onOpenDerivatice,
  onOpenMoveToCard,
  onShowBarcode,
  dataSelected,
  onShowValidation,
  onShowValidation2,
  onShowReturnResult,
  onChangeStatusTest,
  openAnalyte,
  onExport,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    allowAddNew,
    allowAddTestType,
    allowMultipleAddNew,
    allowEdit,
    allowDelete,
    allowCancel,
    allowRestore,
    allowMoveToCard,
    allowImport,
    allowReceive,
    allowAssign,
    allowUpload,
    allowSendOutSamples,
    allow1,
    allow2,
    allow3,
    allow4,
    allow5,
    allowCloneRecord,
    allowMergeRecord,
    allow8,
    allow9,
    allow10,
    allow11,
    allow12,
    allow13,
    allow14,
    allow15,
    allow16,
    allowTest1,
    allowTest2,
    allowTest3,
    allowTest4,
    allowTest5,
    allowTest6,
    resultEntries,
  } = actionConfig;

  const handleAllowEdit = () => {
    if (selected.length === 0) {
      return message.warning(t("SAMPLE.MESSAGE.warning_select_record"));
    }
    if (selected.length !== 1) {
      return message.warning("Vui lòng chọn 1 bản ghi!");
    }
    onEdit();
  };

  return (
    <GroupToolbarStyled className="site-card-border-less-wrapper tw-flex tw-flex-row tw-flex-wrap tw-mb-3 tw-mt-3" style={{ justifyContent: "space-between" }}>
      {Object.keys(actionConfig).length > 0 && (
        <Card size={"small"} bordered={false}>
          <div className={"tw-flex tw-flex-row tw-w-max"}>
            {allowAddNew && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 allowAddNew"}
                danger
                onClick={() => onAddNew()}
              >
                <img
                  className={""}
                  src={AddRecord}
                  alt=""
                  title={t("TOOLBAR.Add")}
                />
              </Button>
            )}
            {allowAddTestType && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 allowAddNew"}
                danger
                onClick={() => onAddTestType()}
              >
                <img
                  className={""}
                  src={Twelve}
                  alt=""
                  title={t("Test type")}
                />
              </Button>
            )}
            {allowMultipleAddNew && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 allowMultipleAddNew"}
                danger
              >
                <img
                  className={""}
                  src={AddRecords}
                  alt=""
                  title={t("TOOLBAR.Add_Multiple")}
                />
              </Button>
            )}
            {allowEdit && (
              <Button
                size={"small"}
                type="text"
                className={`tw-mr-1 allowEdit`}
                danger
                onClick={handleAllowEdit}
              >
                <img
                  className={""}
                  src={EditRecord}
                  alt=""
                  title={t("TOOLBAR.Edit")}
                />
              </Button>
            )}
            {allowDelete && (
              <Popconfirm
                placement="bottomLeft"
                title={t("MODAL.confirm_before_delete", {
                  number: selected.length,
                })}
                // title={`Are you sure you want to delete ${selected.length} records?`}
                onConfirm={onDelete}
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button
                  size={"small"}
                  type="text"
                  className={"tw-mr-1 allowDelete"}
                  danger
                >
                  <img
                    className={""}
                    src={DeleteRecord}
                    alt=""
                    title={t("TOOLBAR.Delete")}
                  />
                </Button>
              </Popconfirm>
            )}
            {resultEntries && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 resultEntries"}
                danger
                onClick={() => onResultEntries()}
              >
                <ContainerOutlined />
              </Button>
            )}
            {allowCancel && (
              <Popconfirm
                placement="bottomLeft"
                title={t("MODAL.confirm_not_recive_samples", {
                  number: selected.length,
                })}
                onConfirm={() => dissociateStorage("DESTROYED")}
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button
                  size={"small"}
                  type="text"
                  className={"tw-mr-1 allowCancel"}
                >
                  <img
                    className={""}
                    src={DissociateStorage}
                    alt=""
                    title={t("ACTIONS.not_recive_samples")}
                  />
                </Button>
              </Popconfirm>
            )}

            {allowRestore && (
              <Popconfirm
                placement="bottomLeft"
                title={t("MODAL.confirm_retore_samples", {
                  number: selected.length,
                })}
                onConfirm={() => dissociateStorage("STORED")}
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button
                  size={"small"}
                  type="text"
                  className={"tw-mr-1 allowRestore"}
                >
                  <img
                    className={""}
                    src={RestoreSamples}
                    alt=""
                    title={t("ACTIONS.retore_samples")}
                  />
                </Button>
              </Popconfirm>
            )}
            {allowSendOutSamples && (
              <Popconfirm
                placement="bottomLeft"
                title={t("MODAL.confirm_send_uot_samples", {
                  number: selected.length,
                })}
                onConfirm={() => dissociateStorage("EXPORTED")}
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
              >
                <Button
                  size={"small"}
                  type="text"
                  className={"tw-mr-1 allowSendOutSamples"}
                >
                  <img
                    className={""}
                    src={ExportedCart}
                    alt=""
                    title={t("ACTIONS.send_out_samples")}
                  />
                </Button>
              </Popconfirm>
            )}
            {allowReceive && (
              <Popconfirm
                placement="bottomLeft"
                title={t("MODAL.confirm_recive_samples", {
                  number: selected.length,
                })}
                onConfirm={() => dissociateStorage("RECEIVED")}
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button
                  size={"small"}
                  type="text"
                  className={"tw-mr-1 allowReceive"}
                >
                  <img
                    className={""}
                    src={ReceivedSample}
                    alt=""
                    title={t("ACTIONS.recive_samples")}
                  />
                </Button>
              </Popconfirm>
            )}
            {allowMoveToCard && (
              <Popconfirm
                placement="bottomLeft"
                title={t("MODAL.confirm_stored_samples", {
                  number: selected.length,
                })}
                onConfirm={() => dissociateStorage("STORED")}
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button
                  size={"small"}
                  type="text"
                  className={"tw-mr-1 allowMoveToCard"}
                >
                  <img
                    className={""}
                    src={StoredSamples}
                    alt=""
                    title={t("ACTIONS.stored_samples")}
                  />
                </Button>
              </Popconfirm>
            )}
            {allowImport && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 allowImport"}
                onClick={() => onUploadTemplate()}
              >
                <img
                  className={""}
                  src={Upload}
                  alt=""
                  title={t("ACTIONS.upload_template")}
                />
              </Button>
            )}
            {/* ADD BUTTON NO ACTION */}
            {allow1 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={" tw-opacity-40"} src={One} alt="" title={t("TOOLBAR.Derivative")} />
                </Button>
              </Popconfirm>
            )}
            {allow2 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={" tw-opacity-40"} src={Two} alt="" title={t("TOOLBAR.Derivative")} />
                </Button>
              </Popconfirm>
            )}
            {allow3 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={" tw-opacity-40"} src={Three} alt="" title={t("TOOLBAR.Derivative")} />
                </Button>
              </Popconfirm>
            )}
            {allow4 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={" tw-opacity-40"} src={Four} alt="" title={t("TOOLBAR.Derivative")} />
                </Button>
              </Popconfirm>
            )}
            {allow5 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={" tw-opacity-40"} src={Five} alt="" title={t("TOOLBAR.Derivative")} />
                </Button>
              </Popconfirm>
            )}

            {/* clone form */}
            {allowCloneRecord && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 "}
                // disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
                onClick={() => {
                  if (selected.length > 0) {
                    if (selected.length > 1) {
                      message.warning(t("SAMPLE.MESSAGE.warning_1"));
                    }
                    else
                      onOpenDerivatice(dataSelected)
                    // onCloneRecord(selected?.[0])
                  }
                }}
              >
                <img className={""} src={Six} alt="" title={t("TOOLBAR.Derivative")} />
              </Button>
            )}
            {allowMergeRecord && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 "}
                // disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
                onClick={() => {
                  if (selected.length > 0) {
                    if (selected.length == 1) {
                      message.warning(t("SAMPLE.MESSAGE.warning_2"));
                    }
                    else {
                      message.warning(t("SAMPLE.MESSAGE.warning"));
                      // onOpenDerivatice(dataSelected, true)
                    }
                    // onCloneRecord(selected?.[0])
                  }
                }}
              >
                <img className={""} src={Seven} alt="" title={t("TOOLBAR.PoolSample")} />
              </Button>
            )}
            {allow8 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={""} src={Eight} alt="" title={t("TOOLBAR.Transfer")} />
                </Button>
              </Popconfirm>
            )}
            {allow9 && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 "}
                onClick={onShowBarcode}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <img className={""} src={Nice} alt="" title={t("TOOLBAR.UpdateBarcode")} />
              </Button>
            )}
            {allow10 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={""} src={Ten} alt="" title={t("TOOLBAR.Print")} />
                </Button>
              </Popconfirm>
            )}
            {allow11 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={""} src={Eleven} alt="" title={t("TOOLBAR.Derivative")} />
                </Button>
              </Popconfirm>
            )}
            {allow12 && (
              /*<Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={""} src={Twelve} alt="" title={t("TOOLBAR.Export")} />
                </Button>
              </Popconfirm>*/
              <Button
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-mr-1 tw-opacity-40" : "tw-mr-1"}
                size={"small"}
                type="text"
                onClick={onExport}
              >
                <img className={""} src={Twelve} alt="" title={t("TOOLBAR.Export")} />
              </Button>
            )}
            {allow13 && (
              <Button size={"small"} type="text" className={"tw-mr-1 "} onClick={() => {
                if (selected.length > 0) {
                  if (selected.length !== 1) {
                    message.warning(t("SAMPLE.MESSAGE.warning_3"));
                  }
                  else
                    openAnalyte(dataSelected)
                  // onCloneRecord(selected?.[0])
                }
              }} className={selected.length <= 0 ? "tw-opacity-40" : ""}>
                <img className={""} src={Thirteen} alt="" title={t("TOOLBAR.AssignTest")} />
              </Button>
            )}
            {allow14 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={""} src={Fourteen} alt="" title={t("TOOLBAR.Derivative")} />
                </Button>
              </Popconfirm>
            )}
            {allow15 && (
              <Popconfirm
                placement="bottomLeft"
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button size={"small"} type="text" className={"tw-mr-1 "}>
                  <img className={""} src={Fifteen} alt="" title={t("TOOLBAR.Derivative")} />
                </Button>
              </Popconfirm>
            )}
            {allow16 && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 "}
                // disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
                onClick={() => {
                  if (selected.length > 0) {
                    if (selected.length !== 1) {
                      message.warning(t("SAMPLE.MESSAGE.warning_3"));
                    }
                    else
                      onOpenMoveToCard(dataSelected, true)
                    // onCloneRecord(selected?.[0])
                  }
                }}
              >
                <img className={""} src={MoveToCart} alt="" title={t("TOOLBAR.MoveToCard")} />
              </Button>
            )}
            {allowTest1 && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 "}
                // disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
                onClick={() => {
                  if (selected.length > 0) {
                    if (selected.length !== 1 && false) {
                      message.warning(t("SAMPLE.MESSAGE.warning_3"));
                    }
                    else
                      onShowReturnResult(dataSelected, true)
                    // onCloneRecord(selected?.[0])
                  }
                }}
              >
                <img className={""} src={ResultImage} alt="" title={t("TOOLBAR.ReturnResult")} />
              </Button>
            )}
            {allowTest2 && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 "}
                // disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
                onClick={() => {
                  if (selected.length > 0) {
                    if (selected.length !== 1 && false) {
                      message.warning(t("SAMPLE.MESSAGE.warning_3"));
                    }
                    else
                      onShowValidation(selected, true)
                    // onCloneRecord(selected?.[0])
                  }
                }}
              >
                <img className={""} src={ValidationTest} alt="" title={t("TOOLBAR.SendForValidation")} />
              </Button>
            )}
            {allowTest3 && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 "}
                // disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
                onClick={() => {
                  if (selected.length > 0) {
                    if (selected.length !== 1 && false) {
                      message.warning(t("SAMPLE.MESSAGE.warning_3"));
                    }
                    else
                      onShowValidation2(selected, true)
                    // onCloneRecord(selected?.[0])
                  }
                }}
              >
                <img className={""} src={Validation} alt="" title={t("TOOLBAR.Validation")} />
              </Button>
            )}

            {/* Mở khóa */}
            {allowTest4 && (
              <Popconfirm
                placement="bottomLeft"
                title={"Bạn có muốn mở khóa kết quả không?"}
                // title={`Are you sure you want to delete ${selected.length} records?`}
                onConfirm={() => onChangeStatusTest(selected, "LOCKED", false)}
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button
                  size={"small"}
                  type="text"
                  className={"tw-mr-1 allowDelete"}
                  danger
                >
                  <img
                    className={""}
                    src={icUnlock}
                    alt=""
                    title={"Mở khóa"}
                  />
                </Button>
              </Popconfirm>
            )}

            {/* Khóa */}
            {allowTest5 && (
              <Popconfirm
                placement="bottomLeft"
                title={"Bạn có muốn khóa kết quả không?"}
                // title={`Are you sure you want to delete ${selected.length} records?`}
                onConfirm={() => onChangeStatusTest(selected, "LOCKED", true)}
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
                disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
              >
                <Button
                  size={"small"}
                  type="text"
                  className={"tw-mr-1 allowDelete"}
                  danger
                >
                  <img
                    className={""}
                    src={icLockTest}
                    alt=""
                    title={"Khóa"}
                  />
                </Button>
              </Popconfirm>
            )}
            {/* Xu hướng */}
            {allowTest6 && (
              <Button
                size={"small"}
                type="text"
                className={"tw-mr-1 "}
                // disabled={selected.length <= 0}
                className={selected.length <= 0 ? "tw-opacity-40" : ""}
                onClick={() => {
                  if (selected.length > 0) {
                    if (selected.length !== 1 && false) {
                      message.warning(t("SAMPLE.MESSAGE.warning_3"));
                    }
                    else
                      // onShowValidation2(selected, true)
                      message.warning("Tính năng đang phát triển!")
                    // onCloneRecord(selected?.[0])
                  }
                }}
              >
                <img className={""} src={"https://storage.googleapis.com/chemical-inventory/icons/trend_analysis.png?ver=260RC201"} alt="" title={"Xu hướng"} />
              </Button>
            )}
          </div>
        </Card>
      )
      }
      <div className={"tw-ml-3"} style={{ border: "2px solid transparent", display: "flex" }}>
        {toolbarExtra || ''}
      </div>
    </GroupToolbarStyled>
  );
};

Toolbar.propTypes = {
  actionConfig: PropTypes.object,
  selected: PropTypes.any,
  onAddNew: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCloneRecord: PropTypes.func,
  toolbarExtra: PropTypes.element,
  onOpenDerivatice: PropTypes.func,
  onOpenMoveToCard: PropTypes.func,
};

Toolbar.defaultProps = {
  actionConfig: {
    // allowAddNew: true,
    // allowEdit: true,
    // allowDelete: true,
    // allowMultipleAddNew: false,
  },
};

export default Toolbar;
