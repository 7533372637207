import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, List, message, Modal, Popconfirm, Row, Select, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {createGroup, deleteGroup} from "../../../apis/permissions";
import {DeleteOutlined} from "@ant-design/icons";

const ModalAddUserGroup = ({visible, dataAppCode, onCancel, reloadData, fetching}) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const ruleRequire = [{ required: true, message: t("VALIDATION.require") }];
  const [selectedApp, setSelectedApp] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [listLoading, setListLoading] = useState(false)

  useEffect(() => {
    if (!dataAppCode || dataAppCode.length < 0) {
      return
    }
    const appCode = form.getFieldValue('AppCode')
    if (appCode) {
      handleChangeApp(appCode)
    }
  }, [dataAppCode])

  const handleCancel = () => {
    onCancel()
    form.resetFields()
    setSelectedApp({})
  }

  const handleChangeApp = (name) => {
    const selectedApp = dataAppCode.find(item => item.AppCode === name)
    if (selectedApp) {
      setSelectedApp(selectedApp)
    }
  }

  const onDeleteGroup = async (id) => {
    setListLoading(true)
    const res = await deleteGroup(id)
    setListLoading(false)
    if (res.data.status === 1) {
      message.success(t('PERMISSION.MESSAGE.deleteGroupSuccess'))
      reloadData()
    } else {
      message.error(t('PERMISSION.MESSAGE.deleteGroupFailure'))
    }
  }

  const clearForm = () => {
    form.setFieldsValue({
      TenGroup: '',
      Mota: ''
    })
  }

  const onFinish = async (values) => {
    setSubmitting(true)
    const res = await createGroup(values)
    setSubmitting(false)
    if (res.data.status === 1) {
      message.success(t('PERMISSION.MESSAGE.createGroupSuccess'))
      clearForm()
      reloadData()
    } else {
      message.error(t('PERMISSION.MESSAGE.createGroupFailure'))
    }
  }
  return (
    <Modal
      title={t(`PERMISSION.BUTTON.groupManagement`)}
      visible={visible}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      cancelButtonProps={{style: {display: 'none'}}}
      okText={t('BUTTON.save')}
      confirmLoading={submitting}
      width={1000}
    >
      <Spin tip="Loading..." spinning={fetching}>
        <Row gutter={[16, 16]}>
          <Col span={12} className={'tw-border-r tw-pr-7'}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 11 }}
              wrapperCol={{ span: 13 }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label={t(`PERMISSION.FORM.AppCode`)}
                name={'AppCode'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Select showSearch placeholder={t("selectValue")} allowClear={true} allowClear={true} onChange={handleChangeApp}>
                  {dataAppCode.map(item => {
                    return <Select.Option value={item.AppCode} key={item.AppCode}>{item.TenApp}</Select.Option>
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={t(`PERMISSION.FORM.groupName`)}
                name={'TenGroup'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t(`PERMISSION.FORM.description`)}
                name={'Mota'}
                labelAlign={"left"}
              >
                <Input.TextArea />
              </Form.Item>
            </Form>
          </Col>
          <Col span={12} className={'tw-pl-7'}>
            <List
              className="demo-loadmore-list"
              loading={listLoading}
              itemLayout="horizontal"
              dataSource={selectedApp?.listGroup || []}
              renderItem={item =>
                (
                  <List.Item
                    actions={[
                      <Popconfirm
                        title={t('PERMISSION.MESSAGE.confirmDeleteGroup')}
                        onConfirm={() => onDeleteGroup(item.Id)}
                        okText={t('BUTTON.yes')}
                        cancelText={t('BUTTON.no')}
                      >
                        <Button type="link" danger size={'small'}>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    ]}
                  >
                    <div>{item.TenGroup}</div>
                  </List.Item>
                )
              }
            />
          </Col>
        </Row>
      </Spin>

    </Modal>
  );
};

ModalAddUserGroup.propTypes = {
  dataAppCode: PropTypes.array
};

export default ModalAddUserGroup;
