import Modal from "antd/lib/modal/Modal";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Datatable from "../../../components/Datatable";
import { useSelector } from "react-redux";
import { selectTests } from "../../../redux/test/selector";
import AnalyteForm from "./analyteForm";
import { configAnalyte } from "../mock/configAnalyte";
import { Row, Button, message, Col, Select } from "antd";
import Form from "antd/lib/form/Form";
import SpecifyFlaggingSet from "./specifyFlaggingSet";
import SetFlagg from "./setFlagg";
import { apiAddAnalyteGroup, apiDeleteAnalyte, apiEditAnalyteGroup, getListAnalyte, getListAnalyteGroup } from "../../../apis/analyte";
import SelectCustom from '../../../common/selectCustom';

const ANALYTE_GROUP_ID = "5ee571d1-6d8c-4dcf-8f7e-698e850134c8"

const Analyte = (props) => {
  const { handleClose, isOpenAnalyte, data: dataTestID } = props;
  const { t } = useTranslation();
  const [data, setData] = useState([])
  const [dataSelected, setDataSelected] = useState([]);
  const [dataAnalyteGroup, setDataAnalyteGroup] = useState([]);
  const [analyteGroup, setAnalyteGroup] = useState("")
  const [basedOn, setBasedOn] = useState("OBSERVER_VALUE")

  const columns = [
    {
      title: t("ANALYTE.FORM.analyteGroup"),
      width: 200,
      dataIndex: "analytesGroupName",
    },
    {
      title: t("ANALYTE.FORM.unit"),
      width: 200,
      dataIndex: "unit",
    },
    {
      title: t("ANALYTE.FORM.limit"),
      width: 200,
      dataIndex: "otherLabResult",
    },
    {
      title: t("ANALYTE.FORM.analyteId"),
      width: 200,
      dataIndex: "analytesId",
    },
    {
      title: t("ANALYTE.FORM.status"),
      width: 200,
      dataIndex: "lod",
    },
    {
      title: t("ANALYTE.FORM.analyteName"),
      width: 200,
      dataIndex: "analytesName",
    },
    {
      title: t("ANALYTE.FORM.loq"),
      width: 200,
      dataIndex: "loq",
    },
    {
      title: t("ANALYTE.FORM.testRunNumber"),
      width: 200,
      dataIndex: "costEstimate",
    },
    {
      title: t("ANALYTE.FORM.methodId"),
      width: 200,
      dataIndex: "methodId",
    },
    {
      title: t("ANALYTE.FORM.observedValue"),
      width: 200,
      dataIndex: "testRunNumber",
    },
    {
      title: t("ANALYTE.FORM.result"),
      width: 200,
      dataIndex: "result",
    },
    // {
    //   title: t("ANALYTE.FORM.flag"),
    //   width: 200,
    //   dataIndex: "flag",
    // },
    {
      title: t("ANALYTE.FORM.criticalLow"),
      width: 200,
      dataIndex: "criticalLow",
    },
    {
      title: t("ANALYTE.FORM.criticalHigh"),
      width: 200,
      dataIndex: "criticalHigh",
    },
    {
      title: t("ANALYTE.FORM.miximum"),
      width: 200,
      dataIndex: "minimum",
    },
    {
      title: t("ANALYTE.FORM.maximum"),
      width: 200,
      dataIndex: "maximum",
    },
    {
      title: t("ANALYTE.FORM.entryDate"),
      width: 200,
      dataIndex: "dayTurnaround",
    },
    {
      title: t("ANALYTE.FORM.entryTime"),
      width: 200,
      dataIndex: "timeTurnaround",
    },
  ];
  const modalRef = useRef();
  const modalSpecifyRef = useRef();
  const modalSetFlagRef = useRef();
  const tableRef = useRef();
  const [form, setForm] = useState(null);
  const handleCancel = () => {
    handleClose();
  };
  const handleOk = () => {
    handleClose();
  };

  useEffect(async () => {
    if (isOpenAnalyte) {
      const groups = await getListGroups();
      setAnalyteGroup(groups?.[0]?.id || "")
    }
  }, [isOpenAnalyte])

  useEffect(() => {
    if (analyteGroup) {
      getList();
    }
    else {
      setData([])
    }
  }, [analyteGroup])

  const handleOkSelectCustom = async (name) => {
    const { data } = await apiAddAnalyteGroup({
      "flagCalculationBase": "OBSERVER_VALUE",
      testIdUUID: dataTestID.id,
      name
    })
    const groups = await getListGroups();
    const element = groups.find(x => x.name == name);
    setAnalyteGroup(element.id);
    setBasedOn(element.flagCalculationBase || "OBSERVER_VALUE")
  }

  const getListGroups = async () => {
    const { data } = await getListAnalyteGroup(dataTestID.id);
    setDataAnalyteGroup(data)
    return data
  }

  const getList = async () => {
    const { data } = await getListAnalyte(analyteGroup || ANALYTE_GROUP_ID);
    const temp = [], o = [];
    data.forEach(d => {
      if (!temp.includes(d.id)) {
        temp.push(d.id);
        o.push(d)
      }
    })
    setData(o)
  }

  const openForm = (record) => {
    if (modalRef.current) {
      modalRef.current.open(dataAnalyteGroup?.[0] || {});
      if (record) {
        modalRef.current.setFieldsValue(record);
      }
    }
  };
  const openSpecifyFlagg = () => {
    if (dataSelected.length != 1) {
      message.warning("Phải chọn 01 bản ghi")
      return;
    }
    modalSpecifyRef.current.openSpecifyFlaggingModal(dataSelected[0].analytesFlagId, dataSelected[0].id, dataSelected[0].analytesName, dataSelected);
  };
  const openSetFlagg = () => {
    modalSetFlagRef.current.openSetFlaggModal();
  };
  const toolbarExtra = () => {
    return (
      <>
        <Row gutter={[32, 8]}>
          <Button className="tw-ml-3" onClick={openSpecifyFlagg}>
            Cài đặt giá trị trong cờ
          </Button>
          <Button className="tw-ml-3" onClick={openSetFlagg}>
            Cài đặt màu cờ
          </Button>
        </Row>
      </>
    );
  };

  const onDelete = (ids) => {
    const promises = [];
    ids.map((id) => {
      return promises.push(handleDelete(id));
    });
    Promise.all(promises)
      .then((result) => {
        getList();
      })
      .catch((error) => {
        // console.log(`Error in promises ${error}`);
        getList();
      })
  };

  const handleDelete = async (id) => {
    await apiDeleteAnalyte(id);
  };

  const BASED_ON = ["OBSERVER_VALUE", "LOD", "LOQ", "RESULT"]

  return (
    <div>
      <Modal
        width={"100vw"}
        style={{ top: 20 }}
        // centered={true}
        title="Cài đặt phân tích"
        visible={isOpenAnalyte}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.close")}
        okButtonProps={{ htmlType: "submit" }}
        forceRender
      >
        <div>
          <Row>
            <Col span={8}>
              <Row>
                <SelectCustom
                  label='Nhóm phân tích'
                  options={dataAnalyteGroup}
                  value={analyteGroup}
                  onChange={(value) => {
                    const element = dataAnalyteGroup.find(x => x.id == value);
                    setAnalyteGroup(value)
                    setBasedOn(element.flagCalculationBase || "OBSERVER_VALUE")
                  }}
                  handleOk={handleOkSelectCustom}
                />
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={8}>Giá trị cờ dựa trên giá trị: </Col>
                <Col span={12}>
                  <Select value={basedOn} onChange={(value) => setBasedOn(value)}>
                    {
                      BASED_ON.map(r => <Select.Option key={r} value={r}>{t(r)}</Select.Option>)
                    }
                  </Select>
                </Col>
                <Col span={4}>
                  <Button type="primary" onClick={async () => {
                    const group = dataAnalyteGroup.find(x => x.id == analyteGroup)
                    await apiEditAnalyteGroup({ ...group, flagCalculationBase: basedOn, testIdUUID: group.testId })
                  }} >Cập nhật</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div>
          <Datatable
            tableRef={tableRef}
            columns={columns}
            dataSource={(data || [])}
            // rowKey={"generalInformation.testID"}
            autoFlatData={true}
            allowSelect={true}
            onSelect={(records) => setDataSelected(records)}
            onAddNew={openForm}
            onDelete={onDelete}
            onEdit={openForm}
            rowKey={"id"}
            actionConfig={{
              allowEdit: true,
              allowAddNew: true,
              allowDelete: true,
            }}
            toolbarExtra={toolbarExtra()}
          />
          <AnalyteForm actionRef={modalRef} configData={configAnalyte} dataTestID={dataTestID} handleSave={() => getList()} testGroup={(dataAnalyteGroup || []).find(x => x.id == analyteGroup) || {}} />
          <SpecifyFlaggingSet actionRef={modalSpecifyRef} handleSave={() => getList()} dataTestID={dataTestID} />
          <SetFlagg actionRef={modalSetFlagRef} />
        </div>
      </Modal>
    </div>
  );
};
export default Analyte;
