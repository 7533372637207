import React, { useEffect, useRef, useState } from "react";
import Datatable from "../../components/Datatable";
import { useTranslation } from "react-i18next";
import { deleteTemplate, getListTemplate } from "../../apis/Template";
import { handleActionByRole, timeFormatter } from "../../utils/utils";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Select, Button, message, Table } from "antd";
import Analyte from "../Test/Analyte";
import { useSelector } from "react-redux";
import { selectRole } from "../../redux/permission/selector";
import ModalAdd from "./ModalAdd";
import { apiDeleteTestGroup, getListTestGroup } from "../../apis/configTest";

const Templates = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tableRef = useRef();
  const roles = useSelector(selectRole);
  const [data, setData] = useState([]);
  const [module, setModule] = useState("SAMPLE");
  const [tableLoading, setTableLoading] = useState(false);
  const modalRef = useRef();
  const [isOpenAnalyte, setIsOpenAnalyte] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [dataSelected, setDataSelected] = useState([]);
  const [dataSelectedItem, setDataSelectedItem] = useState([]);

  const location = useLocation();

  useEffect(() => {
    if (new URLSearchParams(location.search).get("type") === "TEST")
      setModule("TEST")
  }, [location])

  const fetchData = async () => {
    setTableLoading(true);
    if (module == "TEST") {
      const res = await getListTestGroup();
      if (res.status === 200) {
        setData(res.data);
        setTableLoading(false);
      }
      return;
    }

    const res = await getListTemplate(module);
    if (res.status === 200) {
      setData(res.data);
      setTableLoading(false);
    }
  };

  const onDelete = (ids) => {
    const promises = [];
    ids.map((id) => {
      return promises.push(handleDelete(id));
    });
    Promise.all(promises)
      .then((result) => {
        fetchData().then();
      })
      .catch((error) => {
        // console.log(`Error in promises ${error}`);
        fetchData().then();
      });
  };

  const handleDelete = async (id) => {
    await apiDeleteTestGroup(id);
    const res = await deleteTemplate(id);
  };

  useEffect(() => {
    fetchData().then();
    // eslint-disable-next-line
  }, [module]);
  const openForm = (record) => {
    // if (modalRef.current) {
    //   modalRef.current.open();
    // if (record) {
    //   modalRef.current.setFieldsValue(record);
    // }
    setIsOpenAnalyte(true);
  };
  const openAnalyteModal = () => {
    if (dataSelectedItem.length != 1) {
      message.warning("Phải chọn 01 phân loại thử nghiệm");
      return
    }
    setIsOpenAnalyte(true);
  };
  const handleClose = () => {
    setIsOpenAnalyte(false);
  };

  const openAddTestType = () => {
    if (dataSelected.length != 1) {
      message.warning("Phải chọn 01 nhóm thử nghiệm!");
      return;
    }
    setVisibleAdd(true)
  }

  const toolbarExtra = () => {
    return (
      <>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={(value) => setModule(value)}
          value={module}
        >
          <Select.Option value="SAMPLE">Quản lý mẫu</Select.Option>
          <Select.Option value="TEST">Thử nghiệm</Select.Option>
        </Select>
        {module == "TEST" && (
          <>
            <Button type="primary" className="tw-ml-3" onClick={openAddTestType}>
              Tạo phân loại thử nghiệm
            </Button>
            <Button type="primary" className="tw-ml-3" onClick={openAnalyteModal}>
              Tạo phân tích
            </Button>
          </>
        )}
      </>
    );
  };

  const renderJson = (json) => {
    try {
      return JSON.parse(json)
    }
    catch (err) {
      return json
    }
  }

  const columnItems = [
    {
      title: 'Mã kiểm tra', dataIndex: 'testCodeDTO', key: 'testCodeDTO',
      render: (testCodeDTO) => <div>{testCodeDTO?.name}</div>
    },
    { title: 'Mã thử nghiệm', dataIndex: 'testId', key: 'testId' },
    {
      title: 'Loại thử nghiệm', dataIndex: 'type', key: 'type',
      render: (type) => <div>{t(type)}</div>
    },
    {
      title: 'Loại mẫu', dataIndex: 'sampleTypes', key: 'sampleTypes',
      render: (sampleTypes, x) => <div>{
        (sampleTypes || []).map(x => <div>{x?.templateName}</div>)
      }</div>
      // render: (sampleTypes, x) => <div>{sampleTypes?.[0]?.templateName}</div>
    },
    {
      title: 'Người phê duyệt', dataIndex: 'data', key: 'data1',
      render: (data) => <div>{renderJson(data)?.approver?.Username}</div>
      // render: (data) => console.log(JSON.parse(data || '{}'))
    },
    {
      title: 'Kĩ thuật viên', dataIndex: 'data', key: 'data2',
      render: (data) => <div>{
        (renderJson(data)?.technician || []).map(t => <div key={t?.IdUser}>{t?.Username}</div>)
      }</div>
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDataSelectedItem(selectedRows)
    },
    getCheckboxProps: (record) => ({
      // name: record.name,
    }),
  };

  return (
    <div>
      {
        <ModalAdd
          visible={visibleAdd}
          setVisible={setVisibleAdd}
          dataSelected={dataSelected}
          dataTypeTest={data}
          handleSave={() => fetchData().then()}
        />
      }
      <Datatable
        tableRef={tableRef}
        toolbarExtra={toolbarExtra()}
        loading={tableLoading}
        dataSource={data}
        rowKey={"id"}
        autoFlatData={true}
        allowSelect={true}
        onSelect={(records) => setDataSelected(records)}
        expandable={{
          expandedRowRender: record => <Table
            columns={columnItems}
            dataSource={(record.testIdResponseDTOS || []).map(r => { return { ...r, key: r.id } })}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            pagination={false}
          ></Table>,
          // rowExpandable: record => record.name !== 'Not Expandable',
          onExpand: (expanded, record) => console.log(expanded, record),
          rowExpandable: record => record.templateType !== "SAMPLE",
        }}
        // actionConfig={handleActionByRole({
        //   allowEdit: EditConfig,
        //   allowAddNew: CreateConfig,
        //   allowDelete: EditConfig,
        // }, roles)}
        actionConfig={{
          allowEdit: true,
          allowAddNew: true,
          allowDelete: true,
          // allowAddTestType: true,
        }}
        onDelete={onDelete}
        onAddNew={() => {
          // if (module === "TEST") {
          //   setVisibleAdd(true);
          //   return;
          // }
          history.push("/form-setting/add")
        }}
        onAddTestType={() => setVisibleAdd(true)}
        onEdit={(record) => history.push(`/form-setting/${record.id}${module == "TEST" ? '?type=TEST' : ''}`)}
        columns={module == "TEST" ? [
          {
            title: t("TEMPLATE_SETTING.FORM.templateNameTest"),
            // width: 300,
            dataIndex: "name",
            render: (value, record) => {
              return <Link to={`/form-setting/${record.id}?type=TEST`}>{value}</Link>;
            },
          },
          {
            title: t("TEMPLATE_SETTING.FORM.createdAt"),
            // width: 300,
            dataIndex: "createDate",
            render: (value) => {
              return timeFormatter(value);
            },
          },
        ] : [
          {
            title: t("TEMPLATE_SETTING.FORM.templateName"),
            // width: 300,
            dataIndex: "templateName",
            render: (value, record) => {
              return <Link to={`/form-setting/${record.id}`}>{value}</Link>;
            },
          },
          {
            title: t("TEMPLATE_SETTING.FORM.createdAt"),
            // width: 300,
            dataIndex: "createdDate",
            render: (value) => {
              return timeFormatter(value);
            },
          },
        ]}
      />
      <Analyte
        actionRef={modalRef}
        isOpenAnalyte={isOpenAnalyte}
        handleClose={handleClose}
        data={dataSelectedItem?.[0] || {}}
      />
    </div>
  );
};

Templates.propTypes = {};

export default Templates;
