import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Checkbox, Empty } from "antd";
import { useTranslation } from "react-i18next";

const inputBlackList = ["sampleId"];

const SectionItem = ({ title, items, values, onChange, templateType }) => {
  const { t } = useTranslation();

  const handleChangeSection = (e) => {
    if (e.target.checked) {
      // handleChange(e)
      const listChild = Object.keys(items).map((key) => key);
      onChange([...values, e.target.value, ...listChild]);
    } else {
      const listChild = Object.keys(items).map((key) => key);
      const res = values.filter(
        (item) => !listChild.includes(item) && item !== e.target.value
      );
      onChange(res);
    }
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      onChange([...values, e.target.value]);
    } else {
      const tmp = values.filter((item) => item !== e.target.value);
      onChange(tmp);
    }
  };

  return (
    <div style={{ marginBottom: 15 }}>
      <div>
        <Checkbox
          checked={values.includes(title)}
          value={title}
          onChange={handleChangeSection}
        >
          {t(`${templateType}.FORM.${title}`)}
        </Checkbox>
      </div>
      <div style={{ marginLeft: 30 }}>
        {Object.keys(items).map((key) => {
          if (inputBlackList.includes(key)) return "";
          return (
            <div key={key}>
              <Checkbox
                checked={values.includes(key)}
                value={key}
                onChange={handleChange}
              >
                {t(`${templateType}.FORM.${key}`)}
              </Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
};

/*****************************
 * MAIN COMPONENT
 ************************** **/
const FormSelect = ({ configTemplate, onChange, templateType }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!configTemplate || configTemplate.length <= 0) return;
    const list = configTemplate.map((item) => {
      const arr = [];
      if (item.display) {
        arr.push(item.sectionName);
      }
      Object.keys(item.attribute).map((key) => {
        if (item.attribute[key].display) {
          arr.push(key);
        }
        return key;
      });
      // if (arr.length > 0) {
      //   arr.push(item.sectionName)
      // }
      return arr;
    });
    setSelected([].concat(...list));
  }, [configTemplate]);

  useEffect(() => {
    onChange(selected);
    // eslint-disable-next-line
  }, [selected]);

  const handleChange = (values) => {
    setSelected(values);
  };

  return (
    <Card
      style={{
        height: "calc(100vh - 120px)",
        overflow: "auto",
        position: "sticky",
        top: 90,
      }}
    >
      {configTemplate?.length > 0 ? (
        configTemplate.map((item, index) => {
          return (
            <SectionItem
              templateType={templateType}
              key={index}
              title={item.sectionName}
              items={item.attribute}
              values={selected}
              onChange={handleChange}
            />
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};

FormSelect.propTypes = {
  onChange: PropTypes.func,
  configTemplate: PropTypes.array.isRequired,
};

export default FormSelect;
