import axios from "axios";
import { API_METHOD, API_STATUS_CODE } from "../constants/api.constant";
import { TOKEN_NAME } from "../constants";
import { message } from "antd";
import { refreshToken } from "../utils/auth.utils";

export const configHeader = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInVzZXJfaWQiOiI1ZmRjMTkxYy1jYzM5LTQ3N2ItYjUwMi0xZDQ1NGMwOGYwNGMiLCJyb2xlIjoxLCJpYXQiOjE1OTMxNDg5ODQsImV4cCI6MTU5MzMyMTc4NH0.yzHcMPWzYQy30qecFYjztx7Y_PMiGbjPh-8X9o9jbVk';
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    "Accept": "*/*",
    Authorization: token ? `Bearer ${token}` : null,
  };
  return headers;
};

const formConfig = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  const headers = {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

// const fireBaseConfig = () => {
//     const headers = {
//         Authorization:
//             'key=AAAAKPjF_UY:APA91bEaBvbEzv-k8C0GHotRDOx5wIYMEzxVKocNdEuX_839bpasVo8TfJsN4ATxK6fbOZOhdAttbeelkJ4xeqZUgBNMFghNvEaEnNmUi58TPIOzTnDZBiVhyeGj65ju4OHb36cA5lej',
//         'Content-Type': 'application/json',
//     };
//     return headers;
// };

class AxiosService {
  get(url, params, customHeader) {
    return this.doRequest(API_METHOD.GET, url, params, customHeader);
  }

  post(url, data, customHeader) {
    return this.doRequest(API_METHOD.POST, url, data, customHeader);
  }

  put(url, data, customHeader) {
    return this.doRequest(API_METHOD.PUT, url, data, customHeader);
  }

  delete(url, customHeader, data = '') {
    return this.doRequest(API_METHOD.DELETE, url, data, customHeader );
  }

  async doRequest(method, url, data, customHeader) {
    let cusConfig = configHeader();
    if (customHeader) {
      cusConfig = {
        ...cusConfig,
        ...customHeader,
      };
    }
    // if (type === 'FORM') {
    //   cusConfig = formConfig();
    // }
    // if (type === 'FIRE_BASE') {
    //     cusConfig = fireBaseConfig();
    // }
    const options = {
      method,
      url,
      headers: cusConfig,
    };
    if (method === API_METHOD.GET) {
      options.params = data;
    } else {
      options.data = data;
    }
    return axios(options)
      .then((res) => {
        return {
          status: res.status,
          data: res.data,
        };
      })
      .catch((err) => {
        const resp = err.response;
        if (!resp) {
          return {
            status: 503,
            data: {
              msg: "SERVICE UNAVAILABLE",
            },
          };
        }
        switch (resp.status) {
          case API_STATUS_CODE.NOT_FOUND:
          case API_STATUS_CODE.METHOD_NOT_ALLOW:
          case API_STATUS_CODE.SERVICE_UNAVAIBLE:
          case API_STATUS_CODE.BAD_REQUEST: {
            return resp;
          }
          case API_STATUS_CODE.UNAUTHORIZED:
          case API_STATUS_CODE.ERR_ACCESS_DENIED: {
            // message.error('ffff')
            refreshToken.call();
            // localStorage.setItem(TOKEN_NAME, '');
            // document.location.href = '/login';
            return resp;
          }
          default:
            return resp;
        }
      });
  }
}

export default new AxiosService();

// export const instance = axios.create({
//     baseURL: apiUrl,
//     timeout: apiTimeOut,
// });
// const customHeader = (headerSetting = {}) => ({
//     // 'Content-Type': 'application/json',
//     // Accept: 'application/json',
//     Authorization: 'Bearer ' + localStorage.getItem('___pharmaToken') || undefined,
//     ...headerSetting,
// });
//
// const networkErrorTemplate = (e, titleParam, content) => {
//     if (axios.isCancel(e)) {
//         console.debug('Request canceled!');
//         return;
//     }
//     console.debug('Request canceled!');
// };
//
// export const requestApi = async ({ apiOption, successCallback = () => {}, failCallback = () => {} }) => {
//     const method = apiOption?.method || 'get';
//     let headerSetting = {};
//     if (method === 'patch') headerSetting = { 'content-type': 'application/merge-patch+json' };
//     let successResult = null;
//     let errorResult = null;
//     await instance({
//         headers: customHeader(headerSetting),
//         ...apiOption,
//     })
//       .then(res => {
//           successCallback(res);
//           successResult = res;
//           return res;
//       })
//       .catch(res => {
//           if (axios.isCancel(res)) {
//               const error = { errorTitle: 'Request error', errorDescription: 'Request canceled '};
//               failCallback(error);
//               errorResult = error;
//               return error;
//           }
//
//           if (res && res.status === 400) {
//               const error = { errorTitle: 'Request error', errorDescription: 'Request canceled '};
//               failCallback(error);
//               errorResult = error;
//               return error;
//           }
//
//           return networkErrorTemplate(res);
//       });
//
//     return [successResult, errorResult];
// };
