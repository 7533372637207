import React, {useEffect, useState} from "react";
import Datatable from "../../../components/Datatable";
import { useTranslation } from "react-i18next";
import {Form, Input, Button, Row, Select, DatePicker, Col, Dropdown} from "antd";
import axiosService from "../../../services/axiosService";
import {CONFIG} from "../../../configs";
import * as moment from 'moment'
import { useHistory } from "react-router";

const AuditTrail = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState({
    fromDate: null,
    toDate: null,
    status: null,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [selectedId, setSelectedId] = useState([]);
  const history = useHistory()
  const columns = [
    {
      title: t("ALERT.alert_date_time"),
      width: 200,
      dataIndex: "alertTime",
      render: (time, record) => record.status === 'UNREAD' ? <b>{moment(time).format('DD/MM/YYYY HH:mm:ss')}</b> : moment(time).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      title: t("ALERT.module"),
      width: 200,
      dataIndex: "module",
      render: (text, record) => record.status === 'UNREAD' ? <b>{t('ALERT.'+ text)}</b> : t('ALERT.'+ text)
    },
    {
      title: t("ALERT.alert_message"),
      width: 200,
      dataIndex: "alertMessage",
      render: (text, record) => {
        const CustomTag = record.status === 'UNREAD' ? "b" : "div";
        return (
          <CustomTag>{t(record.alertMessage)} ({t(record.linkCategory)}: <a onClick={()=>{history.push(`/sample/${record.linkUuid}`)}}>{record.linkIdName})</a></CustomTag>
        )
      }
    },
    {
      title: t("ALERT.from_user"),
      width: 200,
      dataIndex: "fromUser",
      render: (text, record) => record.status === 'UNREAD' ? <b><a>{text}</a></b> : <a>{text}</a>
    },
    {
      title: t("ALERT.to_user"),
      width: 200,
      dataIndex: "toUser",
      render: (text, record) => record.status === 'UNREAD' ? <b><a>{text}</a></b> : <a>{text}</a>
    }
  ];

  useEffect(() => {
    getList();
  }, [])

  const getList = async () => {
    if (filterStatus === 'ALL') {
      search.status = null;
    } else {
      search.status = filterStatus;
    }

    setLoading(true);
    let { data: {alerts} } = await axiosService.get(`${CONFIG.API_URL.BASE}/api/alert`, search);
    alerts = (alerts || []);
    // console.log(alerts)
    setData(alerts);
    setLoading(false);
  }

  const onFromDateChange = (date) => {
    cloneSearch('fromDate', date.format('MM/DD/yyyy'));
  }

  const onToDateChange = (date) => {
    cloneSearch('toDate', date.format('MM/DD/yyyy'));
  }

  const cloneSearch = (key, value) => {
    const clone = JSON.parse(JSON.stringify(search));
    clone[key] = value;
    setSearch(clone);
    // console.log(clone);
  }

  const deleteAlert = async () => {
    if (!selectedId || selectedId.length < 1) {
      return
    }

    const { status } = await axiosService.delete(`${CONFIG.API_URL.BASE}/api/alert`, null, {ids: selectedId})
    if (status === 200) {
      getList()
    }
  }

  const updateStatusAlert = async (status) => {
    if (!selectedId || selectedId.length < 1) {
      return
    }
    const { status: statusReq } = await axiosService.put(`${CONFIG.API_URL.BASE}/api/alert`, {ids: selectedId, status})
    if (statusReq === 200) {
      getList()
    }
  }

  const onSelectItem = (data) => {
    const idList = (data || []).map(alert => alert.id)
    setSelectedId(idList)
  }

  return (
    <div>
      <Row>
        <Col span={4}>
          <Row>
            <Col span={8}>{t("ALERT.from")}: </Col>
            <Col span={12}><DatePicker placeholder={""} className={"tw-w-full"} onChange={date => onFromDateChange(date)} /></Col>
          </Row>
        </Col>
        <Col span={4}>
          <Row>
            <Col span={8}>{t("ALERT.to")}: </Col>
            <Col span={12}><DatePicker placeholder={""} className={"tw-w-full"} onChange={date => onToDateChange(date)} /></Col>
          </Row>
        </Col>
        <Col span={4}>
          <Row>
            <Col span={8}>{t("ALERT.status")}: </Col>
            <Col span={12}><Select
                showSearch
                style={{ width: 200 }}
                optionFilterProp="children"
                onChange={(value) => setFilterStatus(value)}
                value={filterStatus}
            >
              <Select.Option value="ALL">{t("ALERT.all_alert")}</Select.Option>
              <Select.Option value="READ">{t("ALERT.read")}</Select.Option>
              <Select.Option value="UNREAD">{t("ALERT.unread")}</Select.Option>
            </Select></Col>
          </Row>
        </Col>
        <Col span={4}>
          <Button className="tw-ml-2" type="primary" onClick={getList}>
            {t("ALERT.show")}
          </Button>
        </Col>
        <Col>
          <Button className="tw-ml-2" onClick={deleteAlert}>
            {t("ALERT.delete")}
          </Button>
          <Button className="tw-ml-2" onClick={() => updateStatusAlert('READ')}>
            {t("ALERT.mark_read")}
          </Button>
          <Button className="tw-ml-2" onClick={() => updateStatusAlert('UNREAD')}>
            {t("ALERT.mark_unread")}
          </Button>
        </Col>
      </Row>
      
      <Datatable
        columns={columns}
        loading={loading}
        dataSource={data}
        rowKey={"id"}
        autoFlatData={true}
        allowSelect={true}
        actionConfig={{}}
        onSelect={records => onSelectItem(records)}
        // toolbarExtra={toolbarExtra()}
      />
    </div>
  );
};

export default AuditTrail;
