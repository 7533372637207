import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import Datatable from "../../components/Datatable";
import {useTranslation} from "react-i18next";
import {deleteUserApi, getUsersApi} from "../../apis/user";
import {Button, Image} from "antd";
import UserForm from "./Form";
import UseAllAppPermission from "../../hooks/useAllAppPermission";
import {useSelector} from "react-redux";
import {selectRole} from "../../redux/permission/selector";
import {handleActionByRole} from "../../utils/utils";
import {
  UserManagament_AddUser, UserManagament_AssignRight,
  UserManagament_DeleteUser,
  UserManagament_EditUser
} from "../../constants/roles.constant";

const UserManagement = () => {
  const {t} = useTranslation()
  const tableRef = useRef()
  const roles = useSelector(selectRole);
  const [data, setData] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const {data: allAppPermission} = UseAllAppPermission()
  const [listAppCode, setListAppCode] = useState([])

  // MODAL FORM
  const [visibleModalForm, setVisibleModalForm] = useState(false)
  const [dataEdit, setDataEdit] = useState(null)

  useEffect(() => {
    if (!allAppPermission || allAppPermission.length <= 0) return
    const appCode = allAppPermission.map(item => {
      return item.AppCode
    })
    setListAppCode(appCode)

    fetchUsers({listApp: appCode}).then()
  }, [allAppPermission])

  const fetchUsers = async (data) => {
    setTableLoading(true)
    const res = await getUsersApi(data)
    setTableLoading(false)
    if (res.data.status === 1) {
      setData(res.data.data)
    }
  }

  const afterSubmit = () => {
    setVisibleModalForm(false)
    setDataEdit(null)
    fetchUsers({listApp: listAppCode}).then()
  }

  const handleEdit = (data) => {
    setVisibleModalForm(true)
    setDataEdit(data)
  }

  const handleClose = () => {
    setVisibleModalForm(false)
    setDataEdit(null)
  }

  const handleDeleteUser = (ids) => {
    const promises = [];
    ids.map((id) => {
      return promises.push(deleteUserApi(id));
    });
    Promise.all(promises)
      .then((result) => {
        fetchUsers({listApp: listAppCode}).then()
      })
      .catch((error) => {
        // console.log(`Error in promises ${error}`);
        fetchUsers({listApp: listAppCode}).then()
      });
  }

  const toolbarExtra = () => {
    if (roles.includes(UserManagament_AssignRight)) {
      return <Link to={'/permission-management'}><Button type="primary">{t('USER.setPermission')}</Button></Link>
    }
    return ''
  }


  return (
    <div>
      <Datatable
        tableRef={tableRef}
        toolbarExtra={toolbarExtra()}
        loading={tableLoading}
        dataSource={data}
        rowKey={'IdUser'}
        autoFlatData={true}
        allowSelect={true}
        actionConfig={handleActionByRole({
          allowEdit: UserManagament_EditUser,
          allowAddNew: UserManagament_AddUser,
          allowDelete: UserManagament_DeleteUser
        }, roles)}
        onDelete={handleDeleteUser}
        onAddNew={() => setVisibleModalForm(true)}
        onEdit={record => handleEdit(record)}
        columns={[
          {
            title: t('USER.FORM.Username'),
            width: 300,
            dataIndex: 'Username',
          },
          {
            title: t('USER.FORM.FullName'),
            width: 300,
            dataIndex: 'FullName',
            render: (value, record) => {
              return <div>{record.FirstName} {record.LastName}</div>
            }
          },
          // {
          //   title: t('USER.FORM.FirstName'),
          //   width: 300,
          //   dataIndex: 'FirstName',
          // },
          // {
          //   title: t('USER.FORM.LastName'),
          //   width: 300,
          //   dataIndex: 'LastName',
          // },
          {
            title: "Số điện thoại",
            width: 300,
            dataIndex: 'Phone',
          },
          {
            title: "Email",
            width: 300,
            dataIndex: 'Email',
          },
          {
            title: t('USER.FORM.position'),
            width: 300,
            dataIndex: 'Position',
          },
          // {
          //   title: t('USER.FORM.UserGroup'),
          //   width: 300,
          //   dataIndex: 'UserGroup',
          // },
          {
            title: t('USER.FORM.department'),
            width: 300,
            dataIndex: 'Department',
          },
          {
            title: t('USER.FORM.EmploymentNo'),
            width: 300,
            dataIndex: 'MaUser',
          },
          // {
          //   title: t('USER.FORM.LoginStatus'),
          //   width: 300,
          //   dataIndex: 'LoginStatus',
          // },
          // {
          //   title: t('USER.FORM.LastLoginDate'),
          //   width: 300,
          //   dataIndex: 'LastLoginDate',
          // },
          // {
          //   title: t('USER.FORM.LastLoginTime'),
          //   width: 300,
          //   dataIndex: 'LastLoginTime',
          // }
        ]}
      />

      <UserForm visible={visibleModalForm} dataEdit={dataEdit} onCancel={() => handleClose()} afterSubmit={afterSubmit} listApp={allAppPermission} />
    </div>
  );
};

UserManagement.propTypes = {

};

export default UserManagement;
