import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Input, message, Modal, Radio, Row, Select } from "antd";
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { getUsersApi } from '../../apis/user';
import { apiAddTestCode, apiAddTestType, getListTestCode, getPreviewTestCode } from '../../apis/configTest';
import SelectCustom from '../../common/selectCustom';
import { getListTemplate } from '../../apis/Template';

const ModalAdd = ({ visible = false, setVisible, dataSelected = [], dataTypeTest, handleSave }) => {
  const { t } = useTranslation();
  const [dataUser, setDataUser] = useState([]);
  const [form, setForm] = useState({});
  const [dataForms, setDataForms] = useState([]);
  console.log('form', form);

  const [dataTestCode, setDataTestCode] = useState([]);

  useEffect(() => {
    if (visible) {
      if (dataSelected.length == 1) {
        setForm(form => {
          return { testGroupId: dataSelected[0].id, type: "QUANTITATIVE", sampleTypes: [] };
          return { testGroupId: dataSelected[0].id, type: "QUANTITATIVE", sampleTypes: form?.sampleTypes?.[0]?.sampleTypeId };
          return { ...form, testGroupId: dataSelected[0].id, sampleTypes: form?.sampleTypes?.[0]?.sampleTypeId }
        })
        if (dataSelected[0].id) {
          getListCodes(dataSelected[0].id)
        }
      }
      else {
        setForm({})
      }
    }
  }, [visible, dataSelected])

  useEffect(() => {
    getList();
    fetchListTemplate();
  }, [])

  const getList = async () => {
    const x = await getUsersApi({ "listApp": ["NT_Core", "Sample", "NT_Document", "UserManagament", "FormConfiguration"] })
    const dU = x.data.data.map(x => { return { ...x, value: x.IdUser, lable: x.Username } })
    setDataUser(dU);
  }

  const getListCodes = async (id) => {
    const { data } = await getListTestCode(form?.testGroupId || id)
    setDataTestCode(data)
  }

  const getDetailUser = (data, v) => {
    const element = data.find(user => user.value == v)
    const { value, label } = element || {}
    return element;
    return { value, label };
  }

  const handleOk = async () => {
    let formHandle = { ...form }
    if (!formHandle.testCodeId) {
      message.warning("Test code là trường bắt buộc")
      return;
    }
    if (!formHandle.sampleTypes || formHandle.sampleTypes?.length === 0) {
      message.warning("Sample Type là trường bắt buộc")
      return;
    }
    const sampleTypes = formHandle.sampleTypes.map(f => {
      const e = dataForms.find(x => x.id === f);
      return {
        "sampleTypeId": f,
        "sampleTypeName": e.templateName,
      }
    })
    console.log('sampleTypes', sampleTypes);
    formHandle.sampleTypes = sampleTypes
    formHandle.type = formHandle.type || "QUANTITATIVE"
    formHandle.updatedBy = "string"
    formHandle.createdBy = "string"
    const f = {
      approver: getDetailUser(dataUser, formHandle.approver),
      technician: (formHandle.technician || []).map(tech => getDetailUser(dataUser, tech))
    }
    formHandle.data = JSON.stringify(f)
    formHandle.approver = "0"
    formHandle.technician = "0"

    const { data } = await apiAddTestType(formHandle);
    handleSave && handleSave();
    setVisible && setVisible(false)
  }

  const handleCancel = () => {
    setVisible && setVisible(false)

  }

  const handleChange = async (e, key) => {
    setForm(form => {
      return { ...form, [key]: e }
    })
    if (key == "testGroupId") {
      getListCodes(e)
    }
    if (key == "testCodeId") {
      const { data } = await getPreviewTestCode(e)
      setForm(form => {
        return { ...form, "testId": data }
      })
    }
  }

  const fetchListTemplate = async () => {
    const res = await getListTemplate("SAMPLE");
    if (res.status === 200) {
      setDataForms(res.data || []);
    }
  };

  const handleOkTestCode = async (name) => {
    await apiAddTestCode({
      testGroupId: form?.testGroupId,
      name
    })
    const { data: dataTestCode } = await getListTestCode(form?.testGroupId)
    setDataTestCode(dataTestCode)
    const element = (dataTestCode || []).find(x => x.name === name)
    const { data: d } = await getPreviewTestCode(element.id)
    setForm(form => {
      return { ...form, "testId": d, testCodeId: element.id }
    })
  }

  return (
    <div>
      <Modal
        centered={true}
        title={
          "Phân loại thử nghiệm"
        }
        visible={visible}
        onOk={handleOk}
        // confirmLoading={submitting}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        width={1000}
        forceRender
        className='form-add-test'
      >
        <div>
          <h2>Thông tin thử nghiệm</h2>
          <div>
            <Row className='ant-form-item'>
              <Col span={12}>
                <Row>
                  <Col span={8}>Nhóm thử nghiệm <span style={{ color: 'red' }}>*</span></Col>
                  <Col span={12}>
                    <Select value={form?.testGroupId} onChange={(e) => handleChange(e, "testGroupId")}>
                      {/* {
                        dataTypeTest.map(d => <Select.Option key={d.id} value={d.id}>{d.templateName}</Select.Option>)
                      } */}
                      {
                        dataTypeTest.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)
                      }
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='ant-form-item'>
              <Col span={12}>
                <Row>
                  <Col span={8}>Phân loại thử nghiệm</Col>
                  <Col span={12}>
                    <Radio.Group value={form?.type} onChange={(e) => handleChange(e.target.value, "type")}>
                      <Radio value={"QUANTITATIVE"}>Định lượng</Radio>
                      <Radio value={"QUALITATIVE"}>Định tính</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='ant-form-item'>
              <SelectCustom
                label='Mã thử nghiệm'
                options={dataTestCode.map(x => { return { ...x } })}
                value={form?.testCodeId}
                onChange={(e) => handleChange(e, "testCodeId")}
                handleOk={handleOkTestCode}
              />
              {/* <Col span={12}>
                <Row>
                  <Col span={8}>Test code</Col>
                  <Col span="12">
                    {
                      !isCreate ?
                        <Select value={""} onChange={(e) => handleChange(e, "testGroupId")}>
                          {
                            dataTypeTest.map(d => <Select.Option key={d.id} value={d.id}>{d.templateName}</Select.Option>)
                          }
                        </Select> :
                        <Input
                          ref={inputRef}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          onKeyPress={(e => {
                            if (e.key === 'Enter') {
                              //TODO
                              setIsCreate(false);
                            }
                          })}
                        />
                    }
                  </Col>
                  <Col span="4" style={{ paddingLeft: "0.5rem" }}>
                    {!isCreate ?
                      <PlusOutlined style={{ cursor: "pointer", paddingTop: "0.5rem", color: "#1890ff" }} color="primary" onClick={() => {
                        setIsCreate(true);
                      }} /> :
                      <Button type="primary" onClick={() => {
                        //TODO
                        setIsCreate(false);
                      }}>Lưu</Button>
                    }
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </div>
        </div>

        <div>
          <h2>Bản xem trước</h2>
          <div className='ant-form-item'>
            <TextArea
              value={form?.testId}
              disabled={true}
            />
          </div>
        </div>

        <div>
          <div>
            <Row className='ant-form-item'>
              <Col span={12}>
                <Row>
                  <Col span={8}>Tên nhóm mẫu <span style={{ color: 'red' }}>*</span></Col>
                  <Col span={12}>
                    <Select mode="multiple" value={form?.sampleTypes} style={{ width: 120 }} onChange={(e) => handleChange(e, "sampleTypes")}>
                      {
                        dataForms.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.templateName}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={8}>Nhân viên phê duyệt</Col>
                  <Col span={12}>
                    <Select value={form?.approver} style={{ width: 120 }} onChange={(e) => handleChange(e, "approver")} >
                      {
                        dataUser.map(d => <Select.Option key={d.IdUser} value={d.IdUser}>{d.Username}</Select.Option>)
                      }
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='ant-form-item'>
              <Col span={12}>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={8}>Kĩ thuật viên</Col>
                  <Col span={12}>
                    <Select mode="multiple" value={form?.technician} style={{ width: 120 }} onChange={e => handleChange(e, "technician")}>
                      {
                        dataUser.map(d => <Select.Option key={d.IdUser} value={d.IdUser}>{d.Username}</Select.Option>)
                      }
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>

      </Modal>
    </div>
  )
}

export default ModalAdd;