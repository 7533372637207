import {takeLatest, call, put, delay} from 'redux-saga/effects';
import {
    doGetListUserSuccess,
    doChangeFlagDelete,
    getUserAuthSuccess,
    doGetListUser,
    doCreate,
    doGet, doUpdate, doDelete, doGetUserAuth
} from './slice';
import { notification } from 'antd';

function* getListUser({payload}) {
    // const limit = payload.request.endRow - payload.request.startRow;
    // let page = 1;
    // if (payload.request.startRow !== 0) {
    //     page = payload.request.endRow / limit;
    // }
    // const params = {limit, page};
    // const {status, data} = yield call(getItems, params);
    // const listData = data.data;
    // const userModal = listData.results;
    // if (status === 200) {
    //     if (listData.current_page === listData.next_page) {
    //         listData.limitLength =
    //             (listData.current_page - 1) * limit + listData.results.length;
    //     }
    //     yield put(doGetListUserSuccess(userModal));
    // } else {
    //     // payload.failureCB();
    // }
}

function* createItemRequest({payload}) {
    // const response = yield call(createItem, payload);
    // if (response.status === 201) {
    //     notification.success({
    //         message: 'Notification Title',
    //         description: response.data.message,
    //     });
    // } else {
    //     notification.error({
    //         message: 'Notification Title',
    //         description: response.data.message,
    //     });
    // }
}

function* getItemRequest({payload}) {
    // const {status, data} = yield call(getItem, payload);
    // if (status === 200) {
    //     console.log(data);
    //     // yield put(doGetItemSuccess(data.data));
    // } else {
    //     // yield put(loaderSlice.doSetNotFound());
    // }
}

function* updateItemRequest({payload}) {
    // const response = yield call(updateItem, payload.id, payload.data);
    // if (response.status === 200) {
    //     notification.success({
    //         message: 'Notification Title',
    //         description: response.data.message,
    //     });
    // } else {
    //     notification.error({
    //         message: 'Notification Title',
    //         description: response.data.message,
    //     });
    // }
}

function* deleteItemRequest({payload}) {
    // const response = yield call(deleteItem, payload);
    // if (response.status === 200) {
    //     notification.success({
    //         message: 'Notification Title',
    //         description: response.data.message,
    //     });
    //     yield put(doChangeFlagDelete(true));
    //     yield delay(2000);
    //     yield put(doChangeFlagDelete(false));
    // } else {
    //     notification.error({
    //         message: 'Notification Title',
    //         description: response.data.message,
    //     });
    // }
}
function* getUserAuth({payload}) {
    // const { status, data } = yield call(getUserAuthAPI, payload);
    // if (status === 200) {
    //     yield put(getUserAuthSuccess(data.data));
    // }
}

function* userSaga() {
    yield takeLatest(doGetListUser.type, getListUser);
    yield takeLatest(doCreate.type, createItemRequest);
    yield takeLatest(doGet.type, getItemRequest);
    yield takeLatest(doUpdate.type, updateItemRequest);
    yield takeLatest(doDelete.type, deleteItemRequest);
    yield takeLatest(doGetUserAuth.type, getUserAuth);
}

export default userSaga;
