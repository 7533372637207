const UNIT = [
  "ml",
  "ul (microlit)",
  "l",
  "g",
  "mg",
  "microgram (ug)",
  "kg",
  "ppm",
  "bbm",
  "mmol/L",
  "mol/L",
  "nmol/L",
  "pmol/L",
  "ng/g",
  "%volume",
  "CFU",
  "CFU_GM",
  "CFU/10ml",
  "CFU/100ml/ CFU/15ml",
  "%",
  "CFU/300ml",
  "CFU/50ml",
  "g/L",
  "mg/L",
  "kg/L",
  "mg/ml",
  "g/100g",
  "RLU",
  "mmol",
  "nmol/ml"
];

export const configAnalyte = {
  status: 200,
  data: {
    type: "ANALYTE",
    config: [
      {
        sectionName: "generalInformation",
        display: true,
        attribute: {
          analyteGroup: {
            default: true,
            display: true,
            type: "textbox",
            disabled: true,
          },
          unit: {
            default: true,
            display: true,
            type: "dropdown",
            data: UNIT,
          },
          // flaggingSetName: {
          //   default: true,
          //   display: true,
          //   type: "textbox",
          // },
          limit: { // otherLabResult
            default: true,
            display: true,
            type: "number",
          },
          analyteId: {
            default: true,
            display: true,
            type: "textbox",
            required: true,
          },
          status: { // lod
            default: true,
            display: true,
            type: "textbox",
          },
          analyteName: {
            default: true,
            display: true,
            type: "textbox",
            required: true,
          },
          loq: {
            default: true,
            display: true,
            type: "textbox",
          },
          testRunNumber: { // costEstimate
            default: true,
            display: true,
            type: "number",
          },
          methodId: { // methodId
            default: true,
            display: true,
            type: "textbox",
          },
          observedValue: {
            default: true,
            display: true,
            type: "number",
          },
          result: {
            default: true,
            display: true,
            type: "textbox",
          },
          description: {
            default: true,
            display: true,
            type: "textbox",
          },
        },
      },
      {
        sectionName: "flaggingSetInformation",
        display: true,
        attribute: {
          flag: {
            default: true,
            display: true,
            type: "textbox",
            disabled: true,
          },
          criticalLow: {
            default: true,
            display: true,
            type: "textbox",
            disabled: true,
          },
          miximum: {
            default: false,
            display: false,
            type: "textbox",
            disabled: true,
          },
          criticalHigh: {
            default: true,
            display: true,
            type: "textbox",
            disabled: true,
          },
          maximum: {
            default: true,
            display: true,
            type: "textbox",
            disabled: true,
          },
          entryDate: {
            default: true,
            display: true,
            type: "textbox",
            disabled: true,
          },
          entryTime: {
            default: true,
            display: true,
            type: "textbox",
            disabled: true,
          },
        },
      },
    ],
  },
};

export const columnTestAnalyteRun = (t) => {
  return [
    {
      title: t("ANALYTE.FORM.analyteGroup"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.flaggingSetName"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.analyteName"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.testRunNumber"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.observedValue"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.unit"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.limit"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.status"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.loq"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.mass"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.result"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.flag"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.minimum"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
  ];
};
