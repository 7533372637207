import React, {useEffect, useState} from "react";
import Datatable from "../../../components/Datatable";
import {useTranslation} from "react-i18next";
import {Form, Button, Row, Col, Select, DatePicker} from "antd";
import {getListActionHistory, getListModules, getListActions, exportPdf} from "../../../apis/actionHistory";
import {getFormattedDate, saveByteArray} from "../../../utils/utils";
import {ExportOutlined} from "@ant-design/icons";
import { useHistory } from "react-router";

const AuditTrail = () => {
    const {t} = useTranslation();
    const history = useHistory();

    const [data, setData] = useState([]);
    const [objectTypes, setObjectTypes] = useState([]);
    const [actions, setActions] = useState([]);
    const [searchValue, setSearchValue] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const init = async () => {
            setLoading(true)
            const {status, data} = await getListActionHistory({});
            if (status === 200) {
                setData(data?.content)
            }
            const respObjectType = await getListModules();
            if (respObjectType.status === 200) {
                setObjectTypes(respObjectType.data)
            }
            const respActions = await getListActions();
            if (respActions.status === 200) {
                setActions(respActions.data)
            }
            setLoading(false)
        }
        init()
    }, []);

    const renderOptionObjectTypes = () => {
        return (objectTypes || []).map((el, idx) => <Select.Option key={idx}
                                                                   value={el}>{t('AUDIT_TRIAL.' + el)}</Select.Option>)
    }

    const renderOptionActions = () => {
        return (actions || []).map((el, idx) => <Select.Option key={idx}
                                                               value={el}>{t('AUDIT_TRIAL.' + el)}</Select.Option>)
    }

    const columns = [
           
        {
            title: t("AUDIT_TRIAL.date"),
            width: 200,
            render: (row) => {
                return getFormattedDate(new Date(row.createdDate))
            }
        },
        {
            title: t("AUDIT_TRIAL.moduleName"),
            width: 200,
            render: row => t('AUDIT_TRIAL.' + row.module)
        },
        {
            title: t("AUDIT_TRIAL.action"),
            width: 200,
            render: row => t(`AUDIT_TRIAL.${row.action}`)
        },
        {
            title: t("AUDIT_TRIAL.name"),
            width: 200,
            dataIndex: "fullName",
        },    
        {
            title: t("AUDIT_TRIAL.description"),
            width: 200,
            render: row => <a onClick={()=>{history.push(`/sample/${row.objectId}`)}}>{row.description}</a>
        },
        // {
        //     title: t("AUDIT_TRIAL.codeId"),
        //     width: 200,
        //     dataIndex: "",
        // },        
        // {
        //     title: t("AUDIT_TRIAL.attrName"),
        //     width: 200,
        //     dataIndex: "attrName",
        // },   
    ];

    const onValuesChange = (changedValue, allValue) => {
        const value = {
            ...allValue
        }
        if (value.fromDate) {
            value.fromDate = new Date(new Date(value.fromDate._d.getTime()).toDateString()).getTime()
        }
        if (value.toDate) {
            value.toDate = new Date(new Date(value.toDate._d.getTime()).toDateString()).getTime() + (86400000 - 1000)
        }
        setSearchValue(value)
    }

    const onSearch = async () => {
        setLoading(true)
        const {status, data} = await getListActionHistory(searchValue);
        if (status === 200) {
            setData(data?.content)
        }
        setLoading(false)
    }

    const doExportPdf = async () => {
        setLoading(true)
        const data = await exportPdf(searchValue);
        saveByteArray(`audit-log-${new Date().getTime()}.pdf`, data.data)
        setLoading(false)
    }

    const toolbarExtra = (props) => {
        return (
            <Form onValuesChange={onValuesChange}>
                <Row gutter={24} className="tw-pl-3">
                    <Col>
                        <Form.Item label={t('AUDIT_TRIAL.MODULE')} name="module" style={{
                            width: 350
                        }}>
                            <Select
                                style={{width: 300}}
                                allowClear={true}
                                className="tw-w-full"
                                showSearch
                                placeholder={t('AUDIT_TRIAL.select') + " " + t('AUDIT_TRIAL.MODULE').toLowerCase()}
                            >
                                {renderOptionObjectTypes()}
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/*<Form.Item label="User:" name="username" style={{*/}
                    {/*    marginRight: '10px'*/}
                    {/*}}>*/}
                    {/*    <Select*/}
                    {/*        className="tw-w-full"*/}
                    {/*        showSearch*/}
                    {/*        placeholder="Select value..."*/}
                    {/*    >*/}
                    {/*        <Select.Option>Admin</Select.Option>*/}
                    {/*        <Select.Option>Master</Select.Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}
                    <Col>
                        <Form.Item label={t('AUDIT_TRIAL.time') + ':' + t('AUDIT_TRIAL.from')} name="fromDate">
                            <DatePicker placeholder={t('AUDIT_TRIAL.select') + ' ' + t('AUDIT_TRIAL.from').toLowerCase()}
                                        className={"tw-w-full"}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={t('AUDIT_TRIAL.to')} name="toDate">
                            <DatePicker placeholder={t('AUDIT_TRIAL.select') + ' ' + t('AUDIT_TRIAL.to').toLowerCase()}
                                className={"tw-w-full"}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={t('AUDIT_TRIAL.action')} name="action" style={{
                            width: 200
                        }}>
                            <Select
                                className="tw-w-full"
                                showSearch
                                placeholder={t('AUDIT_TRIAL.select') + ' ' + t('AUDIT_TRIAL.action').toLowerCase()}
                                allowClear={true}
                            >
                                {renderOptionActions()}
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Button className="tw-ml-2" type="primary" onClick={onSearch}>
                        {t('AUDIT_TRIAL.show')}
                    </Button>
                    <Button className="tw-ml-2" type="warning" onClick={doExportPdf}>
                        <ExportOutlined /> {t("ACTIONS.export")}
                    </Button>
                </Row>
            </Form>

        );
    };

    return (
        <div>
            <Datatable
                loading={loading}
                columns={columns}
                dataSource={data}
                rowKey={"id"}
                autoFlatData={true}
                allowSelect={true}
                actionConfig={{}}
                toolbarExtra={toolbarExtra()}
            />
        </div>
    );
};

export default AuditTrail;