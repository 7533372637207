import React, { useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from "react-signature-canvas";
import styled from 'styled-components'
// import UsDimensions from "hooks/usDimensions";

const Signature = ({signRef = null, signBase = null, width, height}) => {
  let sigRef = useRef(null);
  const [imageData, setImageData] = useState('')
  const [mounted, setMounted] = useState(false)
  const [forceRender, setForceRender] = useState(false)

  const clear = () => {
    setForceRender(true)
    setImageData('')
    setTimeout(() => {
      setForceRender(false)
    }, 50)
  }

  const getImage = () => {
    const image = sigRef.toDataURL()
    if (image) {
      setImageData(image)
    } else {
      setImageData('')
    }
  }

  const downloadImage = (dataUrl) => {
    var link = document.createElement('a');
    link.download = 'signature.jpeg';
    link.href = dataUrl;
    link.click();
  }

  useEffect(() => {
    const userAction = {
      getImage: () => imageData,
      clear: clear
    };
    if (signRef && typeof signRef === 'function') {
      signRef(userAction);
    }
    if (signRef && typeof signRef !== 'function') {
      signRef.current = userAction;
    }
    // eslint-disable-next-line
  }, [imageData, mounted, forceRender]);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
      }, 1000)
  }, [])

  const getBase64FromUrl = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  useEffect(() => {
    getBase64FromUrl(signBase, function(data){
      sigRef.fromDataURL(data);
      setImageData(data);
      // signRef.current = {
      //   getImage: () => imageData,
      //   clear: clear
      // };
    })
  }, [sigRef])

  if (forceRender) return <div style={{width: width || 600, height: height || 150}} />
  return (
    <SignatureCanvas
      ref={(ref) => { sigRef = ref }}
      onEnd={() => getImage()}
      penColor='black'
      canvasProps={{width: width || 600, height: height || 150, className: 'sigCanvas'}}
      dotSize={0.1}
    />
  );
};

Signature.propTypes = {

};

export default Signature;

