import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, InputNumber, message, Modal, Radio, Row, Select, Table, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiAddAnalyte, apiEditAnalyte } from '../../apis/analyte';
import { apiAddTestInformationStatus, changeQCSample, postListTestResult, postResultEntryCompleted } from '../../apis/testInformation';

const UNIT = [
  "ml",
  "ul (microlit)",
  "l",
  "g",
  "mg",
  "microgram (ug)",
  "kg",
  "ppm",
  "bbm",
  "mmol/L",
  "mol/L",
  "nmol/L",
  "pmol/L",
  "ng/g",
  "%volume",
  "CFU",
  "CFU_GM",
  "CFU/10ml",
  "CFU/100ml/ CFU/15ml",
  "%",
  "CFU/300ml",
  "CFU/50ml",
  "g/L",
  "mg/L",
  "kg/L",
  "mg/ml",
  "g/100g",
  "RLU",
  "mmol",
  "nmol/ml"
];


let config = {
  analyteGroup: {
    default: true,
    display: true,
    type: "textbox",
    disabled: true,
    key: "analytesGroupName",
  },
  unit: {
    default: true,
    display: true,
    type: "dropdown",
    data: UNIT,
    key: "unit",
  },
  // flaggingSetName: {
  //   default: true,
  //   display: true,
  //   type: "textbox",
  // },
  otherLabResult: { // otherLabResult
    default: true,
    display: true,
    type: "number",
    key: "otherLabResult",
  },
  analyteId: {
    default: true,
    display: true,
    type: "textbox",
    required: true,
    key: "analytesId",
  },
  lod: { // lod
    default: true,
    display: true,
    type: "textbox",
    key: "lod",
  },
  analytesName: {
    default: true,
    display: true,
    type: "textbox",
    required: true,
    key: "analytesName",
  },
  loq: {
    default: true,
    display: true,
    type: "textbox",
    key: "loq",
  },
  costEstimate: { // costEstimate
    default: true,
    display: true,
    type: "number",
    key: "costEstimate",
  },
  methodId: { // methodId
    default: true,
    display: true,
    type: "textbox",
    key: "methodId",
  },
  observedValue: {
    default: true,
    display: true,
    type: "number",
    key: "observedValue",
  },
  result: {
    default: true,
    display: true,
    type: "textbox",
    key: "result",
  },
  description: {
    default: true,
    display: true,
    type: "textbox",
    key: "description",
  },
}

config = Object.entries(config);

const ModalReturnResults = ({ actionRef, reset }) => {
  const { t } = useTranslation();
  const [isMounted, setIsMounted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [records, setRecord] = useState([]);
  const [value, setValue] = useState("REQUEST_REVIEW");
  const [index, setIndex] = useState(0)
  const [fileList, setFileList] = useState([])
  const [formAnalyte, setFormAnalyte] = useState([])
  const [formAnalyteDefault, setFormAnalyteDefault] = useState([])
  const [formTesting, setFormTesting] = useState([])
  const [isCheck, setIsCheck] = useState([]);


  const columns = [
    {
      title: "Mã mẫu",
      width: 200,
      dataIndex: "generalInformation.sampleIdInternal",
    },
    {
      title: "Mã mẫu (ngoài)",
      width: 200,
      dataIndex: "generalInformation.sampleIdExternal",
    },
    {
      title: "Trạng thái mẫu",
      width: 200,
      dataIndex: "generalInformation.sampleStatus",
      render: (x) => t(`STATUSTEMPLATESAMPLE.${x}`)
    },
    {
      title: "Loại mẫu",
      width: 200,
      dataIndex: "testIdResponseDTO.sampleTypes",
      render: (data) => <div>{data?.[0]?.templateName || ""}</div>
    },
    {
      title: "Mã phân tích",
      width: 200,
      dataIndex: "testIdResponseDTO.testId",
    },
    {
      title: "Mẫu QC",
      width: 200,
      dataIndex: "testingResultDTO.qcSample",
      render: (data, x) => <Checkbox
        // defaultChecked={data}
        checked={data}
        onChange={async (e) => {
          await changeQCSample(x["testingResultDTO.id"])
          setRecord(record => {
            let r = [...record];
            r[0]["testingResultDTO.qcSample"] = !r[0]["testingResultDTO.qcSample"];
            return r;
          })
          reset && reset();
        }}
      />
    },
  ];

  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setVisible(false);
  };
  const handleOpen = (records) => {
    const length = records.length;
    setFileList(new Array(length).fill([]))
    // setFormAnalyte(new Array(length).fill({}))
    setFormTesting(new Array(length).fill({
      analysisComment: records?.[0]?.["testingResultDTO.analysisComment"],
      remarks: records?.[0]?.["testingResultDTO.remarks"],
      sampleTestingResult: records?.[0]?.["testingResultDTO.sampleTestingResult"],
    }))
    // console.log(`records?.[0]?.status === 'TEST_DONE'`, records?.[0]?.status === 'TEST_DONE')
    setIsCheck(new Array(length).fill(records?.[0]?.status === 'TEST_DONE'))
    setRecord(records)
    const temp = records.map(x => {
      return {
        // analytesGroupId: x["analytesDTO.analytesGroupId"],
        analytesGroupName: x["analytesDTO.analytesGroupName"],
        analytesId: x["analytesDTO.analytesId"],
        analytesName: x["analytesDTO.analytesName"],
        costEstimate: x["analytesDTO.costEstimate"],
        lod: x["analytesDTO.lod"],
        loq: x["analytesDTO.loq"],
        methodId: x["analytesDTO.methodId"],
        otherLabResult: x["analytesDTO.otherLabResult"],
        // testRunNumber: x["analytesDTO.testRunNumber"],
        // id: x["analytesDTO.id"],
        unit: x["analytesDTO.unit"],
        description: x["analytesDTO.description"],
        result: x["analytesDTO.result"],
        observedValue: x["analytesDTO.oservedResults"] || x["analytesDTO.observedValue"]
      }
    })
    let fA = [...temp];
    fA = fA.map(x => {
      return {
        // ...x,
        analytesGroupName: x?.analytesGroupName || records?.[0]?.["testingResultDTO.analytesGroupName"],
        analytesId: x?.analytesId || records?.[0]?.["testingResultDTO.analytesId"],
        analytesName: x?.analytesName || records?.[0]?.["testingResultDTO.analytesName"],
        costEstimate: x?.costEstimate || records?.[0]?.["testingResultDTO.costEstimate"],
        lod: x?.lod || records?.[0]?.["testingResultDTO.lod"],
        loq: x?.loq || records?.[0]?.["testingResultDTO.loq"],
        methodId: x?.methodId || records?.[0]?.["testingResultDTO.methodId"],
        otherLabResult: x?.otherLabResult || records?.[0]?.["testingResultDTO.otherLabResult"],
        // testRunNumber: x?.testRunNumber || records?.[0]?.["testingResultDTO.testRunNumber"],
        unit: x?.unit || records?.[0]?.["testingResultDTO.unit"],
        description: x?.description || records?.[0]?.["testingResultDTO.description"],
        result: x?.result || records?.[0]?.["testingResultDTO.result"],
        observedValue: x?.observedValue || records?.[0]?.["testingResultDTO.oservedResults"] || records?.[0]?.["testingResultDTO.observedValue"],
      }
    })
    setFormAnalyte(fA);
    setFormAnalyteDefault(temp);
    setVisible(true);
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      open: handleOpen,
      close: handleClose,
    }

    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction)
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction
    }

    return () => {
      setIsMounted(false);
    }
    // eslint-disable-next-line
  }, [])

  const handleSubmit = async () => {
    // if (fileList?.[0]?.length === 0) {
    //   message.error("Phải chọn tệp để gửi kết quả");
    //   return
    // }
    // if (formTesting?.[0]?.analysisComment && formTesting?.[0]?.sampleTestingResult && formTesting?.[0]?.remarks) {
    //   await apiAddTestInformationStatus({
    //     objectList: [records?.[0]?.id],
    //     testStatus: "REVIEWED",
    //   })
    // }
    if (isCheck?.[0]) {
      await postResultEntryCompleted({
        objectList: [records?.[0]?.id]
      })
    }
    let o = { ...formAnalyte?.[0] }
    delete o.analytesGroupName;
    const { data } = await postListTestResult({
      testResultJson: JSON.stringify({
        ...o,
        testInformationId: records?.[0]?.id,
        analysisComment: formTesting?.[0]?.analysisComment,
        remarks: formTesting?.[0]?.remarks,
        sampleTestingResult: formTesting?.[0]?.sampleTestingResult,
        id: records?.[0]?.['testingResultDTO.id']
      }),
    }, fileList?.[0])
    await Promise.all([
      // apiAddAnalyte({
      //   ...formAnalyte?.[0]
      // }),
      // apiAddTestInformationStatus({
      //   objectList: [records?.[0]?.id],
      //   testStatus: "TEST_DONE",
      // })
    ])
    handleClose();
    reset && reset();
  }

  const onChangeCheckbox = (e) => {
    setIsCheck(pre => {
      const n = [...pre]
      n[index] = e.target.checked;
      return n
    })
  }

  const props = {
    onRemove: file => {
      setFileList(fileList => {
        const n = [...fileList];
        const i = n[index].indexOf(file);
        const newFileList = n[index].slice();
        newFileList.splice(i, 1);
        n[index] = newFileList
        return n;
      });
    },
    beforeUpload: file => {
      setFileList(fileList => {
        const n = [...fileList]
        n[index] = [...n[index], file];
        return n;
      });
      return false;
    },
    fileList: fileList?.[index],
    multiple: true,
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const index = records.findIndex(x => x.id == selectedRowKeys)
      setIndex(index)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onChangeText = (e) => {
    setFormTesting(x => {
      const newTesting = [...x]
      newTesting[index] = { ...newTesting[index], [e.target.name]: e.target.value }
      return newTesting;
    })
  }

  return (
    <Modal
      centered={true}
      title={"Trả kết quả"}
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      // cancelButtonProps={{ style: { display: "none" } }}
      okText={t("BUTTON.save")}
      cancelText={"Hủy"}
      okButtonProps={{ htmlType: "submit" }}
      width={1000}
      forceRender
    >
      <Table
        dataSource={records}
        columns={columns}
        rowKey={"id"}
        pagination={false}
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}

      />
      <Row style={{ marginBottom: "16px" }}>
        <Col span={24} ><b>Kết quả phân tích mã mẫu: {records?.[index]?.["generalInformation.sampleIdInternal"]}</b></Col>
      </Row>
      <Row style={{ marginBottom: "16px" }}>
        <Checkbox onChange={onChangeCheckbox} checked={isCheck?.[index]} /> <span style={{ marginLeft: "8px" }}>Đã hoàn thành nhập kết quả</span>
      </Row>
      <Row style={{ marginBottom: "16px" }}>
        Phiếu phân tích
      </Row>
      <Row style={{ marginBottom: "16px" }}>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Tải lên tệp</Button>
        </Upload>
      </Row>
      <Row style={{ marginBottom: "16px" }}>
        <b>Kĩ thuật viên trả kết quả</b>
      </Row>
      <Row gutter={[16, 16]}>
        {config.map((item, i) => {
          return (
            <Col span={12}>
              <Row>
                <Col span={8}>{t(`ANALYTE.FORM.${item[0]}`)}</Col>
                <Col span={12}>
                  {
                    item[1].type === "number" ? <InputNumber disabled={formAnalyteDefault?.[index]?.[item[1]?.key]} value={formAnalyte?.[index]?.[item[1]?.key]} name={item[0]} onChange={(e) => setFormAnalyte(pre => {
                      const newTesting = [...pre]
                      newTesting[index] = { ...newTesting[index], [item[0]]: e }
                      return newTesting
                    })
                    } style={{ width: "100%" }} /> :
                      (item[1].type === "dropdown" ? <Select disabled={formAnalyteDefault?.[index]?.[item[1]?.key]} value={formAnalyte?.[index]?.[item[1]?.key]} name={item[0]} onChange={(e) => setFormAnalyte(pre => {
                        const newTesting = [...pre]
                        newTesting[index] = { ...newTesting[index], [item[0]]: e }
                        return newTesting
                      })
                      }>
                        {
                          (item[1].data || []).map(unit => {
                            return <Select.Option value={unit}>{unit}</Select.Option>
                          })
                        }
                      </Select> : <Input disabled={formAnalyteDefault?.[index]?.[item[1]?.key]} value={formAnalyte?.[index]?.[item[1]?.key]} name={item[0]} onChange={(e) => setFormAnalyte(pre => {
                        const newTesting = [...pre]
                        newTesting[index] = { ...newTesting[index], [item[0]]: e.target.value }
                        return newTesting
                      })
                      } />)
                  }
                </Col>
              </Row>
            </Col>
          )
        })}
      </Row>
      <Row style={{ marginBottom: "16px" }}>
        <b>Đánh giá kết quả</b>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Row>
            <Col span={8}>{"Bình luận phân tích"}</Col>
            <Col span={12}><Input value={formTesting?.[index]?.analysisComment} onChange={onChangeText} name='analysisComment' /></Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={8}>{"Đánh giá thử nghiệm"}</Col>
            <Col span={12}>
              <Select value={formTesting?.[index]?.sampleTestingResult}
                // onChange={e => setFormTesting(x => { return { ...x, "sampleTestingResult": e } })}
                onChange={e => onChangeText({ target: { name: "sampleTestingResult", value: e } })}
              >
                <Select.Option value={"PASS"}>Pass</Select.Option>
                <Select.Option value={"FAIL"}>Fail</Select.Option>
                <Select.Option value={"N/A"}>N/A</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={8}>{"Nhận xét"}</Col>
            <Col span={12}><Input value={formTesting?.[index]?.remarks} onChange={onChangeText} name='remarks' /></Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default ModalReturnResults;