import { takeLatest, call, put, select } from 'redux-saga/effects';
import { getListApi, getListAgGridApi, getListPivotApi, updateItemDemoApi, deleteItemDemoApi, addItemDemoApi } from 'src/apis/demo';
import * as demoSlice from './slice';
import { selectDataList } from './selector';
import { notification } from 'antd';
import {doAddItemDemo, doDeleteItemDemo, doGetList, doGetListAgGrid, doGetListPivot, doUpdateItemDemo} from './slice';

function* getList({ payload }) {
    const { status, data } = yield call(getListApi);
    if (status === 200) {
        yield put(demoSlice.doGetListSuccess(data));
    } else {
        // console.log('false');
    }
}

function* updateItemDemo({ payload }) {
    const { status, data } = yield call(updateItemDemoApi, payload);
    if (status === 200) {
        // console.log(data);
        notification.success({
            message: 'Update success',
        });
    } else {
        notification.error({
            message: 'Update failed.',
        });
    }
}

function* deleteItemDemo({ payload }) {
    const { status, data } = yield call(deleteItemDemoApi, payload);
    if (status === 200) {
        /**
         * this code jus demo
         * * */
        // console.log(data);
        const oldList = yield select(selectDataList); // get list in store
        const newList = oldList.filter(item => item.id !== payload);
        yield put(demoSlice.doDeleteItemDemoSuccess(newList));
        // end
        notification.success({
            message: 'delete success',
        });
    } else {
        notification.error({
            message: 'delete failed.',
        });
    }
}

function* addItemDemo({ payload }) {
    const { status, data } = yield call(addItemDemoApi, payload.data);
    if (status === 200) {
        /**
         * this code jus demo
         * * */
        // console.log(data);
        const oldList = yield select(selectDataList); // get list in store
        const newItem = {};
        newItem.id = oldList.length + 1;
        const newList = [newItem, ...oldList];
        yield put(demoSlice.doAddItemDemoSuccess(newList));
        if (payload.successCB) {
            payload.successCB();
        }
        // end
        notification.success({
            message: 'delete success',
        });
    } else {
        notification.error({
            message: 'delete failed.',
        });
    }
}

function* getListAgGrid({ payload }) {
    const limit = payload.request.endRow - payload.request.startRow;
    let page = 1;
    if (payload.request.startRow !== 0) {
        page = payload.request.endRow / limit;
    }
    const params = { limit, page };
    const { status, data } = yield call(getListAgGridApi, params);
    const rows = data.rows;
    data.rows = rows;
    if (status === 200) {
        if (data.currentPage === data.nextPage) {
            data.limitLength = ((data.currentPage - 1) * limit) + data.rows.length;
        }
        // payload.successCB(data);
        yield put(demoSlice.doGetListAgGridSuccess(data));
    } else {
        // payload.failureCB();
    }
}
function* getListPivot({ payload }) {
    const { status, data } = yield call(getListPivotApi);
    if (status === 200) {
        yield put(demoSlice.doGetListPivotSuccess(data));
    } else {
        // console.log('false');
    }
}

function* saga() {
    yield takeLatest(doGetList.type, getList);
    yield takeLatest(doGetListAgGrid.type, getListAgGrid);
    yield takeLatest(doGetListPivot.type, getListPivot);
    yield takeLatest(doUpdateItemDemo.type, updateItemDemo);
    yield takeLatest(doDeleteItemDemo.type, deleteItemDemo);
    yield takeLatest(doAddItemDemo.type, addItemDemo);
}
export default saga;
