import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {InputNumber, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {cloneSampleForm} from "../../../apis/samples";

const CloneRecord = ({itemRef, afterClone}) => {
  const {t} = useTranslation();
  const [visibleCloneRecord, setVisibleCloneRecord] = useState(false)
  const [cloneParentId, setCloneParentId] = useState('')
  const [cloneQuantity, setCloneQuantity] = useState(1);
  const [submitting, setSubmitting] = useState(false)

  const open = (id) => {
    setVisibleCloneRecord(true)
    setCloneParentId(id)
  }

  const handleOk = async () => {
    setSubmitting(true)
    const res = await cloneSampleForm({
      "currentQuantity": cloneQuantity,
      "parentId": cloneParentId
    })

    setSubmitting(false)
    if (res.status === 201) {
      setVisibleCloneRecord(false)
      message.success(t('SAMPLE.CLONE.success'))
      if (afterClone) {
        afterClone()
      }
    } else {
      message.error(t('SAMPLE.CLONE.failure'))
    }
  }

  const handleCancel = () => {
    setVisibleCloneRecord(false)
    setCloneQuantity(1);
    setCloneParentId('')
  }

  useEffect(() => {
    const userAction = {
      open,
    };
    if (itemRef && typeof itemRef === "function") {
      itemRef(userAction);
    }
    if (itemRef && typeof itemRef !== "function") {
      itemRef.current = userAction;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      title={t('SAMPLE.CLONE.modalTitle')}
      visible={visibleCloneRecord}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('BUTTON.submit')}
      okButtonProps={{
        loading: submitting,
        disabled: submitting,
      }}
      cancelButtonProps={{
        style: {display: 'none'}
      }}
    >
      <span>{t('SAMPLE.CLONE.label')}</span>
      <InputNumber min={1} max={100} value={cloneQuantity} onChange={(value) => setCloneQuantity(value)} style={{width: '100%'}} />
    </Modal>
  );
};

CloneRecord.propTypes = {
  itemRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterClone: PropTypes.func
};

export default CloneRecord;
