import React from "react";
import { Row } from "antd";
import { Tabs } from "antd";
import DashboardDetail from "./DashboardDetail";
import { useTranslation } from "react-i18next";
import AuditTrail from "./AuditTrial";
import Alert from "./Alert";
import Report from "./Report";
import useQueryParams from "src/hooks/useQueryParams";

const { TabPane } = Tabs;
// var options = {
//   plugins: {
//     datalabels: {
//       formatter: (value, ctx) => {
//         console.log("value", value);
//         return value;
//       },
//       color: "red",
//     },
//   },
// };

const Dashboard = () => {
  const { t } = useTranslation();
  const [queryParams, setLocalQueryParams] = useQueryParams({
    tab: 0
  });
  const callback = (key) => {
    setLocalQueryParams({
      ...queryParams,
      tab: key
    })
  };
  return (
    <>
      <Row>
        <Tabs
          defaultActiveKey={queryParams.tab}
          onChange={callback}
          style={{ width: "100%" }}
        >
        <TabPane tab={t("MENU.report")} key="0">
            <Report />
          </TabPane>
          <TabPane tab={t("MENU.dashboard")} key="1">
            <DashboardDetail />
          </TabPane>
          {/* <TabPane tab={t("MENU.task")} key="2">
            <TasksDetail />
          </TabPane> */}
          <TabPane tab={t("MENU.alerts")} key="3">
            <Alert />
          </TabPane>
          <TabPane tab={t("MENU.audit_trial")} key="4">
            <AuditTrail />
          </TabPane>
        </Tabs>
      </Row>
    </>
  );
};

export default Dashboard;
