import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import Datatable from "../../../components/Datatable";
import Checkbox from "antd/lib/checkbox/Checkbox";
import FormSection from "../../../components/FormSection";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { columnTestAnalyteRun } from "../mock/configAnalyte";

const SetFlagg = ({ configData, actionRef, afterSubmit, ...props }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: '#',
      width: 50,
      dataIndex: "stt",
    },
    {
      title: t("ANALYTE.FORM.description"),
      width: 200,
      dataIndex: "description",
    },
    {
      title: t("ANALYTE.FORM.flag"),
      width: 100,
      dataIndex: "flag",
      render: (flag) => <Input disabled value={flag} />
    },
    {
      title: t("ANALYTE.FORM.selectColor"),
      width: 100,
      dataIndex: "color",
      render: (color) => <Input disabled style={{ width: "200px", background: color }} />
    },
  ];
  const columns2 = [
    {
      title: '#',
      width: 50,
      dataIndex: "stt",
    },
    {
      title: t("ANALYTE.FORM.description"),
      width: 200,
      dataIndex: "description",
    },
    {
      title: t("ANALYTE.FORM.selectColor"),
      width: 100,
      dataIndex: "color",
      render: (color) => <Input disabled style={{ width: "200px", background: color }} />
    },
  ];
  const data = [
    {
      key: '1',
      stt: '1',
      description: "Select the background color for the optimal flag.",
      flag: "Critical Low",
      color: "#ed992e"
    },
    {
      key: '2',
      stt: '2',
      description: "Select the background color for the result row within the bar chart.",
      flag: "Low",
      color: "#f5c981"
    },
    {
      key: '3',
      stt: '3',
      description: "Select the background color for the analyte row within the bar chart.",
      flag: "Optional",
      color: "#3efd3b"
    },
  ];
  const dataSource = [
    {
      key: '1',
      stt: '1',
      description: "When 'Flag Calculation' field value is below 'Critical Low'",
      flag: "Critical Low",
      color: "#ed992e"
    },
    {
      key: '2',
      stt: '2',
      description: "When 'Flag Calculation' field value is between 'Minimum' and 'Critical Low'",
      flag: "Low",
      color: "#f5c981"
    },
    {
      key: '3',
      stt: '3',
      description: "When 'Flag Calculation' field value is between 'Minimum' and 'Maximum'",
      flag: "Optional",
      color: "#3efd3b"
    },
    {
      key: '4',
      stt: '4',
      description: "When 'Flag Calculation' field value is between 'Maximum' and 'Critical High'",
      flag: "High",
      color: "#df6975"
    },
    {
      key: '5',
      stt: '5',
      description: "When 'Flag Calculation' field value is above 'Critical High'",
      flag: "Critical High",
      color: "#c10b21"
    },
  ];
  const [visible, setVisible] = useState(false);
  const formRef = useRef();
  const [form] = Form.useForm();
  const handleOk = () => {
    // if (formRef.current) {
    //   formRef.current.submit();
    // }
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setVisible(false);
    form.resetFields();
  };
  const handleOpen = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    // console.log(e);
  };
  useEffect(() => {
    const userAction = {
      close: handleClose,
      openSetFlaggModal: handleOpen,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    // eslint-disable-next-line
  }, []);
  const toolbarExtra = () => {
    return (
      <>
        {/* <Row gutter={[32, 8]}>
          <Form.Item label={t(`ANALYTE.FORM.analyteName`)} labelAlign={"left"}>
            <Input />
          </Form.Item>
        </Row> */}
      </>
    );
  };
  return (
    <div>
      <Modal
        width={"100vw"}
        style={{ top: 20 }}
        title={t(`ANALYTE.FORM.setFlag`)}
        visible={visible}
        // onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        // okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit", style: { display: "none" } }}
        forceRender
      >
        <Datatable
          columns={columns}
          dataSource={dataSource}
          rowKey={"generalInformation.testID"}
          // autoFlatData={true}
          // allowSelect={true}
          actionConfig={{}}
          toolbarExtra={toolbarExtra()}
        />
        <Divider />
        <Datatable
          columns={columns2}
          dataSource={data}
          rowKey={"generalInformation.testID"}
          actionConfig={{}}
        />
      </Modal>
    </div>
  );
};

SetFlagg.propTypes = {
  actionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterSubmit: PropTypes.func,
};

export default SetFlagg;
