import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, message, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CONFIG } from "../../configs";
import { TOKEN_NAME } from '../../constants';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const UploadSingle = ({ name, onChange, fileList, label, isShowButton, ...props }) => {
  const [files, setFiles] = useState([])
  const uploadButton = (
    <div>
      {/* <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div> */}
      <Button className='tw-ml-2' icon={<PlusOutlined />}>Upload</Button>
    </div>
  )

  const handleChange = info => {
    if (info.file.status === 'removed') {
      setFiles(info.fileList);
      onChange(null);
    }
    if (info.file.status === 'uploading') {
      setFiles([info.file])
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        // setLoading(false)
        const file = info.file
        file.url = imageUrl
        setFiles([file])
      }
      );
      if (onChange) {
        onChange(info.file.response)
      }
    }
  };

  useEffect(() => {
    setFiles(fileList || [])
  }, [fileList])

  const configHeader = () => {
    const token = localStorage.getItem(TOKEN_NAME);
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInVzZXJfaWQiOiI1ZmRjMTkxYy1jYzM5LTQ3N2ItYjUwMi0xZDQ1NGMwOGYwNGMiLCJyb2xlIjoxLCJpYXQiOjE1OTMxNDg5ODQsImV4cCI6MTU5MzMyMTc4NH0.yzHcMPWzYQy30qecFYjztx7Y_PMiGbjPh-8X9o9jbVk';
    const headers = {
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Headers': '*',
      // "Accept": "*/*",
      Authorization: token ? `Bearer ${token}` : null,
    };
    return headers;
  };

  return (
    <div>
      {label && <label>{label}</label>}
      <Upload
        listType="picture"
        showUploadList={props.disabled ? { showRemoveIcon: false, showPreviewIcon: true, showDownloadIcon: true } : { showPreviewIcon: false, showDownloadIcon: true }}
        action={`${CONFIG.API_URL.BASE}/api/images/upload`}
        fileList={files}
        headers={configHeader()}
        // onPreview={this.handlePreview}
        // beforeUpload={beforeUpload}
        disabled={props.disabled}
        onChange={handleChange}
        className="upload-list-inline"
      >
        {isShowButton ? uploadButton : null}
        {/* {{files.length >= 1 ? null : uploadButton}} */}
        {props.children ? props.children : null}
      </Upload>
    </div>
  )
};

UploadSingle.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  fileList: PropTypes.array,
  label: PropTypes.string
};

export default UploadSingle;
