import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";

const NotifiModal = ({ actionRef, setIsSaveContinue }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const userAction = {
      openNotifiModal: showModal,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    // eslint-disable-next-line
  }, []);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsSaveContinue(true);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsSaveContinue(false);
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={t("ACTIONS.notification")}
        visible={isModalVisible}
        okText={t("BUTTON.yes")}
        cancelText={t("BUTTON.no")}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {t("ACTIONS.save_and_continue")}
      </Modal>
    </>
  );
};
export default NotifiModal;
