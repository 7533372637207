import showNotification from '../Components/Nofitication';

/**
 * Define a DateUtil that supports some functions to work with date time
 */
export default class NotifyUtil {
    static showApiError(resData) {
        if (resData && resData.data && resData.data.error) {
            showNotification("error", { message: '', description: resData.data.error.message });
        } else {
            showNotification("error", { message: '', description: "Server error!!" });
        }
    }

    static showApiErrorWMessage(message) {
        showNotification("error", { message: '', description: message });
    }

    static showApiSuccess(message) {
        showNotification("success", { message: "", description: message });
    }
}