import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import FormSection from "../../components/FormSection";
import {createUserApi, editUserApi, getCurrentUser} from "../../apis/user";
import UploadSingle from "../../components/Upload/UploadSingle";
import {UploadStyled} from "../../constants/styledComponent.constant";
import moment from "moment-timezone";
import {MinusCircleOutlined} from "@ant-design/icons";
import AppCodeSelection from "./components/AppCodeSelection";
import {apiUpdateSampler} from "../../apis/samples";

const mapDataField = (values) => {
  const data = {
    "GroupApp": values.GroupApp,
    "GeneralInfomation": {
      "IdUser": values.IdUser || null,
      "MaUser": values.MaUser || 'NV1',
      "IdAccount": values.IdAccount || null,
      "LoginStatus": values.LoginStatus,
    },
    "LoginInfomation": {
      "Username": values.Username,
      "Password": values.Password || null,
      "ConfirmPassword": values.PasswordConfirm || null
    },
    "UserInfomation": {
      "FirstName": values.FirstName,
      "LastName": values.LastName,
      "CMND": values.CMND || null,
      "Position": values.Position || null,
      "Department": values.Department,
      "Address": values.Address || null,
      "City": values.City || null,
      "Email": values.Email,
      "Phone": values.Phone || null,
      "EntryDate": moment(values.EntryDate) || null,
      "AlternateEmail": values.AlternateEmail || null,
      "FileAttachmentId": values.FileAttachmentId || null
    }
  }
  return data
}
const UserForm = ({dataEdit, visible, onCancel, afterSubmit, listApp}) => {
  const {t} = useTranslation()
  const ruleRequire = [{ required: true, message: t("VALIDATION.require") }];
  const formRef = useRef()
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [dataCurrentUser, setDataCurrentUser] = useState({})
  const formInitialValues = {
    GroupApp: [undefined]
  }

  useEffect(() => {
    if (!dataEdit) {
      setIsEdit(false)
      return
    }
    setIsEdit(true)
    fetchUserDetail(dataEdit.IdUser).then()
  }, [dataEdit])

  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  }

  const handleCancel = () => {
    onCancel()
    form.resetFields()
  }

  const handleEdit = async (data) => {
    const Username = data.LoginInfomation.Username
    delete data.LoginInfomation
    const res = await editUserApi(data)
    setSubmitting(false)
    if (res.data.status === 1) {
      message.success('Edit user success')
      updateSampler({...data, Username}).then()
      afterSubmit()
    } else {
      message.error('Edit user failure')
    }
  }

  const fetchUserDetail = async (id) => {
    const res = await getCurrentUser(id)
    if (res.data.status === 1) {
      let dataTmp = res.data.data
      if (dataTmp?.GeneralInfomation?.UserRole && dataTmp.GeneralInfomation.UserRole.length > 0) {
        dataTmp.GeneralInfomation.UserRole = dataTmp.GeneralInfomation.UserRole.map(item => {
          return {
            AppCode: item.appCode,
            ListGroup: item.listGroup,
            ListRoles: item.listRoles
          }
        })
      }
      setDataCurrentUser(res.data.data)
      setFormField(res.data.data)
    }
  }

  const updateSampler = async (userData) => {
    const data = {
      "samplerId": userData.GeneralInfomation.MaUser,
      "samplerIdCard": userData.UserInfomation.CMND,
      "samplerName": [userData.UserInfomation.FirstName, userData.UserInfomation.LastName].filter(i => !!i).join(' '),
      "samplerPosition": userData.UserInfomation.Position,
      "samplerUserId": userData.GeneralInfomation.IdUser,
      "samplerDepartment": userData.UserInfomation.Department
    }
    apiUpdateSampler(data).then()
  }

  const onFinish = async (values) => {
    setSubmitting(true)
    if (isEdit) {
      values.IdUser = dataCurrentUser.GeneralInfomation.IdUser
      values.Username = dataCurrentUser.LoginInfomation.Username
      values.IdAccount = dataCurrentUser.GeneralInfomation.IdAccount
    }
    const data = mapDataField(values)
    if (isEdit) {
      handleEdit(data).then()
    } else {
      const res = await createUserApi(data)
      setSubmitting(false)
      if (res.data.status === 1) {
        message.success('Add user success')
        afterSubmit()
      } else {
        message.error(res?.data?.error?.message || 'Thêm tài khoản không thành công!')
      }
    }
  }

  const setFormField = (values) => {
    form.setFieldsValue({
      GroupApp: values.GeneralInfomation.UserRole,
      LoginStatus: values.GeneralInfomation.LoginStatus,
      FirstName: values.UserInfomation.FirstName,
      LastName: values.UserInfomation.LastName,
      CMND: values.UserInfomation.CMND,
      Department: values.UserInfomation.Department,
      Position: values.UserInfomation.Position,
      Email: values.UserInfomation.Email,
      Phone: values.UserInfomation.Phone,
      EntryDate: values.UserInfomation.EntryDate ? moment(values.UserInfomation.EntryDate) : null,
      MaUser: values.GeneralInfomation.MaUser,
      Address: values.UserInfomation.Address,
      AlternateEmail: values.UserInfomation.AlternateEmail,
      City: values.UserInfomation.City
    })
  }

  return (
    <Modal
      centered={true}
      title={
        isEdit ? t("USER.MODAL.edit") : t("USER.MODAL.addNew")
      }
      visible={visible}
      onOk={handleOk}
      confirmLoading={submitting}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={t("BUTTON.save")}
      okButtonProps={{ htmlType: "submit" }}
      width={1000}
      forceRender
    >
      {visible && <Form
        ref={formRef}
        form={form}
        name="basic"
        initialValues={formInitialValues}
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 13 }}
        // initialValues={initialValueForm}
        onFinish={onFinish}
        autoComplete="off"
      >
        <FormSection header={t(`USER.FORM.GroupApp`)}>
          <Form.List name="GroupApp">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, idx) => (
                    <div key={idx}>
                      <Row gutter={[32, 8]}>
                        <AppCodeSelection field={field} form={form} listApp={listApp} />
                        <Col span={4}>
                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              style={{ fontSize: 18, marginLeft: 12, paddingTop: 6 }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          )}
                        </Col>
                      </Row>

                    </div>
                  ))}

                  <Form.Item>
                    <Button type={'primary'} onClick={() => {add()}}>
                      {t('USER.MODAL.addApp')}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </FormSection>
        <FormSection header={t(`USER.FORM.GeneralInformation`)}>
          <Row gutter={[32, 8]}>

            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.LoginStatus`)}
                name={'LoginStatus'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Select showSearch placeholder={t("selectValue")} allowClear={true}>
                  <Select.Option value={1}>Yes</Select.Option>
                  <Select.Option value={2}>No</Select.Option>
                </Select>
              </Form.Item>
            </Col>

          </Row>

        </FormSection>

        {!isEdit && <FormSection header={t(`USER.FORM.LoginInformation`)}>
          <Row gutter={[32, 8]}>
            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.Username`)}
                name={'Username'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.Password`)}
                name={'Password'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.PasswordConfirm`)}
                name={'PasswordConfirm'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>}

        <FormSection header={t(`USER.FORM.UserInformation`)}>
          <Row gutter={[32, 8]}>

            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.FirstName`)}
                name={'FirstName'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.LastName`)}
                name={'LastName'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.CMND`)}
                name={'CMND'}
                labelAlign={"left"}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.position`)}
                name={'Position'}
                labelAlign={"left"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.Email`)}
                name={'Email'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.Phone`)}
                name={'Phone'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.department`)}
                name={'Department'}
                labelAlign={"left"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.EntryDate`)}
                name={'EntryDate'}
                labelAlign={"left"}
                // rules={ruleRequire}
              >
                <DatePicker placeholder={""} className={"tw-w-full"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.EmploymentNo`)}
                name={'MaUser'}
                labelAlign={"left"}
                rules={ruleRequire}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.Address`)}
                name={'Address'}
                labelAlign={"left"}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t(`USER.FORM.City`)}
                name={'City'}
                labelAlign={"left"}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <UploadStyled style={{display: 'none'}}>
          <UploadSingle
            // onChange={(val) => onUpload({ fieldName: item[0], ...val })}
            fileList={[]}
            label={t(`SAMPLE.FORM.FileAttachment`)}
          >
            <Form.Item name={'FileAttachment'} hidden={true}>
              <Input />
            </Form.Item>
          </UploadSingle>
        </UploadStyled>
      </Form>}
    </Modal>
  );
};

UserForm.propTypes = {
  listApp: PropTypes.array
};

export default UserForm;
