import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Select} from "antd";
import {useTranslation} from "react-i18next";

const AppCodeSelection = ({ field, form, listApp}) => {
  const {t} = useTranslation()
  const ruleRequire = [{ required: true, message: t("VALIDATION.require") }];
  const [listGroup, setListGroup] = useState([])

  const handleInitDataEdit = () => {
    const currentApp = form.getFieldValue('GroupApp')
    if (currentApp[field.name]?.AppCode) {
      const currentAppPermission = listApp.find(item => item.AppCode === currentApp[field.name]?.AppCode)
      setListGroup(currentAppPermission?.listGroup || [])
    }
  }

  const onChangeAppCode = () => {
    const values = form.getFieldsValue();
    values[`GroupApp`][field.name]['ListGroup'] = undefined;
    handleInitDataEdit()
  }

  useEffect(() => {
    if (!listApp) return
    setTimeout(() => {
      handleInitDataEdit()
    }, 200)
  }, [listApp])

  return (
    <>
      <Col span={10}>
        <Form.Item
          {...field}
          name={[field.name, `AppCode`]}
          fieldKey={[field.name, `AppCode`]}
          label={t(`USER.FORM.AppCode`)}
          labelAlign={"left"}
          rules={ruleRequire}
        >
          <Select showSearch placeholder={t("selectValue")} allowClear={true} onChange={onChangeAppCode}>
            {listApp.map((item, index) => <Select.Option key={index} value={item.AppCode}>{item.TenApp}</Select.Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          {...field}
          name={[field.name, `ListGroup`]}
          fieldKey={[field.name, `ListGroup`]}
          label={t(`USER.FORM.ListGroup`)}
          labelAlign={"left"}
          rules={ruleRequire}
        >
          <Select showSearch mode="multiple" placeholder={t("selectValue")} allowClear={true}>
            {listGroup.map((item, index) => <Select.Option key={index} value={item.Id}>{item.TenGroup}</Select.Option>)}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

AppCodeSelection.propTypes = {

};

export default AppCodeSelection;
