import jwt from 'jwt-decode';
import {refreshTokenApi} from "../apis/login";
import {REFRESH_TOKEN, TOKEN_NAME} from "../constants";

export const checkExpiredToken = (token) => {
    try {
        if (token) {
            return true; // todo: data mẫu, xóa dòng này khi làm chính thức
            // const jwtDecode = jwt(token);
            // const currentTime = Math.floor(new Date().getTime() / 1000);
            // if (jwtDecode.exp > currentTime) {
            //     return true;
            // }
        }
        return false;
    } catch {
        return false;
    }
};

export const getTokenInfo = (token) => {
    if (checkExpiredToken(token)) {
        return jwt(token);
    }
    return null;
};


// export const refreshToken = async () => {
//     const res = await refreshTokenApi()
//     if (res.data.status === 1) {
//
//     }
// }
export const refreshToken = (function () {
    var isCalled = false

    async function handleRefreshToken () {
        const res = await refreshTokenApi()
        if (res.data.status === 1) {
            const token = res.data.data.access_token
            const refresh_token = res.data.data.refresh_token
            localStorage.setItem(TOKEN_NAME, token)
            localStorage.setItem(REFRESH_TOKEN, refresh_token)
        } else {
            localStorage.setItem(TOKEN_NAME, '')
        }
    }

    var publicInterface = {
        call: function () {
            if (isCalled) return
            // console.log('refresh token called');
            isCalled = true
            handleRefreshToken().then()
        }
    }

    return publicInterface // Object will contain public methods
})()
