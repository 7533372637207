import React from 'react';
import {Input} from "antd";
import styled from "styled-components";

const InputStyled = styled.div`
  .ant-input-lg {
    font-size: 18px;
    padding: 10px 11px;
    ::placeholder {
      color: rgba(3,45,35, 0.6);
    }
  }
`

const PmInputPassword = props => {
  return (
    <InputStyled>
      <Input.Password
        size="large"
        {...props}
        className={`tw-border-green-950 tw-border-2 tw-rounded-md tw-font-Inter tw-font-bold tw-text-green-950`}
      />
    </InputStyled>
  );
};

export default PmInputPassword;
