import styled from "styled-components";

export const UploadStyled = styled.div`
  .ant-upload-picture-card-wrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  .ant-upload-select-picture-card {
    width: 100%;
    height: 200px;
  }
  .ant-upload-list-picture-card-container {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`
