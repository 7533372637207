import axiosService from "../../services/axiosService";
import {CONFIG} from "../../configs";

export const getListActionHistory = (value) => {
    let url = '/action-history?para=1'
    if (value.module) {
        url += `&module=${value.module}`
    }
    if (value.fromDate) {
        url += `&fromDate=${value.fromDate}`
    }
    if (value.toDate) {
        url += `&toDate=${value.toDate}`
    }
    if (value.action) {
        url += `&action=${value.action}`
    }
    if (value.isExportPdf) {
        url += `&export=${value.isExportPdf}`
    }
    return axiosService.get(`${CONFIG.API_URL.BASE}${url}`);
};

export const exportPdf = (value) => {
    let url = '/action-history/export-pdf?para=1'
    if (value.module) {
        url += `&objectType=${value.module}`
    }
    if (value.fromDate) {
        url += `&fromDate=${value.fromDate}`
    }
    if (value.toDate) {
        url += `&toDate=${value.toDate}`
    }
    if (value.action) {
        url += `&action=${value.action}`
    }
    // console.log(url)
    return axiosService.get(`${CONFIG.API_URL.BASE}${url}`);
};

export const getListModules = () => {
    return axiosService.get(`${CONFIG.API_URL.BASE}/action-history/modules`);
};

export const getListActions = () => {
    return axiosService.get(`${CONFIG.API_URL.BASE}/action-history/action`);
};