import axiosService from "src/services/axiosService";
import { CONFIG } from "../../configs";
import { configTest } from "../../Views/Test/mock/configTest";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */
export const getListTemplate = (type) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/templates?type=${type}`);
};

export const addTemplate = (data) => {
  return axiosService.post(`${CONFIG.API_URL.BASE}/api/templates`, data);
};

export const editTemplate = (data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/templates`, data);
};

export const getTemplateById = (templateId) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/templates/${templateId}`);
};

export const deleteTemplate = (templateId) => {
  return axiosService.delete(
    `${CONFIG.API_URL.BASE}/api/templates/${templateId}`
  );
};

export const getSourceConfig = (templateType) => {

  switch (templateType) {
    case "SAMPLE":
      return axiosService.get(
        `${CONFIG.API_URL.BASE}/api/configurations?type=${templateType}`
      );
    case "TEST":
      return axiosService.get(
        `${CONFIG.API_URL.BASE}/api/configurations?type=${templateType}`
      );
    default:
      break;
  }
  // return axiosService.get(`https://gist.githubusercontent.com/cbmgit/852c2702d4342e7811c95f8ffc2f017f/raw/InsuranceCompanies.json`)
};
