import {all} from 'redux-saga/effects';
import saga from './demo/saga';
import loginSaga from './login/saga';
import userSaga from './user/saga';
import samplesSaga from "./samples/saga";
import testSaga from "./test/saga";
import clientSaga from "./client/saga";

function* rootSaga() {
  try {
    yield all([
      saga(),
      userSaga(),
      loginSaga(),
      samplesSaga(),
      testSaga(),
      clientSaga(),
    ]);
  } catch (err) {
    console.trace(err);
  }
}

export default rootSaga;
