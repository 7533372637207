import { sleep } from "../../utils/utils";
import { CONFIG } from "../../configs";
import axiosService from "../../services/axiosService";
import { TOKEN_NAME } from "../../constants";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */
export const getListSample = (id, year, page = 0, size = 10, textSearch) => {
  if (!year) {
    year = new Date().getFullYear().toString();
  }
  // if (id == "0") return axiosService.get(`${CONFIG.API_URL.BASE}/api/samples?year=${year}`);
  if (id == "0")
    return axiosService.get(
      `${
        CONFIG.API_URL.BASE
      }/api/samples?year=${year}&page=${page}&size=${size}${
        textSearch ? "&textSearch=" + textSearch : ""
      }`
    );
  return axiosService.get(
    `${
      CONFIG.API_URL.BASE
    }/api/samples?templateId=${id}&year=${year}&page=${page}&size=${size}${
      textSearch ? "&textSearch=" + textSearch : ""
    }`
  );
  // return axiosService.get(`${CONFIG.API_URL.BASE}/api/samples/${id}?year=${year}`);
};

export const apiGetSampleDetail = (id) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/samples/${id}/detail`);
};

export const apiAddSample = async (templateId, data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/samples/${templateId}`,
    data
  );
};

export const apiDeleteSample = async (data) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/api/samples/${data}`);
};

export const apiEditSample = async (data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/samples`, data);
};

export const apiDissociateStorage = async (id, type) => {
  const data = { status: type };
  return axiosService.put(
    `${CONFIG.API_URL.BASE}/api/samples/${id}/status`,
    data
  );
};

export const updateStatusSample = async (data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/samples/status`, data);
};

export const downloadTemplate = async () => {
  return window.open(`${CONFIG.API_URL.BASE}/api/attachments/sample`);
};

export const uploadTemplate = async (file, currentTemplate) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/samples/${currentTemplate}/upload`,
    formData
  );
};
// NOT_RECEIVE', 'RECEIVED', 'STORED', 'EXPORTED', 'DESTROYED

export const cloneSampleForm = (data) => {
  return axiosService.post(`${CONFIG.API_URL.BASE}/api/samples/separate`, data);
};

export const apiGetReportCode = (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/samples/generateReportCode`,
    data
  );
};

export const apiCheckReport = (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/samples/checkReport`,
    data
  );
};
export const apiGetSamples = (data) => {
  return axiosService.get(
    `${CONFIG.API_URL.BASE}/api/templates/statistic?type=SAMPLE`,
    data
  );
};

export const apiUpdateSampler = (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/samples/updateSamplerInfo`,
    data
  );
};

export const apiGetReports = (id, type) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/reports`, { id, type });
};

function dataURLtoFile(dataurl, filename) {
  if (dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  return new File([""], filename);
}

export const apiPostReports = (image, id, type) => {
  //Usage example:
  const file = dataURLtoFile(image, `file-${Date.now()}.png`);
  const formData = new FormData();
  formData.append("file", file);
  formData.append("id", id);
  formData.append("type", type);

  return axiosService.post(`${CONFIG.API_URL.BASE}/api/reports`, formData);
};

export const getSummaryDataReport = (startDate, endDate) => {
  return axiosService.get(
    `${CONFIG.API_URL.BASE}/api/samples/summary-data?${
      startDate ? `startDate=${startDate}` : ""
    }${endDate ? `&endDate=${endDate}` : ""}`
  );
};

export const getSummaryDosageFormDataReport = (startDate, endDate) => {
  return axiosService.get(
    `${CONFIG.API_URL.BASE}/api/samples/summary-dosage-form-data?${
      startDate ? `startDate=${startDate}` : ""
    }${endDate ? `&endDate=${endDate}` : ""}`
  );
};

export const getSummarySellTypeDataReport = (startDate, endDate) => {
  return axiosService.get(
    `${CONFIG.API_URL.BASE}/api/samples/summary-sell-type-data?${
      startDate ? `startDate=${startDate}` : ""
    }${endDate ? `&endDate=${endDate}` : ""}`
  );
};

export const getExportSummaryDataReport = (
  startDate,
  endDate,
  templateUuids
) => {
  let anchor = document.createElement("a");
  document.body.appendChild(anchor);
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem(TOKEN_NAME),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${CONFIG.API_URL.BASE}/api/samples/export-summary-data?${
      startDate ? `startDate=${startDate}` : ""
    }${endDate ? `&endDate=${endDate}` : ""}${
      templateUuids ? `&templateUuids=${templateUuids}` : ""
    }`,
    requestOptions
  )
    .then((response) => response.blob())
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);
      anchor.href = objectUrl;
      anchor.download = "export.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    });
  return axiosService.get(
    `${CONFIG.API_URL.BASE}/api/samples/export-summary-data`
  );
};

export const getExportSummaryDataReportPdf = (
  startDate,
  endDate,
  templateUuids
) => {
  let anchor = document.createElement("a");
  document.body.appendChild(anchor);
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem(TOKEN_NAME),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${CONFIG.API_URL.BASE}/api/samples/export-summary-data?exportType=PDF${
      startDate ? `&startDate=${startDate}` : ""
    }${endDate ? `&endDate=${endDate}` : ""}${
      templateUuids ? `&templateUuids=${templateUuids}` : ""
    }`,
    requestOptions
  )
    .then((response) => response.blob())
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);
      anchor.href = objectUrl;
      anchor.download = "export.pdf";
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    });
  return axiosService.get(
    `${CONFIG.API_URL.BASE}/api/samples/export-summary-data`
  );
};
