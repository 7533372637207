import { useAuth } from '../hooks/Auth/useAuth';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import useQueryParams from "../hooks/useQueryParams";

export const CheckAuthInPage = () => {
    const auth = useAuth();
    // const history = useHistory();
    useEffect(() => {
        if (auth.isAuth !== undefined && !auth.isAuth) {
            const origin = window.location.origin
            window.location.href = origin + '/login?redirect=' + window.location.href;
            // history.push('/login');
        }
        // eslint-disable-next-line
    }, [auth]);
    return auth.isAuth;
};

export const CheckAuthInLoginPage = () => {
    const auth = useAuth();
    const history = useHistory();
    const [queryParams] = useQueryParams()
    useEffect(() => {
        if (auth.isAuth) {
            if (queryParams.redirect) {
                // window.location.href = queryParams.redirect
                window.location.href = '/'
              } else {
                history.push('/');
            }
        }
        // eslint-disable-next-line
    }, [auth]);
    return auth.isAuth
};
