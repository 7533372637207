import { CONFIG } from "../../configs";
import axiosService from "../../services/axiosService";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */
export const getListSaveConfig = (key) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/samples/saveConfig?saveConfig=${key}`);
};
export const apiAddSaveConfig = async (data) => {
  return axiosService.put(
    `${CONFIG.API_URL.BASE}/api/samples/saveConfig`,
    data
  );
};

export const apiDeleteSaveConfig = async (ids) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/api/samples/saveConfig?ids=${ids}`);
};

export const apiEditSaveConfig = async (id, data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/samples/saveConfig/${id}`, data);
};

export const getSampleOrder = (key) => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/samples/sampleOrder?contractNumber=${key}`);
};

export const getSampleInternalId = () => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/samples/sampleInternalId`);
};