import React, {useEffect, useState} from 'react';
import {Layout, Modal} from 'antd';
import Body from '../Body';
// import Sidebar from 'src/Layout/Sidebar';
import Header from '../Header';
import Footer from '../Footer';
import MyBreadcrumb from "../Breadcrumb";
import {REFRESH_TOKEN, TOKEN_NAME} from "../../constants";
import {useTranslation} from "react-i18next";
import CustomSpin from "../../components/CustomSpin";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../redux/user/selector";
// import {messaging} from 'src/firebase';
// import PermissionPage from 'src/Views/Permission';

const ContentPage = (props) => {
    const {t} = useTranslation()
    const { Content } = Layout;
    const currentUser = useSelector(selectCurrentUser)
    const currentRefreshToken = localStorage.getItem(REFRESH_TOKEN)
    const [modalSession, setModalSession] = useState(false)
    const [spinStatus, setSpinStatus] = useState(true)
    var localStorageSetHandler = function(e) {
        if (e.key === REFRESH_TOKEN && e.value !== currentRefreshToken) {
            // console.log('token refresh', e.value);
            setModalSession(true)
        }
    };

    useEffect(() => {
        document.addEventListener("itemInserted", localStorageSetHandler, false);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (currentUser) {
            setSpinStatus(false)
        }
    }, [currentUser])

    // useEffect(() => {
    //     // const messaging = firebase.messaging();
    //     // console.log(messaging);
    //     messaging.requestPermission().then(() => messaging.getToken()).then(token => {
    //         console.log('token', token);
    //     }).catch(err => {
    //         console.log('err:', err);
    //     });
    //
    //     messaging.onMessage((noti) => {
    //         console.log('data', noti);
    //         const data = JSON.parse(noti.data['gcm.notification.data']);
    //         notification.open({
    //             message: noti.notification.body,
    //             duration: 5,
    //             description: data.content,
    //             icon: <Avatar src={noti.notification.icon} />,
    //         });
    //     });
    // }, []);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* <Sidebar /> */}
            {/* sidebar */}
            <CustomSpin spinning={spinStatus}>
                <Layout className="site-layout">
                    {/* header */}
                    <Header />
                    <Content style={{ margin: '0 16px', marginTop: 64 }}>
                        <MyBreadcrumb />
                        <div
                          className="site-layout-background"
                          style={{ minHeight: 360 }}>
                            <Body />
                        </div>
                    </Content>
                    <Footer />
                    {/* footer */}
                </Layout>
            </CustomSpin>

            <Modal
              title={t('MODAL.alert')}
              visible={modalSession}
              okText={t('BUTTON.reload')}
              closable={false}
              cancelButtonProps={{style: {display: 'none'}}}
              onCancel={() => setModalSession(false)}
              onOk={() => {
                  const origin = window.location.origin
                  localStorage.setItem(TOKEN_NAME, "")
                  window.location.href = origin + '/login?redirect=' + window.location.href;
              }}
              maskClosable={false}
            >
                <div>{t('MODAL.expireSession')}</div>
            </Modal>
        </Layout>
    );
};

export default ContentPage;
