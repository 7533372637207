import { Col, Input, Modal, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiAddTestInformationStatus } from '../../apis/testInformation';

const ModalValidation2 = ({ actionRef, reset }) => {
  const { t } = useTranslation();
  const [isMounted, setIsMounted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [records, setRecord] = useState([]);
  const [form, setForm] = useState({ value: "APPROVED", remarks: "" })

  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setVisible(false);
  };
  const handleOpen = (records) => {
    setForm({ value: "APPROVED", remarks: "" })
    setRecord(records)
    setVisible(true);
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      open: handleOpen,
      close: handleClose,
    }

    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction)
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction
    }

    return () => {
      setIsMounted(false);
    }
    // eslint-disable-next-line
  }, [])

  const handleSubmit = async () => {
    await apiAddTestInformationStatus({
      objectList: records,
      testStatus: form.value,
      validationRemarks: form.remarks
    })
    handleClose();
    reset && reset();
  }

  const onChange = (e) => {
    setForm(form => { return { ...form, [e.target.name]: e.target.value } })
  }

  return (
    <Modal
      centered={true}
      title={"Xác thực kết quả phân tích"}
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      // cancelButtonProps={{ style: { display: "none" } }}
      okText={t("BUTTON.save")}
      cancelText={"Hủy"}
      okButtonProps={{ htmlType: "submit" }}
      width={1000}
      forceRender
    >
      <Row style={{ marginBottom: "1rem" }}>
        <Col span={6}>Kiểm tra trạng thái xác thực</Col>
        <Col span={18}>
          <Radio.Group onChange={onChange} value={form?.value} name="value">
            <Radio value={"APPROVED"}>Phê duyệt</Radio>
            <Radio value={"REJECT"}>Từ chối</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col span={6}>Nhận xét xác thực</Col>
        <Col span={18}>
          <Input value={form?.remarks} onChange={onChange} name="remarks" />
        </Col>
      </Row>
    </Modal>
  )
}

export default ModalValidation2;