import React, {Fragment, useEffect} from 'react';
import {CheckAuthInPage} from '../../utils/checkAuth.utils';
import {useDispatch} from 'react-redux';
import {useAuth} from '../../hooks/Auth/useAuth';
import ContentPage from '../Content';
import {doGetSuccess} from '../../redux/user/slice';
import {getUserAuthAPI} from "../../apis/user";
import {doUpdateRole} from "../../redux/permission/slice";
// import {refreshTokenApi} from "../../apis/login";

var originalSetItem = localStorage.setItem;

localStorage.setItem = function(key, value) {
    var event = new Event('itemInserted');

    event.value = value; // Optional..
    event.key = key; // Optional..

    document.dispatchEvent(event);

    originalSetItem.apply(this, arguments);
};

const AdminPage = () => {

    const dispatch = useDispatch();
    const auth = useAuth();

    const fetchUserDetail = async () => {
        const userId = localStorage.getItem('IdUser')
        const res = await getUserAuthAPI({idUser: userId})
        if (res.status === 200) {
            dispatch(doGetSuccess(res.data.data))
            handleSetRole(res.data.data)
        } else {
            // console.log('get user profile failure', res);
        }
    }

    const handleSetRole = (userData) => {
        const roles = userData.GeneralInfomation.UserRole.reduce((total, curr) => {
            return [...total, curr.appCode, ...curr.listRoles]
        }, [])
        dispatch(doUpdateRole(roles))
    }

    useEffect(() => {
        if (auth.isAuth !== undefined && auth.isAuth) {
            fetchUserDetail().then()
        }
        // eslint-disable-next-line
    }, [dispatch, auth]);

    const showPage = () => {
        let xhtml = null;
        xhtml = (
            <Fragment>
                <ContentPage />
            </Fragment>
        );

        if (CheckAuthInPage()) {
            xhtml = (
                <Fragment>
                    <ContentPage />
                </Fragment>
            );
        }
        return xhtml;
    };
    return showPage();
};
export default AdminPage;
