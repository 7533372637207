import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import styled from "styled-components";
import { DEFAULT_PAGINATION } from "src/constants/constant";
import useQueryParams from "src/hooks/useQueryParams";
import Toolbar from "./toolbar";
import { getFlatRecord } from "src/utils/datatable.utils";
import { useTranslation } from "react-i18next";

const TableStyled = styled.div`
  .ant-table-body {
    max-height: calc(100vh - 250px) !important;
  }
`;

const Datatable = ({
  tableRef,
  columns,
  dataSource,
  size,
  sticky,
  autoFlatData,
  loading,
  allowSelect,
  pagination: propsPagination = {},
  defaultUrlParams: propsDefaultUrlParams = {},
  onDelete = (ids) => console.log(ids),
  onDissociateStorage = (ids, type) => console.log(ids),
  onAddNew = () => console.log("edasd"),
  onAddTestType = () => console.log(""),
  onEdit = (id) => console.log(id),
  onSelect = (records) => console.log(records),
  onExport,
  actionConfig,
  toolbarExtra,
  inputSearch,
  onUploadTemplate = () => { },
  onCloneRecord,
  selectOnly,
  updateQuery,
  total,
  ...props
}) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSelectedRows, setDataSelectedRows] = useState([]);
  const [queryParams, setLocalQueryParams] = useQueryParams({
    page: DEFAULT_PAGINATION.defaultCurrent,
    itemsPerPage: DEFAULT_PAGINATION.pageSize,
    ...propsDefaultUrlParams,
  });
  const [handlingDataSource, setHandlingDataSource] = useState([]);

  useEffect(() => {
    let handlingDataSource = [...dataSource];
    if (autoFlatData)
      handlingDataSource = handlingDataSource.map((record) =>
        getFlatRecord({}, record)
      );
    if (inputSearch) {
      let input_search = change_alias(inputSearch);
      let arr = [];
      let listData = [...handlingDataSource];
      listData.map((value) => {
        const listKeyName = Object.keys(value);
        let check = false;
        for (let item of listKeyName) {
          if (value[item] != null) {
            if (item === "sampleStatus") {
              let val = change_alias(t(`STATUSTEMPLATESAMPLE.${value[item]}`));
              if (val.includes(input_search)) {
                check = true;
                // return val;
              }
            } else {
              let val = change_alias(value[item]);
              if (val.includes(input_search)) {
                check = true;
                // return check;
              }
            }
          }
        }
        if (check) {
          arr.push(value);
        }
        return check;
      });
      handlingDataSource = arr;
    }

    setHandlingDataSource(handlingDataSource)
  }, [dataSource, inputSearch])


  // let handlingDataSource = [...dataSource];
  const change_alias = (alias) => {
    var str = alias;
    str = str.toString();
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.trim();
    return str;
  };
  // if (autoFlatData)
  //   handlingDataSource = handlingDataSource.map((record) =>
  //     getFlatRecord({}, record)
  //   );
  // if (inputSearch) {
  //   let input_search = change_alias(inputSearch);
  //   let arr = [];
  //   let listData = [...handlingDataSource];
  //   listData.map((value) => {
  //     const listKeyName = Object.keys(value);
  //     let check = false;
  //     for (let item of listKeyName) {
  //       if (value[item] != null) {
  //         if (item === "sampleStatus") {
  //           let val = change_alias(t(`STATUSTEMPLATESAMPLE.${value[item]}`));
  //           if (val.includes(input_search)) {
  //             check = true;
  //             // return val;
  //           }
  //         } else {
  //           let val = change_alias(value[item]);
  //           if (val.includes(input_search)) {
  //             check = true;
  //             // return check;
  //           }
  //         }
  //       }
  //     }
  //     if (check) {
  //       arr.push(value);
  //     }
  //     return check;
  //   });
  //   handlingDataSource = arr;
  // }
  const handleAllowSelect = () => {
    if (!allowSelect) return undefined;
    return {
      type: selectOnly ? "radio" : "checkbox",
      fixed: true,
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        onSelect(selectedRows);
        // console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
      },
    };
  };

  const onTableChange = (tablePagination, tableFilters, tableSorter, extra) => {
    const { action } = extra;
    if (action === "paginate") {
      const { current, pageSize } = tablePagination;
      updateQuery && updateQuery(tablePagination)
      setLocalQueryParams({
        ...queryParams,
        page: current,
        itemsPerPage: pageSize,
      });
    }
    if (action === "filter") {
      setLocalQueryParams({ ...queryParams, ...tableFilters });
    }
    if (action === "sort") {
      const mapValues = {
        descend: "desc",
        ascend: "asc",
      };
      const { field, order } = tableSorter;
      const sorterQuery = {
        order: { [field]: mapValues[order] },
      };
      setLocalQueryParams({ ...queryParams, ...sorterQuery });
    }
  };

  // eslint-disable-next-line
  const { order = {}, itemsPerPage, page, ...restParams } = queryParams;
  const mergedPagination = {
    ...DEFAULT_PAGINATION,
    current: !isNaN(parseInt(page))
      ? parseInt(page)
      : DEFAULT_PAGINATION.defaultCurrent,
    pageSize: !isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : DEFAULT_PAGINATION.pageSize,
    total: total,
    showTotal: (total, range) =>
      t("TABLE.show_total", { from: range[0], to: range[1], total }),
    locale: { items_per_page: `/ ${t("TABLE.page")}` },
    ...propsPagination,
  };

  const handleDelete = () => {
    onDelete(selectedRowKeys);
  };
  const dissociateStorage = (type) => {
    onDissociateStorage(selectedRowKeys, type);
  };
  const handleEdit = () => {
    const record = handlingDataSource.find(
      (item) => item[props.rowKey] === selectedRowKeys[0]
    );
    if (record) {
      onEdit(record);
    }
  };

  const clearSelectRows = () => {
    setSelectedRowKeys([]);
    setDataSelectedRows([]);
  };

  const resetPagination = () => {
    setLocalQueryParams({
      ...queryParams,
      page: 1,
    });
  }

  useEffect(() => {
    const userAction = {
      clearSelectRows,
      resetPagination,
    };
    if (tableRef && typeof tableRef === "function") {
      tableRef(userAction);
    }
    if (tableRef && typeof tableRef !== "function") {
      tableRef.current = userAction;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading) {
      setSelectedRowKeys([]);
      setDataSelectedRows([]);
    }
  }, [loading]);
  return (
    <TableStyled>
      <Toolbar
        selected={selectedRowKeys}
        dataSelected={dataSelectedRows}
        actionConfig={actionConfig}
        onDelete={handleDelete}
        onAddNew={onAddNew}
        onAddTestType={onAddTestType}
        onExport={onExport}
        onEdit={handleEdit}
        dissociateStorage={dissociateStorage}
        toolbarExtra={toolbarExtra}
        onUploadTemplate={onUploadTemplate}
        onCloneRecord={onCloneRecord}
        {...props}
      />
      <Table
        rowSelection={handleAllowSelect()}
        loading={loading || false}
        sticky={sticky || false}
        size={size || "small"}
        columns={columns}
        dataSource={handlingDataSource}
        scroll={{ x: 1500, y: 700 }}
        pagination={mergedPagination}
        onChange={onTableChange}
        {...props}
      />
    </TableStyled>
  );
};

// const selectOptionItemPropTypes = {
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
// };
const valueTypePropTypes = [
  "text",
  "textarea",
  "select",
  "multiselect",
  "date",
  "time",
  "timeRange",
  "checkbox",
];

Datatable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string,
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
      ]),
      valueType: PropTypes.oneOf(valueTypePropTypes),
      sorter: PropTypes.bool,
      render: PropTypes.func,
    })
  ),
  onDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onEdit: PropTypes.func,
  onSelect: PropTypes.func,
  onCloneRecord: PropTypes.func,
  actionConfig: PropTypes.object,
  size: PropTypes.string,
  sticky: PropTypes.bool,
  autoFlatData: PropTypes.bool,
  loading: PropTypes.bool,
  allowSelect: PropTypes.bool,
  pagination: PropTypes.object,
  tableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  toolbarExtra: PropTypes.element,
};

export default Datatable;
