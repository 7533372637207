import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    roles: [],
};

const slice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        doUpdateRole: (state, actions) => {
            state.roles = actions.payload;
        },
    },
});

export const { doUpdateRole } = slice.actions;

export default slice.reducer;
