export const TOKEN_NAME = '___pharmaToken';
export const REFRESH_TOKEN = 'refresh_token';

export const COLOR_VALIDATE = {
    WHITE: '#ffffff',
    RED: '#f5222d',
};

export const GENDER = {
    OTHER: 0,
    MALE: 1,
    FEMALE: 2,
};

export const GENDER_ARR = [
    { key: GENDER.FEMALE, label: 'FEMALE' },
    { key: GENDER.MALE, label: 'MALE' },
    { key: GENDER.OTHER, label: 'OTHER' },
];

export const LOADER_STATUS = {
    NOT_FOUND: 'NOT_FOUND',
    FINISH: 'FINISH'
};
