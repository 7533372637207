import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import Datatable from "../../components/Datatable";
import { getUserByAppCode } from "../../apis/user";

const SamplerInformation = ({
  configData,
  actionRef,
  afterSubmit,
  onSelectItem,
  isClearSelectSamplerInforRows,
  fieldNameSearchUser,
  ...props
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [inputSearch, setInputSearch] = useState(null);
  const [visible, setVisible] = useState(false);
  const formRef = useRef();
  const [form] = Form.useForm();
  const [listData, setListData] = useState([]);
  const tableRef = useRef()
  const columns = [
    {
      title: t("SAMPLE.FORM.samplerName"),
      width: 200,
      dataIndex: "samplerName",
    },
    {
      title: t("SAMPLE.FORM.samplerId"),
      width: 50,
      dataIndex: "samplerId",
    },
    {
      title: t("SAMPLE.FORM.samplerPosition"),
      width: 150,
      dataIndex: "samplerPosition",
    },
    {
      title: t("SAMPLE.FORM.samplerIdCard"),
      width: 150,
      dataIndex: "samplerIdCard",
    },
    {
      title: t("SAMPLE.FORM.samplerDepartment"),
      width: 100,
      dataIndex: "samplerDepartment",
    },
  ];
  useEffect(() => {
    if (!visible) return
    getusers();
  }, [visible]);
  const getusers = async () => {
    const res = await getUserByAppCode();
    if (res.data.status === 1) {
      let dataConvert = [];
      for (let item of res.data.data) {
        dataConvert.push({
          samplerName: `${item.FirstName} ${item.LastName}`,
          samplerId: item.MaUser,
          samplerPosition: item.Position,
          samplerIdCard: item.CMND,
          samplerUserId: item.IdUser,
          samplerDepartment: item.Department,
        });
      }

      setData(dataConvert);
    }
  };
  useEffect(() => {
    clearSelectRows();
  }, [isClearSelectSamplerInforRows]);
  const clearSelectRows = () => {
    if (tableRef.current) {
      tableRef.current.clearSelectRows()
    }
  }
  const handleOk = () => {
    onSelectItem(listData);
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
    // clearSelectRows();
    // setListData([]);
  };
  const handleClose = () => {
    setVisible(false);
    form.resetFields();
    clearSelectRows();
    setListData([]);
  };
  const handleOpen = () => {
    setVisible(true);
  };
  useEffect(() => {
    const userAction = {
      close: handleClose,
      openSamplerModal: handleOpen,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    // eslint-disable-next-line
  }, []);
  const onSelect = (data) => {
    setListData(data);
  };
  const searchTable = (e) => {
    let val = e.target.value;
    setInputSearch(val.trim());
  };
  const toolBar = () => {
    return (
      <Input.Search
        allowClear
        // style={{ width: 200 }}
        width={200}
        placeholder={t("ACTIONS.search")}
        onChange={searchTable}
      // value={inputSearch}
      />
    );
  };
  return (
    <div>
      <Modal
        width={"100vw"}
        style={{ top: 20 }}
        title={t(`Sampler Information`)}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.submit")}
        okButtonProps={{ htmlType: "submit" }}
        forceRender
      >
        <Datatable
          tableRef={tableRef}
          selectOnly={fieldNameSearchUser === "custodianName" ? true : false}
          columns={columns}
          dataSource={data}
          rowKey={"samplerUserId"}
          autoFlatData={true}
          allowSelect={true}
          actionConfig={{}}
          onSelect={(data) => onSelect(data)}
          inputSearch={inputSearch}
          toolbarExtra={toolBar()}
        />
      </Modal>
    </div>
  );
};

SamplerInformation.propTypes = {
  actionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterSubmit: PropTypes.func,
};

export default SamplerInformation;
