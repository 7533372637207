import { createSlice } from '@reduxjs/toolkit';

export const MODULE_NAME = 'client';

const initialState = {
    clients: [],
    error: '',
    loading: false,
    submitting: false,
};
const slice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        getList: (state, action) => {
            state.error = '';
            state.loading = true;
        },
        getListSuccess: (state, action) => {
            state.clients = action.payload
            state.loading = false
            state.error = ''
        },
        getListFailure: (state, action) => {
            state.messageErr = action.payload
            state.loading = false
        },
        doAddClient: (state, action) => {
            state.submitting = true
        },
        doAddClientSuccess: (state, action) => {
            state.submitting = false
            state.clients = [action.payload, ...state.clients]
        },
        doDeleteClient: (state, action) => {
            state.loading = true
        },
        doDeleteClientSuccess: (state, action) => {
            state.loading = false
            state.clients = state.clients.filter(client => !action.payload.includes(client.clientID))
        },
        doAllDone: (state, action) => {
            state.loading = false
            state.submitting = false
        },
        doEditClient: (state, action) => {
            state.submitting = true
        },
        doEditClientSuccess: (state, action) => {
            state.submitting = false
            state.clients = state.clients.map(client => {
                if (client.clientID !== action.payload.clientID) return client
                return action.payload
            })
        },

    },
});

export const { getList, getListSuccess, getListFailure, doAddClient, doAddClientSuccess, doDeleteClient, doDeleteClientSuccess, doAllDone, doEditClient, doEditClientSuccess } = slice.actions;

export default slice.reducer;
