import React, { Fragment } from 'react';
import routeList from '../../routes';
import { Switch, withRouter } from 'react-router-dom';
// import NotAuthorizePage from '../../Pages/NotAuth';
import RouterItem from './Item';
import {useSelector} from 'react-redux';
import { selectRole } from 'src/redux/permission/selector';

const Body = () => {
    // const notAuth = () => <NotAuthorizePage />;
    const roles = useSelector(selectRole);

    const showConfigRouter = () => {
        const xhtml = [];

        if (routeList.length > 0) {
            routeList.forEach((route, index) => {
                if (route.main && (!route.role || route.role.some(item => roles.includes(item)))) {
                    xhtml.push(<RouterItem key={index} {...route} />);
                }
                if (
                    route.children &&
                    route.children.length > 0 &&
                    (!route.role || route.role.some(item => roles.includes(item)))
                ) {
                    route.children.forEach((childRoute, childIndex) => {
                        if (childRoute.main) {
                            xhtml.push(
                                <RouterItem key={childIndex} {...childRoute} />
                            );
                        }
                    });
                }
            });
        }

        return <Switch>{xhtml}</Switch>;
    };

    return <Fragment>{showConfigRouter()}</Fragment>;
};

export default withRouter(Body);
