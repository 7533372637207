export const CONFIG = {
    /**
     * development
     * * */
    API_URL: {
        BASE: process.env.REACT_APP_API ? process.env.REACT_APP_API : 'http://112.78.1.167:8085',
        AUTH: process.env.REACT_APP_API_AUTH ? process.env.REACT_APP_API_AUTH : 'http://112.78.1.167:8088',
        DOC: process.env.REACT_APP_API_DOCUMENT ? process.env.REACT_APP_API_DOCUMENT : 'http://112.78.1.167:8089/api/documents',
    },
};
