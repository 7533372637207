import { Col, Form, Input, DatePicker, Select, InputNumber, Radio, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploadSingle from "../../components/Upload/UploadSingle";
import { UploadStyled } from "../../constants/styledComponent.constant";
import { TYPE } from "../../constants/constant";
import { getListTestType, getListTestTypeByGroup } from "../../apis/configTest";
import { getListAnalyte, getListAnalyteByTestId, getListAnalyteGroup } from "../../apis/analyte";
import { getListSample } from "../../apis/samples";
import { getUsersApi } from "../../apis/user";

const listFeildsInputSelectCustom = ["testId", "analyteId", "analyteGroup", "sampleName", "sampleType", "technicianName", "reviewer", "approver"]
const listFeildsInputSelectCustomGetList = ["technicianName", "reviewer", "approver"]
const listDisabled = ["analyteName", "sampleType", "methodId", "sampleIdInternal", "sampleIdExternal", "department", "associatedSamples", "approverId", "technicianId", "reviewerId", "createdBy"]

const listFieldSpecial = ["sampleName, sampleIdExternal"]

export const FieldsRenderComponent = ({
  item,
  fieldStyles,
  hidden,
  onUpload,
  handleChangeForm,
  params,
  form,
  testId,
}) => {
  const { t } = useTranslation();
  const ruleRequire = [{ required: false, message: t("VALIDATION.require") }];
  const [dataClient, setDataClient] = useState([]);

  const getList = async () => {
    // const testId = form.getFieldValue("testId");
    if (item[0] && item[0] === 'testId' && params.testGroupId) {
      const { data } = await getListTestTypeByGroup(params.testGroupId, params.testCodeId)
      setDataClient((data || []).map(x => { return { ...x, name: x.testId } }))
      return;
    }

    if (item[0] && item[0] === 'analyteGroup' && testId.id) {
      const { data } = await getListAnalyteGroup(testId.id)
      setDataClient((data || []))
      return;
    }

    if (item[0] && item[0] === 'analyteId' && testId.analytesGroupId) {
      const { data } = await getListAnalyte(testId.analytesGroupId)
      setDataClient((data || []).map(x => { return { ...x, name: x.analytesId, id: x.id } }))
      return;
    }

    if (item[0] && item[0] === 'sampleType' && testId.sampleTypes) {
      console.log('testid', testId);
      // const { data } = await getListAnalyteGroup(testId.id)
      setDataClient((testId.sampleTypes || []).map(x => { return { name: x.templateName, id: x.id } }))
      return;
    }

    if (item[0] && item[0] === 'sampleName' && testId.sampleTypeId) {
      const { data } = await getListSample(testId.sampleTypeId)
      console.log('data', data);
      setDataClient((data || []).map(x => { return { ...x, name: x.generalInformation.sampleIdExternal, id: x.generalInformation.sampleId } }))
      return;
    }

    if (item[0] && listFeildsInputSelectCustomGetList.includes(item[0])) {
      const { data } = await getUsersApi({ "listApp": ["NT_Core", "Sample", "NT_Document", "UserManagament", "FormConfiguration"] })
      setDataClient((data?.data || []).map(x => { return { ...x, name: x.Username, id: x.Username } }))
      // setDataClient((data?.data || []).map(x => { return { ...x, name: x.FirstName + " " + x.LastName, id: x.FirstName + " " + x.LastName } }))
      // setDataClient((data?.data || []).map(x => { return { ...x, name: x.Username, id: x.IdUser } }))
      return
    }
    setDataClient([])
  }

  useEffect(() => {
    getList()
  }, [item[0], testId, testId, params])

  const disabled = item[1].disabled == true || listDisabled.includes(item[0]);

  if (listFeildsInputSelectCustom.includes(item[0])) {
    return (
      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const formItemValue = getFieldValue(item[0]);
            let defaultOptions = [];
            if (formItemValue) {
              defaultOptions = [{ value: getFieldValue(item[0]), text: getFieldValue(item[0]) }];
            }

            return (
              <Form.Item
                label={item[0] === "sampleName" ? "Số ĐK kiểm nghiệm" : t(`TEST.FORM.${item[0]}`)}
                name={item[0]}
                labelAlign={"left"}
                rules={ruleRequire}
                style={{ ...fieldStyles }}
                hidden={hidden}
              >
                <Select
                  showSearch
                  placeholder={t("selectValue")}
                  value={formItemValue}
                  onChange={value => {
                    handleChangeForm && handleChangeForm(item[0], value)
                    const element = dataClient.find(x => x.id === value) || {}
                    if (item[0] && item[0] === 'testId') {
                      // handleChangeForm && handleChangeForm("testName", value)
                      handleChangeForm && handleChangeForm("dataTestId", element)
                    }
                    if (item[0] && item[0] === 'analyteId') {
                      handleChangeForm && handleChangeForm("analytesId", element.id)
                      // handleChangeForm && handleChangeForm("analyteId", element.id)
                      handleChangeForm && handleChangeForm("analyteName", element.analytesName)
                      handleChangeForm && handleChangeForm("methodId", element.methodId)
                      handleChangeForm && handleChangeForm("analyteDetail", element)
                    }
                    if (item[0] && item[0] === 'analyteGroup') {
                      handleChangeForm && handleChangeForm("dataTestId", { ...testId, analytesGroupId: element.id })
                    }
                    if (item[0] && item[0] === 'sampleType') {
                      handleChangeForm && handleChangeForm("sampleTypeId", element.id)
                    }
                    if (item[0] && item[0] === 'sampleName') {
                      handleChangeForm && handleChangeForm("sampleNameDetail", element)
                    }
                    if (item[0] && item[0] === 'technicianName') {
                      handleChangeForm && handleChangeForm("technicianId", element.MaUser)
                    }
                    if (item[0] && item[0] === 'reviewer') {
                      handleChangeForm && handleChangeForm("reviewerId", element.MaUser)
                    }
                    if (item[0] && item[0] === 'approver') {
                      handleChangeForm && handleChangeForm("approverId", element.MaUser)
                      handleChangeForm && handleChangeForm("approverDepartment", element.FirstName + " " + element.LastName)
                    }
                  }}
                >
                  {
                    dataClient.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)
                  }
                </Select>
              </Form.Item>
            )
          }}
        </Form.Item>
      </Col>
    );
  }

  const renderByType = (item) => {
    switch (item[1].type) {
      case TYPE.textbox:
        return (
          <Form.Item
            label={item[0] === "sampleIdExternal" ? "Tên mẫu" : t(`TEST.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={hidden}
          >
            <Input disabled={disabled} />
          </Form.Item>
        );
      case TYPE.checkbox:
        return (
          <Form.Item
            label={t(`TEST.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={hidden}
          >
            <Radio.Group>
              <Radio value={true}>{t("BUTTON.yes")}</Radio>
              <Radio value={false}>{t("BUTTON.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        );
      case TYPE.number:
        return (
          <Form.Item
            label={t(`TEST.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
          >
            <InputNumber />
          </Form.Item>
        );
      case TYPE.upload:
        return (
          <UploadStyled style={{ ...fieldStyles }} hidden={hidden}>
            <UploadSingle
              onChange={(val) => onUpload({ fieldName: item[0], ...val })}
              fileList={[]}
              label={t(`TEST.FORM.${item[0]}`)}
            >
              <Form.Item name={item[0]} hidden={true}>
                <Input />
              </Form.Item>
            </UploadSingle>
          </UploadStyled>
        );
      case TYPE.dropdown:
        return (
          <Form.Item
            label={item[0] === "sampleName" ? "Số ĐK kiểm nghiệm" : t(`TEST.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
          >
            <Select showSearch placeholder={t("selectValue")}>
              {item[1]?.data?.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      case TYPE.date:
        return (
          <Form.Item
            label={t(`TEST.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={hidden}
          >
            <DatePicker placeholder={""} className={"tw-w-full"} format={"DD/MM/YYYY"} disabled={disabled} />
          </Form.Item>
        );

      case TYPE.time:
        return (
          <Form.Item
            label={t(`TEST.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={hidden}
          >
            <TimePicker placeholder={""} className={"tw-w-full"} disabled={disabled} />
          </Form.Item>
        );
      // return <p>ffff</p>

      default:
        return (
          <Form.Item
            label={t(`TEST.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
          // hidden={true}
          >
            <Input disabled={disabled} />
          </Form.Item>
        );
    }
  };
  return <Col span={12}>{renderByType(item)}</Col>;
};
