import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import TablePdf2 from "./TablePdf2";
import iDate from "utils-format/iDate";
import { apiGetReportCode } from "../../apis/samples";

// Register font
Font.register({
  family: "Times-new-roman",
  src: "/fonts/font-times-new-roman.ttf",
});
Font.register({
  family: "Times-new-roman-bold",
  src: "/fonts/font-times-new-roman-bold.ttf",
});
Font.register({
  family: "Times-new-roman-italic",
  src: "/fonts/Times-New-Roman-Italic.ttf",
});

const monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const SamplePdfView = ({ data, signSampleSender, signRefSampleRecipient }) => {
  const [currentDate] = useState(new Date())
  const {
    custodian,
    customerAddress,
    custodianName,
    productionDate,
    samplingDate,
    samplerName,
    samplerPosition,
    locationName,
    address,
    sampleName,
    client,
    contactPerson,
    pickingPhone,
    submissionDate,
    catalog,
    customerPhone,
    importDrug,
    importFacility,
    expirationDate,
  } = data[0] || {}
  const remark = data.map(item => item.remark).join('; ');
  const [dataTable, setDataTable] = useState([])
  const [numberReport, setNumberReport] = useState(null);

  const handleConvertDataTable = () => {
    let facility = "";
    if (importDrug) {
      facility = importFacility;
    }
    let result = [];
    if (data.length > 0) {
      data = data.sort((a, b) => (a.sampleIdInternal < b.sampleIdInternal) ? -1 : 1);
      data.map((item, index) => {
        const row = {
          stt: index + 1,
          sampleName: item.sampleName,
          productionDate: item.productionDate,
          packedSize: item.packedSize,
          currentQuantity: item.currentQuantity,
          quantifyUnit: item.quantifyUnit,
          producerName: item.producerName,
          producerAddress: item.producerAddress,
          status: item.status,
          substanceName: item.substanceName,
          substanceContent: item.substanceContent,
          substanceUnit: item.substanceUnit,
          registerNumber: item.registerNumber,
          sampleIdInternal: item.sampleIdInternal,
          chronicleBatchDate: item.chronicleBatchDate,
          expirationDate: item.expirationDate,
          facility: item.importFacility,
          unit: item.unit,
          samplerRequired: item.samplerRequired,
          testMethod: item.testMethod,
          sampleIdExternal: item.sampleIdExternal,
          importDrug: item.importDrug,
          importFacility: item.importFacility,
          substanceInformation: item.substanceInformation,
        };
        result.push(row);
      });
    }

    setDataTable(result);
  };

  const getReportCode = () => {
    const userId = localStorage.getItem("IdUser");
    let filter = {
      sampleIds: data.map(item => item.sampleId),
      technicianId: userId,
    };
    apiGetReportCode(filter).then((result) => {
      if (result.status === 200) {
        setNumberReport(result.data.code);
      }
      else {
        return;
      }
    });
  };

  useEffect(() => {
    getReportCode();
    handleConvertDataTable();
  }, [data]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {/* HEADER */}
          <View style={{ textAlign: "center" }}>
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image src={"/images/header.jpg"} style={{ width: "50%" }} />
            </View>
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={[styles.bold, { fontSize: "13px" }]}>
                PHIẾU YÊU CẦU KIỂM NGHIỆM
              </Text>
              <Text style={{ fontSize: "13px" }}>Số: {numberReport}</Text>
            </View>
          </View>

          {/* CONTENT */}
          <View>
            <Text>
              - Đơn vị gửi mẫu: <b style={styles.bold}>{client}</b>
            </Text>
            <Text>
              - Địa chỉ: <b style={styles.bold}>{customerAddress}</b>
            </Text>
            <Text>
              - Người gửi mẫu: <b style={styles.bold}>{contactPerson}</b>
            </Text>
            <Text>
              - Điện thoại liên lạc: <b style={styles.bold}>{customerPhone || "................."}</b>{" "}
              Fax: .................
            </Text>
            <Text>
              - Ngày gửi mẫu:{" "}
              {/* {iDate(new Date(), "{j}/{n}/{f}")} */}
              {submissionDate ?
                <b style={styles.bold}>
                  {iDate(new Date(submissionDate), "{j}/{n}/{f}")}
                </b> :
                <Text>.................</Text>
              }
              {" "}
              {/* - Ngày sản xuất:{" "}
              {productionDate ?
                <b style={styles.bold}>
                  {iDate(new Date(productionDate), "{j}/{n}/{f}")}
                </b> :
                <Text>.................</Text>
              }
              {" "}
              - Hạn đùng:{" "}
              {expirationDate ?
                <b style={styles.bold}>
                  {iDate(new Date(expirationDate), "{j}/{n}/{f}")}
                </b> :
                <Text>.................</Text>
              }
              {" "} */}
              - Ngày trả lời kết quả: ................. {" "}
              {/* <b style={styles.bold}>{iDate(null, "{j}/{n}/{f}")}</b> */}
            </Text>
            <Text>
              - Đồng ý với Quy định về việc gửi mẫu kiểm nghiệm: Đồng ý
              &radic;&nbsp; Không đồng ý 
            </Text>

            <View style={{ marginBottom: "5px" }}></View>
            <TablePdf2 data={dataTable} />
            <View style={{ marginBottom: "5px" }}></View>
            <Text>- Sử dụng nhà thầu phụ: {catalog}</Text>
            {/* <Text>- Sử dụng nhà thầu phụ: Có &radic;&nbsp; Không </Text> */}
            <Text>
              - Nếu phương pháp đề nghị như trên không thực hiện được, người gửi
              mẫu đồng ý cho PTN thử theo phương pháp khác phù hợp: Có
              &radic;&nbsp; Không 
            </Text>
            <Text>- Chuẩn Khách hàng cung cấp: {remark}</Text>
            <Text>
              - Trung tâm xin cam kết bảo mật về thông tin mẫu và thông tin của
              khách hàng (ngoại trừ trường hợp khi pháp luật yêu cầu)
            </Text>
            <Text
              style={{ fontSize: "10px", fontFamily: "Times-new-roman-italic" }}
            >
              <i>
                <b style={{ fontFamily: "Times-new-roman-bold" }}>Lưu ý:</b> Quý
                khách hàng ghi đúng, đầy đủ thông tin để lập phiếu kết quả thử
                nghiệm. Chúng tôi sẽ không thay đổi thông tin về mẫu sau khi
                phát hành phiếu kết quả thử nghiệm và hóa đơn.
              </i>
            </Text>
          </View>
          {/* CONTENT */}

          {/* FOOTER */}
          <View style={styles.footer}>
            <View style={styles.footerItem}>
              <Text style={styles.bold}>NGƯỜI NHẬN MẪU</Text>
              <Text>(Ký và ghi rõ họ tên)</Text>
              <Image src={signRefSampleRecipient} alt="" style={{ width: 400, height: 80 }} />
            </View>

            <View style={styles.footerItem}>
              <Text style={styles.bold}>NGƯỜI GỬI MẪU</Text>
              <Text>(Ký và ghi rõ họ tên)</Text>
              <Image src={signSampleSender} alt="" style={{ width: 400, height: 80 }} />
            </View>
          </View>
          {/* FOOTER */}
        </View>
      </Page>
    </Document>
  );
};

SamplePdfView.propTypes = {};

export default SamplePdfView;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    fontFamily: "Times-new-roman",
    fontStyle: "normal",
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexFlow: "row wrap",
  },
  logo: {
    width: "60px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  bold: {
    fontFamily: "Times-new-roman-bold",
  },
  italic: {
    fontFamily: "Times-new-roman-italic",
  },
  underline: {
    borderBottom: "0.5px solid black",
    display: "inline-block",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "between",
    alignItems: "between",
    marginTop: "5px",
  },
  footerItem: {
    width: '50%',
    textAlign: 'center'
  }
});

const header = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "10px",
  },
  item1: {
    display: "block",
    flex: "0 0 30%",
    maxWidth: "30%",
    textAlign: "center",
    justifyContent: "center",
  },
  item2: {
    display: "block",
    flex: "0 0 70%",
    maxWidth: "70%",
  },
  top: {
    textAlign: "center",
  },
  title: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "10px",
  },
});
