import CharCustom from "../chartCustom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import axiosService from "../../../../../services/axiosService";
import {CONFIG} from "../../../../../configs";

function Test() {
  const { t } = useTranslation();

  const [chartData, setChartData] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    getChartData()
    getTableData()

    let id = setInterval( () => {
      getChartData()
      getTableData()
    }, 60000);
    return () => clearInterval(id);
  }, [])

  const getChartData = async () => {
    const {data: dataApi} = await axiosService.get(`${CONFIG.API_URL.BASE}/api/test-information/count-by-test-group`)
    const result = (dataApi || []).map(chart => ({type: chart.name || '', count: chart.data}))
    setChartData(result)
    // console.log(result)
  }

  const getTableData = async () => {
    const {data: dataAPi} = await axiosService.get(`${CONFIG.API_URL.BASE}/api/test-information/count-by-test-group-code-status`)
    // console.log(dataAPi)
    generateTable(dataAPi || [])
  }

  const generateTable = (dataAPi) => {
    const tableContent = []
    for (let i = 0; i < dataAPi.length; i++) {
      const item = dataAPi[i]
      const { testCountByTestCodes } = item
      const firstRowSpan = testCountByTestCodes.reduce((a,b) => a + b.testCountByStatuses.length, 0)
      const secondRowSpan = testCountByTestCodes[0].testCountByStatuses.length
      tableContent.push(
        <tr key={i}>
          <td rowSpan={firstRowSpan}>{item.groupName}</td>
          <td rowSpan={secondRowSpan}>{testCountByTestCodes[0].testCode}</td>
          <td>{t('TEST.' + testCountByTestCodes[0].testCountByStatuses[0].name)}</td>
          <td>{testCountByTestCodes[0].testCountByStatuses[0].data}</td>
        </tr>
      )

      for (let j = 0; j < testCountByTestCodes.length; j++) {
        const testCountByTestCode = testCountByTestCodes[j]

        if (j === 0) {
          tableContent.push(...testCountByTestCode.testCountByStatuses.map((testStatus, tsI) => (
            <tr key={'testStatus' + i + j + tsI}>
              <td>{t('TEST.' + testStatus.name)}</td>
              <td>{testStatus.data}</td>
            </tr>
          )).filter((t, ti) => ti > 0))
        } else {
          const secondRowSpan = testCountByTestCode.testCountByStatuses.length;
          tableContent.push(
            <tr key={'testCode' + i + j}>
              <td rowSpan={secondRowSpan}>{testCountByTestCode.testCode}</td>
              <td>{t('TEST.' + testCountByTestCode.testCountByStatuses[0].name)}</td>
              <td>{testCountByTestCode.testCountByStatuses[0].data}</td>
            </tr>
          )
          tableContent.push(...testCountByTestCode.testCountByStatuses.map((testStatus, tsI) => (
            <tr key={'testStatus' + i + j + tsI}>
              <td>{t('TEST.' + testStatus.name)}</td>
              <td>{testStatus.data}</td>
            </tr>
          )).filter((t, ti) => ti > 0))
        }
      }
    }
    setData(tableContent)
  }

  return (
    <div className="dashboard-tile-table-class">
      <div className="div_title_header" span={24}>
        <div className="tw-inline-flex">
          <img
            className="img_tilte"
            src="/images/test_sample_mgmt.png"
            align="top"
            alt=""
          />
          <strong>&nbsp;&nbsp;{t("DASHBOARD.test")}</strong>
        </div>
        <div className="tw-flex-col tw-flex">
          <div className="canvas-container-class" span={12}>
            <CharCustom data={chartData} />
          </div>
          <div className="tw-p-0.5">
            <table className="table-standard-border tw-w-full">
              <thead>
                <tr style={{ background: "#1C5B75", color: "#fff" }}>
                  <td width="30%">{t("TEST.test_group")}</td>
                  <td width="30%">{t("TEST.test_code")}</td>
                  <td width="30%">{t("TEST.test_status")}</td>
                  <td width="10%">{t("TEST.test_count")}</td>
                </tr>
              </thead>
              <tbody>{data}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Test;
