import { createSlice } from "@reduxjs/toolkit";

export const MODULE_NAME = "sample";

const initialState = {
  samples: [],
  error: "",
  loading: false,
  submitting: false,
};
const slice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    getListForm: (state, action) => {
      state.error = "";
      state.loading = true;
    },
    getList: (state, action) => {
      state.error = "";
      state.loading = true;
    },
    getListSuccess: (state, action) => {
      state.samples = action.payload;
      state.loading = false;
      state.error = "";
    },
    getListFailure: (state, action) => {
      state.messageErr = action.payload;
      state.loading = false;
    },
    doAddSample: (state, action) => {
      state.submitting = true;
    },
    doAddSampleSuccess: (state, action) => {
      state.submitting = false;
      state.samples = [action.payload, ...state.samples];
    },
    doDeleteSample: (state, action) => {
      state.loading = true;
    },
    doDeleteSampleSuccess: (state, action) => {
      state.loading = false;
      state.samples = state.samples.filter(
        (sample) => !action.payload.includes(sample.generalInformation.sampleId)
      );
    },
    doAllDone: (state, action) => {
      state.loading = false;
      state.submitting = false;
    },
    doEditSample: (state, action) => {
      state.submitting = true;
    },
    doEditSampleSuccess: (state, action) => {
      state.submitting = false;
      state.samples = state.samples.map((sample) => {
        if (
          sample.generalInformation.sampleId !==
          action.payload.generalInformation.sampleId
        )
          return sample;
        return action.payload;
      });
    },
  },
});

export const {
  getListForm,
  getList,
  getListSuccess,
  getListFailure,
  doAddSample,
  doAddSampleSuccess,
  doDeleteSample,
  doDeleteSampleSuccess,
  doAllDone,
  doEditSample,
  doEditSampleSuccess,
} = slice.actions;

export default slice.reducer;
