import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';

const ChartMixed = ({ data= [] }) => {
  const config = {
    // data,
    data,
    xField: 'monthName',
    yField: 'countData',
    isGroup: true,
    isStack: true,
    seriesField: 'substanceType',
    groupField: 'importOrigin',
    tooltip: {
      formatter: (datum) => ({
        name: `${datum.importOrigin} - ${datum.substanceType}`,
        value: datum.countData,
      }),
    },
    label: {
      // 可手动配置 label 数据标签位置
      position: 'top',
      // 'top', 'middle', 'bottom'
      // 可配置附加的布局方法
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: 'interval-adjust-position',
        }, // 数据标签防遮挡
        {
          type: 'interval-hide-overlap',
        }, // 数据标签文颜色自动调整
        {
          type: 'adjust-color',
        },
      ],
    }
  };

  return <Column {...config} />;
};

export default ChartMixed;