import { Col, Form, Input, DatePicker, Select, InputNumber, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addAnalyteUnit, getListAnalyteUnit } from "../../../apis/analyte";
import SelectCustom from "../../../common/selectCustom";
import SelectFormCustom from "../../../common/selectFormCustom";
import UploadSingle from "../../../components/Upload/UploadSingle";
import { TYPE } from "../../../constants/constant";
import { UploadStyled } from "../../../constants/styledComponent.constant";
const listFeildsInputSelectCustom = ["unit"]

export const FieldsRenderComponent = ({
  item,
  fieldStyles,
  hidden,
  onUpload,
}) => {
  const { t } = useTranslation();
  const ruleRequire = [{ required: false, message: t("VALIDATION.require") }];
  const [dataClient, setDataClient] = useState([]);


  const getList = async () => {
    if (item[0] && item[0] === 'unit') {
      const { data } = await getListAnalyteUnit()
      setDataClient((data || []).map(x => { return { name: x, id: x } }))
      return;
    }
  }

  const handleChange = async (e, key) => {
    if (item[0] && item[0] === 'contractId') {
      const { data } = await []
      // handleChangeForm && handleChangeForm("sampleOrderNumber", data)
      return;
    }
  }

  const handleOkTestCode = async (name) => {
    if (item[0] && listFeildsInputSelectCustom.includes(item[0])) {
      await addAnalyteUnit({
        unitName: name
      })
      await getList()
    }
  }

  useEffect(() => {
    if (item[0])
      getList()
  }, [item])

  if (item[0] == "unit") {
    return (
      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const formItemValue = getFieldValue(item[0]);
            let defaultOptions = [];
            if (formItemValue) {
              defaultOptions = [{ value: getFieldValue(item[0]), text: getFieldValue(item[0]) }];
            }

            return (
              <Form.Item
                label={t(`SAMPLE.FORM.${item[0]}`)}
                name={item[0]}
                labelAlign={"left"}
              // rules={ruleRequire}
              // style={{ ...fieldStyles }}
              // hidden={hidden}
              >
                <SelectFormCustom
                  label='Test code'
                  options={dataClient.map(x => { return { ...x } })}
                  value={""}
                  onChange={(e) => handleChange(e, "testCodeId")}
                  handleOk={handleOkTestCode}
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      </Col>
    );
  }
  const renderByType = (item) => {
    const { disabled = false } = item[1];
    switch (item[1].type) {
      case TYPE.textbox:
        return (
          <Form.Item
            label={t(`ANALYTE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={hidden}
          >
            <Input disabled={disabled} />
          </Form.Item>
        );
      case TYPE.checkbox:
        return (
          <Form.Item
            label={t(`ANALYTE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={hidden}
          >
            <Radio.Group>
              <Radio value={true}>{t("BUTTON.yes")}</Radio>
              <Radio value={false}>{t("BUTTON.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        );
      case TYPE.number:
        return (
          <Form.Item
            label={t(`ANALYTE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        );
      case TYPE.upload:
        return (
          <UploadStyled style={{ ...fieldStyles }} hidden={hidden}>
            <UploadSingle
              onChange={(val) => onUpload({ fieldName: item[0], ...val })}
              fileList={[]}
              label={t(`ANALYTE.FORM.${item[0]}`)}
            >
              <Form.Item name={item[0]} hidden={true}>
                <Input />
              </Form.Item>
            </UploadSingle>
          </UploadStyled>
        );
      case TYPE.dropdown:
        return (
          <Form.Item
            label={t(`ANALYTE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
          >
            <Select showSearch placeholder={t("selectValue")}>
              {item[1]?.data?.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      case TYPE.date:
        return (
          <Form.Item
            label={t(`ANALYTE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={hidden}
          >
            <DatePicker placeholder={""} className={"tw-w-full"} />
          </Form.Item>
        );
      // return <p>ffff</p>

      default:
        return (
          <Form.Item
            label={t(`ANALYTE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={true}
          >
            <Input />
          </Form.Item>
        );
    }
  };
  return <Col span={12}>{renderByType(item)}</Col>;
};
