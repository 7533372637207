import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, message } from "antd";
import { apiGetSampleDetail } from "../../apis/samples";
import { useHistory, useParams } from "react-router";
import SectionRender from "./components/sectionRenderr";
import moment from "moment-timezone";
import styled from "styled-components";
import CustomSpin from "../../components/CustomSpin";
import { useTranslation } from "react-i18next";

const hiddenInputs = ["sampleId"];

const SampleDetail = () => {
  const formRef = useRef();
  const history = useHistory()
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { id: sampleId } = useParams();
  const [configData, setConfigData] = useState({})
  const [sampleData, setSampleData] = useState({})
  const [spinning, setSpinning] = useState(true)

  const getSampleDetail = async () => {
    const res = await apiGetSampleDetail(sampleId)
    setSpinning(false)
    if (res.status === 200) {
      let config = res.data.configData;
      config.config = config.config.filter(x => x.sectionName === "generalInformation" ||
        x.sectionName === "quantityInformation" ||
        x.sectionName === "quantityInformation" ||
        x.sectionName === "quantityInformation" ||
        x.sectionName === "chronicleInformation");
      config.config.map(item => {
        Object.keys(item.attribute).map(key => {
          item.attribute[key].disabled = true
        })
      })
      if (config) {
        setConfigData(config)
      }
      if (res.data.sample) {
        setSampleData(res.data.sample)
      }
    } else {
      message.warning(t('SAMPLE.MESSAGE.notFoundDetail'));
      history.goBack();
    }
  }

  useEffect(() => {
    let data = {}
    Object.values(sampleData).map(item => {
      data = {
        ...data,
        ...item
      }
    })
    setFieldsValue(data)
  }, [sampleData])

  const setFieldsValue = (values) => {
    Object.keys(values).map((key) => {
      if (!values[key] && key.toUpperCase().includes("DATE")) {
        values[key] = undefined;
      }
      if (values[key] && key === "chronicleBatchDate") {
        return key;
      }
      if (values[key] && key.toUpperCase().includes("DATE")) {
        if (Number.isInteger(values[key]))
          values[key] = moment(values[key]);
      }
      return key;
    });
    form.setFieldsValue(values);
  };

  useEffect(() => {
    getSampleDetail().then()
  }, [])

  return (
    <CustomSpin spinning={spinning}>
      <SampleDetailWrap style={{ backgroundColor: '#fff' }} className={'tw-px-10 tw-py-5 tw-mt-5'}>
        <Form
          ref={formRef}
          form={form}
          name="basic"
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          // initialValues={initialValueForm}
          // onFinish={onFinish}
          autoComplete="off"
        // onValuesChange={(value, allvalue) => changeValue(value, allvalue)}
        >
          {configData?.config?.map((item, idx) => (
            <SectionRender
              key={idx}
              item={item}
              // ishowDrug={ishowDrug}
              hiddenInputs={hiddenInputs}
            // onUpload={onUpload}
            />
          ))}

        </Form>
        <Button type='primary' style={{ marginTop: "0.5rem", display: "flex", margin: "0.5rem 0 0 auto" }} onClick={history.goBack} >Quay lại</Button>
      </SampleDetailWrap>
    </CustomSpin>
  );
};

SampleDetail.propTypes = {

};

export default SampleDetail;

const SampleDetailWrap = styled.div`
  .ant-picker.ant-picker-disabled, .ant-input[disabled], .ant-select-selector, .ant-input-number-disabled {
    background-color: #fff !important;
    color: #000 !important;
  }
`
