export class Samples {
  constructor(data) {
    this.generalInformation = {
      sampleType: Math.floor(Math.random() * 10000) + 1,
      sampleId: data.sampleId || Math.floor(Math.random() * 10000) + 1,
      sampleIDExternal: data.sampleIdExternal,
      storageIDExternal: data.storageIdExternal,
      barcodeID: data.barcodeId,
      productType: data.productType,
      storageLocation: data.storageLocation,
      batchID: data.batchId,
      batchSize: data.batchSize,
    }
    this.quantityInformation = {
      currentQuantity: {
        value: data.currentQuantity,
        unit: data.currentQuantityUnit
      },
      packedSize: {
        value: data.packedSize,
        unit: data.packedSizeUnit,
      }
    }
    this.custodialInformation = {
      custodianName: data.custodianName,
      custodianPosition: data.custodianPosition,
      custodian: data.custodian
    }
    this.sampleChronicleInformation = {
      expirationDate: data.expirationDate,
      pullOutDate: data.pullOutDate,
      remindBeforePullDate: data.remindBeforePullDate,
      productionDate: data.productionDate,
      samplingDate: data.samplingDate,
    }
    this.clientInformation = {
      associatedClient: data.associatedClient
    }
    this.workflowInformation = {
      associateWorkflowTemplate: data.associateWorkflowTemplate
    }
    this.sampleImage = {
      caption: data.caption,
      image: '',
      sampleStatus: data.sampleStatus
    }
  }

  // getList(data = SAMPLE_DEFAULT_DATA) {
  //   for (let i = 0; i < 5; i++) {
  //     const item = new Samples(data);
  //     item.generalInformation.sampleId += i;
  //     data.push(item)
  //   }
  //   return {
  //     status: 200,
  //     data: data
  //   }
  // }
}

export const SAMPLE_DEFAULT_DATA = {
  sampleType: 'Thuc Pham',
  sampleId: 'FnN_Supplement_',
  sampleIdExternal: 'test pharma',
  storageIdExternal: '',
  barcodeId: 'phar1',
  productType: '',
  storageLocation: '',
  batchId: '',
  batchSize: '',
  currentQuantity: 56,
  currentQuantityUnit: 'ml',
  packedSize: 0,
  packedSizeUnit: 'mg',
  custodianName: 'Binh Vu',
  custodianPosition: '',
  custodian: 'M_ADMIN',
  expirationDate: '',
  remindBeforePullDate: '',
  samplingDate: '',
  productionDate: '',
  pullOutDate: '',
  associatedClient: 'FSM_1',
  associateWorkflowTemplate: '',
  caption: '',
  sampleStatus: ''
}
