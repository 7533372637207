import React from 'react';
import Chart from "react-apexcharts";

const ChartLine = (props) => {
  const months = [];
  props.data.forEach(value => {
    if (months.includes(value?.monthName)) return;
    months.push(value?.monthName)
  })
  const yArray = [];
  props.data.forEach(value => {
    if (yArray.includes(value?.pickingSellType)) return;
    // if (!value?.pickingSellType) return;
    yArray.push(value?.pickingSellType)
  })
  const data = {
    series: yArray.map(y => {
      return {
        name: y,
        data: props.data.filter(value => value.pickingSellType === y).map(x => x.countData)
      }
    }),
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      title: {
        text: 'Page Statistics',
        align: 'left'
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        categories: months,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (mins)"
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val + " per session"
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          }
        ]
      },
      grid: {
        borderColor: '#f1f1f1',
      }
    }
  };
  return <Chart options={data.options} series={data.series} type="line" height={350} />;
}

export default ChartLine;