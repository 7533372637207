export class Client {
  constructor(data) {
    this.clientID = Math.floor(Math.random() * 10000) + 1;
    this.clientIDExternal = data.clientIDExternal;
    this.identificationNumber = data.identificationNumber;
    this.firstName = data.firstName;
    this.entryDate = data.entryDate;
    this.lastName = data.lastName;
    this.lic = data.lic;
    this.facilityIDExternal = data.facilityIDExternal;
    this.account = data.account;
    this.facilityName = data.facilityName;

    this.email = data.email;
    this.street = data.street;
    this.phoneNo = data.phoneNo;
    this.zipCode = data.zipCode;
    this.address = data.address;
    this.billTo = data.billTo;
    this.city = data.city;
    this.shipTo = data.shipTo;
    this.state = data.state;
    this.alternateEmail = data.alternateEmail;
    this.country = data.country;

    this.associatedSubjects = data.associatedSubjects;
    this.associatedEmails = data.associatedEmails;
    this.associatedSamples = data.associatedSamples;

    this.caption = data.caption;
  }
}

export const CLIENT_DATA = {
  clientIDExternal: Math.floor(Math.random() * 10000) + 1,
  identificationNumber: '',
  firstName: '',
  entryDate: '',
  lastName: '',
  lic: '',
  facilityIDExternal: '',
  account: '',
  facilityName: '',

  email: '',
  street: '',
  phoneNo: '',
  zipCode: '',
  address: '',
  billTo: '',
  city: '',
  shipTo: '',
  state: '',
  alternateEmail: '',
  country: '',

  associatedSubjects: '',
  associatedEmails: '',
  associatedSamples: '',

  caption: ''
}
