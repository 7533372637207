import {ROLES} from "../constants/constant";

export class Auth {
  constructor(data) {
    this.token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';
    this.profile = data
  }

  login() {
    return {
      "status": 200,
      "data": {
        "token": this.token,
        "profile": this.profile
      }
    }
  }
}

export const USER_SAMPLE = {
  "id": 1,
  "name": "Leanne Graham",
  "username": "Bret",
  "email": "Sincere@april.biz",
  roles: [ROLES.NT_Sample]
}
export const USER_TEST = {
  "id": 1,
  "name": "Leanne Graham",
  "username": "Bret",
  "email": "Sincere@april.biz",
  roles: [ROLES.NT_Test]
}

export const USER_DASHBOARD = {
  "id": 1,
  "name": "Leanne Graham",
  "username": "Bret",
  "email": "Sincere@april.biz",
  roles: [ROLES.NT_Dashboard]
}

export const USER_TASK = {
  "id": 1,
  "name": "Leanne Graham",
  "username": "Bret",
  "email": "Sincere@april.biz",
  roles: [ROLES.NT_Task]
}

export const USER_CLIENT = {
  "id": 1,
  "name": "Leanne Graham",
  "username": "Bret",
  "email": "Sincere@april.biz",
  roles: [ROLES.NT_Client]
}
export const USER_ADMIN = {
  "id": 1,
  "name": "Leanne Graham",
  "username": "Bret",
  "email": "Sincere@april.biz",
  roles: [ROLES.NT_Admin]
}
