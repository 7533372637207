import axiosService from 'src/services/axiosService';
import {CONFIG} from "../../configs";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */
export const testApi = (config) => {
  const method = config.method
  const endPoint = config.url
  const params = config.params
  return axiosService[method](`${CONFIG.API_URL.BASE}${endPoint}`, params)
};
