import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Input, message, Row, Select } from "antd";
import FormSelect from "./FormSelect";
import FormSection from "../../components/FormSection";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { theme } from "../../constants/color.constant";
import { SaveOutlined } from "@ant-design/icons";
import { useHistory, useLocation, useParams } from "react-router";
import {
  addTemplate,
  editTemplate,
  getSourceConfig,
  getTemplateById,
} from "../../apis/Template";
import _ from "lodash";
import { FieldsRender } from "../../utils/fieldRender.helper";
import { useSelector } from "react-redux";
import { selectRole } from "../../redux/permission/selector";
import { CreateConfig, EditConfig } from "../../constants/roles.constant";
import { apiAddTestGroup, apiEditTestGroup, getDetailTestGroup, getListTestGroup } from '../../apis/configTest'


const WrapInput = styled.div`
  margin-bottom: 15px;
  .ant-form-item-label-left {
    margin-right: 5px;
    label {
      background-color: ${theme.colors.blue["300"]};
      border-radius: 3px;
      padding-left: 3px;
      width: 100%;
      //height: auto;
      white-space: break-spaces;
      //min-height: 100%;
    }
  }
`;

const BtnSubmitStyled = styled.div`
  .ant-form-item {
    justify-content: flex-end;
    text-align: right;
  }
`;

const SettingPage = (props) => {
  const { t } = useTranslation();
  const { id: templateId } = useParams();
  const history = useHistory();
  const formRef = useRef();
  const [form] = Form.useForm();
  const roles = useSelector(selectRole);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [dataTemplate, setDataTemplate] = useState({});
  const [sourceConfig, setSourceConfig] = useState({});
  const [templateType, setTemplateType] = useState("");
  const ruleRequire = [{ required: true, message: t("VALIDATION.require") }];
  const location = useLocation();

  console.log(`isEdit`, isEdit)

  useEffect(() => {
    if (!templateId) return;
    setIsEdit(true);
  }, [templateId]);

  useEffect(() => {
    if (!isEdit) return;
    fetchTemplateById(templateId).then();
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    if (!dataTemplate?.config) return;
    fetchSourceConfig(templateType).then();
    // eslint-disable-next-line
  }, [dataTemplate]);

  const fetchTemplateById = async (id) => {
    let res;
    if (location.search) {
      res = await getDetailTestGroup(id);
    }
    else {
      res = await getTemplateById(id);
    }
    if (res.status === 200) {
      setTemplateType(res.data.templateType || "TEST");
      setDataTemplate(res.data.configData);
      fillFormData(res.data);
    }
  };

  const fetchSourceConfig = async (templateType) => {
    const res = await getSourceConfig(templateType);
    if (res.status !== 200) {
      // console.log("fetch source config error");
      return;
    }
    if (!isEdit) {
      setSourceConfig(res.data);
    } else {
      let tmp = [];
      res.data.config.map((item) => {
        item.display = false;
        let attr = {};
        Object.keys(item.attribute).map((key) => {
          return (attr[key] = {
            ...item.attribute[key],
            display: false,
          });
        });
        return tmp.push({ ...item, attribute: attr });
      });
      let result = _.values(
        _.merge(
          _.keyBy(tmp, "sectionName"),
          _.keyBy(dataTemplate.config, "sectionName")
        )
      );

      setSourceConfig({ config: result });
    }
  };

  const combineConfig = (sourceConfig, inputs) => {
    const configs = [];
    sourceConfig.map((config) => {
      if (inputs.includes(config.sectionName)) {
        const attribute = {};
        Object.keys(config.attribute).map((key) => {
          if (inputs.includes(key)) {
            attribute[key] = {
              ...config.attribute[key],
              display: true,
            };
          }
          return key;
        });
        configs.push({
          sectionName: config.sectionName,
          display: true,
          attribute,
        });
      }
      return config;
    });
    return configs;
  };

  const fillFormData = (data) => {
    form.setFieldsValue({
      moduleName: data.templateType || "TEST",
      templateName: data.templateName || data.name,
      prefix: data.prefix
    });
  };

  const onFinish = async (values) => {
    const data = {
      configData: {
        config: combineConfig(sourceConfig.config, inputs),
      },
      templateName: values.templateName,
      templateType: values.moduleName,
      prefix: values.prefix,
    };
    let res = {};
    if (isEdit) {
      data.id = templateId;
      if (templateType == "TEST") {
        res = await apiEditTestGroup({
          name: data.templateName,
          configData: data.configData,
          id: templateId
        });
      }
      else
        res = await editTemplate(data);
    } else {
      if (templateType == "TEST") {
        res = await apiAddTestGroup({
          name: data.templateName,
          configData: data.configData,
        });
      }
      else
        res = await addTemplate(data);
    }
    if (res.data.id || templateType == "TEST") {
      message.success(`${isEdit ? "Sửa" : "Tạo mới"} cài đặt thành công`);
      console.log(`"/form-setting" + (templateType === "TEST" ? "?type=TEST" : "")`, "/form-setting" + (templateType === "TEST" ? "?type=TEST" : ""))
      history.push("/form-setting" + (templateType === "TEST" ? "?type=TEST" : ""));
    } else {
      message.error(`${isEdit ? "Sửa" : "Tạo mới"} cài đặt không thành công`);
    }
  };

  const onChangeModule = (value) => {
    setTemplateType(value);
    fetchSourceConfig(value).then();
  };

  const handleChangeForm = (inputNames) => {
    setInputs(inputNames);
  };
  const renderSection = () => {
    // const sourceFiltered = dataTemplate.configData
    if (!sourceConfig?.config) return "";
    const deepCopy = _.cloneDeep(sourceConfig.config);
    const xhtml = deepCopy.map((item, index) => {
      if (!inputs.includes(item.sectionName)) return "";
      if (item.attribute?.uploadFile) {
        const uploadFile = { ...item.attribute.uploadFile };
        delete item.attribute.uploadFile;
        item.attribute = {
          ...item.attribute,
          uploadFile,
        };
      }
      return (
        <FormSection
          header={t(`${templateType}.FORM.${item.sectionName}`)}
          key={index}
        >
          <Row gutter={[32, 8]}>
            {Object.keys(item.attribute).map((key, _index) => {
              if (!inputs.includes(key)) return "";
              return FieldsRender({
                type: key === "uploadFile" ? "UPLOAD" : "input",
                key: `child_${_index}_${index}`,
                label: t(`${templateType}.FORM.${key}`),
                name: key,
                disabled: true,
              });
            })}
          </Row>
        </FormSection>
      );
    });

    return xhtml;
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Row gutter={[36, 16]}>
        <Col flex="1 1 200px">
          <Card>
            <Form
              ref={formRef}
              form={form}
              name="basic"
              labelCol={{ span: 11 }}
              wrapperCol={{ span: 13 }}
              // initialValues={initialValueForm}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row gutter={[32, 8]}>
                <Col flex="0 1 500px">
                  <WrapInput style={{ width: "500px" }}>
                    <Form.Item
                      label={templateType == "TEST" ? t("TEMPLATE_SETTING.FORM.templateNameTest") : t("TEMPLATE_SETTING.FORM.templateName")}
                      name={"templateName"}
                      labelAlign={"left"}
                      rules={ruleRequire}
                    >
                      <Input />
                    </Form.Item>
                  </WrapInput>
                  <WrapInput style={{ width: "500px" }}>
                    <Form.Item
                      label={t("TEMPLATE_SETTING.FORM.prefix")}
                      name={"prefix"}
                      labelAlign={"left"}
                    // rules={ruleRequire}
                    >
                      <Input disabled={isEdit} />
                    </Form.Item>
                  </WrapInput>
                  <WrapInput style={{ width: "500px" }}>
                    <Form.Item
                      label={t("TEMPLATE_SETTING.FORM.select_module")}
                      name={"moduleName"}
                      labelAlign={"left"}
                      rules={ruleRequire}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeModule}
                        disabled={isEdit}
                      >
                        <Select.Option value="SAMPLE">Sample</Select.Option>
                        {/*<Select.Option value="TASK">Task</Select.Option>*/}
                        <Select.Option value="TEST">Test</Select.Option>
                        {/*<Select.Option value="CLIENT">Client</Select.Option>*/}
                      </Select>
                    </Form.Item>
                  </WrapInput>
                </Col>
                <Col flex="auto">
                  {(roles.includes(EditConfig) ||
                    roles.includes(CreateConfig)) && (
                      <BtnSubmitStyled>
                        <Form.Item className="Item">
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                              height: "auto",
                              padding: "3px 5px",
                              alignItems: "center",
                            }}
                          >
                            <SaveOutlined />
                            {t("TEMPLATE_SETTING.FORM.save")}
                          </Button>
                        </Form.Item>
                      </BtnSubmitStyled>
                    )}
                </Col>
              </Row>
            </Form>

            <Form
              name="basic"
              labelCol={{ span: 11 }}
              wrapperCol={{ span: 13 }}
            >
              {sourceConfig?.config?.length > 0 && renderSection()}
            </Form>
          </Card>
        </Col>
        <Col flex="0 1 350px">
          <FormSelect
            templateType={templateType}
            onChange={handleChangeForm}
            configTemplate={sourceConfig?.config || []}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SettingPage;
