import React from 'react';
import LoginForm from './LoginForm';
import { CheckAuthInLoginPage } from 'src/utils/checkAuth.utils';

const Login = () => {
    const isAuth = CheckAuthInLoginPage();
    if (isAuth === undefined) return null;
    return (
        <div className="login-wrap tw-h-screen">
            <LoginForm />
        </div>
    );
};

export default Login;
