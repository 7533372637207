import React from "react";
import DashBoard from "src/Views/Dashboard";
import NotFountPage from "src/Views/NotFound";
import BlankPage from "src/Views/BlankPage";
import NotAuthorizePage from "src/Views/NotAuth";
import { SnippetsOutlined } from "@ant-design/icons";
import Samples from "src/Views/Samples";
import Role from "../Views/Role";
import { ROLES } from "../constants/constant";
import Test from "../Views/Test";
import Client from "../Views/Client";
import Templates from "../Views/Setting";
import SettingPage from "../Views/Setting/TemplateGenerate";
import TestApi from "../Views/ApiTesting";
import UserManagement from "../Views/User";
import Document from "../Views/Document";
import PermissionSetting from "../Views/User/PermissionSetting";
import {
  CreateConfig, EditConfig, FormConfiguration, NT_Document,
  Sample_View, UserManagament,
  UserManagament_AssignRight,
  UserManagament_ViewUser,
  ViewFormConfig,
  Sample,
  Test as TestRole,
  Client as ClientRole,
} from "../constants/roles.constant";
import SampleDetail from "../Views/Samples/SampleDetail";

const routes = [
  {
    name: "MENU.home",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/home_page.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: true,
    path: "/",
    // role: ['NT_Admin', 'NT_Dashboard'],
    main: () => <DashBoard />,
  },
  {
    name: "MENU.sample",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/sample_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: true,
    path: "/sample",
    role: [Sample],
    main: () => <Samples />,
  },
  {
    name: "MENU.sampleDetail",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/sample_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: false,
    path: "/sample/:id",
    // role: [Sample_View],
    main: () => <SampleDetail />,
  },
  {
    name: "MENU.task",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/storage_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: true,
    path: '/task',
    // role: [ROLES.NT_Admin, ROLES.NT_Task],
    main: () => <DashBoard tab={"3"} />,
  },
  {
    name: "MENU.test",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/test_sample_mgmt.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: true,
    path: "/test",
    // role: [ROLES.NT_Admin, ROLES.NT_Test],
    role: [TestRole],
    main: () => <Test />,
  },
  {
    name: "DASHBOARD.clients",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/client_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: true,
    path: "/client",
    // role: [ROLES.NT_Admin, ROLES.NT_Client],
    role: [ClientRole],
    main: () => <Client />,
  },
  {
    name: "MENU.setting",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/request_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: true,
    path: "/form-setting",
    role: [FormConfiguration],
    main: () => <Templates />,
  },
  {
    name: "MENU.setting",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/request_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: false,
    path: "/form-setting/add",
    role: [CreateConfig],
    main: () => <SettingPage />,
  },
  {
    name: "MENU.setting",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/request_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: false,
    path: "/form-setting/:id",
    // role: [EditConfig],
    main: () => <SettingPage />,
  },
  {
    name: "MENU.user",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/user.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: true,
    path: '/user-management',
    role: [UserManagament],
    main: () => <UserManagement />,
  },
  {
    name: "MENU.permission",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/package_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: false,
    path: '/permission-management',
    role: [UserManagament_AssignRight],
    main: () => <PermissionSetting />,
  },
  {
    name: "MENU.document",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/document_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: true,
    path: '/document',
    role: [NT_Document],
    main: () => <Document />,
  },
  // {
  //   name: "MENU.instrument_calibration_maintenace",
  //   icon: () => (
  //     <img
  //       className="img_tilte"
  //       src="/images/instrument_management.png"
  //       align="top"
  //       alt=""
  //     />
  //   ),
  //   exact: true,
  //   isMenu: true,
  //   // path: '/',
  //   role: ["admin", "customer"],
  //   // main: () => <DashBoard />,
  // },
  // {
  //   name: "Composite Reports & Advanced Search",
  //   icon: () => (
  //     <img
  //       className="img_tilte"
  //       src="/images/composite_report.png"
  //       align="top"
  //       alt=""
  //     />
  //   ),
  //   exact: true,
  //   isMenu: true,
  //   // path: '/',
  //   role: ["admin", "customer"],
  //   // main: () => <DashBoard />,
  // },
  // {
  //   name: "Trainings",
  //   icon: () => (
  //     <img
  //       className="img_tilte"
  //       src="/images/training_management.png"
  //       align="top"
  //       alt=""
  //     />
  //   ),
  //   exact: true,
  //   isMenu: true,
  //   // path: '/',
  //   role: ["admin", "customer"],
  //   // main: () => <DashBoard />,
  // },
  // {
  //   name: "MENU.role",
  //   icon: () => (
  //     <img
  //       className="img_tilte"
  //       src="/images/training_management.png"
  //       align="top"
  //       alt=""
  //     />
  //   ),
  //   exact: true,
  //   isMenu: true,
  //   path: "/roles",
  //   role: ["NT_Admin"],
  //   main: () => <Role />,
  // },
  //
  // {
  //   name: "Pages",
  //   icon: () => <SnippetsOutlined />,
  //   role: ["admin"],
  //   isMenu: true,
  //   exact: true,
  //   children: [
  //     {
  //       name: "BlankPage",
  //       path: "/blank-page",
  //       isMenu: true,
  //       exact: true,
  //       main: () => <BlankPage />,
  //     },
  //     {
  //       name: "404",
  //       path: "/404",
  //       isMenu: true,
  //       exact: true,
  //       main: () => <NotFountPage />,
  //     },
  //     {
  //       name: "Authentication",
  //       path: "/authentication",
  //       isMenu: true,
  //       exact: true,
  //       main: () => <NotAuthorizePage />,
  //     },
  //   ],
  // },
  {
    name: "Test api",
    icon: () => (
      <img
        className="img_tilte"
        src="/images/inventory_management.png"
        align="top"
        alt=""
      />
    ),
    exact: true,
    isMenu: false,
    path: "/api",
    main: () => <TestApi />,
  },
  {
    path: "",
    exact: true,
    isMenu: false,
    main: () => <NotFountPage />,
  },
];

export default routes;
