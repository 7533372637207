import React from 'react';

import './styles.scss';

const Loading = () => {
    return (
        <div className="lds-facebook">
            <div />
            <div />
            <div />
        </div>
    )
}

export default Loading;