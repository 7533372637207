import {TOKEN_NAME} from "../../constants";
import axiosService from "../../services/axiosService";
import {CONFIG} from "../../configs";


export const getListAppApi = () => {
  const token = localStorage.getItem(TOKEN_NAME)
  return axiosService.get(`${CONFIG.API_URL.AUTH}/api/permissions/app_list`, '', {
    Authorization: `Bearer ${token}`,
  })
};

export const getListGroupApi = (appCode) => {
  const token = localStorage.getItem(TOKEN_NAME)
  return axiosService.get(`${CONFIG.API_URL.AUTH}/api/permissions/app_group_permission?appCode=${appCode}`, '', {
    Authorization: `Bearer ${token}`,
  })
};

export const getAllAppPermission = () => {
  const token = localStorage.getItem(TOKEN_NAME)
  return axiosService.get(`${CONFIG.API_URL.AUTH}/api/permissions/all_apps_permission`, '', {
    Authorization: `Bearer ${token}`,
  })
}


export const setPermissionApi = (data) => {
  const token = localStorage.getItem(TOKEN_NAME)
  return axiosService.post(`${CONFIG.API_URL.AUTH}/api/permissions/update_permission_group`, data, {
    Authorization: `Bearer ${token}`,
  })
}

export const createGroup = (data) => {
  const token = localStorage.getItem(TOKEN_NAME)
  return axiosService.post(`${CONFIG.API_URL.AUTH}/api/permissions/create_group`, data, {
    Authorization: `Bearer ${token}`,
  })
}

export const deleteGroup = (id) => {
  const token = localStorage.getItem(TOKEN_NAME)
  return axiosService.get(`${CONFIG.API_URL.AUTH}/api/permissions/delete_group?idGroup=${id}`, '', {
    Authorization: `Bearer ${token}`,
  })
}

