import React from "react";
import { Row } from "antd";
import FormSection from "src/components/FormSection";
import { useTranslation } from "react-i18next";
import { FieldsRenderComponent } from "./filedRender";

const SectionRender = (props) => {
  const { item } = props;
  const data = Object.entries(item.attribute);
  const { t } = useTranslation();
  return item.display ? (
    <FormSection header={t(`ANALYTE.FORM.${item.sectionName}`)}>
      <Row gutter={[32, 8]}>
        {data.map((item, idx) => {
          if (props?.hiddenInputs?.includes(item[0])) return;
          return (
            <FieldsRenderComponent
              key={idx}
              item={item}
              onUpload={props.onUpload}
            />
          );
        })}
      </Row>
    </FormSection>
  ) : null;
};

export default SectionRender;
