import React, { useState } from "react";
import { Table, Button } from "antd";
import { Checkbox } from "antd";
import styled from "styled-components";
const RoleDetailWrapper = styled.div`
.ant-table-thead > tr > th{
    border: 1px solid #f0f0f0;
`;
const RoleDetail = () => {
  const columns = [
    {
      title: "Roles",
      width: 200,
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Permissions",
      children: [
        {
          title: "Create request",
          dataIndex: "create_question",
          key: "create_question",
          width: 150,
          editable: true,
          render: (text, record, index) => {
            return (
              <div className="tw-text-center">
                <Checkbox
                  checked={text}
                  onChange={() => changeCheck(text, "create_question", index)}
                />
              </div>
            );
          },
        },
        {
          title: "Approve",
          dataIndex: "approve",
          key: "approve",
          width: 150,
          render: (text, record, index) => {
            return (
              <div className="tw-text-center">
                <Checkbox
                  checked={text}
                  onChange={() => changeCheck(text, "approve", index)}
                />
              </div>
            );
          },
        },
        {
          title: "Issue",
          dataIndex: "issue",
          key: "issue",
          width: 150,
          render: (text, record, index) => {
            return (
              <div className="tw-text-center">
                <Checkbox
                  checked={text}
                  onChange={() => changeCheck(text, "issue", index)}
                />
              </div>
            );
          },
        },
        {
          title: "Print",
          dataIndex: "print",
          key: "print",
          width: 150,
          render: (text, record, index) => {
            return (
              <div className="tw-text-center">
                <Checkbox
                  checked={text}
                  onChange={() => changeCheck(text, "print", index)}
                />
              </div>
            );
          },
        },
        {
          title: "Report",
          dataIndex: "report",
          key: "report",
          width: 150,
          render: (text, record, index) => {
            return (
              <div className="tw-text-center">
                <Checkbox
                  checked={text}
                  onChange={() => changeCheck(text, "report", index)}
                />
              </div>
            );
          },
        },
        {
          title: "Create database",
          dataIndex: "create_database",
          key: "create_database",
          width: 150,
          render: (text, record, index) => {
            return (
              <div className="tw-text-center">
                <Checkbox
                  checked={text}
                  onChange={() => changeCheck(text, "create_database", index)}
                />
              </div>
            );
          },
        },
        {
          title: "Manager user",
          dataIndex: "manager_user",
          key: "manager_user",
          width: 150,
          render: (text, record, index) => {
            return (
              <div className="tw-text-center">
                <Checkbox
                  checked={text}
                  onChange={() => changeCheck(text, "manager_user", index)}
                />
              </div>
            );
          },
        },
        {
          title: "Audit trail",
          dataIndex: "audit_trail",
          key: "audit_trail",
          width: 150,
          render: (text, record, index) => {
            return (
              <div className="tw-text-center">
                <Checkbox
                  checked={text}
                  onChange={() => changeCheck(text, "audit_trail", index)}
                />
              </div>
            );
          },
        },
      ],
    },
  ];
  const data = [
    {
      role: "Admin",
      create_question: true,
      approve: true,
      issue: true,
      print: true,
      report: true,
      create_database: true,
      manager_user: true,
      audit_trail: true,
      id: 1
    },

    {
      role: "Approver",
      create_question: false,
      approve: true,
      issue: true,
      print: true,
      report: true,
      create_database: true,
      manager_user: false,
      audit_trail: true,
      id: 2
    },
    {
      role: "Staff",
      create_question: true,
      approve: false,
      issue: false,
      print: true,
      report: true,
      create_database: true,
      manager_user: false,
      audit_trail: true,
      id: 3
    },
    {
      role: "Reviewer",
      create_question: true,
      approve: false,
      issue: false,
      print: false,
      report: false,
      create_database: false,
      manager_user: false,
      audit_trail: false,
      id: 4
    },
  ];
  const [dataCus, setDataCus] = useState(data);
  const changeCheck = (check, name, index) => {
    const data = [...dataCus];
    data[index][name] = !check;
    setDataCus(data);
  };
  return (
    <RoleDetailWrapper>
      <div className="tw-overflow-auto">
        <Table pagination={false} columns={columns} dataSource={dataCus} rowKey={'id'} />
      </div>
      <div className="tw-text-center tw-pt-2">
        <Button type="primary">Save</Button>
      </div>
    </RoleDetailWrapper>
  );
};
export default RoleDetail;
