import React, { memo, useState, useEffect } from 'react'
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import styles from './Map.module.css'
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map = ({ locations = [] }) => {
  // const initViewPort = useInitViewPort()
  const [viewport, setViewPort] = useState({})
  const [markerInfo, setMarkerInfo] = useState(null)

  useEffect(() => {
    setViewPort(locations[0] || {});
  }, [locations])

  const onMarkerClick = (location) => setMarkerInfo(location)

  const renderMaker = () => {
    return locations.map((location, index) => {
      const { latitude, longitude } = location;
      return (
        <Marker
          latitude={latitude}
          longitude={longitude}
          key={index}
        >
          <div className="map-maker" onClick={() => onMarkerClick(location)}>
            <div className='pin'></div>
            <div className='pulse'></div>
            <span><img width="300%" src="http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_red.png" /></span>
          </div>
        </Marker>
      )
    })
  }
  // return ''

  const onItemClick = (spa_url) => {
    // return router.push(`/${spa_url}`)
  }

  const renderMap = () => {
    return (
      markerInfo && <Popup
        tipSize={5}
        anchor="bottom"
        longitude={markerInfo.longitude}
        latitude={markerInfo.latitude}
        onClose={() => onMarkerClick(null)}
        closeOnClick={false}
      >
        <div className={styles.popup} onClick={() => onItemClick(markerInfo.spa_url)}>

          <img width={240} src={markerInfo.img_cover} />
          <p className={styles.title}>{markerInfo.spa_name}</p>
          <p className={styles.address}>{markerInfo.full_address}</p>
          <div className="rating-product d-flex">
            <div className="mr-2"><i className="ion-android-star"></i></div>
            <p className="mr-2 font-weight-bold">{markerInfo.ranking_point}/5</p>
            <p className={`mr-2 ${styles.rating}`}>{markerInfo.ranking_count} rating</p>
          </div>

        </div>
      </Popup>
    )
  }

  return (
    <ReactMapGL
      onViewportChange={viewport => setViewPort({ ...viewport })}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxApiAccessToken="pk.eyJ1IjoiZGluaHRyb25nbGllbiIsImEiOiJja2Vqa3k4bG8wN3ZuMnN0NjVzM2Nrc3BvIn0.1t_F2GuWSYwPa15XMUe_pQ"
      height='50vh'
      width='100%'
      {...viewport}
    >
      {renderMaker()}
      {renderMap()}
    </ReactMapGL>
  )
}
export default memo(Map)
