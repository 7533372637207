import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Col, DatePicker, Form, Input, Modal, Row, Select, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { apiAddAnalyteFlag, getAnalyteFlagById } from "../../../apis/analyte";

const SpecifyFlaggingSet = ({
  configData,
  actionRef,
  afterSubmit,
  handleSave,
  dataTestID,
  ...props
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("ANALYTE.FORM.flaggingSetName"),
      width: 200,
      dataIndex: "generalInformation.testID",
    },
    {
      title: t("ANALYTE.FORM.minimum"),
      width: 200,
      dataIndex: "generalInformation.testName",
    },
    {
      title: t("ANALYTE.FORM.maximum"),
      width: 200,
      dataIndex: "generalInformation.testName",
    },
    {
      title: t("ANALYTE.FORM.criticalLow"),
      width: 200,
      dataIndex: "generalInformation.testName",
    },
    {
      title: t("ANALYTE.FORM.criticalHigh"),
      width: 200,
      dataIndex: "generalInformation.testName",
    },
  ];
  const [visible, setVisible] = useState(false);
  const [form, setForm] = useState({})
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  // console.log(`dataTestID`, dataTestID)

  const handleOk = async () => {
    /*
    "analytesUUID": "c49d91c2-d23e-4c55-832b-70b67634fa21",
    "criticalHigh": 1110,
    "criticalLow": 1110,
    "dayTurnaround": 1110,
    "flaggingName": "flag tét",
    "id": null,
    "maximum": 0,
    "minimum": 0,
    "timeTurnaround": 0
  */
    const { data } = await apiAddAnalyteFlag({ ...form, analytesUUID: id })
    handleSave && handleSave();
    setForm({})
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setVisible(false);
    // form.resetFields();
    setForm({})
  };
  const handleOpen = async (analytesFlagId, id, analytesName, dataSelected) => {
    console.log('dataSelected', dataSelected);
    setVisible(true);
    setIsEdit(!!analytesFlagId)
    setId(id);
    setName(analytesName);
    if (analytesFlagId) {
      const { data } = await getAnalyteFlagById(analytesFlagId);
      setForm(data)
    }
    setForm({
      ...dataSelected[0]
    })
  };

  console.log(`form`, form)

  const onChange = (e) => {
    setForm(form => { return { ...form, [e.target.name]: e.target.value } })
  };

  const onChangeNumber = (e, key) => {
    setForm(form => { return { ...form, [key]: e } })
  };
  useEffect(() => {
    const userAction = {
      close: handleClose,
      openSpecifyFlaggingModal: handleOpen,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    // eslint-disable-next-line
  }, []);
  const toolbarExtra = () => {
    return (
      <Row className='ant-form-item'>
        <Col span={6}>
          <Row>
            <Col span={8}>{t(`ANALYTE.FORM.analyteName`)}</Col>
            <Col span={12}><Input disabled value={name} /></Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col span={8}>{t(`TEST.FORM.testType`)}</Col>
            <Col span={12}><Input disabled value={t(dataTestID.type)} /></Col>
          </Row>
        </Col>
      </Row>
    );
  };
  return (
    <div>
      <Modal
        width={"100vw"}
        style={{ top: 20 }}
        title={t(`ANALYTE.FORM.flaggingSetInformation`)}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        forceRender
      >
        {/* <Datatable
          columns={columns}
          dataSource={data}
          rowKey={"generalInformation.testID"}
          autoFlatData={true}
          allowSelect={true}
          actionConfig={{}}
          toolbarExtra={toolbarExtra()}
        /> */}
        {toolbarExtra()}
        <Row>
          <Col span={6} className='ant-form-item'>
            <Row>
              <Col span={8}>Tên cờ</Col>
              <Col span={12}><Input onChange={onChange} value={form?.["flaggingName"]} name="flaggingName" /></Col>
            </Row>
          </Col>
          <Col span={6} className='ant-form-item'>
            <Row>
              <Col span={8}>Giá trị nhỏ nhất</Col>
              <Col span={12}><InputNumber step={0.1} onChange={(v) => onChangeNumber(v, "minimum")} value={form?.["minimum"]} name="minimum" /></Col>
            </Row>
          </Col>
          <Col span={6} className='ant-form-item'>
            <Row>
              <Col span={8}>Giá trị lớn nhất</Col>
              <Col span={12}><InputNumber step={0.1} onChange={(v) => onChangeNumber(v, "maximum")} value={form?.["maximum"]} name="maximum" /></Col>
            </Row>
          </Col>
          <Col span={6} className='ant-form-item'>
            <Row>
              <Col span={8}>{t("ANALYTE.FORM.criticalLow")}</Col>
              <Col span={12}><InputNumber step={0.1} onChange={(v) => onChangeNumber(v, "criticalLow")} value={form?.["criticalLow"]} name="criticalLow" /></Col>
            </Row>
          </Col>
          <Col span={6} className='ant-form-item'>
            <Row>
              <Col span={8}>{t("ANALYTE.FORM.criticalHigh")}</Col>
              <Col span={12}><InputNumber step={0.1} onChange={(v) => onChangeNumber(v, "criticalHigh")} value={form?.["criticalHigh"]} name="criticalHigh" /></Col>
            </Row>
          </Col>
          <Col span={6} className='ant-form-item'>
            <Row>
              <Col span={8}>Thời gian hoàn thành (giờ)</Col>
              <Col span={12}><InputNumber step={0.1} onChange={(v) => onChangeNumber(v, "timeTurnaround")} value={form?.["timeTurnaround"]} name="timeTurnaround" /></Col>
            </Row>
          </Col>
          <Col span={6} className='ant-form-item'>
            <Row>
              <Col span={8}>Số ngày hoàn thành (ngày)</Col>
              <Col span={12}><InputNumber step={0.1} onChange={(v) => onChangeNumber(v, "dayTurnaround")}  name="dayTurnaround" /></Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

SpecifyFlaggingSet.propTypes = {
  actionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterSubmit: PropTypes.func,
};

export default SpecifyFlaggingSet;
