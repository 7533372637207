import { createSlice } from '@reduxjs/toolkit';

export const MODULE_NAME = 'test';

const initialState = {
    testData: [],
    error: '',
    loading: false,
    submitting: false,
};
const slice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        getList: (state, action) => {
            state.error = '';
            state.loading = true;
        },
        getListSuccess: (state, action) => {
            state.testData = action.payload
            state.loading = false
            state.error = ''
        },
        getListFailure: (state, action) => {
            state.messageErr = action.payload
            state.loading = false
        },
        doAddTest: (state, action) => {
            state.submitting = true
        },
        doAddTestSuccess: (state, action) => {
            state.submitting = false
            state.testData = [action.payload, ...state.testData]
        },
        doDeleteTest: (state, action) => {
            state.loading = true
        },
        doDeleteTestSuccess: (state, action) => {
            state.loading = false
            state.testData = state.testData.filter(test => !action.payload.includes(test.generalInformation.testID))
        },
        doAllDone: (state, action) => {
            state.loading = false
            state.submitting = false
        },
        doEditTest: (state, action) => {
            state.submitting = true
        },
        doEditTestSuccess: (state, action) => {
            state.submitting = false
            state.testData = state.testData.map(test => {
                if (test.generalInformation.testID !== action.payload.generalInformation.testID) return test
                return action.payload
            })
        },

    },
});

export const {
    getList,
    getListSuccess,
    getListFailure,
    doAddTest,
    doAddTestSuccess,
    doDeleteTest,
    doDeleteTestSuccess,
    doAllDone,
    doEditTest,
    doEditTestSuccess
} = slice.actions;

export default slice.reducer;
