import { Col, Form, Input } from "antd";
import React from "react";
import { UploadStyled } from "../constants/styledComponent.constant";
import UploadSingle from "../components/Upload/UploadSingle";

export const FieldsRender = ({ type, label, name, key, disabled }) => {
  if (type === "UPLOAD") {
    return (
      <Col span={12}>
        <UploadStyled>
          <UploadSingle fileList={[]} disabled={disabled} />
        </UploadStyled>
      </Col>
    );
  }
  return (
    <Col span={12} key={key}>
      <Form.Item label={label} name={name} labelAlign={"left"}>
        <Input disabled={disabled} />
      </Form.Item>
    </Col>
  );
};
