import {MODULE_NAME} from './slice';

export const selectClients = (state) => ({
    loading: state[MODULE_NAME].loading,
    error: state[MODULE_NAME].error,
    data: state[MODULE_NAME].clients,
  });

export const formStatus = (state) => ({
  submitting: state[MODULE_NAME].submitting
})
