import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, message, Modal, Radio, Row, Select } from "antd";
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';


// chưa xong
const SelectCustom = ({
  label = "",
  onChange,
  options = [],
  handleOk,
  value,
  span = 12,
}) => {
  const { t } = useTranslation();
  const [isCreate, setIsCreate] = useState(false);
  const [name, setName] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (isCreate && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCreate])

  return (
    <Col span={span}>
      <Row>
        <Col span={8}>{label}</Col>
        <Col span="12">
          {
            !isCreate ?
              <Select value={value} onChange={(e) => onChange && onChange(e)} >
                {
                  options.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)
                }
              </Select> :
              <Input
                ref={inputRef}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyPress={(e => {
                  if (e.key === 'Enter') {
                    //TODO
                    setIsCreate(false);
                  }
                })}
              />
          }
        </Col>
        <Col span="4" style={{ paddingLeft: "0.5rem" }}>
          {!isCreate ?
            <PlusOutlined style={{ cursor: "pointer", paddingTop: "0.5rem", color: "#1890ff" }} color="primary" onClick={() => {
              setIsCreate(true);
            }} /> :
            <Button type="primary" onClick={() => {
              //TODO
              handleOk && handleOk(name);
              setIsCreate(false);
            }}>Lưu</Button>
          }
        </Col>
      </Row>
    </Col>
  )
}

export default SelectCustom;