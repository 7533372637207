import { sleep } from "../../utils/utils";
import { CONFIG } from "../../configs";
import axiosService from "../../services/axiosService";

/**
 * Login Function
 * @param {model} include:
 * @param username
 * @param password
 */
export const getListCart = () => {
  return axiosService.get(`${CONFIG.API_URL.BASE}/api/carts`);
};
export const apiAddCart = async (data) => {
  return axiosService.post(
    `${CONFIG.API_URL.BASE}/api/carts`,
    data
  );
};

export const apiDeleteCart = async (data) => {
  return axiosService.delete(`${CONFIG.API_URL.BASE}/api/carts/${data}`);
};

export const apiEditCart = async (data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/carts`, data);
};

export const addToCart = async (data) => {
  return axiosService.put(`${CONFIG.API_URL.BASE}/api/carts/add-to-cart`, data);
};

