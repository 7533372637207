import React, { useState, useEffect } from "react";
import { Modal, Button, Divider, Row, Col, Upload } from "antd";
import { useTranslation } from "react-i18next";

const ModalShowErr = (props) => {
  const { t } = useTranslation();
  const renderErr = (err) => {
    switch (err) {
      case "UploadError_001":
        return t("MODAL.err_1");
      case "UploadError_002":
        return t("MODAL.err_2");
      case "UploadError_003":
        return t("MODAL.err_3");
      case "UploadError_004":
        return t("MODAL.err_4");

      default:
        return null;
    }
  };
  return (
    <Modal
      onCancel={props.onCloseModalErr}
      footer={[
        <Button key="back" onClick={props.onCloseModalErr}>
          OK
        </Button>,
      ]}
      title={t("ACTIONS.upload_fail")}
      visible={props.isError}
    >
      <>
        {props.listErr.map((item, index) => (
          <Row>
            <Col span={24}>
              <p className="tw-m-0" key={index}>
                {" "}
                &#10146;&nbsp;
                {t("ACTIONS.messErr", {
                  line: item.line,
                  column: item.column,
                })}
              </p>
            </Col>
            <Col className="tw-pl-6" span={24}>
              {t("MODAL.detail")} {renderErr(item.errorType)}
            </Col>
          </Row>
        ))}
      </>
    </Modal>
  );
};
export default ModalShowErr;
